import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Grid,
  withWidth,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Dialog,
  Tooltip,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Dropzone from 'react-dropzone';
import publicConfig from 'artsteps2-config/public.json';

import { __ } from 'artsteps2-common';
import { API_STATUS, getApiResource, getApiStatus, getUIProperty } from '../../../reducers';
import { compose, withDispatch, withState } from '../../../enhancers';
import {
  apiPATCH,
  apiGET,
  setFormData,
  addMessage,
  clearMessages,
  setUIProperty,
} from '../../../actions';
import utils from '../../../utils';
import countryCodes from './countries.json';
import { StyledButton, StyledTextField, Text } from '../../../styles/GenericStyled';
import logo from '../../../styles/images/logo-artsteps.png';

const IMAGE_WIDTH = publicConfig.user.profile.image.imageWidth;
const IMAGE_HEIGHT = publicConfig.user.profile.image.imageHeight;
const IMAGE_QUALITY = publicConfig.user.profile.image.quality;

const FALLBACK_FILE_SIZE_LIMIT = publicConfig.uploader.fallBackUFileSizeLimit;
const fileSizeLimits = publicConfig.uploader.fileSizeLimit;

const useStyles = makeStyles({
  rootDiv: {
    margin: 'auto',
    padding: '20px',
    width: '60%',
    marginTop: '10px',
  },
  rootDivMobile: {
    marginTop: '10px',
    margin: 'auto',
    padding: '0',
    width: '90%',
  },
  grid: {
    flexGrow: 1,
  },
  input: {
    color: 'rgba(52, 58, 64, 0.5)',
    font: 'normal normal bold 18px/23px Muli',
    border: '2px solid #f3f3f3',
    height: '54px',
    padding: '0',
  },
  inputLabel: {
    font: 'normal normal bold 18px/3px Muli',
    color: '#343A40',
    paddingBottom: '15px',
  },
  avatar: {
    width: '161px',
    height: '161px',
    position: 'absolute',
    filter: 'grayscale(80%)',
  },
  photoIcon: {
    width: '52px',
    height: '52px',
    position: 'relative',
    zIndex: '4',
    color: 'white',
  },
  imageDiv: {
    paddingTop: '60px',
    paddingBottom: '60px',
    paddingLeft: '30px',
  },
  privateSpaceButton: {
    width: '100%',
    height: '74px',
  },
  dialogPaperPS: {
    minHeight: '200px',
    maxHeight: '200x',
    minWidth: '526px',
    maxWidth: '526px',
  },
  dialogPaperIM: {
    minHeight: '630px',
    maxHeight: '630px',
    minWidth: '526px',
    maxWidth: '526px',
  },
  dropzone: {
    width: '463px',
    height: '443px',
    margin: '0 auto',
    border: '6px solid #12AD8F',
  },
  dropzoneImage: {
    width: '451px',
    height: '431px',
  },
});

const useDisableUnderline = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

// const userFields = {
//   profile: {
//     name: '',
//     birthday: '',
//     gender: '',
//     occupation: '',
//     website: '',
//     bio: '',
//     country: '',
//     city: '',
//     image: '',
//   },
//   subscribe: '',
//   lastLogin: '',
//   emails: [
//     {
//       address: '',
//       verified: '',
//     },
//   ],
//   inactive: '',
//   services: {
//     password: '',
//     google: '',
//   },
//   roles: '',
//   spaces: [],
// };

export const ProfileEditorView = ({
  isPrivateSpace,
  onUpdateUser = () => Promise.resolve({ response: { error: 'error' } }),
  onAddMessage = () => Promise.resolve(false),
  onFetchUser = () => Promise.resolve(false),
  onAddMessageConnectToSpace = () => Promise.resolve(false),
  onSetConnectToSpaceForm = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onClearMessagesConnectToSpace = () => Promise.resolve(false),
  onStopEditing = () => Promise.resolve(false),
  width,
  resetStateOnCancel = false,
  isAdminEditing = false,
  ready = false,
}) => {
  const classes = useStyles();
  const underline = useDisableUnderline();
  const [data, setData] = React.useState(null);
  const [dataCopy, setDataCopy] = React.useState(null);
  const [uploadPicOpen, setUploadPicOpen] = React.useState(false);
  const [registrationCodeProfile, setRegistrationCodeProfile] = React.useState('');
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);
  const [update, setUpdate] = React.useState(true);
  React.useEffect(() => {
    onFetchUser().then(({ response }) => {
      if (data === null) {
        setData({ ...response });
        setDataCopy({ ...response });
        setDisableSaveButton(true);
      }
    });
  }, [update]);

  const handleFieldChange = event => {
    const { name, value, checked } = event.target;
    let subField;
    setDisableSaveButton(false);
    if (name.includes('profile')) {
      subField = name.split('.')[1];
      data.profile[subField] = value;
      setData({ ...data, profile: { ...data.profile } });
    } else setData({ ...data, [name]: value !== '' ? value : checked });

    // else if (name.includes('emails') && !name.includes('google')) {
    //   index = name.split('-')[1];
    //   subField = name.split('.')[1].split('-')[0];
    //   data.emails[index][subField] = value !== '' ? value : checked;
    //   setData({ ...data, emails: [...data.emails] });
    // } else if (name.includes('services')) {
    //   if (name.includes('google')) {
    //     index = name.split('.')[3];
    //     subField = name.split('.')[2];
    //     data.services.google.emails[index][subField] = value !== '' ? value : checked;
    //   } else {
    //     data.services.password.bcrypt = value;
    //   }
    //   setData({ ...data, services: { ...data.services } });
    // } else if (name.includes('roles')) {
    //   index = name.split('-')[1];
    //   data.roles.__global_roles__[index] = value;
    //   setData({ ...data, roles: { __global_roles__: [...data.roles.__global_roles__] } });
    // }
  };

  const handleRegistrationCodeChange = e => {
    setRegistrationCodeProfile(e.target.value);
  };

  const onSubmit = () => {
    if (!data.profile.name) {
      return onAddMessage({
        type: 'error',
        title: __('the_name_field_is_empty'),
      });
    }

    return onUpdateUser({ ...data }).then(({ response }) => {
      if (response.error) {
        return onAddMessage({ type: 'error', title: __(response.error) });
      }

      onClearMessages();
      onAddMessage({ type: 'success', title: __('update_profile_success') });
      return onStopEditing();
    });
  };

  // for private spaces
  const onConnectFormSubmit = () => {
    if (!registrationCodeProfile) {
      onAddMessageConnectToSpace({
        type: 'error',
        title: 'please fill in registration code',
      });
      return;
    }

    // eslint-disable-next-line consistent-return
    return onUpdateUser({
      registrationCode: registrationCodeProfile,
    }).then(({ response }) => {
      if (response.error) {
        setRegistrationCodeProfile('');
        return onAddMessageConnectToSpace({ type: 'error', title: response.error });
      }
      setData(null);
      onClearMessagesConnectToSpace();
      onSetConnectToSpaceForm({ registrationCodeProfile: '' });
      setRegistrationCodeProfile('');
      setUpdate(!update);
      return onAddMessageConnectToSpace({
        type: 'success',
        title: 'Successfully connected to space!',
      });
    });
  };

  // const getImageSrc = ({ uri, bin = {} }, index) => uri || bin.preview;

  const parseDataUri = (uri, filename, previewURI) =>
    utils.image
      .toDataURL({
        uri,
        height: IMAGE_HEIGHT,
        width: IMAGE_WIDTH,
        quality: IMAGE_QUALITY,
      })
      .then(dataUri => Promise.resolve(utils.buffer.fromDataURL(dataUri, filename, previewURI)));

  const parseFile = (...args) =>
    parseDataUri(...args).then(bin => {
      if (bin.name.match(/\.obj$/)) {
        const content = bin.content
          .replace(/[\r\n]+[a-z]{1}\s*[\r\n]+/g, '\n')
          .replace(/[\r\n]+([a-z]{1}){1}\s\s+/g, '\n$1 ');
        URL.revokeObjectURL(bin.preview);
        const blob = new Blob([content], { type: bin.contentType });
        const previewURI = URL.createObjectURL(blob);
        return Promise.resolve({ ...bin, content, preview: previewURI });
      } else if (bin.name.match(/\.mtl$/)) {
        const content = bin.content
          .replace(/map_Ka ([a-z]+(\\\\|\/))+/gi, 'map_Ka ')
          .replace(/map_Kd ([a-z]+(\\\\|\/))+/gi, 'map_Kd ')
          .replace(/map_Bump ([a-z]+(\\\\|\/))+/gi, 'map_Bump ');
        URL.revokeObjectURL(bin.preview);
        const blob = new Blob([content], { type: bin.contentType });
        const previewURI = URL.createObjectURL(blob);
        return Promise.resolve({ ...bin, content, preview: previewURI });
      }
      return Promise.resolve(bin);
    });

  const onDropFiles = (files = []) =>
    Promise.all(
      files.map(
        file =>
          new Promise(resolve => {
            const sizeLimit = fileSizeLimits.image || FALLBACK_FILE_SIZE_LIMIT;
            if (file.size && file.size < sizeLimit) {
              const reader = new FileReader();
              reader.onloadend = () => resolve(parseFile(reader.result, file.name, file.preview));
              reader.readAsDataURL(file);
            } else {
              onAddMessage({
                title: __('upload_size_limit_exceeded', {
                  filename: file.name,
                  fileSize: (file.size / 1024).toFixed(2),
                  sizeLimit: (sizeLimit / 1024).toFixed(2),
                }),
                type: 'warning',
              });
            }
          }),
      ),
    ).then(binaries => {
      setDisableSaveButton(false);
      setData({
        ...data,
        profile: {
          ...data.profile,
          image: {
            bin: binaries[0],
            preview: binaries[0].preview,
          },
        },
      });
    });

  return (
    <div>
      {ready && data !== null && (
        <div
          className={
            width !== 'sm' && width !== 'md' && width !== 'xs'
              ? classes.rootDiv
              : classes.rootDivMobile
          }
        >
          {/* Edit Controls */}
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Text type={width === 'xs' ? 'h5' : 'h4'}>Edit Profile</Text>
            </Grid>
            <Grid item>
              <StyledButton
                background="#E6F7F4"
                mycolor="#00CA9F"
                style={{ marginLeft: 'auto', marginRight: '25px' }}
                onClick={() => onSubmit()}
                disabled={disableSaveButton}
              >
                Save Changes
                <CheckIcon style={{ color: '#00CA9F' }} fontSize="large" />
              </StyledButton>
            </Grid>
          </Grid>

          <Grid
            container
            justify="center"
            alignItems="flex-start"
            spacing={4}
            className={classes.grid}
          >
            {/* User Image */}
            <Grid item md={6} xs={12}>
              <Tooltip title="Click to edit profile picture" placement="left" arrow>
                <div className={classes.imageDiv}>
                  <Button onClick={() => setUploadPicOpen(true)}>
                    <AddAPhotoIcon className={classes.photoIcon} />
                    <Avatar
                      alt={__('avatar')}
                      src={data.profile.image.preview}
                      className={classes.avatar}
                    />
                  </Button>
                </div>
              </Tooltip>
            </Grid>
            <Grid item md={6} xs={12} />
            {/* Profile */}
            <Grid item md={4} xs={12}>
              <StyledTextField
                fullWidth
                id="name"
                name="profile.name"
                label="Your name: "
                value={data.profile.name}
                onChange={handleFieldChange}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    font: 'normal normal bold 24px/3px Muli',
                    color: '#343A40',
                  },
                }}
                InputProps={{
                  classes: underline,
                  style: {
                    color: 'rgba(52, 58, 64, 0.5)',
                    font: 'normal normal bold 18px/23px Muli',
                    border: '2px solid #f3f3f3',
                    height: '58px',
                    paddingLeft: '20px',
                  },
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextField
                fullWidth
                placeholder="B"
                type="date"
                id="birthday"
                name="profile.birthday"
                label="Date of birth: "
                value={data.profile.birthday ? data.profile.birthday.split('T')[0] : ''}
                onChange={handleFieldChange}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    font: 'normal normal bold 24px/3px Muli',
                    color: '#343A40',
                  },
                }}
                InputProps={{
                  classes: underline,
                  style: {
                    color: 'rgba(52, 58, 64, 0.5)',
                    font: 'normal normal bold 18px/23px Muli',
                    border: '2px solid #f3f3f3',
                    height: '58px',
                    paddingLeft: '20px',
                  },
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextField
                fullWidth
                id="website"
                name="profile.website"
                label="Your website: "
                value={data.profile.website}
                onChange={handleFieldChange}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    font: 'normal normal bold 24px/3px Muli',
                    color: '#343A40',
                  },
                }}
                InputProps={{
                  classes: underline,
                  style: {
                    color: 'rgba(52, 58, 64, 0.5)',
                    font: 'normal normal bold 18px/23px Muli',
                    border: '2px solid #f3f3f3',
                    height: '58px',
                    paddingLeft: '20px',
                  },
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextField
                fullWidth
                id="city"
                name="profile.city"
                label="City: "
                value={data.profile.city}
                onChange={handleFieldChange}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    font: 'normal normal bold 24px/3px Muli',
                    color: '#343A40',
                  },
                }}
                InputProps={{
                  classes: underline,
                  style: {
                    color: 'rgba(52, 58, 64, 0.5)',
                    font: 'normal normal bold 18px/23px Muli',
                    border: '2px solid #f3f3f3',
                    height: '58px',
                    paddingLeft: '20px',
                  },
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputLabel id="country" className={classes.inputLabel}>
                Your Country:{' '}
              </InputLabel>
              <Select
                className={classes.input}
                fullWidth
                id="country"
                name="profile.country"
                label="Your Country: "
                value={data.profile.country}
                onChange={handleFieldChange}
                SelectDisplayProps={{
                  classes: underline,
                  style: {
                    color: 'rgba(52, 58, 64, 0.5)',
                    font: 'normal normal bold 18px/23px Muli',
                    paddingLeft: '20px',
                  },
                }}
              >
                {countryCodes.map((code, index) => (
                  <MenuItem key={index} value={code}>
                    {__(`regions.country_${code}`)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12} />
            <Grid item md={8} xs={12}>
              <StyledTextField
                fullWidth
                id="occupation"
                name="profile.occupation"
                label="Occupation/Business sector:"
                value={data.profile.occupation}
                onChange={handleFieldChange}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    font: 'normal normal bold 24px/3px Muli',
                    color: '#343A40',
                  },
                }}
                InputProps={{
                  classes: underline,
                  style: {
                    color: 'rgba(52, 58, 64, 0.5)',
                    font: 'normal normal bold 18px/23px Muli',
                    border: '2px solid #f3f3f3',
                    height: '58px',
                    paddingLeft: '20px',
                  },
                }}
              />
            </Grid>
            <Grid item md={4} xs={12} />
            <Grid item md={8} xs={12}>
              <StyledTextField
                multiline
                fullWidth
                rows={4}
                rowsMax={4}
                id="bio"
                name="profile.bio"
                label="Bio: "
                value={data.profile.bio}
                onChange={handleFieldChange}
                helperText={data.profile.bio ? data.profile.bio.length : 0}
                FormHelperTextProps={{
                  style: {
                    marginLeft: 'auto',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    font: 'normal normal bold 24px/3px Muli',
                    color: '#343A40',
                  },
                }}
                InputProps={{
                  classes: underline,
                  style: {
                    color: 'rgba(52, 58, 64, 0.5)',
                    font: 'normal normal bold 18px/23px Muli',
                    border: '2px solid #f3f3f3',
                    height: '131px',
                    padding: '10px',
                    overflowWrap: 'normal',
                    paddingLeft: '20px',
                  },
                }}
              />
            </Grid>
            {!isPrivateSpace ? (
              <Grid item md={4} xs={12}>
                <StyledTextField
                  fullWidth
                  type="text"
                  id="registrationCodeProfile"
                  name="registrationCodeProfile"
                  label="Private Spaces:"
                  placeholder="Enter invitation code"
                  value={registrationCodeProfile}
                  onChange={handleRegistrationCodeChange}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      font: 'normal normal bold 24px/3px Muli',
                      color: '#343A40',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {registrationCodeProfile.length > 0 && (
                          <IconButton onClick={onConnectFormSubmit}>
                            <CheckIcon style={{ color: '#030303' }} fontSize="large" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                    classes: underline,
                    style: {
                      color: 'rgba(52, 58, 64, 0.5)',
                      font: 'normal normal bold 18px/23px Muli',
                      border: '2px solid #f3f3f3',
                      height: '58px',
                      paddingLeft: '20px',
                    },
                  }}
                />
                {data.spaceDomains && data.spaceDomains.length !== 0 && (
                  <>
                    {data.spaceDomains.map((spaceDomain, index) => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                        href={`${window.location.protocol}//${
                          spaceDomain.subdomain
                        }.${window.location.hostname
                          .replace('www2', '')
                          .replace('www', '')
                          .replace('.', '')}`}
                      >
                        <StyledButton
                          fullWidth
                          background="rgba(243, 243, 243, 1)"
                          mycolor="black"
                          style={{ font: 'normal normal 18px/23px Muli', marginTop: '20px' }}
                        >
                          {`${spaceDomain.subdomain}.${window.location.hostname
                            .replace('www2', '')
                            .replace('www', '')
                            .replace('.', '')}`}
                        </StyledButton>
                      </a>
                    ))}
                  </>
                )}
              </Grid>
            ) : (
              <Grid item md={4} xs={12} />
            )}
            {/* {isAdminEditing && {}} */}
            {/* Emails */}
            {/* {isAdminEditing && (
              <>
                <Grid item md={4} xs={12}>
                  <h2 style={{ lineHeight: '0.5', marginBottom: '40px' }}>Emails</h2>

                  {data.emails.map((email, index) => (
                    <React.Fragment key={index}>
                      <StyledTextField
                        fullWidth
                        id={`address-${index + 1}`}
                        onChange={handleFieldChange}
                        name={`emails.address-${index}`}
                        value={email.address}
                        label={`Address-${index + 1}`}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            font: 'normal normal bold 24px/3px Muli',
                            color: '#343A40',
                          },
                        }}
                        InputProps={{
                          classes: underline,
                          style: {
                            color: 'rgba(52, 58, 64, 0.5)',
                            font: 'normal normal bold 18px/23px Muli',
                            border: '2px solid #f3f3f3',
                            height: '58px',
                            paddingLeft: '20px',
                          },
                        }}
                      />
                      <br />
                      <br />
                      <InputLabel id={`verified-${index}`} className={classes.inputLabel}>
                        Verified-{index + 1}
                      </InputLabel>
                      <Switch
                        id={`verified-${index}`}
                        checked={Boolean(email.verified)}
                        onChange={handleFieldChange}
                        color="primary"
                        name={`emails.verified-${index}`}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </React.Fragment>
                  ))}
                </Grid>
                {/* subscribe */}
            {/* <Grid item md={4} xs={12}>
                  <InputLabel htmlFor={`subscribe-select-label`} className={classes.inputLabel}>
                    Subscribe
                  </InputLabel>
                  <Switch
                    checked={data.subscribe}
                    onChange={handleFieldChange}
                    color="primary"
                    name={'subscribe'}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                {/* lastLogin */}
            {/* <Grid item md={4} xs={12}>
                  <StyledTextField
                    fullWidth
                    id="lastLogin"
                    name="lastLogin"
                    label="Last Login:"
                    value={data.lastLogin}
                    disabled={true}
                    onChange={handleFieldChange}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        font: 'normal normal bold 24px/3px Muli',
                        color: '#343A40',
                      },
                    }}
                    InputProps={{
                      classes: underline,
                      style: {
                        color: 'rgba(52, 58, 64, 0.5)',
                        font: 'normal normal bold 18px/23px Muli',
                        border: '2px solid #f3f3f3',
                        height: '58px',
                        paddingLeft: '20px',
                      },
                    }}
                  />
                </Grid> */}
            {/* Roles */}
            {/* <Grid item md={4} xs={12}>
                  {data.roles &&
                    data.roles.__global_roles__.map((role, index) => (
                      <StyledTextField
                        key={index}
                        fullWidth
                        id="role"
                        name={`roles-${index}`}
                        label={`Role-${index + 1}`}
                        value={role}
                        onChange={handleFieldChange}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            font: 'normal normal bold 24px/3px Muli',
                            color: '#343A40',
                          },
                        }}
                        InputProps={{
                          classes: underline,
                          style: {
                            color: 'rgba(52, 58, 64, 0.5)',
                            font: 'normal normal bold 18px/23px Muli',
                            border: '2px solid #f3f3f3',
                            height: '58px',
                            marginBottom: '15px',
                            paddingLeft: '20px',
                          },
                        }}
                      />
                    ))}
                </Grid> */}
            {/* Inactive */}
            {/* <Grid item md={4} xs={12}>
                  <InputLabel id={`inactive-select-label`} className={classes.inputLabel}>
                    Inactive
                  </InputLabel>
                  <Switch
                    checked={data.inactive}
                    onChange={handleFieldChange}
                    color="primary"
                    name={'inactive'}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid> */}
            {/* Services */}
            {/* <Grid item md={4} xs={12}>
                  <h2 style={{ lineHeight: '0.5', marginBottom: '40px' }}>Services</h2>
                  {Object.keys(userFields.services).map((f, index) => (
                    <Grid container spacing={3} key={index}>
                      {f === 'google' && (
                        <Grid item xs={12}>
                          <StyledTextField
                            fullWidth
                            key={index}
                            id={`google-id${index + 1}`}
                            name={`services.google.id-${index}`}
                            label="Google ID"
                            value={data.services.google ? data.services.google.id : 'None'}
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                font: 'normal normal bold 24px/3px Muli',
                                color: '#343A40',
                              },
                            }}
                            InputProps={{
                              classes: underline,
                              style: {
                                color: 'rgba(52, 58, 64, 0.5)',
                                font: 'normal normal bold 18px/23px Muli',
                                border: '2px solid #f3f3f3',
                                height: '58px',
                                paddingLeft: '20px',
                              },
                            }}
                          />
                          {data.services.google &&
                            data.services.google.emails.map((email, index) => (
                              <React.Fragment key={index}>
                                <StyledTextField
                                  fullWidth
                                  key={index}
                                  id={`google-${index + 1}`}
                                  name={`services.google.value.${index}`}
                                  onChange={handleFieldChange}
                                  label={`Google Address-${index + 1}`}
                                  value={email.value}
                                  disabled={true}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      font: 'normal normal bold 24px/3px Muli',
                                      color: '#343A40',
                                    },
                                  }}
                                  InputProps={{
                                    classes: underline,
                                    style: {
                                      color: 'rgba(52, 58, 64, 0.5)',
                                      font: 'normal normal bold 18px/23px Muli',
                                      border: '2px solid #f3f3f3',
                                      height: '58px',
                                      paddingLeft: '20px',
                                    },
                                  }}
                                />
                                <InputLabel htmlFor={`google-verified-${index}`}>
                                  Google Verified-{index + 1}
                                </InputLabel>
                                <Switch
                                  checked={email.verified}
                                  onChange={handleFieldChange}
                                  disabled={true}
                                  color="primary"
                                  name={`services.google.verified.${index}`}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </React.Fragment>
                            ))}
                        </Grid>
                      )}
                      {f === 'password' && index === 0 && (
                        <Grid item xs={12}>
                          <StyledTextField
                            fullWidth
                            id={`services-${f}`}
                            name={`services.${f}`}
                            onChange={handleFieldChange}
                            label={`Password`}
                            value={data.services[f] && data.services[f].bcrypt}
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                font: 'normal normal bold 24px/3px Muli',
                                color: '#343A40',
                              },
                            }}
                            InputProps={{
                              classes: underline,
                              style: {
                                color: 'rgba(52, 58, 64, 0.5)',
                                font: 'normal normal bold 18px/23px Muli',
                                border: '2px solid #f3f3f3',
                                height: '58px',
                                paddingLeft: '20px',
                              },
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid> */}
            {/* </> */}
          </Grid>
        </div>
      )}
      <Dialog
        onClose={() => setUploadPicOpen(false)}
        aria-labelledby="upload-Dialog"
        open={uploadPicOpen}
        classes={{ paper: classes.dialogPaperIM }}
      >
        <br />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ width: '90%', margin: '0 auto' }}
        >
          <Grid item>
            <Text type="h4">Edit Picture</Text>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setUploadPicOpen(false);
                setData({
                  ...data,
                  profile: {
                    ...data.profile,
                    image: dataCopy.profile.image,
                  },
                });
              }}
            >
              <CloseIcon fontSize="large" />
            </Button>
          </Grid>
        </Grid>
        <br />
        <Tooltip title="Click or drag picture" placement="left" arrow>
          <Dropzone
            id="drop"
            name="profile.image"
            onDrop={onDropFiles}
            multiple={false}
            accept="image/*"
            className={classes.dropzone}
          >
            {() => (
              <div>
                <img
                  alt={__('avatar')}
                  src={data.profile.image.preview === undefined ? logo : data.profile.image.preview}
                  className={classes.dropzoneImage}
                />
              </div>
            )}
          </Dropzone>
        </Tooltip>
        <br />
        <StyledButton
          background="rgba(243, 243, 243, 1)"
          mycolor="black"
          style={{ marginLeft: 'auto', marginRight: '25px' }}
          onClick={() => {
            setDisableSaveButton(false);
            setUploadPicOpen(false);
          }}
        >
          OK
        </StyledButton>
      </Dialog>
    </div>
  );
};

const mapState = (state, { userId, isAdminEditing }) => ({
  ready: getApiStatus(state, `users/${userId}`) === API_STATUS.IDLE,
  user: getApiResource(state, `users/${userId}`),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
});

const filter = {
  populate: 'spaces',
  include: [
    'username',
    'emails',
    'profile.country',
    'profile.city',
    'profile.gender',
    'profile.birthday',
    'profile.occupation',
    'profile.bio',
    'profile.website',
    'spaces',
    'roles',
    'services',
    'subscribe',
    'lastLogin',
    'inactive',
  ],
};

const mapDispatch = (dispatch, { userId, isAdminEditing }) => ({
  onFetchUser: () => dispatch(apiGET(`users/${userId}`, isAdminEditing ? filter : {})),
  onSetForm: data => dispatch(setFormData(`profile:${userId}`, data)),
  onSetConnectToSpaceForm: data => dispatch(setFormData('connectToSpace', data)),
  onUpdateUser: data => dispatch(apiPATCH(`users/${userId}`, data)),
  onAddMessage: message => dispatch(addMessage(message, 'profile')),
  onAddMessageConnectToSpace: message => dispatch(addMessage(message, 'connectToSpace')),
  onClearMessagesConnectToSpace: () => dispatch(clearMessages('connectToSpace')),
  onClearMessages: () => dispatch(clearMessages('profile')),
  onStopEditing: () => dispatch(setUIProperty(`profiles/${userId}/editing`, false)),
});

const ProfileEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withWidth(),
)(ProfileEditorView);

export default ProfileEditor;

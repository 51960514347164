import React from 'react';
// import { __ } from 'artsteps2-common';
import Grid from '@material-ui/core/Grid';
import InputField from '../../generic/forms/InputField';
import TagsInput from '../fields/TagsInput';
import InputSelect from '../../generic/forms/InputSelect';
import Dimensions from '../fields/Dimensions';
import { VerticalItems } from './FormStyled';
import InteractiveField from '../fields/InteractiveField';

const VideoEditor = ({ formData, licenses, handleChange }) => (
  <Grid item xs={12} md={6}>
    <VerticalItems>
      <InputField
        handleChange={handleChange}
        value={formData.title}
        name="title"
        label="Title"
        required
      />
      <InputField
        handleChange={handleChange}
        value={formData.caption}
        name="caption"
        textArea
        label={
          <>
            Description{' '}
            <small style={{ fontSize: '12px', color: 'red' }}>
              (Use hyperlinks by acquiring a premium 3D space template)
            </small>
          </>
        }
      />
      <InteractiveField
        formData={formData}
        handleChange={handleChange}
        checked={formData.model && formData.model.nonInteractive}
      />
      <Dimensions
        handleChange={handleChange}
        formData={formData}
        dimensions={['height', 'width']}
      />
      <TagsInput
        tags={formData.tags}
        handleChange={handleChange}
        label="Artifact Tags"
        hint="Categorize your artifacts"
      />
      <InputSelect
        value={formData.license}
        handleChange={handleChange}
        label="Licenses"
        hint="Artifact License"
        name="license"
        defaultValue={licenses && licenses.length > 0 && licenses[0]._id}
        options={licenses}
      />
    </VerticalItems>
  </Grid>
);

export default VideoEditor;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { __ } from 'artsteps2-common';

import MetaTags from '../components/layouts/MetaTags';
import FullLayout from '../components/layouts/FullLayout';

import { setLocation, apiPOST, authLogout } from '../actions';
import { compose, withDispatch, withState, withLocalState, withLifecycle } from '../enhancers';
import { getApiResource } from '../reducers';

const EmailVerificationView = ({
  onRedirect = () => Promise.resolve(false),
  verificationReqStatus = 'pending',
}) => {
  return (
    <FullLayout>
      <MetaTags title={__('sign_in')} description={__('welcome_header')} />
      <div className="login-form">
        <div className="ui two column stackable grid">
          <div className="stretched column cover-column">
            <div className="ui basic segment">
              <h1 className="ui header white-text">{__('welcome_header')}</h1>
              <h4 style={{ whiteSpace: 'pre-line' }}>{__('welcome_subtitle')}</h4>
            </div>
          </div>
          <div className="column" style={{ display: 'block' }}>
            <NavLink to="/" className="logo" />
            <div className="ui horizontal divider">{__('Email Verification')}</div>
            <p className="welcome-message" style={{ whiteSpace: 'pre-line' }}>
              {verificationReqStatus === 'pending' && __('verification_pending')}
              {verificationReqStatus === 'success' && __('verification_successful')}
              {verificationReqStatus === 'already_verified' && __('already_verified')}
              {verificationReqStatus === 'error' && __('verification_failed')}
            </p>
            <div className="ui form">
              <button onClick={() => onRedirect('/')} className="ui teal button">
                {__('Go to Artsteps')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

const mapDispatch = (dispatch, { userId, verificationToken, setVerificationReqStatus }) => ({
  onRedirect: page => dispatch(setLocation(page)),
  sendVerificationRequest: () => {
    dispatch(apiPOST(`auth/artsteps/email-verification/${userId}/${verificationToken}`, {})).then(
      ({ response }) => {
        if (response.error) {
          return setVerificationReqStatus('error');
        }
        if (response.message === 'already verified') {
          return setVerificationReqStatus('already_verified');
        }
        //perform a logout for force refresh verified
        dispatch(authLogout());
        return setVerificationReqStatus('success');
      },
    );
  },
});

const mapState = (state, { userId }) => ({
  user: getApiResource(state, `users/${userId}`),
});

const lifecycleMap = {
  onDidMount: ({ sendVerificationRequest }) => sendVerificationRequest(),
};

const EmailVerificationPage = compose(
  withLocalState('verificationReqStatus', 'setVerificationReqStatus', 'pending'),
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(EmailVerificationView);

export default EmailVerificationPage;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fixRotationValue = exports.toRGB = exports.createEvents = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var createEvents = function createEvents(events, value, opts) {
  return events ? Object.keys(events).reduce(function (mapped, event) {
    return Object.assign(mapped, (0, _defineProperty2["default"])({}, event, function (e) {
      return events[event](e, value, opts);
    }));
  }, {}) : undefined;
};

exports.createEvents = createEvents;

var toRGB = function toRGB(colour) {
  if (!colour || colour[0] !== '#') {
    return colour;
  }

  return colour.length === 5 ? colour.substring(0, 4) : colour.substring(0, 7);
};

exports.toRGB = toRGB;

var fixRotationValue = function fixRotationValue(degrees) {
  return Math.round(degrees / 45) * 45;
};

exports.fixRotationValue = fixRotationValue;
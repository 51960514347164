import React from 'react';
import { Grid, Avatar } from '@material-ui/core';
import { Check, Notifications } from '@material-ui/icons';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastCss.css';

/*
To use it you need those imports:
import { ToastContainer } from 'react-toastify';
import toast from 'src/components/toast/toast';

Then use the toastContainer in your rendering JSX:
<ToastContainer />

and finally to use it:
    toast.success('Lorem ipsum dolor sit');
    toast.error('Lorem ipsum dolor sit');
    toast.warning('Lorem ipsum dolor sit');
    toast.update('Lorem ipsum dolor sit');

 */

const Content = ({ closeToast, msg, type, title }) => (
  <div className="toast-padding">
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={0}
      wrap="nowrap"
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          wrap="nowrap"
        >
          <Grid item>
            <Avatar className={`bell-wrapper-${type}`}>
              <Notifications className={`bell-${type}`} />
            </Avatar>
          </Grid>
          <Grid item>
            <span className={`toast-text-${type}`}>{title}</span>
            <br />
            <span className="toast-p">{msg}</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Check className="check-icon" onClick={closeToast} />
      </Grid>
    </Grid>
  </div>
);

export default {
  success(msg = '', title = 'SUCCESS') {
    return toast(<Content msg={msg} type="SUCCESS" title={title} />, {
      transition: Zoom,
      hideProgressBar: true,
      closeButton: false,
      position: 'bottom-right',
    });
  },
  error(msg = '', title = 'ERROR') {
    return toast(<Content msg={msg} type="ERROR" title={title} />, {
      transition: Zoom,
      hideProgressBar: true,
      closeButton: false,
      position: 'bottom-right',
    });
  },
  warning(msg = '', title = 'WARNING') {
    return toast(<Content msg={msg} type="WARNING" title={title} />, {
      transition: Zoom,
      hideProgressBar: true,
      closeButton: false,
      position: 'bottom-right',
    });
  },
  update(msg = '', title = 'UPDATE') {
    return toast(<Content msg={msg} type="UPDATE" title={title} />, {
      transition: Zoom,
      hideProgressBar: true,
      closeButton: false,
      position: 'bottom-right',
    });
  },
};

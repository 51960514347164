"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRoom = void 0;

var _constants = _interopRequireDefault(require("./constants"));

var _tools = require("./tools");

var _walls = require("./walls");

var _doors = require("./doors");

var createRoom = function createRoom(exhibition) {
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (!exhibition.walls) {
    return null;
  }

  var _constants$DEFAULT_GR = _constants["default"].DEFAULT_GROUND_EXTENT,
      maxX = _constants$DEFAULT_GR.maxX,
      maxZ = _constants$DEFAULT_GR.maxZ,
      minX = _constants$DEFAULT_GR.minX,
      minZ = _constants$DEFAULT_GR.minZ;
  var settings = exhibition.generalSettings || {};
  return [{
    primitive: 'a-box',
    key: 'exhibition-ground',
    'static-body': true,
    events: (0, _tools.createEvents)(events.floor, undefined, opts),
    material: {
      shader: 'flat',
      color: (0, _tools.toRGB)(exhibition.floorColor) || _constants["default"].COLOUR.GROUND,
      src: settings.groundTexture || exhibition.floorTexture || '#ground-texture',
      repeat: "".concat(maxX - minX, " ").concat(maxZ - minZ)
    },
    scale: [maxX - minX, _constants["default"].DEFAULT_WALL_WIDTH, maxZ - minZ].join(' '),
    position: [(maxX + minX) / 2, 0, (maxZ + minZ) / 2].join(' '),
    'ground-listener': '',
    'click-manager': {
      draggingDelay: _constants["default"].DRAGGING_DELAY
    }
  }, {
    primitive: 'a-entity',
    key: 'exhibition-walls',
    children: (0, _walls.createWalls)(exhibition.walls || [], events, opts)
  }, {
    primitive: 'a-entity',
    key: 'exhibition-doors',
    children: (0, _doors.createDoors)(exhibition.doors || [], events, opts)
  }, {
    primitive: 'a-box',
    key: 'exhibition-border-n',
    'static-body': true,
    material: {
      shader: 'flat',
      transparent: true,
      opacity: 0
    },
    scale: [maxX - minX, _constants["default"].DEFAULT_WALL_HEIGHT, _constants["default"].DEFAULT_WALL_WIDTH].join(' '),
    position: [0, _constants["default"].DEFAULT_WALL_HEIGHT / 2, maxZ].join(' ')
  }, {
    primitive: 'a-box',
    key: 'exhibition-border-s',
    'static-body': true,
    material: {
      shader: 'flat',
      transparent: true,
      opacity: 0
    },
    scale: [maxX - minX, _constants["default"].DEFAULT_WALL_HEIGHT, _constants["default"].DEFAULT_WALL_WIDTH].join(' '),
    position: [0, _constants["default"].DEFAULT_WALL_HEIGHT / 2, minZ].join(' ')
  }, {
    primitive: 'a-box',
    key: 'exhibition-border-e',
    'static-body': true,
    material: {
      shader: 'flat',
      transparent: true,
      opacity: 0
    },
    scale: [_constants["default"].DEFAULT_WALL_WIDTH, _constants["default"].DEFAULT_WALL_HEIGHT, maxZ - minZ].join(' '),
    position: [maxX, _constants["default"].DEFAULT_WALL_HEIGHT / 2, 0].join(' ')
  }, {
    primitive: 'a-box',
    key: 'exhibition-border-w',
    'static-body': true,
    material: {
      shader: 'flat',
      transparent: true,
      opacity: 0
    },
    scale: [_constants["default"].DEFAULT_WALL_WIDTH, _constants["default"].DEFAULT_WALL_HEIGHT, maxZ - minZ].join(' '),
    position: [minX, _constants["default"].DEFAULT_WALL_HEIGHT / 2, 0].join(' ')
  }];
};

exports.createRoom = createRoom;
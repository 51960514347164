import React from 'react';
import ProfileEditor from './forms/ProfileEditor';
import ProfileViewer from './ProfileViewer';
import { compose, withState, withDispatch } from '../../enhancers';
import { setUIProperty } from '../../actions';
import { getUIProperty } from '../../reducers';
import UserPanel from './UserPanel';

export const ProfileFrameView = ({
  userId,
  isEditing = false,
  onStopEditing = () => false,
  onStartEditing = () => false,
  tab,
}) => (
  <div>
    {isEditing ? (
      <ProfileEditor userId={userId} onClose={onStopEditing} isAdminEditing={false} />
    ) : (
      <div>
        <ProfileViewer userId={userId} />
        <UserPanel userId={userId} tab={tab} profilePath={`/profile/${userId}`} />
      </div>
    )}
  </div>
);

const mapState = (state, { userId }) => ({
  isEditing: getUIProperty(state, `profiles/${userId}/editing`),
});

const mapDispatch = (dispatch, { userId }) => ({
  onStopEditing: () => dispatch(setUIProperty(`profiles/${userId}/editing`, false)),
  onStartEditing: () => dispatch(setUIProperty(`profiles/${userId}/editing`, true)),
});

const ProfileFrame = compose(withState(mapState), withDispatch(mapDispatch))(ProfileFrameView);

export default ProfileFrame;

import React, { useEffect, useState } from 'react';
import OurModal from '../generic/OurModal';
import { BodyWithPadding, StyledButton, Text, VerticalItems } from '../../styles/GenericStyled';
import COLORS from '../../styles/colors';
import privateConfig from 'artsteps2-config/private.json';
import Link from 'react-router-dom/Link';
import logo from '../../styles/images/logo-artsteps.png';
import {__} from "artsteps2-common";
import {getUIProperty} from "../../reducers";
import {setLocation, setUIProperty} from "../../actions";
import {compose, withDispatch, withState} from "../../enhancers";
import CircularProgress from "@material-ui/core/CircularProgress";


const MarketplaceCheckoutModalView = ({
  marketplaceModal,
  onSetModal = () => Promise.resolve(false),
  onRedirect = () => Promise.resolve(false),
}) => {
  const [sec, setSec] = useState(5);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (sec > 0) await setSec(val => val - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSec(6);
  }, [marketplaceModal]);

  return (
    <OurModal
      width={'25%'}
      height={'35%'}
      minWidth="300px"
      minHeight="350px"
      openModal={marketplaceModal}
      setOpenModal={() => onSetModal(false)}
    >
      <BodyWithPadding padding="25px" style={{ textAlign: 'center' }}>
        <img style={{ width: '80px', height: '80px' }} src={logo} alt={__('brand_name')} />
        {sec > 0 && (
          <Text type="h6" align="center">
            Your purchase is now complete!
          </Text>
        )}
        {sec <= 0 && (
          <Text type="h6" align="center">
            Your new template is now ready!
          </Text>
        )}
        <br />
        {sec > 0 && (
          <Text type="body1" align="center">
            Please wait a few seconds until we set things up.
          </Text>
        )}
        {sec <= 0 && (
          <Text type="body1" align="center">
            Click the button below to use it:
          </Text>
        )}
        <br />
        <VerticalItems>
          <StyledButton
            type="secondary"
            background={COLORS.ourGreen}
            onClick={() => {
              onSetModal(false);
              onRedirect('/curate/new/1');
              window.location.reload();
            }}
            disabled={sec > 0}
          >
            Create {sec > 0 && `(${sec})s`}
          </StyledButton>
        </VerticalItems>
        <br />
        {/*{sec > 0 && <CircularProgress />}*/}
      </BodyWithPadding>
    </OurModal>
  );
};

const mapState = state => ({
  marketplaceModal: getUIProperty(state, 'marketplaceModalOpen'),
});

const mapDispatch = dispatch => ({
  onSetModal: bool => dispatch(setUIProperty('marketplaceModalOpen', bool)),
  onRedirect: location => dispatch(setLocation(location)),
});

const MarketplaceCheckoutModal = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(MarketplaceCheckoutModalView);

export default MarketplaceCheckoutModal;

import React from 'react';
import { __ } from 'artsteps2-common';
import MetaTags from '../components/layouts/MetaTags';
import AccountRemoval from '../components/users/forms/AccountRemoval';
import DefaultLayout from '../components/layouts/DefaultLayout';

const PrivacySettingsPage = () => (
  <DefaultLayout>
    <MetaTags title={__('privacy_settings')} />
    <div className="ui main container text">
      <div className="ui segment vertical">
        <h1 className="ui header">{__('privacy_settings')}</h1>
        <div className="ui segment vertical">
          <h2>{__('remove_account')}</h2>
          <p>{__('remove_account_text')}</p>
          <AccountRemoval />
        </div>
      </div>
    </div>
  </DefaultLayout>
);

export default PrivacySettingsPage;

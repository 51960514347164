import React from 'react';
import { __ } from 'artsteps2-common';
import Loader from '../../../generic/Loader';
import Form from '../../../generic/forms/Form';
import utils from '../../../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../../../enhancers';
import { API_STATUS, getApiResource, getApiStatus, getForm } from '../../../../reducers';
import {
  setFormData,
  apiGET,
  apiPOST,
  apiPATCH,
  addMessage,
  clearMessages,
} from '../../../../actions';

export const ExhibitionInfoEditorView = ({
  exhibitionId,
  exhibition,
  form = { data: {} },
  categories = [],
  saving = false,
  ready = true,
  onUpsert = () => Promise.resolve({ response: { error: 'error' } }),
  onSave = () => Promise.resolve(false),
  onClose,
  onAddMessage = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
}) => {
  const onSubmit = () =>
    onUpsert({
      title: form.data.title,
      space: exhibition.state,
      description: form.data.description,
      categories: form.data.categories,
      tags: (form.data.tags || []).map(tag => tag.trim()).filter(tag => tag),
      audio: form.data.audio,
    }).then(({ response }) =>
      response.error
        ? onAddMessage({ title: __(response.error), type: 'error' })
        : onAddMessage({
            title: __('exhibition_save_info_success'),
            type: 'success',
          }).then(() => onSave()),
    );

  return (
    <div className="exhibition-info-editor">
      <h2>{__('public_info')}</h2>
      <div className="exhibition-info">
        <Form
          onSubmit={onSubmit}
          ready={ready}
          form={form}
          fields={[
            {
              title: { label: __('title'), type: 'text', required: true },
              description: { label: __('description'), type: 'textarea' },
              categories: {
                label: __('categories'),
                hint: __('categories_hint'),
                type: 'multiselect',
                options: categories.reduce((opts, c) => ({ ...opts, [c._id]: c.title }), {}),
                required: true,
              },
              audio: {
                label: __('audio'),
                hint: __('audio_hint'),
                type: 'audio',
                preview: () => utils.exhibition.getExhibitionAudio({ _id: exhibitionId }),
              },
              tags: {
                label: __('tags'),
                type: 'text',
                fromFormValue: (tags = []) => tags.join(', ').replace(/\s+/g, ' '),
                toFormValue: value => (value || '').split(','),
              },
            },
          ]}
          buttons={[
            ...(onClose
              ? [
                  {
                    colour: 'secondary',
                    label: __('close'),
                    onClick: onClose,
                    type: 'button',
                  },
                ]
              : []),
            {
              colour: 'secondary',
              label: __('save'),
              disabled:
                !form.data.title ||
                form.data.categories.length === 0 ||
                (form.data.audio &&
                  form.data.audio.type !== 'none' &&
                  !form.data.audio.uri &&
                  !form.data.audio.file &&
                  form.data.audio.bin &&
                  !form.data.audio.bin.content),
            },
          ]}
        />
        {saving && <Loader message={`${__('saving')} ${saving}`} />}
        {saving || ready || <Loader />}
      </div>
    </div>
  );
};

const mapState = (state, { exhibition: { _id: exhibitionId = 'new' } = {} }) => ({
  exhibitionId,
  form: getForm(state, `exhibition:${exhibitionId}`),
  categories: Object.values(getApiResource(state, 'categories')),
  ready: getApiStatus(state, 'categories') === API_STATUS.IDLE,
});

const mapDispatch = (dispatch, { exhibition: { _id: exhibitionId = 'new' } = {} }) => ({
  onSetForm: exhibition =>
    dispatch(
      setFormData(`exhibition:${exhibitionId}`, {
        ...exhibition,
        categories: (exhibition.categories || []).map(category => category._id || category),
      }),
    ),
  onFetchCategories: () => dispatch(apiGET('categories')),
  onUpsert: exhibition =>
    exhibitionId === 'new'
      ? dispatch(apiPOST('exhibitionsUserProfile', exhibition, { populate: 'categories' }))
      : dispatch(
          apiPATCH(`exhibitionsUserProfile/${exhibitionId}`, exhibition, {
            populate: 'categories',
          }),
        ),
  onAddMessage: message => dispatch(addMessage(message, 'exhibitions')),
  onClearMessages: () => dispatch(clearMessages('exhibitions')),
});

const lifecycleMap = {
  onDidMount: ({ onFetchCategories, onSetForm, exhibition }) =>
    onFetchCategories().then(() => onSetForm(exhibition)),
  onDidUpdate: (props, { onSetForm, exhibition = {} }) =>
    props.exhibition && props.exhibition._id !== exhibition._id && onSetForm(exhibition),
};

const ExhibitionInfoEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionInfoEditorView);

export default ExhibitionInfoEditor;

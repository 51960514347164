import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const YesOrNo = ({ title, message, optionA, optionB, open, setOpen, handleYes }) => {
  return (
    <Dialog
      disableScrollLock
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {optionA}
        </Button>
        <Button onClick={() => handleYes()} color="primary" autoFocus>
          {optionB}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default YesOrNo;

import React, { Component } from 'react';
import qs from 'qs';
import config from 'artsteps2-config/public.json';
import { __ } from 'artsteps2-common';

const PAGE_SIZE = 10;
const SEARCH_TIMEOUT = 600;
const FLICKR_API_PATH = 'https://www.flickr.com/services/rest/';
const FLICKR_LICENSES = [1, 2, 3, 4, 5, 6, 7];

const toArray = obj => {
  if (Array.isArray(obj)) {
    return obj;
  }
  return obj ? [obj] : [];
};

class FlickrSelect extends Component {
  static queryFlickr(query) {
    return fetch(
      `${FLICKR_API_PATH}?${qs.stringify({
        ...query,
        api_key: config.flickr.apikey,
        format: 'json',
      })}`,
      { headers: new Headers({ Accept: 'application/json' }) },
    );
  }

  static findPicturesSrc(photos) {
    return Promise.all(
      photos.map(photo =>
        this.queryFlickr({
          method: 'flickr.photos.getSizes',
          photo_id: photo.id,
        })
          .then(response => response.text())
          .then(res => JSON.parse(res.match(/jsonFlickrApi\((.*)\)/)[1]))
          .then(({ sizes }) => ({
            title: photo.title,
            src:
              sizes.size
                .filter(l => l.label === 'Medium 800')
                .map(s => s.source)
                .pop() ||
              sizes.size
                .filter(l => l.label === 'Medium')
                .map(s => s.source)
                .pop(),
            thumbnail: sizes.size
              .filter(l => l.label === 'Large Square')
              .map(s => s.source)
              .pop(),
          })),
      ),
    );
  }

  constructor(props) {
    super(props);
    const value = props.asArray ? (props.value || [])[0] : props.value;
    this.state = {
      searchTerm: '',
      results: value && value.uri ? [{ thumbnail: value.uri, url: value.uri }] : [],
      loading: false,
      // Pagging
      currentPage: 1,
      totalPages: 0,
    };
  }

  // Component Methods -------------------------------------------------------
  componentWillReceiveProps(props) {
    const value = props.asArray ? (props.value || [])[0] : props.value;

    if (this.state.results.length || !value || !value.uri) {
      return;
    }

    this.setState({
      results: [
        {
          thumbnail: (value.image || value).uri,
          url: value.uri,
        },
      ],
    });
  }

  // Ui Events -------------------------------------------------------
  onInputChanged(v) {
    // unselect previously selected value
    this.cachedResults = []; // clear cached results
    const newValue = v.target.value;
    this.setState({ searchTerm: newValue, loading: true });
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    if (newValue !== '') {
      this.searchTimeout = setTimeout(() => {
        FlickrSelect.getData(newValue, null).then(imgs => {
          if (imgs.image.length === 0) {
            this.onAssetClicked(null);
            this.setState({
              loading: false,
              message: __('no_images_found'),
              results: [],
              currentPage: 1,
              totalPages: 0,
            });
          } else {
            this.setState({
              results: imgs.image.map(u => ({
                url: u.src,
                title: u.title,
                thumbnail: u.thumbnail,
              })),
              loading: false,
              currentPage: 1,
              message: '',
              totalPages: Math.floor(imgs.tpage / PAGE_SIZE),
            });
          }
        });
      }, SEARCH_TIMEOUT);
    } else {
      this.onAssetClicked(null);
      this.setState({
        results: [],
        loading: false,
        currentPage: 1,
        totalPages: 0,
      });
    }
  }

  onAssetClicked(asset) {
    const value = asset && {
      uri: asset.url,
      image: { uri: asset.url },
      type: 'flickr',
    };

    this.props.setFiles(this.props.asArray ? toArray(value) : value);
  }

  static getData(keywords, pageToken) {
    return this.queryFlickr({
      method: 'flickr.photos.search',
      sort: 'relevance',
      license: FLICKR_LICENSES.join(','),
      text: keywords,
      per_page: PAGE_SIZE,
      page: pageToken || 0,
    })
      .then(response => response.text())
      .then(result => JSON.parse(result.match(/jsonFlickrApi\((.*)\)/)[1]))
      .then(async data => {
        const collection = {};
        collection.tpage = data.photos.pages;
        collection.image = await this.findPicturesSrc(data.photos.photo);
        return collection;
      });
  }

  showNextPage(offset) {
    const nextPage = this.state.currentPage + offset;
    this.setState({ loading: true });
    FlickrSelect.getData(this.state.searchTerm, nextPage).then(imgs => {
      this.setState({
        results: imgs.image.map(u => ({
          url: u.src,
          title: u.title,
          thumbnail: u.thumbnail,
        })),
        loading: false,
        currentPage: nextPage,
      });
      this.cachedResults.push(imgs.nextPageToken);
    });
  }

  renderPaging() {
    if (this.state.totalPages === 0) {
      return null;
    }
    return (
      <div className="pager">
        {/* Previous Page Button */}
        {this.state.currentPage <= 1 || (
          <button
            onClick={() => this.showNextPage(-1)}
            className="ui basic compact tiny button"
            type="button"
          >
            <i className="left chevron icon" />
          </button>
        )}

        {/* Current Page */}
        <span className="page">{`${this.state.currentPage}/${this.state.totalPages}`}</span>

        {/* Next Page Button */}
        {this.state.currentPage < this.state.totalPages && (
          <button
            onClick={() => this.showNextPage(1)}
            className="ui basic compact tiny button"
            type="button"
          >
            <i className="right chevron icon" />
          </button>
        )}
      </div>
    );
  }

  render() {
    const value = this.props.asArray ? (this.props.value || [])[0] : this.props.value;

    return (
      <div
        style={{ width: '100%' }}
        className={`poly-selector ui field ${this.props.required ? 'required' : ''}`}
      >
        <label htmlFor={this.props.id}>{this.props.label || ''}</label>
        <div
          style={{ width: '100%' }}
          className={`ui right icon input${this.state.loading ? ' loading' : ''}`}
        >
          <input
            style={{ width: '100%' }}
            id={this.props.id}
            type="text"
            placeholder={__('search')}
            className="ui input"
            autoComplete="off"
            value={this.state.searchTerm}
            onChange={v => this.onInputChanged(v)}
          />
          <i className="search icon" />
        </div>

        <div className="upload-container flickr">
          <div className="flickr-search-message">{this.state.message}</div>
          <div className="artifact-list">
            <div className="ui horizontal list">
              {this.state.results.map(asset => (
                <div
                  role="button"
                  tabIndex={0}
                  key={asset.url}
                  className="artifact-wrapper item"
                  onKeyPress={event => event.keyCode === 13 && this.onAssetClicked(asset)}
                  onClick={() => this.onAssetClicked(asset)}
                >
                  <div className={`artifact ${value && value.uri === asset.url ? 'selected' : ''}`}>
                    <img alt={asset.title} title={asset.title} src={asset.thumbnail} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {this.renderPaging()}
      </div>
    );
  }
}

export default FlickrSelect;

import React from 'react';
import colors from '../../styles/colors';
import { StyledButton, Text } from '../../styles/GenericStyled';
import { __ } from 'artsteps2-common';
import { Grid, withWidth } from '@material-ui/core';
import Link from 'react-router-dom/Link';
import privateConfig from 'artsteps2-config/private.json';


const FeaturedSectionView = ({
  title,
  subtitle,
  label,
  button,
  setOpenRegister,
  isPrivateSpace,
  exploreRef,
  createRef,
  expandRef,
  connectRef,
  showBlack,
  width,
  children,
}) => {
  return (
    <div className="ui inverted vertical featured center aligned segment">
      <div className="segment cover">
        {children}
        <div className="ui container ctaplacement">
          <h1
            className="ui inverted header"
            style={{ fontSize: `${width === 'lg' ? '50px !important' : ''}` }}
          >
            {title}
            {subtitle && (
              <span className="subtitle">
                <br />
                {subtitle}
              </span>
            )}
          </h1>
          <h3 className="ui inverted header" style={{ marginBottom: '30px' }}>
            {label}
          </h3>

          {button && (
            <StyledButton
              onClick={() => setOpenRegister(true)}
              type="secondary"
              mycolor={colors.ourWhite}
              mycolorhover={colors.ourBlack}
              background="bottom"
              backgroundhover={colors.ourWhite}
              bordercolor={colors.ourWhite}
              style={{ marginBottom: '10px', fontWeight: 'bold' }}
            >
              {button.text}
            </StyledButton>
          )}
          {isPrivateSpace === false &&
            privateConfig.accounts.services.paddle.marketplaceEnabled === true && (
              <Link to="/marketplace">
                <StyledButton
                  type="secondary"
                  mycolor={colors.ourOrange}
                  mycolorhover={colors.ourBlack}
                  background="bottom"
                  backgroundhover={colors.ourOrange}
                  bordercolor={colors.ourWhite}
                  style={{
                    marginLeft: '30px',
                    marginRight: '30px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  NEW: Check the 3D templates marketplace{/*Buy 3D spaces from marketplace*/}
                </StyledButton>
              </Link>
            )}
          {!isPrivateSpace && !showBlack && (
            <div
              style={{
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '40px',
                marginTop: '40px',
                position: 'absolute',
                top: 'calc(40vh - 1.5em)' /*40vh - 0.9em)*/,
              }}
            >
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid
                  item
                  xs={3}
                  style={{ textShadow: '0 0 black', cursor: 'pointer' }}
                  onClick={() => exploreRef.current.scrollIntoView()}
                >
                  <Text
                    type="sub1"
                    align="center"
                    mycolor="white"
                    style={{ fontSize: width > '1000' ? '30px' : '25px' }}
                  >
                    {__('intro.feature_explore')}
                  </Text>
                  <div
                    className="features"
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '10px',
                      marginBottom: '0px',
                      width: '84px',
                      paddingLeft: '5px',
                    }}
                  >
                    <div className="feature explore" />
                  </div>
                  <Text
                    type="bod1"
                    align="center"
                    mycolor="white"
                    style={{ fontSize: width > '1000' ? '18px' : '15px' }}
                  >
                    {__('intro.feature_explore_text')}
                  </Text>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ textShadow: '0 0 black', cursor: 'pointer' }}
                  onClick={() => createRef.current.scrollIntoView()}
                >
                  <Text
                    type="sub1"
                    align="center"
                    mycolor="white"
                    style={{ fontSize: width > '1000' ? '30px' : '25px' }}
                  >
                    {__('intro.feature_create')}
                  </Text>
                  <div
                    className="features"
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '10px',
                      marginBottom: '0px',
                      width: '84px',
                      paddingLeft: '5px',
                    }}
                  >
                    <div className="feature create" />
                  </div>
                  <Text
                    type="bod1"
                    align="center"
                    mycolor="white"
                    style={{ fontSize: width > '1000' ? '18px' : '15px' }}
                  >
                    {__('intro.feature_create_text')}
                  </Text>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ textShadow: '0 0 black', cursor: 'pointer' }}
                  onClick={() => expandRef.current.scrollIntoView()}
                >
                  <Text
                    type="sub1"
                    align="center"
                    mycolor="white"
                    style={{ fontSize: width > '1000' ? '30px' : '25px' }}
                  >
                    {__('intro.feature_expand')}
                  </Text>
                  <div
                    className="features"
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '10px',
                      marginBottom: '0px',
                      width: '84px',
                      paddingLeft: '5px',
                    }}
                  >
                    <div className="feature expand" />
                  </div>
                  <Text
                    type="bod1"
                    align="center"
                    mycolor="white"
                    style={{ fontSize: width > '1000' ? '18px' : '15px' }}
                  >
                    {__('intro.feature_expand_text')}
                  </Text>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ textShadow: '0 0 black', cursor: 'pointer' }}
                  onClick={() => connectRef.current.scrollIntoView()}
                >
                  <Text
                    type="sub1"
                    align="center"
                    mycolor="white"
                    style={{ fontSize: width > '1000' ? '30px' : '25px' }}
                  >
                    {__('intro.feature_socialize')}
                  </Text>
                  <div
                    className="features"
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '10px',
                      marginBottom: '0px',
                      width: '84px',
                      paddingLeft: '5px',
                    }}
                  >
                    <div className="feature socialize" />
                  </div>
                  <Text
                    type="bod1"
                    align="center"
                    mycolor="white"
                    style={{ fontSize: width > '1000' ? '18px' : '15px' }}
                  >
                    {__('intro.feature_socialize_text')}
                  </Text>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FeaturedSection = FeaturedSectionView;

export default FeaturedSection;

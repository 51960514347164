import React, { useRef, useEffect, useCallback } from 'react';
import utils from '../../../utils';
import debounce from 'debounce';

const DEFAULT_WIDTH = 420;
const DEFAULT_HEIGHT = 221;

const TextPreview = ({
  bin,
  textColor,
  textAlignment,
  onPreviewLoad,

  previewRef,
}) => {
  const textCanvasRef = useRef();
  const callBackCanvasCreation = useCallback(
    debounce(
      (text, textColor, textAlignment, canvas) =>
        new Promise((resolve, reject) => {
          if (!previewRef) reject();
          canvas.width = previewRef.offsetWidth || DEFAULT_WIDTH;
          canvas.height = previewRef.offsetHeight || DEFAULT_HEIGHT;

          const ctx = canvas.getContext('2d');
          ctx.fillStyle = 'black';
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          const words = (text || '').trim().split(/ |\n/);
          const lineHeight = Math.min(
            Math.sqrt((canvas.height * canvas.width) / words.join('').length),
            canvas.height / 2,
          );
          const x = canvas.width / 2 - lineHeight / 4;
          let y = lineHeight;
          let line = '';

          ctx.font = `${lineHeight}px monospace`;

          switch (textAlignment) {
            case 2:
              ctx.textAlign = 'left';
              break;
            case 1:
              ctx.textAlign = 'center';
              break;
            case 0:
              ctx.textAlign = 'right';
              break;
            default:
              ctx.textAlign = 'center';
          }
          ctx.fillStyle = textColor || 'white';

          words.forEach(word => {
            if (line && ctx.measureText(`${line} ${word}`).width > canvas.width) {
              ctx.fillText(line, x + 5, y, canvas.width);
              y += lineHeight;
              line = '';
            }
            line = `${line} ${word}`;
          });

          ctx.fillText(line, x, y, canvas.width);

          resolve(canvas);
        }).then(canvas => onPreviewLoad(canvas)),
      300,
    ),
    [],
  );

  useEffect(() => {
    if (textCanvasRef && textCanvasRef.current) {
      callBackCanvasCreation(
        utils.buffer.toUTF8String(bin),
        textColor,
        textAlignment,
        textCanvasRef.current,
      );
    }
  }, [bin, textColor, textAlignment, textCanvasRef, callBackCanvasCreation]);

  return <canvas ref={textCanvasRef} />;
};

export default TextPreview;

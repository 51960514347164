import React from 'react';
import { NavLink } from 'react-router-dom';
import { __ } from 'artsteps2-common';

import MetaTags from '../components/layouts/MetaTags';
import FullLayout from '../components/layouts/FullLayout';

import { setLocation } from '../actions';
import { compose, withDispatch, withState } from '../enhancers';
import { getApiResource } from '../reducers';

const WelcomeView = ({ setOpenLogin, onRedirect = () => Promise.resolve(false), user }) => {
  return (
    <FullLayout>
      <MetaTags title={__('sign_in')} description={__('welcome_header')} />
      <div className="login-form">
        <div className="ui two column stackable grid">
          <div className="stretched column cover-column">
            <div className="ui basic segment">
              <h1 className="ui header white-text">{__('welcome_header')}</h1>
              <h4 style={{ whiteSpace: 'pre-line' }}>{__('welcome_subtitle')}</h4>
            </div>
          </div>
          <div className="column" style={{ display: 'block' }}>
            <NavLink to="/" className="logo" />
            <div className="ui horizontal divider">{__('Registration successful!')}</div>
            <p className="welcome-message" style={{ whiteSpace: 'pre-line' }}>
              {user.profile ? `${user.profile.name}, ` : ''}
              {__('welcome_message')}
            </p>
            <div className="ui form">
              <button onClick={() => setOpenLogin(true)} className="ui teal button">
                {__('Go to Login')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

const mapDispatch = dispatch => ({
  onRedirect: page => dispatch(setLocation(page)),
  setOpenLogin: status => dispatch('openLogin', status),
});

const mapState = (state, { userId }) => ({
  user: getApiResource(state, `users/${userId}`),
});

const WelcomePage = compose(withState(mapState), withDispatch(mapDispatch))(WelcomeView);

export default WelcomePage;

import React from 'react';
import AdminPanelWrapper from './AdminPanelWrapper';
import { getApiResource, getApiStatus, getAuthUser, getAuthUserRoles } from '../../reducers';
import { apiGET, setLocation } from '../../actions';
import { compose, withDispatch, withLifecycle, withState } from '../../enhancers';
import { Redirect } from 'react-router';
import { DataGrid } from '@material-ui/data-grid';
import { ExpandButton, StyledButton, Text } from '../../styles/GenericStyled';
import Box from '@material-ui/core/Box';
import colors from '../../styles/colors';
import MyToolTip from '../generic/MyToolTip';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const PaymentsAdminWrapperView = ({
  payments,
  currentUser,
  ready,
  isAdmin,
  onRedirect = () => Promise.resolve(false),
}) => {
  if (!isAdmin) return <Redirect to="/" />;
  const columns = [
    {
      field: 'template',
      headerName: 'Template',
      headerAlign: 'left',
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Text>
          {row.template?.title} {row.template?.size}
        </Text>
      ),
    },
    {
      field: 'paymentSucceeded.earnings',
      headerName: 'Price earnings',
      headerAlign: 'left',
      flex: 0.5,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>
          {row?.paymentSucceeded?.earnings} {row?.paymentSucceeded?.currency}
        </Text>
      ),
    },
    {
      field: 'paymentSucceeded.country',
      headerName: 'Country',
      headerAlign: 'left',
      flex: 0.4,
      sortable: false,
      renderCell: ({ row }) => (
        <Text>
          {row?.paymentSucceeded?.country}
        </Text>
      ),
    },
    {
      field: 'user',
      headerName: 'User',
      headerAlign: 'left',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        if (!row.user) return <></>
        return (
          <>
            <MyToolTip title="Visit" placement="top">
              <StyledButton
                type="tertiary"
                onClick={() => onRedirect(`/profile/${row?.user?._id}`)}
                background="white"
                mycolor="black"
                bordercolor="gray"
              >
                <Text>{row?.user?._id}</Text>
              </StyledButton>
            </MyToolTip>
            <MyToolTip title="Copy to clipboard" placement="top">
              <Box
                component={ExpandButton}
                boxShadow={3}
                mycolor={colors.ourBlack}
                mycolorhover={colors.ourWhite}
                background={colors.ourWhite}
                backgroundhover={colors.ourBlack}
                onClick={() => navigator.clipboard.writeText(row?.user?._id)}
                style={{ marginLeft: '10px' }}
              >
                <FileCopyIcon/>
              </Box>
            </MyToolTip>
          </>
        );
      },
    },
    {
      field: 'receiptUrl',
      headerName: 'Receipt',
      headerAlign: 'left',
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <MyToolTip title="Visit" placement="top">
          <a href={row?.receiptUrl} target="_blank" rel="noopener noreferrer">
            <StyledButton
              type="tertiary"
              style={{ marginLeft: 'auto' }}
              background="white"
              mycolor="black"
              bordercolor="gray"
            >
              <Text>View receipt</Text>
            </StyledButton>
          </a>
        </MyToolTip>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'CreatedAt',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({ row }) => <Text>{new Date(row.createdAt).toLocaleString()}</Text>,
    },
  ];
  //console.log(payments);

  return (
    <AdminPanelWrapper>
      <div style={{ width: '100%' }}>
        <Text>
          Total Sales: &nbsp;
          {payments.reduce(function(sum, elem) {
              return sum + parseFloat(elem?.paymentSucceeded?.earnings);
            }, 0)
            .toFixed(2)}
          &nbsp; EUR
        </Text>
        <DataGrid
          getRowId={row => row._id}
          loading={!ready}
          autoHeight
          rowHeight={70}
          rows={payments}
          columns={columns}
          pageSize={40}
        />
      </div>

    </AdminPanelWrapper>
  );
};

const ADMIN_ROLES = ['admin'];

const mapState = state => ({
  payments: Object.values(getApiResource(state, `templates/payments/adminPanel`)),
  currentUser: getAuthUser(state),
  ready: getApiStatus(state, 'reports') !== 'fetching',
  isAdmin: getAuthUserRoles(state).some(role => ADMIN_ROLES.includes(role)),
});

const mapDispatch = dispatch => ({
  onFetchPayments: () => dispatch(apiGET('templates/payments/adminPanel')),
  onRedirect: location => dispatch(setLocation(location)),
});
const lifecycleMap = {
  onWillMount: ({ onFetchPayments }) => onFetchPayments(),
};

const PaymentsAdminWrapper = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(PaymentsAdminWrapperView);


export default PaymentsAdminWrapper;

import React from 'react';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { StyledButton } from '../../styles/GenericStyled';

const StyledBackdrop = withStyles({
  root: {
    backdropFilter: 'blur(7px) !important',
  },
})(Backdrop);

const OurModal = ({
  children,
  openModal = false,
  setOpenModal,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  customClose = () => Promise.resolve(true),
}) => {
  const onClose = () => {
    setOpenModal(false);
    customClose();
  };
  const PaperModal = withStyles({
    root: {
      width,
      height,
      maxWidth,
      maxHeight,
      minWidth,
      minHeight,
      outline: 'none',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      position: 'absolute',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  })(Paper);
  return (
    <Modal
      open={openModal}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropComponent={StyledBackdrop}
    >
      <PaperModal elevation={8}>
        <StyledButton
          type="ghost"
          style={{
            fontSize: '1.5rem',
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: '1',
          }}
          background="black"
          mycolor="white"
          onClick={onClose}
        >
          <CloseIcon />
        </StyledButton>
        {children}
      </PaperModal>
    </Modal>
  );
};

export default OurModal;

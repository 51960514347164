import React from 'react';
import Grid from '@material-ui/core/Grid';
import { __ } from 'artsteps2-common';
import Uploader from '../../generic/forms/Uploader-2';
import { VerticalItems } from './FormStyled';
import UrlInput from '../fields/UrlInput';
import FilePreview from '../previews/FilePreview';
import FlickrSelect from '../../generic/forms/FlickrSelect';
import UploadOptionSelect from '../fields/UploadOptionSelect';
import PolySelect from '../../generic/forms/PolySelect';
import AudioField from '../fields/AudioField';
import { Text } from '../../../styles/GenericStyled';

const ArtifactEditorLeft = ({
  files,
  setFiles,
  audioFiles,
  setAudioFiles,
  onRemove,
  options,
  acceptedFiles,
  maxFiles,
  type,
  uploadOption = 'file',
  setUploadOption,
  setThumbnail,
}) => {
  return (
    <Grid item xs={12} md={6}>
      <VerticalItems>
        <UploadOptionSelect
          uploadOption={uploadOption}
          setUploadOption={setUploadOption}
          options={options}
        />

        {uploadOption === 'file' ? (
          <>
            <div style={{ width: '100%', marginTop: '-20px' }}>
              <Text align="left" type="body3">
                Supported file formats ( {acceptedFiles.join(' ')}). Max file size 4 MB.
              </Text>
            </div>
            <Uploader
              uploadOption={uploadOption}
              type={type}
              acceptedFiles={acceptedFiles}
              setFiles={setFiles}
              onRemove={onRemove}
              maxFiles={maxFiles}
            />
          </>
        ) : (
          <UrlInput type={type} files={files} setFiles={setFiles} uploadOption={uploadOption} />
        )}
        {uploadOption === 'flickr' && (
          <FlickrSelect
            asArray
            setFiles={setFiles}
            searchQuery={files && files.length > 0 && files[0].uri}
            label={`${__('search_for')} ${type}`}
          />
        )}
        <FilePreview
          uploadOption={uploadOption || options[0].value}
          setThumbnail={setThumbnail}
          files={files}
          setFiles={setFiles}
          showDelete={false}
          type={type}
        />
        <AudioField setAudioFiles={setAudioFiles} audioFiles={audioFiles} />
      </VerticalItems>
    </Grid>
  );
};

export default ArtifactEditorLeft;

import React from 'react';
import { __ } from 'artsteps2-common';
import Form from '../../generic/forms/Form';
import { compose, withState, withDispatch } from '../../../enhancers';
import { API_STATUS, getForm, getApiStatus, getAuthUser } from '../../../reducers';
import { apiPATCH, addMessage, clearMessages, clearFormData } from '../../../actions';

export const PasswordEditorView = ({
  currentUser = {},
  ready = true,
  form = { data: {}, name: 'password' },
  onChangePassword = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onClearForm = () => Promise.resolve(false),
}) => {
  if (!currentUser._id) {
    return <div className="change-password">{__('youre_logged_out')}</div>;
  }

  const onSubmit = () => {
    if (!form.data.oldPassword) {
      return onAddMessage({ title: __('empty_field_password'), type: 'error' });
    }
    if (!form.data.password) {
      return onAddMessage({ title: __('empty_field_password'), type: 'error' });
    }
    if (!form.data.confirmation) {
      return onAddMessage({ title: __('confirm_password'), type: 'error' });
    }
    if (form.data.password !== form.data.confirmation) {
      return onAddMessage({ title: __('password_mismatch'), type: 'error' });
    }

    return onChangePassword(form.data.password, form.data.oldPassword).then(({ response }) => {
      if (!response.error) {
        onClearMessages();
        onClearForm();
        onAddMessage({
          type: 'success',
          title: __('change_password_success'),
        });
        return;
      }
      onAddMessage({ title: __(response.error), type: 'error' });
    });
  };

  return (
    <div className="change-password">
      <Form
        form={form}
        ready={ready}
        onSubmit={onSubmit}
        fields={{
          oldPassword: {
            type: 'password',
            required: true,
            label: __('your_pass'),
            autoComplete: 'off',
          },
          password: {
            type: 'password',
            required: true,
            label: __('your_new_pass'),
            autoComplete: 'off',
          },
          confirmation: {
            type: 'password',
            required: true,
            label: __('confirm_your_new_pass'),
            autoComplete: 'off',
          },
        }}
        buttons={[{ label: __('change_password'), colour: 'teal' }]}
      />
    </div>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  form: getForm(state, 'password'),
  ready: getApiStatus(state, 'users') === API_STATUS.IDLE,
});

const mapDispatch = (dispatch, { currentUser = {} }) => ({
  onChangePassword: (password, oldPassword) =>
    dispatch(
      apiPATCH(`users/${currentUser._id}`, { services: { password } }, { password: oldPassword }),
    ),
  onAddMessage: message => dispatch(addMessage(message, 'password')),
  onClearMessages: message => dispatch(clearMessages('password')),
  onClearForm: () => dispatch(clearFormData('password')),
});

const PasswordEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(PasswordEditorView);

export default PasswordEditor;

import React, { useEffect, useState, useRef } from 'react';
import InputRange from 'react-simple-range';
import { __ } from 'artsteps2-common';
import styled from 'styled-components';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';

import utils from '../../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { setUIProperty } from '../../../actions';
import { getUIProperty } from '../../../reducers';
import { OptionButton } from '../../../styles/GenericStyled';
import MyToolTip from '../../generic/MyToolTip';
import MonitorProgress from './MonitorProgress';

const BackgroundInputRange = styled.div`
  margin-top: 2px;
  margin-left: 0.25rem;
  background-color: rgba(128, 128, 128, 0.6);
  padding: 20px 4px 12px 0px;
  border-radius: 9px;
`;

const DEFAULT_VOLUME = 0.05;

const ExhibitionAudioPlayerView = ({
  src = undefined,
  expanded = false,
  onChangeExpanded = () => true,
  exhibition = { _id: '' },
}) => {
  const [volume, setVolume] = useState(1);
  const audioRef = useRef();
  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.volume = volume;
    }
    if (!src) audioRef.current.volume = 0;
  }, [volume, audioRef, src]);

  return (
    <div>
      {src && <MonitorProgress audioRef={audioRef} exhibitionId={exhibition._id} />}
      <MyToolTip placement="right" title={`${src ? 'Sound' : 'Exhibition has no sound'}`}>
        <OptionButton
          style={{ paddingTop: '5px' }}
          onClick={() => {
            if (src) {
              expanded === 'volume' ? onChangeExpanded(undefined) : onChangeExpanded('volume');
            }
          }}
        >
          {src && volume === 0 && <VolumeMuteIcon />}
          {src && volume > 0 && volume <= 0.5 && <VolumeDownIcon />}
          {src && volume > 0.5 && <VolumeUpIcon />}
          {!src && <VolumeOffIcon />}
        </OptionButton>
      </MyToolTip>
      {expanded === 'volume' && (
        <BackgroundInputRange>
          <InputRange
            vertical
            max={100}
            min={0}
            step={1}
            value={volume * 100}
            thumbSize={16}
            thumbColor="#fff"
            onChange={({ value }) => setVolume(value / 100)}
          />
        </BackgroundInputRange>
      )}
      <audio loop ref={audioRef} src={src} controls={false}>
        <track default kind="captions" src={`data:text/plain,${__('exhibition_audio')}`} />
      </audio>
    </div>
  );
};

const mapState = (state, { exhibition = {} }) => ({
  src: utils.exhibition.getExhibitionAudio(exhibition),
  volume: getUIProperty(state, `exhibitions/${exhibition._id}/volume`),
  expanded: getUIProperty(state, `exhibitions/${exhibition._id}/expanded`),
});

const mapDispatch = (dispatch, { exhibition, muted }) => ({
  onChangeExpanded: option =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/expanded`, option)),
  onSetVolume: volume => dispatch(setUIProperty(`exhibitions/${exhibition._id}/volume`, volume)),
});

const lifecycleMap = {
  onDidUpdate: ({ volume: prevVolume, prevAudio }, { volume, audio }) => {
    if (audio && (prevVolume !== volume || prevAudio !== audio)) {
      const audioRef = audio;
      audioRef.volume = volume === undefined ? DEFAULT_VOLUME : volume;
      Promise.resolve(audioRef.play()).catch(() => {});
    }
  },
};

const ExhibitionAudioPlayer = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionAudioPlayerView);

export default ExhibitionAudioPlayer;

import React from 'react';
import { withWidth } from '@material-ui/core';
import { API_STATUS, getApiResource, getApiStatus } from '../../reducers';
import { apiGET } from '../../actions';
import { compose, withDispatch, withLifecycle, withState } from '../../enhancers';
import ExhibitionCard from './exhibitionCard/ExhibitionCard';

/*
Adobe XD Wireframe: https://xd.adobe.com/view/2411e9ac-43dc-4409-4063-7ac14babfa72-5220/screen/9b7c7fd1-fa24-476c-bd19-a44843cdde3c/
 */

export const ExhibitionCardListView = ({ exhibitions, blocked = false, categories, filter }) => {
  if (!blocked) {
    return <></>;
  }
  return (
    <div>
      {exhibitions.map((exhibition, index) => (
        <ExhibitionCard
          key={index}
          exhibition={exhibition}
          categories={categories}
          firstCategory={
            filter.categories !== undefined
              ? categories.find(obj => obj._id === filter.categories)
              : undefined
          }
        />
      ))}
    </div>
  );
};

const createQuery = ({ filter, limit, offset, sort, include, populate }) => ({
  filter,
  sort,
  page: { limit, offset },
  include,
  populate,
});

const mapState = (state, props) => ({
  exhibitions: Object.values(getApiResource(state, 'exhibitions', createQuery(props))),
  blocked: getApiStatus(state, 'exhibitions', createQuery(props)) === API_STATUS.IDLE,
  categories: Object.values(getApiResource(state, `categories`)),
});
const mapDispatch = (dispatch, props) => ({
  onFetchExhibitions: () => dispatch(apiGET('exhibitions', createQuery(props))),
  onFetchCategories: () => dispatch(apiGET('categories')),
});

const onInitialization = ({ onFetchExhibitions, onFetchCategories }) => {
  onFetchCategories();
  onFetchExhibitions();
};

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: (prevProps, props) =>
    JSON.stringify(createQuery(prevProps)) !== JSON.stringify(createQuery(props)) &&
    onInitialization(props),
};
const ExhibitionCardList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
  withWidth(),
)(ExhibitionCardListView);
export default ExhibitionCardList;

import React from 'react';
import { __ } from 'artsteps2-common';
import { HeaderLink, Text } from '../../styles/GenericStyled';

import { getApiResource, getAuthUser, getUIProperty } from '../../reducers';
import { compose, withState } from '../../enhancers';
import privateConfig from 'artsteps2-config/private.json';


const NavLinksView = ({
  spaceSettings,
  currentUserId,
  //activeLink,
  onClickedLink,
  isPrivateSpace,
}) => {
  return (
    <>
      {(!spaceSettings || spaceSettings.showExhibitions) && (
        <Text
          key="1"
          type="body1"
          mycolor="#343a40"
          onClick={() => {
            onClickedLink(__('explore'));
          }}
        >
          <HeaderLink mycolor="rgb(102 102 102)" to="/explore">
            {__('explore')}
          </HeaderLink>
        </Text>
      )}
      {!isPrivateSpace && (
        <>
          {privateConfig.accounts.services.paddle.marketplaceEnabled === true &&
            <Text
              key="2"
              type="body1"
              mycolor="#343a40"
              onClick={() => {
                onClickedLink('Marketplace');
              }}
            >
              <HeaderLink mycolor="rgb(102 102 102)" to="/marketplace">
                Marketplace
              </HeaderLink>
            </Text>
          }
          <Text key="3" type="body1" mycolor="#343a40">
            <HeaderLink
              mycolor="rgb(102 102 102)"
              as="a"
              href="https://blog.artsteps.com"
              target="_blank"
            >
              Blog
            </HeaderLink>
          </Text>
        </>
      )}
    </>
  );
};

const mapState = state => ({
  currentUserId: (getAuthUser(state) || {})._id,
  spaceSettings: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`).settings,
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
});

const NavLinks = compose(withState(mapState))(NavLinksView);

export default NavLinks;

import React, { useEffect, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { compose } from 'redux';
import { withDispatch, withState } from '../../enhancers';
import { getUIProperty } from '../../reducers';
import { setUIProperty } from '../../actions';

export const ArtifactOverlayView = ({
  open = true,
  content,
  children,
  onClose,
  fullscreenMode = false,
  widthFirst = false,
  onFullScreenClose,
  onFocus,
  onArtifactDisplayFull,
  onArtifactDisplayMini,
}) => {
  const myDiv = useRef();
  useEffect(() => {
    // check if display on fullscreen mode and enable the appropriate class and button
    if (fullscreenMode) {
      myDiv && myDiv.current && myDiv.current.classList.add('fullscreen-card');
    } else {
      myDiv && myDiv.current && myDiv.current.classList.remove('fullscreen-card');
    }
  }, [fullscreenMode]);
  return (
    open && (
      <div ref={myDiv} className="artifact-card">
        {onClose && (
          <CloseIcon
            className="artifact-close-button"
            onClick={() => {
              onClose();
              onFullScreenClose();
              onFocus();
            }}
          />
        )}
        {!fullscreenMode && (
          <FullScreenIcon
            className="artifact-fullscreen-button"
            onClick={() => {
              onArtifactDisplayFull();
            }}
          />
        )}
        {fullscreenMode && (
          <FullscreenExitIcon
            className="artifact-fullscreen-button"
            onClick={() => {
              onArtifactDisplayMini();
            }}
          />
        )}
        <div className="artifact-content">
          {content}
          {children}
        </div>
      </div>
    )
  );
};

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onFullScreenClose: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/fullscreenArtifactCard`, false)),
  onFocus: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/keyboardCapture`, true)),
  onArtifactDisplayFull: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/fullscreenArtifactCard`, true)),
  onArtifactDisplayMini: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/fullscreenArtifactCard`, false)),
});

const mapState = (state, { exhibitionId }) => ({
  fullscreenMode: getUIProperty(state, `exhibitions/${exhibitionId}/fullscreenArtifactCard`),
  widthFirst: getUIProperty(state, `exhibitions/${exhibitionId}/widthFirst`),
});

const ArtifactOverlay = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ArtifactOverlayView);

export default ArtifactOverlay;

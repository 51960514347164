import qs from 'qs';
import { routerReducer } from 'react-router-redux';
import { ROUTER_NAMESPACE, ROUTER_ACTION_LOCATION_CHANGE } from '../actions/routing';

export default (state, action) => locationReducer(routerReducer(state, action), action);

const locationReducer = (state = {}, { type, payload: { search = '', query = {} } = {} }) => {
  switch (type) {
    case `${ROUTER_NAMESPACE}/${ROUTER_ACTION_LOCATION_CHANGE}`: {
      return {
        ...state,
        location: {
          ...state.location,
          query: {
            ...qs.parse(search.replace(/^\?/, '')),
            ...(query || {}),
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export const getLocation = state => state.location || {};
export const getLocationPathname = state => getLocation(state).pathname;
export const getLocationQuery = state => getLocation(state).query;

import React from 'react';
import { TextField } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { DropzoneArea } from 'material-ui-dropzone';
import colors from '../../../styles/colors';
import Switch from '@material-ui/core/Switch';

export const BlueSwitch = withStyles({
  switchBase: {
    color: colors.ourBlue,
    '&$checked': {
      color: colors.ourBlue,
    },
    '&$checked + $track': {
      backgroundColor: colors.ourBlue,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const PreviewDiv = styled.div`
  width: 100%;
  position: relative;
`;

//https://codesandbox.io/s/style-text-field-htbem?file=/src/App.js
export const StyledTextField = withStyles({
  root: {
    color: colors.ourRed,
    '&.Mui-focused': {
      border: '2px solid red',
      '&.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
})(props => <TextField {...{ ...props, variant: 'outlined', fullWidth: true }} />);

export const StyledTextArea = withStyles({
  root: {
    color: colors.ourRed,
    '&.Mui-focused': {
      border: '2px solid red',
      '&.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
})(props => (
  <TextField
    {...{ ...props, multiline: true, rows: 4, rowsMax: 4, variant: 'outlined', fullWidth: true }}
  />
));

export const StyledSelectInput = withStyles({
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Muli', 'Verdana'].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
})(InputBase);

export const StyledDropZoneArea = withStyles({
  root: {
    backgroundColor: '#343A4012',
    color: 'gray',
    minHeight: 'unset',
    height: '48px',
    paddingTop: '8px',
  },
  text: {
    fontSize: '1.3rem !important',
    fontFamily: 'Muli',
  },
})(DropzoneArea);

export const VerticalItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    margin-bottom: 24px;
  }
`;

export const DimensionsDiv = styled.div`
  display: flex;
  width: 100%;
  & > div {
    margin-left: 16px;
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

import { connect } from 'react-redux';
import withLifecycle from '@hocs/with-lifecycle';
import {
  compose,
  wrapDisplayName,
  withState as withRecomposedState,
  withProps,
  withHandlers,
  onlyUpdateForKeys,
} from 'recompose';

export { compose, connect, withLifecycle, withProps, withHandlers, onlyUpdateForKeys };

export const withState = map => Component => {
  const WithState = connect(
    map,
    null,
    null,
    { pure: true },
  )(Component);
  WithState.displayName = wrapDisplayName(Component, 'withState');
  return WithState;
};

export const withDispatch = map => Component => {
  const WithDispatch = connect(
    null,
    map,
    null,
    { pure: true },
  )(Component);
  WithDispatch.displayName = wrapDisplayName(Component, 'withDispatch');
  return WithDispatch;
};

export const withLocalState = (...args) => Component => {
  const WithLocalState = withRecomposedState(...args)(Component);
  WithLocalState.displayName = wrapDisplayName(Component, 'withLocalState');
  return WithLocalState;
};

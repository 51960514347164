import React from 'react';
import { getAuthUser, getApiResource, API_KEYS, getApiStatus } from '../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../enhancers';
import { setUIProperty, apiGET } from '../actions';
import ExhibitionList from '../components/exhibitions/lists/ExhibitionList';
import DefaultLayout from '../components/layouts/DefaultLayout';
import { Text } from '../styles/GenericStyled';

const ViewReportsPageView = ({
  ready,
  reports = [{ _id: null }],
  sortReports,
  sortedReports,
  currentUser,
}) => {
  const reportedExhibitions = reports.map(report => report.exhibition);
  const isAdmin = currentUser && currentUser.roles.__global_roles__.includes('admin');

  // Bypass logic to wait for the Api Get request to finish in order to call our function to populate  the Reports type by exhibition
  React.useEffect(() => {
    sortReports();
  }, [reports.length]);

  return isAdmin ? (
    reportedExhibitions && (
      <DefaultLayout>
        <Text align="center" type="h5">
          Total Reports: {reportedExhibitions.length}
        </Text>
        {ready || reportedExhibitions.length === 0 ? (
          <ExhibitionList
            isAdmin
            sortedReports={sortedReports}
            reportedExhibitions={reportedExhibitions}
            filter={{ _id: { $in: reportedExhibitions } }} // fetching only the exhibitions who match the exhibitionId array
            include={[
              'categories',
              'description',
              `views.${API_KEYS.META}.totalCount`,
              `likes.${API_KEYS.META}.totalCount`,
              'visits',
              'rating',
              '_v1Rating',
            ]}
            populate={['user']}
          />
        ) : (
          reportedExhibitions.length !== 0 && <Text type="h5"> Fetching reports...</Text>
        )}
      </DefaultLayout>
    )
  ) : (
    <DefaultLayout>
      <Text type="h1">You are not an admin :)</Text>
    </DefaultLayout>
  );
};

const mapState = state => ({
  reports: Object.values(getApiResource(state, `reports`)),
  currentUser: getAuthUser(state),
  ready: getApiStatus(state, 'reports') !== 'fetching',
});

const mapDispatch = (dispatch, { reports = [{ exhibition: null }] }) => ({
  onFetchReports: () => dispatch(apiGET('reports')),
  sortReports: () => {
    const sortedReports = {};
    // make an empty array for the reports of each exhibition
    reports.forEach(obj => {
      sortedReports[obj.exhibition] = {};
    });

    // for each exhibition populate the according report types and count them
    reports.forEach(obj => {
      sortedReports[obj.exhibition][obj.type] = sortedReports[obj.exhibition][obj.type]
        ? sortedReports[obj.exhibition][obj.type] + 1
        : 1;
    });

    dispatch(setUIProperty('sortedReports', sortedReports));
  },
});

const lifecycleMap = {
  onWillMount: ({ onFetchReports }) => onFetchReports(),
};

const ViewReportsPage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ViewReportsPageView);

export default ViewReportsPage;

import React from 'react';
import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import { BlueSwitch } from '../forms/FormStyled';

const SwitchField = ({ handleChange, checked, label, name, defaultValue }) => {
  // checked = defaultValue;

  return (
    <HorizontalItems style={{ width: '100%' }}>
      <Text>{label}</Text>
      <BlueSwitch
        checked={checked === undefined ? defaultValue : checked}
        onChange={handleChange}
        name={name}
      />
    </HorizontalItems>
  );
};

export default SwitchField;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toDataURL = exports.fromDataURL = exports.toUTF8String = exports.fromUTF8String = exports.toString = exports.fromString = exports.bufferToString = exports.bufferFromString = exports.bufferToUTF8String = exports.bufferFromUTF8String = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _textEncoding = require("text-encoding");

var atob = typeof window === 'undefined' ? function (str) {
  return Buffer.from(str, 'base64').toString('binary');
} : window.atob;
var btoa = typeof window === 'undefined' ? function (str) {
  return Buffer.from(str).toString('base64');
} : window.btoa;
var bufferFromUTF8String = typeof window === 'undefined' ? function (str) {
  return new Uint8Array(Buffer.from(str));
} : function (str) {
  return new _textEncoding.TextEncoder().encode(str);
};
exports.bufferFromUTF8String = bufferFromUTF8String;
var bufferToUTF8String = typeof window === 'undefined' ? function (buffer) {
  return Buffer.from(buffer).toString('utf8');
} : function (buffer) {
  return new _textEncoding.TextDecoder().decode(buffer);
};
exports.bufferToUTF8String = bufferToUTF8String;

var bufferFromString = function bufferFromString(str) {
  if (!str) {
    return undefined;
  }

  var buffer = new Uint8Array(new ArrayBuffer(str.length));

  for (var i = 0; i < str.length; i += 1) {
    buffer[i] = str.charCodeAt(i);
  }

  return buffer;
};

exports.bufferFromString = bufferFromString;

var bufferToString = function bufferToString(buffer) {
  if (!buffer) {
    return undefined;
  }

  var str = '';

  for (var i = 0; i < buffer.length; i += 1) {
    str += String.fromCharCode(buffer[i]);
  }

  return str;
};

exports.bufferToString = bufferToString;

var fromString = function fromString(str, name, preview) {
  return str && {
    name: name,
    preview: preview,
    contentType: 'text/plain',
    content: str
  };
};

exports.fromString = fromString;

var toString = function toString() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$content = _ref.content,
      content = _ref$content === void 0 ? '' : _ref$content;

  return content.buffer ? bufferToString(content.buffer) : content;
};

exports.toString = toString;

var fromUTF8String = function fromUTF8String(str, name, preview) {
  return str && {
    name: name,
    preview: preview,
    contentType: 'text/plain; encoding=utf-8',
    content: bufferToString(bufferFromUTF8String(str))
  };
};

exports.fromUTF8String = fromUTF8String;

var toUTF8String = function toUTF8String() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      content = _ref2.content;

  return content && bufferToUTF8String(content.buffer || bufferFromString(content));
};

exports.toUTF8String = toUTF8String;

var fromDataURL = function fromDataURL(dataUri, name, preview) {
  /* /^data:([a-z/]*);base64,(.+)$/.exec(dataUri); */
  if (typeof dataUri !== 'string' || dataUri.length < 7) {
    return undefined;
  }

  var idx = 5;

  while (dataUri.charAt(idx) !== ',') {
    idx += 1;
  }

  var _dataUri$substring$sp = dataUri.substring(5, idx).split(';'),
      _dataUri$substring$sp2 = (0, _slicedToArray2["default"])(_dataUri$substring$sp, 2),
      contentType = _dataUri$substring$sp2[0],
      encoding = _dataUri$substring$sp2[1];

  var content = dataUri.substring(idx + 1);
  return {
    name: name,
    preview: preview,
    contentType: contentType || 'text/plain',
    content: encoding === 'base64' ? atob(content) : content
  };
};

exports.fromDataURL = fromDataURL;

var toDataURL = function toDataURL() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$content = _ref3.content,
      content = _ref3$content === void 0 ? '' : _ref3$content,
      _ref3$contentType = _ref3.contentType,
      contentType = _ref3$contentType === void 0 ? 'text/plain' : _ref3$contentType;

  return "data:".concat(contentType, ";base64,").concat(btoa(content));
};

exports.toDataURL = toDataURL;
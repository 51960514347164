import React from 'react';
import { __ } from 'artsteps2-common';

import MetaTags from '../components/layouts/MetaTags';
import DefaultLayout from '../components/layouts/DefaultLayout';

import designSampleSrc from '../styles/images/features/samples/design-your-vr-exhibition-after.png';
import uploadSampleSrc from '../styles/images/features/samples/upload-your-digital-content.png';
import storySampleSrc from '../styles/images/features/samples/create-your-story.png';
import vrSampleSrc from '../styles/images/features/samples/experience-vr.png';
import shareSampleSrc from '../styles/images/features/samples/share-and-inspire.png';
import youtubeLogoSrc from '../styles/images/features/logo/youtube-logo.png';
import { StyledButton, Text } from '../styles/GenericStyled';
import colors from '../styles/colors';
import Grid from '@material-ui/core/Grid';
import * as PrivateComponent from '../styles/PrivateSpacesPageStyled';
import { IconButton } from '@material-ui/core';

const TourPage = ({ setOpenRegister }) => (
  <DefaultLayout>
    <MetaTags title={__('take_tour')} />
    <div className="ui segment basic container tour-page">
      <div className="ui padded segment basic vertical section design">
        <div className="ui relaxed stackable grid">
          <div className="ui seven wide column">
            <h2 className="ui header">{__('intro.tour_header_design')}</h2>
            <div className="features">
              <div className="tour_feature space">
                <h3 className="ui header">{__('intro.tour_feature_space')}</h3>
                <p>{__('intro.tour_feature_space_text')}</p>
              </div>
              <div className="tour_feature color">
                <h3 className="ui header">{__('intro.tour_feature_color')}</h3>
                <p>{__('intro.tour_feature_color_text')}</p>
              </div>
            </div>
          </div>
          <div className="ui nine wide column">
            <img className="ui image" src={designSampleSrc} alt="" />
          </div>
        </div>
      </div>

      <div className="ui padded segment basic vertical section upload">
        <div className="ui relaxed stackable grid mobile vertically reversed">
          <div className="ui nine wide column">
            <img className="ui image" src={uploadSampleSrc} alt="" />
          </div>
          <div className="ui seven wide column">
            <h2 className="ui header">{__('intro.tour_header_upload')}</h2>
            <div className="features">
              <div className="tour_feature images">
                <h3 className="ui header">{__('intro.tour_feature_images')}</h3>
                <p>{__('intro.tour_feature_images_text')}</p>
              </div>
              <div className="tour_feature videos">
                <h3 className="ui header">{__('intro.tour_feature_videos')}</h3>
                <p>{__('intro.tour_feature_videos_text')}</p>
              </div>
              <div className="tour_feature objects">
                <h3 className="ui header">{__('intro.tour_feature_objects')}</h3>
                <p>{__('intro.tour_feature_objects_text')}</p>
              </div>
              <div className="tour_feature sound">
                <h3 className="ui header">{__('intro.tour_feature_sound')}</h3>
                <p>{__('intro.tour_feature_sound_text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ui padded segment basic vertical section story">
        <div className="ui relaxed stackable grid">
          <div className="ui seven wide column">
            <h2 className="ui header">{__('intro.tour_header_story')}</h2>
            <div className="features">
              <div className="tour_feature story">
                <h3 className="ui header">{__('intro.tour_feature_tours')}</h3>
                <p>{__('intro.tour_feature_tours_text')}</p>
              </div>
              <div className="tour_feature story">
                <h3 className="ui header">{__('intro.tour_feature_story')}</h3>
                <p>{__('intro.tour_feature_story_text')}</p>
              </div>
            </div>
          </div>
          <div className="ui nine wide column">
            <img className="ui image" src={storySampleSrc} alt="" />
          </div>
        </div>
      </div>

      <div className="ui padded segment basic vertical section vr">
        <div className="ui relaxed stackable grid mobile vertically reversed">
          <div className="ui nine wide column">
            <img className="ui image" src={vrSampleSrc} alt="" />
          </div>
          <div className="ui seven wide column">
            <h2 className="ui header">{__('intro.tour_header_vr')}</h2>
            <div className="features">
              <div className="tour_feature images">
                <h3 className="ui header">{__('intro.tour_feature_vr')}</h3>
                <p>{__('intro.tour_feature_vr_text')}</p>
              </div>
              <div className="tour_feature videos">
                <h3 className="ui header">{__('intro.tour_feature_immersive')}</h3>
                <p>{__('intro.tour_feature_immersive_text')}</p>
              </div>
              <div className="tour_feature objects">
                <h3 className="ui header">{__('intro.tour_feature_cost')}</h3>
                <p>{__('intro.tour_feature_cost_text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ui padded segment basic vertical section share">
        <div className="ui relaxed stackable grid">
          <div className="ui seven wide column">
            <h2 className="ui header">{__('intro.tour_header_share')}</h2>
            <div className="features">
              <div className="tour_feature story">
                <h3 className="ui header">{__('intro.tour_feature_share')}</h3>
                <p>{__('intro.tour_feature_share_text')}</p>
              </div>
              <div className="tour_feature social">
                <h3 className="ui header">{__('intro.tour_feature_social')}</h3>
                <p>{__('intro.tour_feature_social_text')}</p>
              </div>
              <div className="tour_feature community">
                <h3 className="ui header">{__('intro.tour_feature_community')}</h3>
                <p>{__('intro.tour_feature_community_text')}</p>
              </div>
            </div>
          </div>
          <div className="ui nine wide column">
            <img className="ui image" src={shareSampleSrc} alt="" />
          </div>
        </div>
      </div>
    </div>

    <PrivateComponent.Requestgrid
      container
      justify="center"
      alignItems="center"
      direction="row"
      spacing={2}
    >
      <Grid item>
        <Text type="h5" mycolor={colors.ourWhite}>
          Visit Artsteps Channel in
        </Text>
      </Grid>
      <Grid item>
        <IconButton>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCVWwJOWQ2eHB0raK_7OGh7w"
            rel="noreferrer"
          >
            <img src={youtubeLogoSrc} style={{ height: '50px' }} />
          </a>
        </IconButton>
      </Grid>
    </PrivateComponent.Requestgrid>

    <div className="call-to-action message" style={{ margin: '0' }}>
      <div className="ui container">
        <Text style={{ marginRight: '10px' }} type="h5" mycolor={colors.ourWhite}>
          {__('intro.call_to_action')}
        </Text>
        <StyledButton
          onClick={() => setOpenRegister(true)}
          type="secondary"
          mycolor={colors.ourWhite}
          mycolorhover={colors.ourBlack}
          background={colors.ourBlack}
          backgroundhover={colors.ourWhite}
          bordercolor={colors.ourWhite}
        >
          {__('get_started')}
        </StyledButton>
      </div>
    </div>
  </DefaultLayout>
);

export default TourPage;

import React from 'react';
import { __ } from 'artsteps2-common';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getAuthUserRoles, getApiResource } from '../../../reducers';
import { apiPATCH } from '../../../actions';
import { ExpandButton } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';
import Box from '@material-ui/core/Box';
import MyToolTip from '../../generic/MyToolTip';

export const ExhibitionPickerView = ({
  staffPick = false,
  editable = false,
  onPick = () => Promise.resolve(false),
}) =>
  editable && (
    <MyToolTip title={__(staffPick ? 'unpick' : 'pick')} placement="top">
      <Box
        component={ExpandButton}
        boxShadow={3}
        mycolor={colors.ourBlack}
        mycolorhover={colors.ourWhite}
        background={colors.ourWhite}
        backgroundhover={colors.ourBlack}
        onClick={() => onPick(!staffPick)}
      >
        {staffPick ? <RemoveIcon /> : <AddIcon />}
      </Box>
    </MyToolTip>
  );

const EDITOR_ROLES = ['editor', 'admin'];

const mapState = (state, { exhibitionId, editable }) => ({
  staffPick: getApiResource(state, `exhibitions/${exhibitionId}`).staffPick,
  editable: getAuthUserRoles(state).some(role => EDITOR_ROLES.includes(role)),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onPick: staffPick => dispatch(apiPATCH(`exhibitions/${exhibitionId}`, { staffPick })),
});

const ExhibitionPicker = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionPickerView);

export default ExhibitionPicker;

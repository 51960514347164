import React from 'react';
import { Text, StyledButton, HorizontalItems, VerticalItems } from '../styles/GenericStyled';
import DefaultLayout from '../components/layouts/DefaultLayout';

const StyledComponentsPage = () => {
  return (
    <DefaultLayout>
      <div tyle={{ margin: '10px' }}>
        <VerticalItems>
          <Text type="h1"> H1 Headline</Text>
          <Text type="h2"> H2 Headline</Text>
          <Text type="h3"> H3 Headline</Text>
          <Text type="h4"> H4 Headline</Text>
          <Text type="h5"> H5 Headline</Text>
          <Text type="h6"> H6 Headline</Text>
          <Text type="sub1"> Subtitle 1</Text>
          <Text type="sub2"> Subtitle 2</Text>
          <Text type="bod1"> Body 1</Text>
          <Text type="bod2"> Body 2</Text>
        </VerticalItems>

        <HorizontalItems style={{ marginBottom: '10px' }}>
          <StyledButton type="primary" mycolor="white" background="#fe7670">
            Primary
          </StyledButton>
          <StyledButton type="secondary" mycolor="black" background="cyan">
            Secondary
          </StyledButton>
          <StyledButton type="tertiary">Tertiary</StyledButton>
          <StyledButton type="ghost" mycolor="black">
            Ghost
          </StyledButton>
          <StyledButton type="secondary" border={1} mycolor="black" background="white">
            Bordered
          </StyledButton>
          <StyledButton type="primary" disabled mycolor="white" background="#fe7670">
            Disabled
          </StyledButton>
        </HorizontalItems>
      </div>
    </DefaultLayout>
  );
};

export default StyledComponentsPage;

import Popover from '@material-ui/core/Popover';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

/* --- SHARE --- */
export const StyledModal = withStyles({
  root: {
    left: '100px',
    top: '100px',
    margin: 'auto',
    position: 'relative',
    maxHeight: '700px',
    maxWidth: '1280px',
  },
  paper: {
    width: '100%',
    height: '100%',
    border: '2px solid #000',
  },
})(Popover);

export const ShareDiv = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    display: inline-flex;
  }
`;

export const StyledTextField = styled(TextField)`
  & > div {
    padding: 5px 0px 50px 5px;
  }
`;

/* --- REPORT ---*/
export const ReportDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px;

  & > * {
    display: inline-flex;
  }
`;

export const StyledPopover = withStyles({
  root: {
    position: 'absolute !important',
  },
  paper: {
    overflow: 'hidden',
    width: '433px',
  },
})(Popover);

export const T1 = styled.div`
  margin: 20px 0 20px 0;
  font-size: 1.2em;
  opacity: 0.6;
  text-align: center;
`;

/* ---  COMMENTS ---*/
export const CommentDiv = styled.div`
  position: relative;
  display: inline-flex;
  background: #f3f3f3;
  font-family: 'Muli', Regular;
  border-radius: 22px;
  margin: 10px;
`;

export const ReplyDiv = styled.div`
  position: absolute;
  bottom: 8px;
  right: 10px;
`;

export const CommentText = styled.div`
  margin: 0px 120px 20px 20px;
  font-size: 1.2em;
  line-height: 1.2em;
  opacity: 0.6;
`;
export const TitleText = styled.p`
  padding: 0;
  font-size: 1.3rem;
  font-weight: bold;
  color: black;
`;

export const Buttons = styled(Button)`
  font-size: 1.1rem !important;
  padding: 10px 25px;
  margin-left: 14px;
  border-radius: 27px;
  text-transform: none;
  height: 40px;

  ${props =>
    props.contribute &&
    css`
      background-color: #fe7670;
      color: white;
      &:hover {
        opacity: 0.5;
        background-color: #fe7670;
        transition: opacity 350ms;
      }
    `}
    ${props =>
      props.create === 'true' &&
      css`
        background-color: #343a40;
        color: #ffffff85;
        &:hover {
          opacity: 0.5;
          background-color: #343a40;
          transition: opacity 350ms;
          color: #ffffff85;
        }
      `}
    ${props =>
      props.follow &&
      css`
        background-color: #343a40;
        color: #ffffff;
        &:hover {
          opacity: 0.5;
          background-color: #343a40;
          transition: opacity 350ms;
          color: #ffffff85;
        }
      `}
      ${props =>
        props.share &&
        css`
          background-color: #ffffff;
          border: 2px solid black;
          color: black;
          &:hover {
            opacity: 0.5;
            background-color: #343a40;
            transition: opacity 350ms;
            color: #ffffff85;
          }
        `}
        ${props =>
          props.tag &&
          css`
            color: #405363;
            background: #f3f3f3;

            &:hover {
              opacity: 0.5;
              background-color: #343a40;
              transition: opacity 350ms;
              color: #ffffff;
            }
          `}

          ${props =>
            props.reply &&
            css`
              color: #808080;
              background: #fafafa;
              &:hover {
                opacity: 0.5;
                background-color: #343a40;
                transition: opacity 350ms;
                color: #ffffff;
              }
            `}
`;

export const HorizontalItems = styled.div`
  font-family: 'Muli', 'Lucida Sans', 'Lucida Sans Regular';
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  & > span {
    opacity: 0.8;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }

  & > span > * {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    margin-left: 5px;
  }
`;

export const VerticalItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > span {
    opacity: 0.8;
    margin-left: 10px;
    display: inline-flex;
  }
`;

export const TitleLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-size: 20px;
  font-weight: bold;
  color: #343a40;
`;

export const Description = styled.div`
  margin: 10px;
  font-size: 1.5em;
  line-height: 1.2em;
`;

export const InvisibleButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

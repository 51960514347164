import React, { useEffect } from 'react';
import { __ } from 'artsteps2-common';
import MetaTags from '../components/layouts/MetaTags';
import FullLayout from '../components/layouts/FullLayout';
import { setLocation } from '../actions';
import { withState, withDispatch, compose } from '../enhancers';
import { getAuthUser } from '../reducers';
import LoginForm from '../modal_content/LoginForm';
import { BodyWithPadding } from '../styles/GenericStyled';
import useWindowSize from '../hooks/windowSize';

const LoginPageView = ({ currentUser, onRedirect }) => {
  useEffect(() => {
    if (currentUser) onRedirect('');
  }, [currentUser]);

  const size = useWindowSize();

  return (
    <FullLayout>
      <MetaTags title={__('sign_in')} description={__('sign_in_header')} />
      <div className="login-form">
        <div className="ui two column stackable grid">
          {size.width >= 812 && (
            <div className="stretched column cover-column">
              <div className="ui basic segment">
                <h1 className="ui header white-text">{__('sign_in_subheader')}</h1>
                <h4>{__('sign_in_header')}</h4>
              </div>
            </div>
          )}
          <BodyWithPadding padding="20px" style={{ margin: 'auto' }}>
            <LoginForm page />
          </BodyWithPadding>
        </div>
      </div>
    </FullLayout>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
});

const mapDispatch = dispatch => ({
  onRedirect: page => dispatch(setLocation(page)),
});

const LoginPage = compose(withState(mapState), withDispatch(mapDispatch))(LoginPageView);

export default LoginPage;

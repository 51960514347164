import React from 'react';
import MetaTags from '../layouts/MetaTags';
import { withState } from '../../enhancers';
import { getApiResource } from '../../reducers';

export const CategoryMetaView = ({ category = {} }) => <MetaTags title={category.title} />;

const mapState = (state, { categoryId }) => ({
  category: getApiResource(state, `articleCategories/${categoryId}`),
});

const CategoryMeta = withState(mapState)(CategoryMetaView);

export default CategoryMeta;

import VisibilityIcon from '@material-ui/icons/Visibility';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Box from '@material-ui/core/Box';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EditIcon from '@material-ui/icons/Edit';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import DropDownMenu from '../../generic/DropDownMenu';
import { InvisibleButton } from './ExhibitionInfoStyled';
import ExhibitionLikes from './ExhibitionLikes';
import ExhibitionViews from './ExhibitionViews';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { apiGET, setUIProperty } from '../../../actions';
import { getApiResource, getUIProperty } from '../../../reducers';
import utils from '../../../utils';
import ExhibitionEmbed from './ExhibitionEmbed';
import ExhibitionReport from './ExhibitionReport';
import ExhibitionPicker from './ExhibitionPicker';
import ExhibitionDeletion from './ExhibitionDeletion';
import useWindowSize from '../../../hooks/windowSize';
import colors from '../../../styles/colors';
import MyTooltip from '../../../components/generic/MyToolTip';
import {
  HorizontalItems,
  Text,
  NLinesBreak,
  FullLine,
  ExpandButton,
} from '../../../styles/GenericStyled';

const bp1 = 650; // break point 1

// const useStyles = makeStyles(theme => ({
//   mobileIcon: {
//     fontSize: 35,
//   },
// }));

const ExhibitionInfoFirstRowView = ({
  exhibition,
  currentUserId,
  showDescription = true,
  setShowDescription,
}) => {
  // const classes = useStyles();
  const size = useWindowSize();

  const [isMobile, setIsMobile] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  // having an effect that sets if the screen size if mobile or not
  useEffect(() => {
    setIsMobile(size.width < bp1);
  }, [size.width]);
  return (
    <>
      <HorizontalItems style={{ position: 'relative' }}>
        {isMobile && (
          <InvisibleButton
            onClick={() => setShowDescription(!showDescription)}
            style={{ position: 'absolute', top: '0px', right: '0px', zIndex: '100' }}
          >
            <HorizontalItems>
              {showDescription ? (
                <span>
                  <ArrowDropUpIcon />
                </span>
              ) : (
                <span>
                  <ArrowDropDownIcon />
                </span>
              )}
            </HorizontalItems>
          </InvisibleButton>
        )}
        <span style={{ width: '100%' }} title={exhibition.title}>
          <NLinesBreak lines={2}>
            <Text type="h6">{exhibition.title}</Text>
          </NLinesBreak>
        </span>
      </HorizontalItems>
      <HorizontalItems style={{ position: 'relative', flexWrap: 'wrap', marginTop: '5px' }}>
        {!isMobile && (
          <HorizontalItems style={{ marginBottom: '10px' }}>
            <span style={{ opacity: 0.5 }}>
              <VisibilityIcon />
              <b>
                <ExhibitionViews exhibitionId={exhibition._id} />
              </b>
            </span>
            {exhibition.publishedAt && (
              <span style={{ opacity: 0.5 }}>
                <AccessTimeIcon />
                <b>{utils.date.fromNow(new Date(exhibition.publishedAt))}</b>
              </span>
            )}
          </HorizontalItems>
        )}
        {false && (
          <span style={{ opacity: 0.5 }}>
            <DirectionsWalkIcon />
            <b>18</b>
          </span>
        )}

        <span>
          <ExhibitionPicker exhibitionId={exhibition._id} />
        </span>
        <span>
          <ExhibitionDeletion exhibitionId={exhibition._id} />
        </span>
        {!isMobile && (
          <>
            <span style={{ marginLeft: 'auto' }}>
              <ExhibitionEmbed exhibitionId={exhibition._id} />
            </span>
            <span style={{ marginLeft: '9px' }}>
              <ExhibitionLikes exhibitionId={exhibition._id} />
            </span>
            <span style={{ marginLeft: '9px' }}>
              {currentUserId !== exhibition.user._id && (
                <>
                  <DropDownMenu>
                    <HorizontalItems
                      onClick={() => setOpenReport(true)}
                      background="#F3757550"
                      mycolor="#F37575"
                    >
                      <span>
                        <Text type="body2">Report</Text>
                        <ReportProblemIcon style={{ color: 'red', opacity: '0.5' }} />
                      </span>
                    </HorizontalItems>
                  </DropDownMenu>
                  <ExhibitionReport
                    openReport={openReport}
                    setOpenReport={setOpenReport}
                    exhibitionId={exhibition._id}
                  />
                </>
              )}
            </span>
            {currentUserId === exhibition.user._id && (
              <span>
                <MyTooltip placement="top" title="Edit">
                  <Link to={`/curate/${exhibition._id}/1`}>
                    <Box
                      component={ExpandButton}
                      boxShadow={3}
                      mycolor={colors.ourBlack}
                      mycolorhover={colors.ourWhite}
                      background={colors.ourWhite}
                      backgroundhover={colors.ourBlack}
                    >
                      <EditIcon />
                    </Box>
                  </Link>
                </MyTooltip>
              </span>
            )}
          </>
        )}
      </HorizontalItems>

      {isMobile && (
        <HorizontalItems>
          <span style={{ opacity: 0.5 }}>
            <VisibilityIcon />
            <b>
              <ExhibitionViews exhibitionId={exhibition._id} />
            </b>
          </span>

          {exhibition.publishedAt && (
            <span style={{ opacity: 0.5 }}>
              <AccessTimeIcon />
              <b>{utils.date.fromNow(new Date(exhibition.publishedAt))}</b>
            </span>
          )}
        </HorizontalItems>
      )}

      {isMobile && (
        <>
          <FullLine />
          <HorizontalItems style={{ margin: '15px 0px 15px', justifyContent: 'space-around' }}>
            <ExhibitionLikes exhibitionId={exhibition._id} />

            <ExhibitionEmbed mobile="true" exhibitionId={exhibition._id} />

            {currentUserId !== exhibition.user._id && (
              <>
                <DropDownMenu>
                  <HorizontalItems
                    onClick={() => setOpenReport(true)}
                    background="#F3757550"
                    mycolor="#F37575"
                  >
                    <span>
                      <Text type="body2">Report</Text>
                      <ReportProblemIcon style={{ color: 'red', opacity: '0.5' }} />
                    </span>
                  </HorizontalItems>
                </DropDownMenu>
                <ExhibitionReport
                  openReport={openReport}
                  setOpenReport={setOpenReport}
                  exhibitionId={exhibition._id}
                />
              </>
            )}
            {currentUserId === exhibition.user._id && (
              <span style={{ marginLeft: '9px' }}>
                <MyTooltip placement="top" title="Edit">
                  <Link to={`/curate/${exhibition._id}/1`}>
                    <Box
                      component={ExpandButton}
                      boxShadow={3}
                      mycolor={colors.ourBlack}
                      mycolorhover={colors.ourWhite}
                      background={colors.ourWhite}
                      backgroundhover={colors.ourBlack}
                    >
                      <EditIcon />
                    </Box>
                  </Link>
                </MyTooltip>
              </span>
            )}
          </HorizontalItems>
        </>
      )}
    </>
  );
};

const mapState = (state, { exhibition }) => ({
  showDescription: getUIProperty(state, 'showDescription'),
  user: getApiResource(state, `users/${exhibition.user._id || exhibition.user}`), // In the profile view the user isnt an object
  isReportOpen: getUIProperty(state, `exhibitions/overlay/report`),
});

const mapDispatch = (dispatch, { exhibition }) => ({
  setShowDescription: showDescription =>
    dispatch(setUIProperty('showDescription', showDescription)),
  onFetchUser: () => dispatch(apiGET(`users/${exhibition.user._id}`)),
  onOpenReport: () => dispatch(setUIProperty(`exhibitions/overlay/report`, true)),
  onCloseReport: () => dispatch(setUIProperty(`exhibitions/overlay/report`, false)),
});

const lifecycleMap = {
  onDidMount: ({ exhibition = {}, onFetchUser }) => {
    exhibition.user._id && onFetchUser();
  },
  onDidUpdate: (props, { exhibition = {}, onFetchUser }) =>
    exhibition.user._id !== props.exhibition.user._id && onFetchUser(),
};
const ExhibitionInfoFirstRow = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionInfoFirstRowView);
export default ExhibitionInfoFirstRow;

import { initializeApp } from 'firebase/app';
import privateConfig from 'artsteps2-config/private.json';

import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where, setDoc, doc } from 'firebase/firestore';

let database = {};
let auth = {};

if (privateConfig?.voting?.active) {
  const firebaseConfig = { ...privateConfig.firebase };
  const firebase = initializeApp(firebaseConfig);

  database = getFirestore(firebase);
  auth = getAuth(firebase);
}

const firebaseActions = {
  castVote: async function(userId, exhibitionId) {
    await signInAnonymously(auth);
    await setDoc(doc(database, 'votes', userId), {
      exhibition: exhibitionId,
      userId: userId,
      timestamp: new Date().toISOString(),
    });
  },

  voteEmpty: async function(userId) {
    await signInAnonymously(auth);
    const q = query(collection(database, 'votes'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    /*querySnapshot.forEach(doc => {
      console.log(doc.id, ' => ', doc.data());
    });*/
    return querySnapshot.empty;
  },

  getAllVotes: async function() {
    await signInAnonymously(auth);
    const querySnapshot = await getDocs(collection(database, 'votes'));
    const votes = [];
    querySnapshot.forEach(doc => {
      votes.push(doc.data());
    });
    return votes;
  },
};
export default firebaseActions;

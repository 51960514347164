import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose, withDispatch, withState } from '../../enhancers';
import { setUIProperty } from '../../actions';
import { getUIProperty } from '../../reducers';
import { Text, VerticalItems } from '../../styles/GenericStyled';
import {
  IFTopBar,
  IFDiv,
  IFCloseIcon,
  IFFullscreenExitIcon,
  IFFullscreenIcon,
} from '../../styles/styledComponents/IFrameStyled';
import utils from '../../utils';

const YoutubeFrameView = ({
  src = '',
  width,
  height,
  setIFrameLink,
  showTopBar,
  IFFullscreen = false,
  setIFFullscreen = () => true,
  absolute,
  onPreviewLoad = () => true,
}) => {
  const [IFrameReady, setIFrameReady] = useState(false);
  const [url, setURL] = useState('');

  useEffect(() => {
    if ((src && src.includes('youtu.be/')) || src.includes('youtube.com')) {
      setURL(utils.youtube.getYoutubeEmbed(src));
      document.getElementById('iframe-div').focus();
    }
  }, [src]);

  const handleClose = () => {
    setIFrameLink(undefined);
    document.getElementById('containter-unity-frame').focus();
  };

  return (
    <IFDiv
      id="iframe-div"
      tabIndex="0"
      width={IFFullscreen ? '100%' : width}
      height={IFFullscreen ? '100%' : height}
      absolute={+absolute}
    >
      {showTopBar && (
        <IFTopBar>
          <div style={{ marginLeft: 'auto' }}>
            {IFFullscreen ? (
              <IFFullscreenExitIcon onClick={() => setIFFullscreen(false)} />
            ) : (
              <IFFullscreenIcon onClick={() => setIFFullscreen(true)} />
            )}
            <IFCloseIcon onClick={handleClose} />
          </div>
        </IFTopBar>
      )}

      <div style={{ position: absolute ? 'absolute' : 'relative', height: '100%', width: '100%' }}>
        <iframe
          onLoad={() => {
            onPreviewLoad(null, 'youtube');
            setIFrameReady(true);
          }}
          style={{
            background: 'white',
            width: '100%',
            height: '100%',
            display: IFrameReady ? 'block' : 'none',
          }}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          width="100%"
          height="100%"
          src={url}
          title="youtube-video-embed"
        />
        {!IFrameReady && (
          <VerticalItems style={{ height: '100%', width: '100%' }}>
            <CircularProgress />
            <Text type="h6">Loading...</Text>
          </VerticalItems>
        )}
      </div>
    </IFDiv>
  );
};

const mapState = state => ({
  IFFullscreen: getUIProperty(state, 'IFFullscreen'),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  setIFFullscreen: value => dispatch(setUIProperty('IFFullscreen', value)),
  setIFrameLink: word => dispatch(setUIProperty(`exhibitions/${exhibitionId}/IFrameLink`, word)),
});

const YoutubeFrame = compose(withState(mapState), withDispatch(mapDispatch))(YoutubeFrameView);

export default YoutubeFrame;

import RoomIcon from '@material-ui/icons/Room';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { __ } from 'artsteps2-common';
import colours from '../../../../styles/colours.json';
import Overlay from '../../../generic/Overlay';
import StorypointList from '../../../storypoints/lists/StorypointList';
import StorypointEditor from '../../../storypoints/forms/StorypointEditor';
import utils from '../../../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../../../enhancers';
import { apiGET, setUIProperty, setFormProperty } from '../../../../actions';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getAuthUser,
  getUIProperty,
  getFormProperty,
} from '../../../../reducers';
import {
  HorizontalItems,
  StyledButton,
  StyledTextField,
  Text,
} from '../../../../styles/GenericStyled';
import MyToolTip from '../../../generic/MyToolTip';
import { ToolbarSection, UploadContainer } from '../ExhibitionEditStyled';

const DEFAULT_STORY_ID = 'default';
const COLOURS = colours.random;

export const ExhibitionStoryPanelView = ({
  storypoints = [],
  exhibitionId,
  searchTerm,
  editorOpen = false,
  isPreviouslySaved = true,
  onEditorOpen = () => Promise.resolve(false),
  onEditorClose = () => Promise.resolve(false),
  onPlaceStorypoint = () => Promise.resolve(false),
  onSearch = () => Promise.resolve(false),
}) => {
  const getNextColour = (offset = 0) => {
    const colour = COLOURS[(storypoints.length + offset) % COLOURS.length];
    if (storypoints.some(p => p.colour === colour) && offset <= COLOURS.length) {
      return getNextColour(offset + 1);
    }
    return colour;
  };

  const getNextOrder = () => Math.max(-1, ...storypoints.map(p => p.order)) + 1;

  const renderStorypoints = () => {
    const searchText = (searchTerm || '').toLowerCase();
    const filteredStorypoints = storypoints.filter(
      s =>
        !searchText ||
        (s.title && s.title.toLowerCase().indexOf(searchText) > -1) ||
        (s.description && s.description.toLowerCase().indexOf(searchText) > -1) ||
        (s.audio &&
          s.audio.blob &&
          s.audio.blob.name &&
          s.audio.blob.name.toLowerCase().indexOf(searchText) > -1) ||
        (s.audio && s.audio.uri && s.audio.uri.toLowerCase().indexOf(searchText) > -1),
    );

    if (filteredStorypoints.length && !storypoints.length) {
      return <div className="help-text">{__('no_storypoints_found')}</div>;
    }

    return (
      <StorypointList
        storypoints={filteredStorypoints}
        controls
        storyId={DEFAULT_STORY_ID}
        exhibitionId={exhibitionId}
      />
    );
  };

  return (
    <div className="exhibition-story-container">
      <ToolbarSection>
        <Text type="h7">
          {__('story_points')} ({storypoints.length})
        </Text>
        <HorizontalItems>
          <StyledTextField
            style={{ padding: '10px' }}
            fullWidth
            variant="outlined"
            type="text"
            placeholder={__('search')}
            onChange={event => onSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </HorizontalItems>
        <UploadContainer>
          <MyToolTip
            placement="top"
            title={isPreviouslySaved ? __('autosave_failure') : __('add_story_point_tooltip')}
          >
            <UploadContainer>
              <StyledButton
                disabled={isPreviouslySaved}
                type="secondary"
                data-inverted
                data-position="top center"
                onClick={onEditorOpen}
              >
                <RoomIcon />
                {__('add_story_point')}
              </StyledButton>
            </UploadContainer>
          </MyToolTip>
          {renderStorypoints()}
        </UploadContainer>
      </ToolbarSection>
      <Overlay fixed open={editorOpen} onClose={onEditorClose}>
        <StorypointEditor
          exhibitionId={exhibitionId}
          colour={getNextColour()}
          order={getNextOrder()}
          onSave={storypoint => onEditorClose().then(() => onPlaceStorypoint(storypoint))}
        />
      </Overlay>
    </div>
  );
};

const createQuery = ({ _id: user } = {}, exhibition) => ({
  filter: { user, exhibition },
});

const mapState = (state, { exhibitionId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    storypoints: Object.values(
      getApiResource(state, 'storypoints', createQuery(currentUser, exhibitionId)),
    ).sort((a, b) => a.order - b.order),
    ready:
      getApiStatus(state, 'storypoints', createQuery(currentUser, exhibitionId)) ===
      API_STATUS.IDLE,
    searchTerm: getFormProperty(state, 'storypoints', 'search'),
    editorOpen: getUIProperty(state, 'storypoint:new/editor'),
    isPreviouslySaved: !getUIProperty(state, `exhibitions/${exhibitionId}/import`),
  };
};

const mapDispatch = (dispatch, { exhibitionId, painting, currentUser }) => ({
  onFetchStorypoints: () => dispatch(apiGET('storypoints', createQuery(currentUser, exhibitionId))),
  onSearch: searchTerm => dispatch(setFormProperty('storypoints', 'search', searchTerm)),
  onEditorOpen: () => dispatch(setUIProperty('storypoint:new/editor', true)),
  onEditorClose: () => dispatch(setUIProperty('storypoint:new/editor', false)),
  onPlaceStorypoint: storypoint =>
    utils.exhibition
      .exportStorypoint(storypoint)
      .then(exportedStorypoint =>
        dispatch(
          setUIProperty(`exhibitions/${exhibitionId}/placingStorypoint`, exportedStorypoint),
        ),
      ),
});

const lifecycleMap = {
  onDidMount: ({ onFetchStorypoints }) => onFetchStorypoints(),
};

const ExhibitionStorypointsPanel = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionStoryPanelView);

export default ExhibitionStorypointsPanel;

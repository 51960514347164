import React from 'react';
import Grid from '@material-ui/core/Grid';
import { VideoArea } from '../../styles/PrivateSpacesPageStyled';
import videoPreview from '../../styles/images/privateSpaces/virtual-events-video.mp4';
import { Controller, Scene } from 'react-scrollmagic';

const ServicesContent = ({ video = videoPreview, size, children, ...props }) => {
  return (
    <div>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems={size.width > 1279 ? 'flex-start' : 'center'}
        //alignItems="flex-start"
        spacing={4}
        style={{ marginTop: '40px', height: '100%', marginBottom: '80px' }}
      >
        <Grid item xs={12} lg={6}>
          {children}
        </Grid>
        <Grid item xs={12} lg={6} style={{ height: '100%' }}>
          {size.width > 1279 ? (
            <Controller>
              <div>
                <Scene duration={800} pin enabled offset={100}>
                  <div>
                    <VideoArea active autoPlay loop>
                      <source src={video} type="video/mp4" />
                    </VideoArea>
                  </div>
                </Scene>
              </div>
            </Controller>
          ) : (
            <div style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}>
              <VideoArea active autoPlay loop>
                <source src={video} type="video/mp4" />
              </VideoArea>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ServicesContent;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileAssetUrl = void 0;

var _public = _interopRequireDefault(require("artsteps2-config/public.json"));

var FILE_SYSTEM_ROOT_URL = _public["default"].usersFileSystemUrl;

var getFileAssetUrl = function getFileAssetUrl(userId, fileAssetName) {
  if (!userId || !fileAssetName) return null;
  return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(userId, "/").concat(fileAssetName);
};

exports.getFileAssetUrl = getFileAssetUrl;
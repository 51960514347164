import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import { StyledSelectInput } from '../forms/FormStyled';

const UploadOptionSelect = ({ uploadOption, setUploadOption, type, options = [], noHint }) => {
  return (
    <FormControl variant="filled" style={{ width: '100%' }}>
      {!noHint && (
        <HorizontalItems>
          <Text>Upload option</Text>
        </HorizontalItems>
      )}
      <Select
        style={{ width: '100%' }}
        MenuProps={{ disableScrollLock: true }}
        input={<StyledSelectInput />}
        value={uploadOption}
        name="Upload option"
        onChange={e => setUploadOption(e.target.value)}
      >
        {options.map(option => (
          <MenuItem
            key={option.value || option._id || option.value || option.name}
            value={option.value || option._id}
          >
            {option.name || option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UploadOptionSelect;

import React from 'react';
import '../styles/artifact-viewer.css';
import { compose, withDispatch } from '../enhancers';
import { setUIProperty } from '../actions';

const addHttp = link => {
  let linkParts = link.split('.');
  if (linkParts.length > 0 && linkParts[0].includes('http')) return link;

  return `https://${link}`;
};

const IFrameButtonView = ({ word }) => (
  <a
    className="linkButton ghostButton"
    href={addHttp(word)}
    target="_blank"
    rel="noopener noreferrer"
  >
    {word}
  </a>
);

const mapDispatch = (dispatch, { exhibitionId }) => ({
  setIFrameLink: word => dispatch(setUIProperty(`exhibitions/${exhibitionId}/IFrameLink`, word)),
});

const IFrameButton = compose(withDispatch(mapDispatch))(IFrameButtonView);

export default IFrameButton;

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { __ } from 'artsteps2-common';
import Loader from '../generic/Loader';
import utils from '../../utils';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getApiMeta,
  getAuthUser,
  getUIProperty,
} from '../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { apiGET, apiPOST, apiDELETE, apiHEAD, setUIProperty } from '../../actions';
import { Text } from '../../styles/GenericStyled';
import { HorizontalItems, InvisibleButton } from '../exhibitions/viewer/ExhibitionInfoStyled';
import Avatar from '@material-ui/core/Avatar';
import { isWidthUp, withWidth } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    [theme.breakpoints.up('md')]: {
      paddingTop: '100px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '50px',
    },
  },
  avatar: {
    [theme.breakpoints.up('lg')]: {
      width: '150px',
      height: '150px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '100px',
      height: '100px',
    },
    [theme.breakpoints.only('md')]: {
      width: '100px',
      height: '100px',
    },
    [theme.breakpoints.only('sm')]: {
      width: '70px',
      height: '70px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '32px',
      height: '32px',
      marginTop: '20px',
    },
  },
}));

export const ProfileViewerView = ({
  user: { profile = {}, _id: userId } = {},
  ready = true,
  isEditing,
  currentUser,
  followers = 0,
  following = 0,
  onFollow,
  onUnfollow,
  onStartEditing = () => false,
  width,
}) => {
  const classes = useStyles();

  const [showDescription, setShowDescription] = React.useState(true);
  return (
    <div className={classes.mainDiv}>
      {ready ? null : <Loader />}
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={2}>
          {profile.image && (
            <Avatar
              className={classes.avatar}
              alt={__('avatar')}
              src={utils.user.getUserImage({ _id: userId, profile })}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <HorizontalItems>
            <Text type={isWidthUp('md', width) ? 'h3' : 'h4'}>{profile.name}</Text>
            {!isEditing && currentUser && userId === currentUser._id && (
              <IconButton style={{ marginLeft: 'auto' }} onClick={() => onStartEditing()}>
                <SettingsIcon style={{ color: '#C9C9C9' }} fontSize="large" />
              </IconButton>
            )}
          </HorizontalItems>

          {profile.occupation && <Text type="sub1">{profile.occupation}</Text>}

          {(profile.country || profile.city) && (
            <Text type="sub1">
              {profile.city && profile.city}
              {profile.country && profile.city ? <span>, </span> : null}
              {profile.country && __(`regions.country_${profile.country}`)}
            </Text>
          )}
          <HorizontalItems>
            {profile.website && <Text type="h5">{profile.website}</Text>}
            {profile.bio && isWidthUp('sm', width) && (
              <InvisibleButton
                onClick={() => setShowDescription(!showDescription)}
                style={{ marginLeft: 'auto' }}
              >
                <HorizontalItems>
                  {showDescription ? (
                    <span>
                      <Text type="h6">{__('less')}</Text> <ArrowDropUpIcon fontSize="large" />
                    </span>
                  ) : (
                    <span>
                      <Text type="h6">{__('more')}</Text> <ArrowDropDownIcon fontSize="large" />
                    </span>
                  )}
                </HorizontalItems>
              </InvisibleButton>
            )}
          </HorizontalItems>
        </Grid>
      </Grid>
      <br />
      {profile.bio && showDescription && <Text type="sub2">{profile.bio}</Text>}
    </div>
  );
};

const mapState = (state, { userId }) => {
  const { _id: currentUserId } = getAuthUser(state) || {};
  return {
    currentUserId,
    currentUser: getAuthUser(state),
    isEditing: getUIProperty(state, `profiles/${userId}/editing`),
    follows: Object.values(
      getApiResource(state, 'follows', {
        filter: { user: userId, follower: currentUserId },
      }),
    ),
    user: getApiResource(state, `users/${userId}`),
    following: getApiMeta(state, 'follows', { filter: { follower: userId } }).totalCount,
    followers: getApiMeta(state, 'follows', { filter: { user: userId } }).totalCount,
    currentFollow: [0],
    ready:
      getApiStatus(state, `users/${userId}`) === API_STATUS.IDLE &&
      getApiStatus(state, 'follows', { filter: { follower: userId } }) === API_STATUS.IDLE &&
      getApiStatus(state, 'follows', { filter: { user: userId } }) === API_STATUS.IDLE,
  };
};

const mapDispatch = (dispatch, { userId, currentUserId, follows = [] }) => ({
  onFetchFollows: () =>
    currentUserId && currentUserId !== userId
      ? dispatch(
          apiGET('follows', {
            filter: { user: userId, follower: currentUserId },
          }),
        )
      : Promise.resolve(undefined),
  onQueryFollowers: () => dispatch(apiHEAD('follows', { filter: { user: userId } })),
  onQueryFollowing: () => dispatch(apiHEAD('follows', { filter: { follower: userId } })),
  onFetchUser: () => dispatch(apiGET(`users/${userId}`)),
  onFollow:
    currentUserId &&
    currentUserId !== userId &&
    follows.length === 0 &&
    (() =>
      dispatch(
        apiPOST(
          'follows',
          { user: userId },
          {
            filter: { user: userId },
            populate: 'follower',
          },
        ),
      )),
  onUnfollow:
    currentUserId &&
    currentUserId !== userId &&
    follows.length > 0 &&
    (() => dispatch(apiDELETE(`follows/${follows[0]._id}`))),
  onStartEditing: () => dispatch(setUIProperty(`profiles/${userId}/editing`, true)),
});

const onInitialization = ({ onFetchUser, onFetchFollows, onQueryFollowers, onQueryFollowing }) =>
  Promise.all([onFetchUser(), onQueryFollowers(), onQueryFollowing(), onFetchFollows()]);

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: ({ userId }, props) => userId !== props.userId && onInitialization(props),
};

const ProfileViewer = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
  withWidth(),
)(ProfileViewerView);

export default ProfileViewer;

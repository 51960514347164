import React, { Component } from 'react';
import qs from 'qs';
import config from 'artsteps2-config/public.json';
import { __ } from 'artsteps2-common';

const PAGE_SIZE = 10;
const SEARCH_TIMEOUT = 600;
const FORMAT = 'OBJ';
const POLY_API_PATH = 'https://poly.googleapis.com/v1/assets';

const toArray = obj => {
  if (Array.isArray(obj)) {
    return obj;
  }
  return obj ? [obj] : [];
};

class PolySelect extends Component {
  // Component Methods -------------------------------------------------------
  constructor(props) {
    super(props);
    const value = props.asArray ? (props.value || [])[0] : props.value;
    this.state = {
      searchTerm: '',
      results:
        value && value.uri && value.image
          ? [{ thumbnail: { url: value.image.uri }, url: value.uri }]
          : [],
      loading: false,
      // Pagging
      currentPage: 1,
      totalPages: 0,
    };
  }

  componentWillReceiveProps(props) {
    const value = props.asArray ? (props.value || [])[0] : props.value;
    if (this.state.results.length || !value || !value.uri || !value.image) {
      return;
    }

    this.setState({
      results: [
        {
          thumbnail: { url: (value.image || value).uri },
          url: value.uri,
        },
      ],
    });
  }

  // Ui Events -------------------------------------------------------
  onInputChanged(v, a) {
    this.onAssetClicked(null); // unselect previously selected value
    this.cachedResults = []; // clear cached results
    const newValue = v.target.value;
    this.setState({ searchTerm: newValue, loading: true });

    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      PolySelect.getData(newValue, null).then(res => {
        this.setState({
          results: res.assets,
          loading: false,
          currentPage: 1,
          totalPages: Math.floor(res.totalSize / PAGE_SIZE),
        });
        this.cachedResults = [res.assets, res.nextPageToken];
      });
    }, SEARCH_TIMEOUT);
  }

  onAssetClicked(asset) {
    const value = asset && {
      uri: asset.url,
      image: { uri: asset.thumbnail.url },
      type: 'poly',
    };

    const resources = asset && asset.resources.map(uri => ({ uri }));

    this.props.setFiles(this.props.asArray ? [...toArray(value), ...toArray(resources)] : value);
  }

  // Get Data Promise -------------------------------------------------------
  static getData(keywords, pageToken) {
    const query = {
      format: FORMAT,
      maxComplexity: 'MEDIUM',
      pageSize: PAGE_SIZE,
      key: config.poly.apikey,
      keywords,
      pageToken: pageToken || '',
    };
    const url = `${POLY_API_PATH}?${qs.stringify(query)}`;
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json; encoding=utf8',
    });

    return fetch(url, { headers, method: 'GET' })
      .then(res => res.json())
      .then(({ assets = [], totalSize = 0, nextPageToken = '' } = {}) => {
        const filteredAssets = assets
          .filter(asset => asset.formats.find(f => f.formatType === FORMAT))
          .map(asset => {
            const format = asset.formats.find(f => f.formatType === FORMAT);
            return {
              ...asset,
              url: format.root.url,
              resources: (format.resources || []).map(r => r.url),
            };
          });

        return {
          assets: filteredAssets,
          nextPageToken,
          totalSize,
        };
      });
  }

  showNextPage(offset) {
    if (this.state.loading) return;

    const nextPage = this.state.currentPage + offset;
    const pageResults = this.cachedResults[nextPage - 1];
    if (typeof pageResults !== 'string') {
      this.setState({ results: pageResults, currentPage: nextPage });
      return;
    }

    // results are not cached
    this.setState({ loading: true });
    PolySelect.getData(this.state.searchTerm, pageResults).then(res => {
      this.setState({
        results: res.assets,
        loading: false,
        currentPage: nextPage,
      });
      this.cachedResults[nextPage - 1] = res.assets;
      this.cachedResults.push(res.nextPageToken);
    });
  }

  // Rendering -------------------------------------------------------
  renderPaging() {
    if (this.state.totalPages === 0) {
      return null;
    }
    return (
      <div className="pager">
        {/* Previous Page Button */}
        {this.state.currentPage <= 1 || (
          <button
            onClick={() => this.showNextPage(-1)}
            className="ui basic compact tiny button"
            type="button"
          >
            <i className="left chevron icon" />
          </button>
        )}

        {/* Current Page */}
        <span className="page">
          {this.state.currentPage}/{this.state.totalPages}
        </span>

        {/* Next Page Button */}
        {this.state.currentPage < this.state.totalPages && (
          <button
            onClick={() => this.showNextPage(1)}
            className="ui basic compact tiny button"
            type="button"
          >
            <i className="right chevron icon" />
          </button>
        )}
      </div>
    );
  }

  render() {
    const value = this.props.asArray ? (this.props.value || [])[0] : this.props.value;

    return (
      <div
        style={{ width: '100%' }}
        className={`poly-selector ui field ${this.props.required ? 'required' : ''}`}
      >
        <label htmlFor={this.props.id}>{this.props.label || ''}</label>
        <div
          style={{ width: '100%' }}
          className={`ui right icon input${this.state.loading ? ' loading' : ''}`}
        >
          <input
            id={this.props.id}
            type="text"
            placeholder={__('search')}
            className="ui input"
            autoComplete="off"
            value={this.state.searchTerm}
            onChange={v => this.onInputChanged(v)}
          />
          <i className="search icon" />
        </div>

        <div className="upload-container poly">
          <div className="artifact-list">
            <div className="ui horizontal list">
              {this.state.results.map(asset => (
                <div
                  role="button"
                  tabIndex={0}
                  key={asset.url}
                  className="artifact-wrapper item"
                  onKeyPress={event => event.keyCode === 13 && this.onAssetClicked(asset)}
                  onClick={() => this.onAssetClicked(asset)}
                >
                  {asset.thumbnail && (
                    <div
                      className={`artifact ${value && value.uri === asset.url ? 'selected' : ''}`}
                    >
                      <img alt={asset.displayName || ''} src={asset.thumbnail.url} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {this.renderPaging()}
      </div>
    );
  }
}

export default PolySelect;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getArticleImage = exports.getArticleBlobURI = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _buffer = require("./buffer");

var PATH_ARTICLES = '/api/articles';
var PATH_ARTICLE_IMAGE = 'images';

var getRootURL = function getRootURL() {
  return typeof window === 'undefined' ? process.env.ROOT_URL || 'http://localhost:3000' : "".concat(window.location.protocol, "//").concat(window.location.host);
};

var getArticleBlobURI = function getArticleBlobURI(article, bin, path) {
  if (!bin) {
    return undefined;
  }

  if (bin.preview) {
    return bin.preview;
  }

  if (bin.content && bin.contentType) {
    return (0, _buffer.toDataURL)(bin);
  }

  return "".concat(getRootURL()).concat(PATH_ARTICLES, "/").concat(article._id, "/").concat(path);
};

exports.getArticleBlobURI = getArticleBlobURI;

var getArticleImage = function getArticleImage(article, title) {
  if (!article || !article.images) {
    return undefined;
  }

  var _article$images$map$f = article.images.map(function (im, idx) {
    return [im.bin, idx];
  }).find(function (bin) {
    return bin.name && bin.name.match(title);
  }),
      _article$images$map$f2 = (0, _slicedToArray2["default"])(_article$images$map$f, 2),
      image = _article$images$map$f2[0],
      index = _article$images$map$f2[1];

  if (!image || !image.contentType) {
    return undefined;
  }

  return getArticleBlobURI(article, image, "".concat(PATH_ARTICLE_IMAGE, "/").concat(index, "/bin"));
};

exports.getArticleImage = getArticleImage;
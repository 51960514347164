import React from 'react';
import { __ } from 'artsteps2-common';
import { compose, withDispatch, withState, withLifecycle } from '../../../enhancers';
import { API_STATUS, getApiResource, getApiStatus } from '../../../reducers';
import { apiGET } from '../../../actions';
import Follower from './Follower';
import Loader from '../../generic/Loader';

export const FollowListView = ({ userId, ready = true, follows = [] }) => {
  if (!ready) {
    return (
      <div className="ui main segment basic">
        <Loader />
      </div>
    );
  }

  if (!follows.length) {
    return (
      <div className="ui main segment basic">
        {__(userId ? 'no_followers_found' : 'no_following_found')}
      </div>
    );
  }

  return (
    <div className="ui main segment basic follower-list-grid">
      <div className="ui vertical list">
        {follows.map(
          follow =>
            follow._id && (
              <Follower
                key={follow._id}
                follower={{
                  ...(follow.follower || follow.user),
                  followingSince: follow.createdAt,
                }}
              />
            ),
        )}
      </div>
    </div>
  );
};

const createQuery = (user, follower) => ({
  filter: { user, follower },
  populate: ['user', 'follower'],
});

const mapState = (state, { userId, followerId }) => ({
  follows: Object.values(getApiResource(state, 'follows', createQuery(userId, followerId))).map(
    f => ({
      _id: f._id,
      createdAt: f.createdAt,
      ...(userId ? { follower: f.follower } : { user: f.user }),
    }),
  ),
  ready: getApiStatus(state, 'follows', createQuery(userId, followerId)) === API_STATUS.IDLE,
});

const mapDispatch = (dispatch, { userId, followerId }) => ({
  onFetchFollows: () => dispatch(apiGET('follows', createQuery(userId, followerId))),
});

const onInitialization = ({ onFetchFollows }) => onFetchFollows();

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: ({ userId, followerId }, props) =>
    (userId !== props.userId || followerId !== props.followerId) && onInitialization(props),
};

const FollowList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(FollowListView);

export default FollowList;

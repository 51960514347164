import React from 'react';
import OurModal from '../generic/OurModal';
import LoginForm from '../../modal_content/LoginForm';
import { BodyWithPadding } from '../../styles/GenericStyled';
import { withState, withDispatch, compose } from '../../enhancers';
import { getUIProperty } from '../../reducers';
import { setUIProperty } from '../../actions';

const LoginModalView = ({ openLogin, setOpenLogin }) => {
  return (
    <OurModal
      minWidth="320px"
      minHeight="560px"
      maxWidth="450px"
      maxHeight="800px"
      width="30%"
      height="80%"
      openModal={openLogin}
      setOpenModal={setOpenLogin}
    >
      <BodyWithPadding padding="40px">
        <LoginForm />
      </BodyWithPadding>
    </OurModal>
  );
};

const mapState = state => ({
  openLogin: getUIProperty(state, 'openLogin'),
});

const mapDispatch = dispatch => ({
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
});

const LoginModal = compose(withState(mapState), withDispatch(mapDispatch))(LoginModalView);

export default LoginModal;

import React, { useState, useEffect } from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import Recaptcha from 'react-recaptcha';
import { compose, withState, withDispatch } from '../enhancers';
import { getForm, getAuthUser, getUIProperty } from '../reducers';
import {
  apiPOST,
  setLocation,
  addMessage,
  clearMessages,
  clearFormData,
  setUIProperty,
} from '../actions';

const logo = require('../styles/images/send-button.png');

const initialState = {
  username: '',
  email: '',
  kind: '',
  subject: '',
  description: '',
};

function ContactPageView({
  onSetRecaptcha = () => Promise.resolve(false),
  recaptcha = '',
  open,
  onCreateContactForm = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onAddMessage,
  form,
}) {
  const [isVerified, setVerified] = useState(false);
  const [submitActive, setActive] = useState(true);
  const [inputData, setInputData] = useState({ ...initialState });

  const onSubmit = e => {
    e.preventDefault();
    onCreateContactForm(form.data)
      .then(response => {
        onClearMessages();
        setInputData({ ...initialState });
        onAddMessage({ title: 'Message Sent', type: 'success' });
      })
      .catch(error => onAddMessage({ title: error, type: 'error' }));
  };

  useEffect(() => {
    if (!open) onSetRecaptcha('contact_recaptcha');
  }, [open]);

  const recaptchaLoaded = () => {
    if (recaptcha !== 'contact_recaptcha') onSetRecaptcha('contact_recaptcha');
  };

  const recaptchaExpired = () => {
    setVerified(false);
  };

  const verifyCallback = response => {
    if (response) setVerified(!isVerified);
  };

  const handleChange = e => {
    inputData[e.target.name] = e.target.value;
    setInputData({ ...inputData });
    form.data = { ...inputData };
    if (!Object.keys(inputData).some(e => inputData[e] === '')) setActive(false);
    else setActive(true);
  };

  return (
    <DefaultLayout style={{ minHeight: 'unset' }}>
      <div className="contact-wrapper">
        <div className="contactus-header">
          <h2>Contact Artsteps</h2>
        </div>
        <div className="contact-section">
          <form className="contact-form" onSubmit={onSubmit}>
            <div className="ui two column stackable padded grid contact-grid">
              <div className="stretched column contact-column">
                <div className="form-field">
                  <label className="contact-label" htmlFor={'1-contact'}>
                    Your name
                  </label>
                  <div className="ui input">
                    <input
                      onChange={e => handleChange(e)}
                      type="text"
                      id={'1-contact'}
                      required
                      name="username"
                      value={inputData.username}
                      className="contact-input"
                    />
                  </div>
                </div>

                <div className="form-field">
                  <label className="contact-label">Your email</label>
                  <div className="ui input">
                    <input
                      onChange={handleChange}
                      type="email"
                      required
                      name="email"
                      value={inputData.email}
                      className="contact-input"
                    />
                  </div>
                </div>

                <div className="form-field">
                  <label className="contact-label">Type of contact</label>
                  <ul className="donate-now">
                    <li>
                      <input
                        onChange={handleChange}
                        type="radio"
                        id="technicalSup"
                        name="kind"
                        required
                        value="1"
                        checked={inputData.kind === '1' ? true : false}
                        className="contact-input"
                      />
                      <label htmlFor="technicalSup">Technical Support</label>
                    </li>
                    <li>
                      <input
                        onChange={handleChange}
                        type="radio"
                        id="Sales"
                        name="kind"
                        value="2"
                        checked={inputData.kind === '2' ? true : false}
                        className="contact-input"
                      />
                      <label htmlFor="Sales">Sales</label>
                    </li>
                  </ul>
                </div>

                <div className="form-field">
                  <label className="contact-label">Subject</label>
                  <div className="ui input">
                    <input
                      onChange={handleChange}
                      type="text"
                      required
                      name="subject"
                      value={inputData.subject}
                      className="contact-input"
                    />
                  </div>
                </div>
              </div>
              <div className="stretched column">
                <div className="form-field">
                  <label className="contact-label">Message</label>
                  <textarea
                    onChange={handleChange}
                    type="text"
                    required
                    rows="13"
                    name="description"
                    value={inputData.description}
                    className="contact-textarea"
                  />
                </div>
                <div className="form-field recap">
                  {recaptcha !== 'contribute_recaptcha' && (
                    <Recaptcha
                      sitekey="6Lf-Kv4UAAAAAAyo7tDAa5j4ArMF7oUYFiSXrW_8"
                      render="explicit"
                      onloadCallback={recaptchaLoaded}
                      expiredCallback={recaptchaExpired}
                      verifyCallback={verifyCallback}
                    />
                  )}
                </div>
                <button
                  className="contact-submit-button"
                  type="submit"
                  // onClick={onSubmit}
                  disabled={submitActive || !isVerified}
                >
                  Send your Message
                  <img className="send-button" src={logo} alt="paper-plane" />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="cover-column">
          {/* <AppIconsSection googlePlayURI={GOOGLE_PLAY_URI} appStoreURI={APP_STORE_URI} /> */}
        </div>
      </div>
    </DefaultLayout>
  );
}

const mapState = state => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    form: getForm(state, 'contact'),
    isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
    recaptcha: getUIProperty(state, 'recaptcha'),
    open: getUIProperty(state, 'contributionModalOpen'),
  };
};

const mapDispatch = dispatch => ({
  onCreateContactForm: form => dispatch(apiPOST('contact', form)),
  onRedirect: page => dispatch(setLocation(page)),
  onAddMessage: message => dispatch(addMessage(message, 'contact')),
  onClearForm: () => dispatch(clearFormData('contact')),
  onClearMessages: () => dispatch(clearMessages('contact')),
  onSetRecaptcha: loaded => dispatch(setUIProperty('recaptcha', loaded)),
});

const ContactPage = compose(withState(mapState), withDispatch(mapDispatch))(ContactPageView);

export default ContactPage;

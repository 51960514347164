import React, { useEffect, useState } from 'react';
import OurModal from '../generic/OurModal';
import { BodyWithPadding, StyledButton, Text, VerticalItems } from '../../styles/GenericStyled';
import COLORS from '../../styles/colors';
import privateConfig from 'artsteps2-config/private.json';
import Link from 'react-router-dom/Link';
import logo from '../../styles/images/logo-artsteps.png';
import {__} from "artsteps2-common";


const VotingInfoModal = () => {
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    const timesWatched = localStorage.getItem('showVotingInfoModal') || 0;
    if (
      privateConfig?.voting?.active &&
      timesWatched <= 5 &&
      (window.location.pathname === '/' || window.location.pathname === '/explore')
    )
      setTimeout(() => {
        localStorage.setItem('showVotingInfoModal', parseInt(timesWatched) + 1);
        return setOpenInfo(true);
      }, 5000);
  }, []);

  return (
    <OurModal
      width={'25%'}
      height={'35%'}
      minWidth="300px"
      minHeight="250px"
      openModal={openInfo}
      setOpenModal={setOpenInfo}
    >
      <BodyWithPadding padding="25px" style={{ textAlign: 'center' }}>
        <img style={{ width: '80px', height: '80px' }} src={logo} alt={__('brand_name')} />
        <Text type="h5" align="center">
          VR Exhibition Contest
        </Text>
        <br />
        <Text align="center" type="body1">
          Show the artsteps community some love and vote for your favorite creator.
        </Text>
        <br />
        <VerticalItems>
          <Link to={privateConfig.voting.uri}>
            <StyledButton type="secondary" background={COLORS.ourOrange} onClick={()=>setOpenInfo(false)}>
              Vote
            </StyledButton>
          </Link>
        </VerticalItems>
      </BodyWithPadding>
    </OurModal>
  );
};

export default VotingInfoModal;

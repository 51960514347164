import React from 'react';
import { __ } from 'artsteps2-common';
import { setUIProperty } from '../../../actions';
import { compose, withDispatch, withState } from '../../../enhancers';
import { getUIProperty } from '../../../reducers';
import MyToolTip from '../../generic/MyToolTip';
import { OptionButton } from '../../../styles/GenericStyled';

export const ViewportControllerView = ({ fullscreen, onToggleFullscreen }) => (
  <div>
    <MyToolTip placement="right" title={__('fullscreen')}>
      <OptionButton onClick={onToggleFullscreen}>
        <i className={`ui ${fullscreen ? 'compress' : 'expand'} icon`} />
      </OptionButton>
    </MyToolTip>
  </div>
);

const mapState = (state, { exhibition = {} }) => ({
  fullscreen: getUIProperty(state, `exhibitions/${exhibition._id}/fullscreen`),
});

const mapDispatch = (dispatch, { exhibition = {}, fullscreen }) => ({
  onToggleFullscreen: () =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/fullscreen`, !fullscreen)),
});

const ViewportController = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ViewportControllerView);

export default ViewportController;

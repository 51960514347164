import React from 'react';
import ExhibitionAudioPlayer from './ExhibitionAudioPlayer';
import ExhibitionQualitySettings from './ExhibitionQualitySettings';
import ExhibitionTutorial from './ExhibitionTutorial';
import ViewportController from './ViewportController';

const ExhibitionViewOptions = ({ exhibition, paragraphs }) => (
  <div style={{ position: 'absolute', top: '0px', left: '0px' }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ExhibitionTutorial exhibition={exhibition} paragraphs={paragraphs} />
      <ViewportController exhibition={exhibition} />
      <ExhibitionQualitySettings exhibition={exhibition} />
      <ExhibitionAudioPlayer exhibition={exhibition} />
    </div>
  </div>
);
export default ExhibitionViewOptions;

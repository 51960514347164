import React from 'react';
import { StyledDropZoneArea } from '../../artifacts/forms/FormStyled';
import utils from '../../../utils';

//https://yuvaleros.github.io/material-ui-dropzone/
const Uploader = ({ setFiles, maxFiles, acceptedFiles, uploadOption, type }) => {
  return (
    <StyledDropZoneArea
      maxFileSize={300000000}
      acceptedFiles={acceptedFiles}
      showPreviewsInDropzone={false} //Shows previews BELOW the dropzone.
      showPreviews={true} //Shows preview INSIDE the dropzone area.
      useChipsForPreview
      id="file-uploader"
      Icon="no-icon"
      filesLimit={maxFiles || 1}
      required
      onChange={files => {
        files.map(file => {
          return new Promise((resolve, reject) => {
            setFiles([]);
            const reader = new FileReader();
            reader.onload = event => {
              const { result } = event.target;

              setFiles(oldFileArray => [
                ...oldFileArray,
                {
                  size: file.size,
                  bin:
                    type === 'image' && uploadOption === 'file'
                      ? {}
                      : utils.buffer.fromDataURL(result, file.name, result),
                  base64: result,
                  type: file.type,
                  name: file.name,
                },
              ]);
            };
            // Wait till complete
            reader.onloadend = e => {
              //setUploadReady(true);
            };
            // Make sure to handle error states
            reader.onerror = e => {
              reject(e);
            };

            reader.readAsDataURL(file);
          });
        });
      }}
      onDelete={file => {
        setFiles(oldFileArray => oldFileArray.filter(f => f.bin.name !== file.name));
      }}
    />
  );
};
export default Uploader;

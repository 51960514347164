import React from 'react';
import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';
import { BlueSwitch } from '../forms/FormStyled';

const InteractiveField = ({ handleChange, checked, formData }) => (
  <HorizontalItems style={{ width: '100%' }}>
    <Text>Interactive</Text>
    <BlueSwitch
      checked={!checked}
      onChange={e =>
        handleChange({
          target: {
            name: 'model',
            value: { ...formData.model, nonInteractive: !e.target.checked },
          },
        })
      }
      name="model.nonInteractive"
    />
    <Text style={{ marginLeft: '10px' }} mycolor={colors.ourRed}>
      {!checked ? '(Can be clicked)' : '(Cannot be clicked)'}
    </Text>
  </HorizontalItems>
);

export default InteractiveField;

import ControlPointIcon from '@material-ui/icons/ControlPoint';
import React from 'react';
import { __ } from 'artsteps2-common';
import { withDispatch } from '../../enhancers';
import { setUIData } from '../../actions';
import { StyledButton } from '../../styles/GenericStyled';
import { UploadContainer } from '../exhibitions/editor/ExhibitionEditStyled';

export const ArtifactUploaderView = ({
  children,
  type = 'image',
  onEditorOpen = () => Promise.resolve(false),
}) => (
  <UploadContainer style={{ marginTop: '16px' }}>
    <StyledButton type="secondary" onClick={onEditorOpen} style={{ marginBottom: '17px' }}>
      <ControlPointIcon style={{ marginRight: '3px' }} /> {__(`artifact_add_${type}`)}
    </StyledButton>
    {children}
  </UploadContainer>
);

const mapDispatch = (dispatch, { type }) => ({
  onEditorOpen: () =>
    dispatch(
      setUIData('artifacts', {
        editingArtifactId: 'new',
        editingArtifactType: type,
      }),
    ),
});

const ArtifactUploader = withDispatch(mapDispatch)(ArtifactUploaderView);

export default ArtifactUploader;

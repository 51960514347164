import { assocPath, path as pathAssoc, mergeDeepRight, memoizeWith } from 'ramda';
import { UI_NAMESPACE, UI_ACTION_SET, UI_ACTION_MERGE } from '../actions/ui';

const splitPath = memoizeWith(str => str, str => (str ? str.split('/') : []));

export default (state = {}, { type: nsType = '', data }) => {
  const [namespace, ...path] = splitPath(nsType);
  if (namespace !== UI_NAMESPACE || !path || !path.length) {
    return state;
  }

  const type = path.pop();

  switch (type) {
    case UI_ACTION_SET: {
      return assocPath(path, data, state);
    }
    case UI_ACTION_MERGE: {
      return mergeDeepRight(state, assocPath(path, data, {}));
    }
    default: {
      return state;
    }
  }
};

export const getUIProperty = (state = {}, prop = '') => pathAssoc(splitPath(prop), state);

import React from 'react';
import utils from '../utils';

import ExhibitionViewFrame from '../components/exhibitions/viewer/ExhibitionViewFrame';
import ExhibitionMeta from '../components/exhibitions/viewer/ExhibitionMeta';

const ExhibitionEmbedPage = ({ exhibitionId, width, height, query }) => (
  <>
    <ExhibitionMeta exhibitionId={exhibitionId} />
    <ExhibitionViewFrame
      embedded
      exhibitionId={exhibitionId}
      webVR={utils.navigator.isIE()}
      width={width}
      height={height}
    />
  </>
);

export default ExhibitionEmbedPage;

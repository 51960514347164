import React, { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import logo from '../styles/images/logo.png';
import { ReactComponent as Send } from '../styles/images/send-24px.svg';
import { apiPOST, addMessage, setUIProperty } from '../actions';
import { compose, withState, withDispatch, withLifecycle } from '../enhancers';
import { getAuthUser, getUIProperty } from '../reducers';
import * as PrivateComponent from '../styles/PrivateSpacesPageStyled';
import DefaultLayout from '../components/layouts/DefaultLayout';
import useWindowSize from '../hooks/windowSize';
import PSbanner from '../components/privateSpaces/PSbanner';
import { BodyWithPadding, StyledButton, Text } from '../styles/GenericStyled';
import ServicesContent from '../components/privateSpaces/ServicesContent';
import videoPreview from '../styles/images/privateSpaces/private-space-video.mp4';

const { getNames } = require('country-list');

const breakPoint = 1515;

const isDisabled = state => {
  let disabled = false;
  for (const [key, value] of Object.entries(state)) {
    if (key !== 'company' && value === '') disabled = true;
  }
  return disabled;
};

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 5px;
  display: none;
  ${props =>
    props.visible &&
    css`
      display: flex;
    `}
`;

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '300px',
    width: '357px',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

const initialState = {
  username: '',
  email: '',
  country: '',
  company: '',
  licence: '',
};

const licences = ['Commercial Licence', 'Academic Licence', 'Other/Not sure'];

const listInfo = [
  'Brand the URL of your ArtSteps web page with a name of your choice',
  'Add the logo of your organization to customize your gallery',
  'See details of visitors to your exhibitions',
  'Upload high resolution images of your artifacts and works',
  'Add clickable links on the descriptions of your artifacts',
  'Host an unlimited number of exhibitions',
  'Design your exhibitions on exclusive templates of galleries',
];

function PrivateSpacesPageView(props) {
  const [open, setOpen] = React.useState(false);
  const [inputData, setInputData] = useState({ ...initialState });
  const [countryInput, setCountryInput] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    document.title = `Artsteps | Private Web Space`;
  }, []);

  const size = useWindowSize();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputData({ ...initialState });
  };

  const handleSubmit = e => {
    props.onCreateContactForm({ ...inputData }).then(({ response }) => {
      if (!response.error) {
        props.onAddMessage({ title: 'Your message has been sent', type: 'success' });
      } else {
        props.onAddMessage({ title: response.error, type: 'error' });
      }
    });
    handleClose();
  };

  const handleChange = (event, option) => {
    let name;
    let value;
    if (option === null) {
      value = '';
      name = 'country';
    } else if (!option) {
      name = event.target.name ? event.target.name : event.target.getAttribute('name');
      value = event.target.value !== 0 ? event.target.value : event.target.getAttribute('value');
    } else {
      value = option;
      name = 'country';
    }
    inputData[name] = value;
    setInputData({ ...inputData });
  };

  return (
    <DefaultLayout>
      <PSbanner
        title="Private Web Space"
        sub1="Present your stories on an exclusive website with your unique domain name and access control"
        button={
          <StyledButton onClick={handleOpen} background="#12AD8F">
            Request a quote
          </StyledButton>
        }
      />
      <BodyWithPadding padding="100px 5% 0% 5%">
        <ServicesContent video={videoPreview} size={size}>
          <Text type={'h4'} align="left">
            What We Offer
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            Our Private Web Spaces give you privacy, brand consistency as well as advanced features
            that can create a premium experience for your visitors
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Unique domain name</Text>
            </li>
            <li>
              <Text type={'sub2'}>VR space branded with your logo</Text>
            </li>
            <li>
              <Text type={'sub2'}>User access control</Text>
            </li>
            <li>
              <Text type={'sub2'}>High-resolution images</Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Clickable links in the descriptions of your works and artifacts
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Access to exclusive pre-configured 3D gallery space templates
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>Hosting of multiple exhibitions</Text>
            </li>
            <li>
              <Text type={'sub2'}>Metrics on visitor numbers</Text>
            </li>
          </ul>
          <br />
          <br />
          <Text type={'h4'} align="left">
            How It Works
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            We can set up your Private Web Space to ensure that you have control over who can enter
            your VR space. You only have to:
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Add your logo</Text>
            </li>
            <li>
              <Text type={'sub2'}>Choose your domain name</Text>
            </li>
            <li>
              <Text type={'sub2'}>Decide who will have access to your VR space</Text>
            </li>
            <li>
              <Text type={'sub2'}>Design the set-up of your images & 3D objects</Text>
            </li>
          </ul>
          <br />
          <br />
          <Text type={'h4'} align="left">
            Chosen By
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            Private Web Spaces are a favorite for a variety of organizations, from non-profit
            academic institutions and cultural entities to companies and corporations
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Art Institutions for VR exhibitions</Text>
            </li>
            <li>
              <Text type={'sub2'}>Companies for VR artistic happenings</Text>
            </li>
            <li>
              <Text type={'sub2'}>Schools for VR classes or VR celebrations</Text>
            </li>
            <li>
              <Text type={'sub2'}>Universities for VR presentations or VR anniversaries</Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Marketing Agencies for VR displays of their clients’ products
              </Text>
            </li>
          </ul>
        </ServicesContent>
      </BodyWithPadding>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={4}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Grid item>
          <Text type="h5" mycolor="black" align="center">
            <b>Interested in this Service?</b>
          </Text>
        </Grid>
        <Grid item>
          <StyledButton onClick={handleOpen} background="#12AD8F">
            Request a quote
          </StyledButton>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        style={{ overflow: 'auto' }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PrivateComponent.ModalGrid container direction="column" justify="center" spacing={2}>
            <Box component={Grid} item textAlign="center">
              <img alt="" src={logo} width="177px" height="59px" />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.CustomTypography variant="h4" modalhead="true">
                Request Details
              </PrivateComponent.CustomTypography>
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="username-input">
                Name
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="username-input"
                onChange={e => handleChange(e)}
                name="username"
                value={inputData.username}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="email-input">
                Email
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="email-input"
                onChange={handleChange}
                name="email"
                value={inputData.email}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="country-input">
                Country
              </PrivateComponent.InputsLabel>
              <PrivateComponent.CustomAutocomplete
                value={inputData.country}
                onChange={(e, value) => handleChange(e, value)}
                onInputChange={(event, newInputValue) => {
                  setCountryInput(newInputValue);
                }}
                inputValue={countryInput}
                autoComplete
                options={getNames()}
                clearOnEscape
                getOptionSelected={option => option}
                getOptionLabel={option => option}
                renderOption={option => <React.Fragment>{option}</React.Fragment>}
                renderInput={params => (
                  <PrivateComponent.CustomTextField
                    {...params}
                    placeholder="Select"
                    style={{ border: 0 }}
                    required
                  />
                )}
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="company-input">
                <PrivateComponent.CustomTypography company="true">
                  Company
                </PrivateComponent.CustomTypography>
                <PrivateComponent.CustomTypography optional="true">
                  (Optional)
                </PrivateComponent.CustomTypography>
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="company-input"
                onChange={handleChange}
                name="company"
                value={inputData.company}
              />
            </Box>
            <Box component={Grid} item textAlign="center" style={{ position: 'relative' }}>
              <PrivateComponent.InputsLabel htmlFor="licence-input">
                Licence
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="licence-input"
                aria-controls="licence-input"
                aria-haspopup="true"
                name="licence"
                value={inputData.licence}
                placeholder="Select"
                readOnly
                onClick={handleMenu}
                required
              />
              {openMenu ? <PrivateComponent.UpArrow /> : <PrivateComponent.DownArrow />}
              <StyledMenu
                id="licence-input"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                open={openMenu}
                onClose={handleCloseMenu}
              >
                {licences.map((licence, index) => (
                  <PrivateComponent.DropDownOptions
                    key={`space${index}`}
                    value={licence}
                    name="licence"
                    onClick={e => {
                      handleChange(e);
                      handleCloseMenu();
                    }}
                  >
                    {licence}
                  </PrivateComponent.DropDownOptions>
                ))}
              </StyledMenu>
            </Box>
            <Box component={CloseButton} onClick={handleClose} visible={size.width < breakPoint}>
              <CloseIcon />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.SendButton disabled={isDisabled(inputData)} onClick={handleSubmit}>
                Send your Request
                <SvgIcon>
                  <Send />
                </SvgIcon>
              </PrivateComponent.SendButton>
            </Box>
          </PrivateComponent.ModalGrid>
        </Fade>
      </Modal>
    </DefaultLayout>
  );
}

const lifecycleMap = {
  onDidMount: () => {
    ReactPixel.track('ViewContent');
  },
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  requestOpen: getUIProperty('requestOpen'),
});
const mapDispatch = dispatch => ({
  onCreateContactForm: form =>
    dispatch(apiPOST('privatespaces', { ...form, requestQuoteFor: 'Private Space Service' })),
  onAddMessage: message => dispatch(addMessage(message, 'privatespaces')),
  setOpenRequest: status => dispatch(setUIProperty('requestOpen', status)),
});

const PrivateSpacesPage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(PrivateSpacesPageView);

export default PrivateSpacesPage;

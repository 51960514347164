import React from 'react';
import AdminPanelWrapper from './AdminPanelWrapper';
import { getApiResource, getApiStatus, getAuthUser, getAuthUserRoles } from '../../reducers';
import { apiGET, setLocation } from '../../actions';
import { compose, withDispatch, withLifecycle, withState } from '../../enhancers';
import { Redirect } from 'react-router';
import { DataGrid } from '@material-ui/data-grid';
import { Text } from '../../styles/GenericStyled';

const TemplateSalesAdminWrapperView = ({
  sales,
  currentUser,
  ready,
  isAdmin,
  onRedirect = () => Promise.resolve(false),
}) => {
  if (!isAdmin) return <Redirect to="/" />;
  const columns = [
    {
      field: 'template',
      headerName: 'Template',
      headerAlign: 'left',
      sortable: true,
      flex: 1,
      renderCell: ({ row }) => (
        <Text>
          {row.template?.title}
        </Text>
      ),
    },
    {
      field: 'size',
      headerName: 'Size',
      headerAlign: 'left',
      sortable: true,
      flex: 1,
      renderCell: ({ row }) => (
        <Text>
          {row.template?.size}
        </Text>
      ),
    },
    {
      field: 'purchases',
      headerName: 'Purchases',
      headerAlign: 'left',
      sortable: true,
      flex: 1,
      renderCell: ({ row }) => <Text>{row?.purchases}</Text>,
    },
  ];
  //console.log(payments);

  return (
    <AdminPanelWrapper>
      <div style={{ width: '100%' }}>
        <DataGrid
          getRowId={row => row?.template?._id}
          loading={!ready}
          autoHeight
          rowHeight={70}
          rows={sales}
          columns={columns}
          pageSize={40}
        />
      </div>
    </AdminPanelWrapper>
  );
};

const ADMIN_ROLES = ['admin'];

const mapState = state => ({
  sales: Object.values(getApiResource(state, `templates/sales/adminPanel`)),
  currentUser: getAuthUser(state),
  ready: getApiStatus(state, 'reports') !== 'fetching',
  isAdmin: getAuthUserRoles(state).some(role => ADMIN_ROLES.includes(role)),
});

const mapDispatch = dispatch => ({
  onFetchSales: () => dispatch(apiGET('templates/sales/adminPanel')),
  onRedirect: location => dispatch(setLocation(location)),
});
const lifecycleMap = {
  onWillMount: ({ onFetchSales }) => onFetchSales(),
};

const TemplateSalesAdminWrapper = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(TemplateSalesAdminWrapperView);

export default TemplateSalesAdminWrapper;

import React, { useEffect, useState, useCallback } from 'react';
import { compose, withState, withDispatch } from '../../../enhancers';
import { apiGET, setUIProperty, apiDELETE, setLocation } from '../../../actions';
import { getApiStatus, API_STATUS, getApiResource, getAuthUserRoles } from '../../../reducers';
import debounce from 'debounce';

/*****************MATERIAL IMPORTS ****************/
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { MenuItem, Grow, MenuList, InputBase, ClickAwayListener, Popper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import TableContainer from '@material-ui/core/TableContainer';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { Text, StyledButton } from '../../../styles/GenericStyled';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
/********* CLIENT'S COMPONENTS ********************/
import colors from '../../../styles/colors';
import Dialog from '../../generic/Dialog';

const columns = [
  { id: '_id', minWidth: 260, label: 'ID', searchField: 'text', searchFieldWidth: '80%' },
  { id: 'profile', minWidth: 326, label: 'Profile', searchField: 'text', searchFieldWidth: '73%' },
  { id: 'emails', minWidth: 303, label: 'Email', searchField: 'text', searchFieldWidth: '73%' },
  { id: 'lastLogin', minWidth: 163, label: 'Last Login' },
  { id: 'roles', minWidth: 135, label: 'Roles', searchField: 'menu' },
];

const secondaryColumns = [
  { id: 'services', minWidth: 170, label: 'Services' },
  { id: 'subscribe', minWidth: 170, label: 'Subscriber' },
  { id: 'inactive', minWidth: 170, label: 'Inactive' },
  { id: 'spaces', minWidth: 170, label: 'Spaces' },
];

const searchInputInitialState = {
  _id: '',
  emails: '',
  roles: '',
  profile: '',
};

const searchRoles = ['curator', 'admin'];

const ADMIN_ROLES = ['admin'];

const query = (limit = 10, offset = 0, filter = {}) => {
  // if (psAdmin && psAdmin.spaceDomains)
  //  filter = { ...filter, spaces: { $in: [psAdmin.spaceDomains[0]._id] } };

  return {
    filter: { ...filter },
    populate: 'spaces',
    include: [
      'username',
      'emails',
      'profile.name',
      'profile.country',
      'profile.city',
      'profile.gender',
      'profile.birthday',
      'profile.occupation',
      'profile.bio',
      'profile.website',
      'spaces',
      'roles',
      'services',
      'subscribe',
      'lastLogin',
      'inactive',
    ],
    page: { limit: limit, offset: offset },
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    color: 'white!important',
  },
  active: {
    color: 'white!important',
  },
  icon: {
    color: 'white!important',
    opacity: 1,
  },
  iconDirectionDesc: {
    color: 'white!important',
    opacity: 0.5,
  },
  iconDirectionAsc: {
    color: 'white!important',
    opacity: 0.5,
  },
}));

const FilterMenu = ({ searchInput, category, handleChangeSearch }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div style={{ display: 'inline-block' }}>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ padding: 0, color: colors.ourWhite }}
      >
        <FilterListIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {searchRoles.map(role => (
                    <MenuItem>
                      <Checkbox
                        checked={searchInput.roles === role}
                        onChange={e => {
                          if (e.target.checked) handleChangeSearch(e, category, role);
                          else handleChangeSearch(e, category, null);
                        }}
                      />
                      <Text type="body2">{role}</Text>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const RenderFullValue = props => {
  const { cell, openedUser, id, column } = props;

  return (
    <TableCell
      style={{
        paddingBottom: 0,
        paddingTop: 0,
        verticalAlign: 'top',
        borderBottom: id === openedUser ? '1px solid rgba(224, 224, 224, 1)' : '0',
      }}
      colSpan={column === 'spaces' ? 3 : 0}
      scope="row"
      align="left"
    >
      <Collapse in={id === openedUser} timeout="auto">
        <Box margin={1}>
          <Text type="h5" gutterBottom component="div">
            <Text type="h7">{column}</Text>
          </Text>
          <Table size="small">
            <TableHead>
              <TableRow>
                {cell.keys &&
                  cell.keys.map((key, index) => (
                    <TableCell style={{ borderBottom: 0 }} key={index + key} align={'left'}>
                      <Text type="body2">{key}</Text>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {cell.fullValue.length > 0 &&
                cell.fullValue.map((value, index) => (
                  <TableRow key={value + index}>
                    <TableCell style={{ borderBottom: 0 }} component="th" scope="row">
                      <Text type="body2">{value || 'Not defined'}</Text>
                    </TableCell>
                  </TableRow>
                ))}
              {cell.fullValue.length === 0 && (
                <TableRow>
                  <TableCell style={{ borderBottom: 0 }} component="th" scope="row">
                    <Text type="body2">{cell.value || 'Not defined'}</Text>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  );
};

const RenderRowWithHandler = ({
  user,
  setOpen,
  openedUser,
  normalize,
  selected = [],
  setSelected,
  setDialogOpen,
  onRedirect,
}) => {
  return (
    <TableRow hover role="checkbox">
      <TableCell padding="checkbox" align="center">
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => (openedUser === user._id ? setOpen(false) : setOpen(user._id))}
        >
          {user._id === openedUser ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>

      {columns.map(column => {
        const cell = normalize(user, column.id);
        return (
          <TableCell key={column.label} align={'left'}>
            <Text type="body2">{cell.value}</Text>
          </TableCell>
        );
      })}
      <TableCell align="left">
        <Grid container justify="space-evenly">
          <Grid item>
            <StyledButton
              onClick={() => {
                onRedirect(`/admin-panel/users/${user._id}`);
              }}
              mycolor="white"
              background="#1a2a77"
            >
              View
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              onClick={() => {
                setSelected(user._id);
                setDialogOpen(true);
              }}
              mycolor="white"
              background="#8f9694"
            >
              Delete
            </StyledButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const UserAdminPanelWrapperView = ({
  fetchUsers,
  usersReady = false,
  setAdminView,
  onRemove,
  onRedirect,
  isAdmin = undefined,
  users,
}) => {
  const [renderedUsers, setRenderedUsers] = useState([...Object.values(users)]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('ID');
  const [selected, setSelected] = useState(null);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [openedUser, setOpen] = React.useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [limit, setLimit] = useState(2);
  const [offset, setOffset] = useState(0);
  const [searchInput, setSearchInput] = useState(searchInputInitialState);
  const [filter, setFilter] = useState(null);
  const classes = useStyles();

  const callbackFetch = useCallback(
    debounce(
      (limit, offset, filter) =>
        fetchUsers(query(limit, offset, filter)).then(({ response }) =>
          setRenderedUsers([...Object.values(response)]),
        ),
      300,
    ),
    [],
  );

  useEffect(() => {
    if (isAdmin !== undefined) {
      callbackFetch(limit, offset, filter);
    }
  }, [updateData, limit, offset, isAdmin, filter]);

  const sortBy = columnId => {
    let res;
    function prepareSorting(obj, columnId) {
      switch (columnId) {
        case '_id':
          return obj['_id'];
        case 'profile':
          return obj.profile.name;
        case 'lastLogin':
          return !obj.lastLogin ? 0 : new Date(obj.lastLogin.split('T')[0]).getTime();
        case 'emails':
          return obj.emails[0].address;
        default:
          break;
      }
    }
    if (order === 'asc')
      res = renderedUsers.sort((a, b) => {
        if (prepareSorting(a, columnId) > prepareSorting(b, columnId)) return 1;
        if (prepareSorting(a, columnId) < prepareSorting(b, columnId)) return -1;
        return 0;
      });
    else
      res = renderedUsers.sort((a, b) => {
        if (prepareSorting(a, columnId) < prepareSorting(b, columnId)) return 1;
        if (prepareSorting(a, columnId) > prepareSorting(b, columnId)) return -1;
        return 0;
      });
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(columnId);
    setRenderedUsers([...res]);
  };

  const handleChangeSearch = (e, searchFilter, menuOption) => {
    const { value } = e.target;
    let resultFilter = { ...filter };
    switch (searchFilter) {
      case 'emails':
        resultFilter = { ...resultFilter, emails: { $elemMatch: { address: { $regex: value } } } };
        break;
      case '_id':
        resultFilter = { ...resultFilter, _id: value };
        break;
      case 'roles':
        resultFilter = {
          ...resultFilter,
          roles: {
            __global_roles__: [menuOption],
          },
        };
        break;
      case 'profile':
        resultFilter = {
          ...resultFilter,
          'profile.name': { $regex: value },
        };
        break;
      default:
        resultFilter = { ...resultFilter };
        break;
    }
    if (value === '' && !menuOption) {
      if (searchFilter === 'profile') delete resultFilter['profile.name'];
      else delete resultFilter[searchFilter];
    }

    setFilter({ ...resultFilter });
    setSearchInput({ ...searchInput, [searchFilter]: menuOption || value });
  };

  const handleChangePage = (event, newPage) => {
    if (newPage <= page) {
      setOffset(offset - rowsPerPage);
    } else {
      setOffset(offset + rowsPerPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    console.log(event.target.value);
    setLimit(+event.target.value);
    setRowsPerPage(+event.target.value);
    setOffset(0);
    setPage(1);
  };

  const normalize = (user, columnId) => {
    if (user[columnId] === undefined && columnId !== 'lastLogin')
      return {
        value: (
          <Switch
            checked={user[columnId]}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled
          />
        ),
        fullValue: [],
        keys: [],
      };
    else if (user[columnId] === undefined && columnId === 'lastLogin')
      return {
        value: 'No Last Login Found',
        fullValue: [],
        keys: [],
      };

    if (columnId === 'emails')
      return {
        value: user.emails[0].address || 'No Email Found',
        fullValue: user[columnId],
        keys: Object.keys(user.emails[0]),
      };
    else if (columnId === 'roles')
      return {
        value:
          user.roles.__global_roles__.map((role, index) => (index !== 0 ? `,${role}` : role)) ||
          'No roles Found',
        fullValue: user[columnId],
        keys: ['roles'],
      };
    else if (columnId === 'spaces')
      return {
        value: user.spaces || 'No Spaces Found',
        fullValue: user[columnId],
      };
    else if (columnId === 'services')
      return {
        value: user.services || 'No Services Found',
        fullValue: user[columnId],
        keys: Object.keys(user.services),
      };
    else if (columnId === 'profile')
      return {
        value: user.profile.name || 'No Username Found',
        fullValue: user[columnId],
        keys: Object.keys(user.profile),
      };
    else if (columnId === 'lastLogin')
      return {
        value: user[columnId].split('T')[0],
        fullValue: user[columnId].split('T')[0],
        keys: [`${user[columnId]}`],
      };
    else
      return {
        value: user[columnId],
        fullValue: user[columnId],
        keys: [
          <Switch
            checked={user[columnId]}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled
          />,
        ],
      }; //subscribe,inactive
  };

  return (
    <Paper>
      <TableContainer style={{ fontFamily: 'Muli,Regular' }}>
        <Table aria-label="collapsible table">
          <TableHead style={{ backgroundColor: colors.ourBlack }}>
            <TableRow>
              <TableCell style={{ minWidth: '48px' }} align="center"></TableCell>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={'left'}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{
                    minWidth: column.minWidth,
                    padding: '4px 16px 4px 16px',
                    verticalAlign: 'middle',
                    color: colors.ourWhite,
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => sortBy(column.id)}
                    classes={{ ...classes }}
                  >
                    <Text type="h7">{column.label}</Text>
                  </TableSortLabel>
                  {column.searchField === 'text' && (
                    <InputBase
                      style={{
                        borderBottom: '1px solid #8f8f8f',
                        minWidth: column.searchFieldWidth,
                        color: colors.ourWhite,
                        fontSize: '15px',
                        fontFamily: 'Muli,Regular',
                      }}
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={e => handleChangeSearch(e, column.id)}
                      value={searchInput[column.id]}
                    />
                  )}
                  {column.searchField === 'menu' && (
                    <FilterMenu
                      searchInput={searchInput}
                      category={column.id}
                      handleChangeSearch={handleChangeSearch}
                    />
                  )}
                </TableCell>
              ))}
              <TableCell
                style={{ minWidth: '370px', verticalAlign: 'baseline', color: colors.ourWhite }}
                align="center"
              >
                <Text type="h7">Actions</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersReady &&
              renderedUsers.map((user, index) => (
                <React.Fragment key={user._id + index}>
                  <RenderRowWithHandler
                    user={user}
                    openedUser={openedUser}
                    setOpen={setOpen}
                    normalize={normalize}
                    setAdminView={setAdminView}
                    selected={selected}
                    setSelected={setSelected}
                    onRedirect={onRedirect}
                    setDialogOpen={setDialogOpen}
                  />
                  <TableRow>
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        verticalAlign: 'top',
                        //borderBottom: '0',
                        borderBottom:
                          user._id === openedUser ? '1px solid rgba(224, 224, 224, 1)' : '0',
                      }}
                    />
                    {secondaryColumns.map(column => {
                      const cell = normalize(user, column.id);
                      return (
                        <RenderFullValue
                          cell={cell}
                          column={column.id}
                          id={user._id}
                          key={index + column.id}
                          openedUser={openedUser}
                        />
                      );
                    })}
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[2, 10, 100]}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        count={-1}
        labelRowsPerPage={<Text>Rows per page</Text>}
        labelDisplayedRows={() => <Text>Page {page}</Text>}
        nextIconButtonProps={{
          disabled: renderedUsers.length < rowsPerPage,
          'aria-label': 'rows per page',
        }}
        backIconButtonProps={{ disabled: page === 1 ? true : false }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{ paddingRight: '51px', minWidth: '383px' }}
      />

      <Dialog
        open={isDialogOpen}
        title={'Delete User and All his information including Exhibitions and Artifacts'}
        message={'Confirm'}
        type="warning"
        onConfirm={() => {
          onRemove(selected).then(() => {
            setUpdateData(!updateData);
            setDialogOpen(false);
          });
        }}
        onReject={() => setDialogOpen(false)}
      />
    </Paper>
  );
};

const mapState = state => ({
  usersReady: getApiStatus(state, 'users') === API_STATUS.IDLE,
  users: getApiResource(state, 'users'),
  // psAdmin: getUIProperty(state, 'psAdmin'),
  isAdmin: getAuthUserRoles(state).some(role => ADMIN_ROLES.includes(role)),
});

const mapDispatch = (dispatch, { isAdmin }) => ({
  fetchUsers: filter => dispatch(apiGET('users', filter)),
  setAdminView: view => dispatch(setUIProperty('adminView', view)),
  onRemove: user => dispatch(apiDELETE(`users/${user}`)),
  onRedirect: location => dispatch(setLocation(location)),
});

const UserAdminPanelWrapper = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(UserAdminPanelWrapperView);

export default UserAdminPanelWrapper;

import { apiPOST, API_URI } from './api';
import { setUIProperty } from '../actions';

const KEY_AUTH = 'auth';

export const AUTH_NAMESPACE = 'auth';
export const AUTH_ACTION_REQUEST_AUTHORIZATION = 'AUTHORIZE_REQUEST';
export const AUTH_ACTION_REQUEST_RENEWAL = 'AUTHORIZE_RENEWAL';
export const AUTH_ACTION_AUTHORIZE = 'AUTHORIZE';
export const AUTH_ACTION_DEAUTHORIZE = 'DEAUTHORIZE';
export const AUTH_ACTION_REGISTER = 'AUTHORIZE';
export const AUTH_ACTION_RENEW = 'RENEW';

export const authLogin = (username, password) => dispatch => {
  dispatch({
    type: `${AUTH_NAMESPACE}/${AUTH_ACTION_REQUEST_AUTHORIZATION}`,
    service: 'password',
  });
  return dispatch(apiPOST('auth/artsteps', { username, password })).then(({ response }) => {
    if (response.error) {
      storeCredentials(null);
      return dispatch({
        type: `${AUTH_NAMESPACE}${AUTH_ACTION_DEAUTHORIZE}`,
        service: 'password',
        response,
      });
    }
    storeCredentials(response);
    return dispatch({
      type: `${AUTH_NAMESPACE}/${AUTH_ACTION_AUTHORIZE}`,
      service: 'password',
      response,
    });
  });
};

export const authRenew = (token, user) => dispatch => {
  dispatch({
    type: `${AUTH_NAMESPACE}/${AUTH_ACTION_REQUEST_RENEWAL}`,
  });
  return dispatch(apiPOST('auth/renew-jwt', { timestamp: Date.now() }));
};

export const authRegister = user => dispatch => {
  dispatch({
    type: `${AUTH_NAMESPACE}/${AUTH_ACTION_REGISTER}`,
    service: 'password',
  });
  return dispatch(apiPOST('users', user));
};

export const oauthLogin = service => dispatch => {
  dispatch({
    service,
    type: `${AUTH_NAMESPACE}/${AUTH_ACTION_REQUEST_AUTHORIZATION}`,
  });
  return new Promise(resolve => {
    const win = window.open(`${API_URI}/auth/${service}`, 'oauth', 'height=600,width=450');
    window.onOAuth = response => {
      storeCredentials(response);
      win.close();
      resolve({ response });
    };
  }).then(() => dispatch(restoreAuth()));
};

export const authLogout = () => dispatch => {
  storeCredentials(null);
  dispatch(setUIProperty('unverifiedNotificationOpen', true));
  return Promise.resolve(
    dispatch({
      type: `${AUTH_NAMESPACE}/${AUTH_ACTION_DEAUTHORIZE}`,
      response: {},
    }),
  );
};

export const restoreAuth = () => dispatch => {
  const credentials = getStoredCredentials();
  if (credentials && credentials.user && credentials.token && isTokenValid(credentials.token)) {
    return Promise.resolve(
      dispatch({
        type: `${AUTH_NAMESPACE}/${AUTH_ACTION_AUTHORIZE}`,
        response: credentials,
      }),
    );
  }
  return Promise.resolve(
    dispatch({
      type: `${AUTH_NAMESPACE}/${AUTH_ACTION_DEAUTHORIZE}`,
      response: credentials,
    }),
  );
};

export const isTokenExpiring = token => {
  if (!token || token.indexOf('.') < 0) return true;
  const expireWindow = 60 * 5; // in seconds
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp < Date.now() / 1000 + expireWindow;
  } catch (err) {
    return true;
  }
};

const isTokenValid = token => {
  if (!token || token.indexOf('.') < 0) return false;
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 > Date.now();
  } catch (err) {
    return false;
  }
};

export const getStoredCredentials = () => {
  try {
    return JSON.parse(localStorage.getItem(KEY_AUTH));
  } catch (ignore) {
    return null;
  }
};

export const storeCredentials = credentials => {
  try {
    if (credentials) {
      localStorage.setItem(KEY_AUTH, JSON.stringify(credentials));
    } else {
      localStorage.removeItem(KEY_AUTH);
    }
    return getStoredCredentials();
  } catch (ignore) {
    return null;
  }
};

import React, { useEffect } from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import CategoryMeta from '../components/articles/CategoryMeta';
import Category from '../components/articles/lists/Category';
import CategoryList from '../components/articles/lists/CategoryList';
import emailImage from '../styles/images/email_artsteps.png';
import { compose, withDispatch } from '../enhancers';
import { apiGET } from '../actions';

import '../styles/maintenancebanner.css';

const ArticleCategoriesPageView = ({ categoryId, onFetchCategories, onFetchArticles }) => {
  useEffect(() => {
    onFetchCategories();
    onFetchArticles();
  }, []);

  return (
    <DefaultLayout>
      <CategoryMeta categoryId={categoryId} />
      <div className="article-categories ui main container">
        {categoryId ? (
          <Category editable breadcrumb categoryId={categoryId} displayChildren={1} />
        ) : (
          <CategoryList displayChildren={1} editable />
        )}
        <p className="further-assistance">
          If you require further assistance please contact us at{' '}
          <img className="sales-email-img" src={emailImage} alt="emailImage" />
        </p>
      </div>
    </DefaultLayout>
  );
};

const mapDispatch = dispatch => ({
  onFetchCategories: () => dispatch(apiGET('articleCategories')),
  onFetchArticles: () => dispatch(apiGET('articles')),
});

const ArticleCategoriesPage = compose(withDispatch(mapDispatch))(ArticleCategoriesPageView);

export default ArticleCategoriesPage;

import React, { useEffect } from 'react';
import { compose, withState, withDispatch } from '../../enhancers';
import { getForm, getAuthUser, getUIProperty } from '../../reducers';
import {
  apiPOST,
  setLocation,
  addMessage,
  clearMessages,
  clearFormData,
  setUIProperty,
} from '../../actions';
import ModalForm from './ModalForm';
import OurModal from '../generic/OurModal';
import '../../styles/userContributionModal.css';

function ContributePageView({
  onShowContributionModal = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  onClearForm = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onSetRecaptcha = () => Promise.resolve(false),
  onCreateContactForm = () => Promise.resolve(false),
  open,
  recaptcha = 'contribute_recaptcha',
}) {

  useEffect(() => {
    // open modal by url
    const urlQuery = window.location.href
      .split('/')[3]
      .replace('?', '')
      .split('=');
    if (urlQuery[0] === 'openModal' && urlQuery[1] === 'contribute') onShowContributionModal(true);
  }, [window.location.href, onShowContributionModal]);

  useEffect(() => {
    if (open) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('body-freeze-scrolling');
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('body-freeze-scrolling');
    }
    if (recaptcha === 'contact_recaptcha' && recaptcha !== 'contribute_recaptcha')
      onSetRecaptcha('contribute_recaptcha');
  }, [open]);

  return (
    <OurModal
      minWidth="355px"
      maxWidth="69%"
      height="90%"
      openModal={open}
      setOpenModal={onShowContributionModal}
    >
      <ModalForm
        onCreateContactForm={onCreateContactForm}
        onAddMessage={onAddMessage}
        onClearForm={onClearForm}
        onClearMessages={onClearMessages}
        setOpenModal={onShowContributionModal}
        recaptcha={recaptcha}
        onSetRecaptcha={onSetRecaptcha}
      />
    </OurModal>

    // <ContactFormSent logo={logo} />
  );
}

const mapState = state => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    form: getForm(state, 'contact'),
    open: getUIProperty(state, 'contributionModalOpen'),
    recaptcha: getUIProperty(state, 'recaptcha'),
  };
};

const mapDispatch = dispatch => ({
  onCreateContactForm: form => dispatch(apiPOST('contributeFiles', form)),
  onRedirect: page => dispatch(setLocation(page)),
  onAddMessage: message => dispatch(addMessage(message, 'contact')),
  onClearForm: () => dispatch(clearFormData('contact')),
  onClearMessages: () => dispatch(clearMessages('contact')),
  onClose: () => dispatch(setUIProperty('contributionModalOpen', false)),
  onShowContributionModal: show => dispatch(setUIProperty('contributionModalOpen', show)),
  onSetRecaptcha: loaded => dispatch(setUIProperty('recaptcha', loaded)),
});

const ContributePage = compose(withState(mapState), withDispatch(mapDispatch))(ContributePageView);

export default ContributePage;

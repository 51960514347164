import React, { useState, useEffect } from 'react';
import InputField from '../../generic/forms/InputField';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import { __ } from 'artsteps2-common';
import Loader from '../../generic/Loader';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { getUIProperty, getForm, getApiResource } from '../../../reducers';
import { apiPOST, apiPATCH, addMessage, setFormData, apiGET } from '../../../actions';
import { BodyWithPadding, HorizontalItems, StyledButton } from '../../../styles/GenericStyled';
import UploadOptionSelect from '../../artifacts/fields/UploadOptionSelect';
import { Text } from '../../../styles/GenericStyled';
import FlickrSelect from '../../generic/forms/FlickrSelect';
import FilePreview from '../../artifacts/previews/FilePreview';
import Uploader from '../../generic/forms/Uploader-2';
import { VerticalItems } from '../../artifacts/forms/FormStyled';
import UrlInput from '../../artifacts/fields/UrlInput';
import SwitchField from '../../artifacts/fields/SwitchField';
import publicConfig from 'artsteps2-config/public.json';
import { settings } from '../../admin/private-spaces/spaceSettings';
import { List, ListItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { TextFields } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';

const SIZE_LIMIT = publicConfig.uploader.payloadSize;

const options = [
  { value: 'file', name: 'File', _id: 0 },
  { value: 'url', name: 'Url', _id: 1 },
  { value: 'flickr', name: 'Flickr', _id: 1 },
];

const mapFormData = (space, logoFiles, backGroundFiles) => {
  let mappedSpace = { ...space };
  const mappedSettings = {};

  settings.forEach(setting => {
    mappedSettings[setting.value] = space[setting.value];
    mappedSpace[setting.value] = undefined;
  });

  const mappedLogo =
    logoFiles && logoFiles.length > 0
      ? [
          {
            ...logoFiles[0],
            bin: logoFiles[0].bin ? { ...logoFiles[0].bin, preview: undefined } : undefined,
            base64: undefined,
            image: undefined,
          },
        ]
      : undefined;

  const mappedBackGround =
    backGroundFiles && backGroundFiles.length > 0
      ? [
          {
            ...backGroundFiles[0],
            bin: backGroundFiles[0].bin
              ? { ...backGroundFiles[0].bin, preview: undefined }
              : undefined,
            base64: undefined,
            image: undefined,
          },
        ]
      : undefined;

  mappedSpace = {
    ...mappedSpace,
    settings: mappedSettings,
    logo: mappedLogo,
    backGround: mappedBackGround,
  };

  return mappedSpace;
};

const PrivateSpaceEditorView = ({
  form = { data: {} },
  saving = false,
  onUpsert,
  setOpen,
  onAddMessage = () => Promise.resolve(false),
  onRemove,
  type = 'image',
  onSetFormData,
  editingSpace,
  metadata,
}) => {
  // Should seperate the state of the images and the state of the other form
  const onSubmit = () => {
    setIsFormValid(false);

    const dataToSend = mapFormData(form.data, logoFiles, backGroundFiles);
    const size = new TextEncoder().encode(JSON.stringify(dataToSend)).length;

    if (size < SIZE_LIMIT)
      return onUpsert(dataToSend).then(({ response }) =>
        response.error
          ? onAddMessage({ title: __(response.error), type: 'error' })
          : onAddMessage({
              title: `Successfully  ${
                editingSpace === 'new ' ? 'created' : 'edited'
              } private space`,
              type: 'success',
            }).then(() => setOpen(false)),
      );
    else
      return onAddMessage({
        title: __('size_general'),
        description: `Your payload size is ${(size / 1000000).toFixed(2)} MB.`,
        type: 'error',
      });
  };

  const [logoUploadOption, setLogoUploadOption] = useState('file');
  const [backGroundUploadOption, setBackGroundUploadOption] = useState('file');
  const [isFormValid, setIsFormValid] = useState(false);
  const [logoFiles, setLogoFiles] = useState([]);
  const [backGroundFiles, setBackGroundFiles] = useState([]);
  const [tmp, setTmp] = useState('');

  useEffect(() => {
    setIsFormValid(form.data.title && form.data.subtitle && form.data.subdomain && form.data.email);
  }, [
    form.data.title,
    form.data.subtitle,
    form.data.subdomain,
    form.data.email,
    backGroundFiles,
    logoFiles,
  ]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    onSetFormData({ [name]: type === 'checkbox' ? checked : value });
  };
  return (
    <>
      <BodyWithPadding padding="24px">
        <Text type="h5">
          {' '}
          {editingSpace === 'new' ? 'Create' : 'Edit'} Private Space &emsp;
          <small style={{ color: '#12AD8F', fontSize: '14px' }}>
            {editingSpace !== 'new' && `Members: ${metadata?.countUsers || 0}, Exhibitions: ${metadata?.countExhibitions || 0}`}{/*TODO*/}
          </small>
        </Text>
        <Grid container spacing={5} direction="row" justify="space-evenly" alignItems="flex-start">
          <Grid item xs={12} md={4}>
            <Text type="h7">Logo</Text>
            <VerticalItems>
              <UploadOptionSelect
                uploadOption={logoUploadOption}
                setUploadOption={setLogoUploadOption}
                options={options}
              />
              {logoUploadOption === 'file' ? (
                <>
                  <div style={{ width: '100%', marginTop: '-24px', marginBottom: '0px' }}>
                    <Text align="left" type="body3">
                      Supported file formats ( {['.jpg', '.png', '.jpeg'].join(' ')})
                    </Text>
                  </div>
                  <Uploader
                    acceptedFiles={['.jpg', '.png', '.jpeg']}
                    setFiles={setLogoFiles}
                    onRemove={onRemove}
                    maxFiles={1}
                  />
                </>
              ) : (
                <UrlInput
                  type={type}
                  files={logoFiles}
                  setFiles={setLogoFiles}
                  uploadOption={logoUploadOption}
                />
              )}
              {logoUploadOption === 'flickr' && (
                <FlickrSelect
                  asArray
                  setFiles={setLogoFiles}
                  searchQuery={logoFiles && logoFiles.length > 0 && logoFiles[0].uri}
                  label={`${__('search_for')} ${type}`}
                />
              )}
              <FilePreview
                privateSpaceClient
                logoUploadOption={logoUploadOption || options[0].value}
                setFiles={setLogoFiles}
                files={logoFiles}
                showDelete={false}
                type={type}
              />
            </VerticalItems>
            <Text type="h6">Background</Text>
            <VerticalItems>
              <UploadOptionSelect
                uploadOption={backGroundUploadOption}
                setUploadOption={setBackGroundUploadOption}
                options={options}
              />
              {backGroundUploadOption === 'file' ? (
                <>
                  <div style={{ width: '100%', marginTop: '-24px', marginBottom: '0px' }}>
                    <Text align="left" type="body3">
                      Supported file formats ( {['.jpg', '.png', '.jpeg'].join(' ')})
                    </Text>
                  </div>
                  <Uploader
                    notFileSystem
                    acceptedFiles={['.jpg', '.png', '.jpeg']}
                    setFiles={setBackGroundFiles}
                    onRemove={onRemove}
                    maxFiles={1}
                  />
                </>
              ) : (
                <UrlInput
                  type={type}
                  files={backGroundFiles}
                  setFiles={setBackGroundFiles}
                  uploadOption={backGroundUploadOption}
                />
              )}
              {backGroundUploadOption === 'flickr' && (
                <FlickrSelect
                  asArray
                  setFiles={setBackGroundFiles}
                  searchQuery={
                    backGroundFiles && backGroundFiles.length > 0 && backGroundFiles[0].uri
                  }
                  label={`${__('search_for')} ${type}`}
                />
              )}
              <FilePreview
                backGroundUploadOption={backGroundUploadOption || options[0].value}
                setFiles={setBackGroundFiles}
                files={backGroundFiles}
                showDelete={false}
                type={type}
              />
            </VerticalItems>
          </Grid>
          <Grid item xs={12} md={4}>
            <VerticalItems>
              {editingSpace === 'new' && (
                <InputField
                  required
                  disabled
                  handleChange={handleChange}
                  value={form.data.password}
                  name="password"
                  label="Password"
                />
              )}
              <InputField
                required
                handleChange={handleChange}
                value={form.data.subdomain}
                name="subdomain"
                label="Subdomain"
              />
              <InputField
                required
                handleChange={handleChange}
                value={form.data.email}
                name="email"
                label="Email"
              />
              <InputField
                required
                handleChange={handleChange}
                value={form.data.title}
                name="title"
                label="Title"
              />
              <InputField
                required
                handleChange={handleChange}
                value={form.data.subtitle}
                name="subtitle"
                label="Subtitle"
              />
              {/**/}
              <div style={{ width: '100%' }}>
                <Text style={{ marginRight: 'auto', width: '100%', marginLeft: '2px' }}>
                  Templates (
                  {!form?.data?.templates || form?.data?.templates?.length === 0
                    ? '0'
                    : form?.data?.templates?.length}
                  )
                </Text>
                <div style={{ border: '1px solid lightgray', borderRadius: '5px', width: '100%' }}>
                  {form?.data?.templates && form?.data?.templates?.length !== 0 && (
                    <List style={{ width: '100%', maxHeight: '200px', overflowY: 'scroll' }}>
                      {form?.data?.templates?.map(template => (
                        <ListItem key={template}>
                          <Text type="body2" mycolor="black" style={{ marginRight: 'auto' }}>
                            {template}
                          </Text>
                          <IconButton
                            style={{ marginLeft: 'auto' }}
                            onClick={() => {
                              onSetFormData({
                                templates: form.data.templates.filter(t => t !== template),
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      ))}
                    </List>
                  )}

                  <TextField
                    variant="outlined"
                    onChange={e => setTmp(e.target.value)}
                    value={tmp}
                    name="tmp"
                    label="Add an exhibition ObjectId"
                    style={{ width: '100%' }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        if (tmp === '') return;
                        if (form.data?.templates?.includes(tmp)) {
                          setTmp('');
                          return;
                        }
                        if (form.data.templates === undefined) {
                          onSetFormData({ templates: [tmp] });
                        } else {
                          onSetFormData({ templates: form.data.templates.concat([tmp]) });
                        }
                        setTmp('');
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            if (tmp === '') return;
                            if (form.data?.templates?.includes(tmp)) {
                              setTmp('');
                              return;
                            }
                            if (form.data.templates === undefined) {
                              onSetFormData({ templates: [tmp] });
                            } else {
                              onSetFormData({ templates: form.data.templates.concat([tmp]) });
                            }
                            setTmp('');
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </div>
              {/**/}
            </VerticalItems>
          </Grid>
          <Grid item xs={12} md={4}>
            <VerticalItems>
              {settings.map((setting, idx) => (
                <SwitchField
                  key={idx}
                  handleChange={handleChange}
                  checked={form.data[setting.value]}
                  label={setting.text}
                  name={setting.value}
                  defaultValue={setting.defaultValue}
                />
              ))}
            </VerticalItems>
          </Grid>
        </Grid>
        <HorizontalItems>
          <StyledButton disabled={!isFormValid} style={{ marginLeft: '8px' }} onClick={onSubmit}>
            <SaveIcon style={{ marginRight: '4px' }} />
            Save
          </StyledButton>
        </HorizontalItems>
      </BodyWithPadding>
      {saving && <Loader message={`${__('saving')} ${saving}`} />}
    </>
  );
};

const mapState = (state, { spaceId }) => {
  const editingSpace = getUIProperty(state, 'editingSpace');
  return {
    form: getForm(state, editingSpace),
    editingSpace,
    metadata: getApiResource(state, `privatespaces/spaceMetadata/${editingSpace}`),
  };
};

const mapDispatch = (dispatch, { editingSpace }) => {
  const onSetFormData = data => dispatch(setFormData(editingSpace, data));
  return {
    getMetadata: () => dispatch(apiGET(`privatespaces/spaceMetadata/${editingSpace}`)),
    onUpsert: data =>
      editingSpace === 'new'
        ? dispatch(apiPOST('spaces', data))
        : dispatch(apiPATCH(`spaces/${data._id}`, data)),
    onSetFormData,
    onAddMessage: message => dispatch(addMessage(message)),
    onGenerateCode: () => {
      fetch('/api/generateUniquePassword').then(res =>
        res.json().then(result => onSetFormData(result)),
      );
    },
  };
};

const lifecycleMap = {
  onWillMount: ({ getMetadata }) => getMetadata(),
};

const PrivateSpaceEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(PrivateSpaceEditorView);

export default PrivateSpaceEditor;

import React from 'react';
import { __ } from 'artsteps2-common';
import Link from '../components/generic/Link';

import MetaTags from '../components/layouts/MetaTags';
import FullLayout from '../components/layouts/FullLayout';
import PasswordRecovery from '../components/users/forms/PasswordRecovery';

const PasswordRecoveryPage = ({ token, userId, query: { authorization } = {} }) => (
  <FullLayout>
    <MetaTags title={__('forgot_your_password')} />
    <div className="login-form">
      <div className="ui two column stackable grid">
        <div className="stretched column cover-column">
          <div className="ui basic segment">
            <h1>{__('forgot_your_password')}</h1>
          </div>
        </div>
        <div className="stretched column">
          <Link to="/" className="logo" />
          <PasswordRecovery
            token={token}
            userId={userId}
            authToken={authorization}
            redirectionURL="/login"
          />
        </div>
      </div>
    </div>
  </FullLayout>
);

export default PasswordRecoveryPage;

import React from 'react';
import { path } from 'ramda';
import { __ } from 'artsteps2-common';
import utils from '../../../utils';
import Loader from '../../generic/Loader';
import { setUIProperty, setUIData } from '../../../actions';
import { getUIProperty } from '../../../reducers';
import {
  compose,
  withState,
  withDispatch,
  withLocalState,
  withLifecycle,
  onlyUpdateForKeys,
} from '../../../enhancers';

export const WebVRWrapperView = ({
  components: { Entity, Scene },
  instanceId,
  exhibitionModel = {},
  ready = true,
  cursor = 'auto',
  onLoad = () => Promise.resolve(false),
  onArtifactHover = () => Promise.resolve(false),
  onArtifactDisplayed = () => Promise.resolve(false),
  onArtifactPlayback = () => Promise.resolve(false),
}) => {
  if (!exhibitionModel || !Entity || !Scene) {
    return <Loader />;
  }

  const propsToString = props => {
    const keys = Object.keys(props || {}).filter(key => props[key] !== undefined);
    if (keys.some(key => typeof props[key] === 'function')) {
      return props;
    }

    return keys.map(key => `${key}: ${props[key]}`).join('; ');
  };

  const createElement = element => {
    const properties = { ...element };
    const Primitive = properties.primitive;
    if (!Primitive) {
      return null;
    }

    const children = properties.children ? properties.children.map(createElement) : null;
    delete properties.children;
    Object.keys(properties).forEach(prop => {
      if (typeof properties[prop] === 'object') {
        properties[prop] = propsToString(properties[prop]);
      }
    });

    if (Primitive === 'a-scene') {
      delete properties.primitive;
      properties['vr-mode-ui'] = 'enabled: false';
      return <Scene {...properties}>{children}</Scene>;
    }

    if (Primitive.match(/^a-.+/)) {
      return <Entity {...properties}>{children}</Entity>;
    }

    delete properties.primitive;

    return <Primitive {...properties}>{children}</Primitive>;
  };

  const events = {
    scene: {
      loaded: onLoad,
    },
    artifacts: {
      click: (event, artifact) =>
        onArtifactPlayback(artifact.artifactId).then(() =>
          onArtifactDisplayed(artifact.artifactId),
        ),
    },
  };

  return (
    <div className="webvr-frame" style={{ cursor }} allowFullScreen id={instanceId}>
      {createElement(utils.aframe.createScene(exhibitionModel, events))}
      {ready || <Loader message={`${__('loading')} ${exhibitionModel.exhibitionTitle}`} />}
    </div>
  );
};

const mapState = (state, { exhibitionId }) => ({
  instanceId: 'webvr',
  exhibitionModel: getUIProperty(state, `exhibitions/${exhibitionId}/import`),
  fullscreen: getUIProperty(state, `exhibitions/${exhibitionId}/fullscreen`),
  nextStorypoint: getUIProperty(state, `exhibitions/${exhibitionId}/nextStorypoint`),
  currentStorypoint: getUIProperty(state, `exhibitions/${exhibitionId}/currentStorypoint`),
  artifactState: getUIProperty(state, 'artifacts'),
});

const mapDispatch = (dispatch, { exhibitionId, instanceId, exhibitionModel = {} }) => ({
  onArtifactPlayback: artifactId => {
    const element = document.getElementById(`artifact-${artifactId}`);
    return element && element.play ? element.play().catch(() => {}) : Promise.resolve(false);
  },

  onArtifactDisplayed: artifactId =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayedArtifact`, artifactId)),

  onArtifactHover: artifactId =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/hoveredArtifact`, artifactId)),

  onMoveToPoint: pointId => {
    const { watchPosition, watchRotation } =
      (exhibitionModel.storyPoints || []).find(p => p.storyPointId === pointId) || {};
    const options = utils.aframe.createOptions(exhibitionModel);
    const scene = document.querySelector(`#${instanceId} a-scene`);
    scene.dispatchEvent(
      new CustomEvent('storypoint-change', {
        detail: {
          pointId,
          watchPosition,
          watchRotation,
          options,
        },
      }),
    );
    return dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        currentStorypoint: pointId,
      }),
    );
  },

  onLoad: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/initializedAt`, Date.now())),
});

const handleEvents = (
  {
    fullscreen: wasFullscreen = false,
    nextStorypoint: prevStorypoint,
    artifactState: prevArtifactState = {},
  },
  {
    fullscreen = false,
    nextStorypoint,
    currentStorypoint,
    instanceId,
    onMoveToPoint,
    artifactState = {},
  },
) => {
  if (nextStorypoint && nextStorypoint !== prevStorypoint && nextStorypoint !== currentStorypoint) {
    onMoveToPoint(nextStorypoint);
  }

  Object.keys(artifactState).forEach(id => {
    const volume = path([id, 'volume'], artifactState);
    const prevVolume = path([id, 'volume'], prevArtifactState);
    if (volume === prevVolume) {
      return;
    }
    const video = document.getElementById(`artifact-${id}`);
    if (!video) {
      return;
    }
    video.muted = false;
    video.volume = volume || 0;
  });

  Object.keys(artifactState).forEach(id => {
    const playback = path([id, 'playback'], artifactState);
    const prevPlayback = path([id, 'playback'], prevArtifactState);
    if (playback === prevPlayback) {
      return;
    }
    const video = document.getElementById(`artifact-${id}`);
    if (!video) {
      return;
    }
    const promise = playback || playback === undefined ? video.play() : video.pause();
    if (promise) {
      promise.catch(() => false);
    }
  });
};

const lifecycleMap = {
  onWillMount: ({ onComponentsChange, instanceId }) =>
    import('custom-event-polyfill')
      .then(() => import('aframe'))
      .then(() => import('aframe-faceset-component'))
      .then(() => import('aframe-text-geometry-component'))
      .then(() => import('aframe-extras/dist/aframe-extras.controls'))
      .then(() => import('aframe-extras/dist/aframe-extras.misc'))
      .then(() => import('aframe-animation-component'))
      .then(() => utils.aframe.modules.registerDoorGeometry(window.AFRAME, instanceId))
      .then(() => utils.aframe.modules.registerEvents(window.AFRAME, instanceId))
      .then(() => utils.aframe.modules.registerAttribute(window.AFRAME, instanceId))
      .then(() => import('aframe-react'))
      .then(({ Entity, Scene }) => onComponentsChange({ Entity, Scene })),
  onDidUpdate: (prevProps, props) => handleEvents(prevProps, props),
};

const WebVRWrapper = compose(
  withLocalState('components', 'onComponentsChange', {}),
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
  onlyUpdateForKeys(['components', 'instanceId', 'exhibitionModel', 'ready', 'cursor']),
)(WebVRWrapperView);

export default WebVRWrapper;

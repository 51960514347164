import React from 'react';
import Grid from '@material-ui/core/Grid';
import { BannerImage } from '../../styles/PrivateSpacesPageStyled';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const CarouselWrapper = ({ images, small = false }) => {
  return (
    <Grid container>
      <Carousel
        autoPlay
        infiniteLoop
        swipeable
        showArrows={small} //{false}
        useKeyboardArrows
        showIndicators
        emulateTouch
        interval={small ? 5000 : 4000} //{4000}
        animationHandler="fade"
        showStatus={false}
        showThumbs={false}
        stopOnHover={false}
      >
        {images.map((image, index) => {
          if (small === true) {
            return <img key={index} src={image} alt={index} style={{ borderRadius: '10px' }} />;
          }
          return (
            <BannerImage key={index} src={image} style={{ borderRadius: '0px 0px 0px 100px' }} />
          );
        })}
      </Carousel>
    </Grid>
  );
};

export default CarouselWrapper;

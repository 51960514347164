import styled, { css } from 'styled-components';
import FolderIcon from '@material-ui/icons/Folder';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import Paper from '@material-ui/core/Paper';
import colors from '../../../styles/colors';

export const StyledFolder = styled(Paper)`
  width: 100%;
  margin-top: 10px;
  padding: 15px;
  border-radius: 4px;
  background-color: ${colors.ourWhite};
  color: ${colors.ourBlack};
  cursor: pointer;

  &:hover {
    background-color: ${colors.ourBlack};
    color: ${colors.ourWhite};
    color: ${props => (props.selected ? colors.ourGreen : '')};
  }

  color: ${props => (props.selected ? colors.ourGreen : '')};

  ${props =>
    props.notag &&
    css`
      color: ${colors.ourRed};
      font-style: italic;

      &:hover {
        background-color: ${colors.ourBlack};
        color: ${colors.ourRed};
    `}
`;

export const ArtifactContainer = styled.div`
  width: 56px;
  height: 56px;
`;

export const StyledFolderIcon = styled(FolderIcon)`
  opacity: ${props => (props.selected ? 1 : 0.6)};

  &:hover {
    opacity: 1;
  }
`;

export const StyledListIcon = styled(ViewComfyIcon)`
  opacity: ${props => (props.selected ? 1 : 0.6)};

  &:hover {
    opacity: 1;
  }
`;

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import TextureUploader from '../../../textures/TextureUploader';
import TextureList from '../../../textures/lists/TextureList';
import { compose, withState, withDispatch, withLifecycle } from '../../../../enhancers';
import { apiGET, setUIProperty, setUIData, setFormProperty } from '../../../../actions';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getAuthUser,
  getUIProperty,
  getFormProperty,
} from '../../../../reducers';
import colours from '../../../../styles/colours.json';
import { ToolbarSection, ColorList, ColorButton } from '../ExhibitionEditStyled';
import { InvisibleButton } from '../../lists/ExhibitionStyled';
import { HorizontalItems, StyledTextField, Text } from '../../../../styles/GenericStyled';
import ExhibitionThemePanel from './ExhibitionTheme/ExhibitionThemePanel';

export const ExhibitionTexturesPanelView = ({
  textures = [],
  searchTerm = '',
  colour = '#000000',
  exhibitionId,
  ready = true,
  painting = false,
  placingTexture = false,
  onStartPainting = () => Promise.resolve(false),
  onStopPainting = () => Promise.resolve(false),
  onSearch = () => Promise.resolve(false),
}) => {
  const [showColors, setShowColors] = useState(true);
  const [showTextures, setShowTextures] = useState(false);

  const renderTextures = () => {
    const filteredTextures = textures.filter(
      ({ title = '', file: { bin = {} } = {} }) =>
        title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        (bin.name && bin.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1),
    );
    return (
      <TextureUploader>
        {textures.length === 0 && <div className="help-text">{__('help_textures')}</div>}
        {textures.length > 0 && filteredTextures.length === 0 && (
          <div className="help-text">{__('no_textures_found')}</div>
        )}
        <TextureList
          textures={filteredTextures}
          exhibitionId={exhibitionId}
          ready={ready}
          controls
        />
      </TextureUploader>
    );
  };

  const renderColours = () => (
    <ToolbarSection>
      <HorizontalItems>
        <InvisibleButton onClick={() => setShowColors(showColors => !showColors)}>
          {showColors ? (
            <ArrowDropUpIcon fontSize="large" />
          ) : (
            <ArrowDropDownIcon fontSize="large" />
          )}
        </InvisibleButton>
        <Text type="h7">
          {__('colours')} ({colours.basic.length})
        </Text>
      </HorizontalItems>
      {showColors && (
        <ColorList>
          {colours.basic.map(colourCode => (
            <ColorButton
              onClick={() =>
                painting && colour === colourCode ? onStopPainting() : onStartPainting(colourCode)
              }
              background={colourCode}
              key={colourCode}
              selected={+(painting && colour === colourCode)}
              inactive={+(placingTexture || (painting && colour !== colourCode))}
            ></ColorButton>
          ))}
        </ColorList>
      )}
    </ToolbarSection>
  );

  return (
    <div className="exhibition-textures-editor">
      {renderColours()}
      <ExhibitionThemePanel exhibitionId={exhibitionId} />
      <ToolbarSection>
        <HorizontalItems>
          <InvisibleButton onClick={() => setShowTextures(showTextures => !showTextures)}>
            {showTextures ? (
              <ArrowDropUpIcon fontSize="large" />
            ) : (
              <ArrowDropDownIcon fontSize="large" />
            )}
          </InvisibleButton>
          <Text type="h7">
            {__('textures')} ({textures.length})
          </Text>
        </HorizontalItems>

        {showTextures && (
          <HorizontalItems>
            <StyledTextField
              style={{ padding: '10px' }}
              fullWidth
              variant="outlined"
              type="text"
              placeholder={__('search')}
              onChange={event => onSearch(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </HorizontalItems>
        )}
        {showTextures && renderTextures()}
      </ToolbarSection>
    </div>
  );
};

const createQuery = () => ({});

const mapState = (state, { exhibitionId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    painting: getUIProperty(state, `exhibitions/${exhibitionId}/painting`),
    placingTexture: !!getUIProperty(state, `exhibitions/${exhibitionId}/placingTexture`),
    colour: getUIProperty(state, `exhibitions/${exhibitionId}/paintingColour`),
    textures: Object.values(getApiResource(state, 'textures', createQuery(currentUser))),
    ready: getApiStatus(state, 'textures', createQuery(currentUser)) === API_STATUS.IDLE,
    searchTerm: getFormProperty(state, 'textures', 'search'),
  };
};

const mapDispatch = (dispatch, { exhibitionId, painting, currentUser }) => ({
  onFetchTextures: () => dispatch(apiGET('textures', createQuery(currentUser))),
  onStartPainting: colour =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        painting: true,
        placingTexture: undefined,
        paintingColour: colour,
      }),
    ),
  onStopPainting: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingTexture: undefined,
        painting: false,
      }),
    ),
  onPaintAll: colour => dispatch(setUIProperty(`exhibitions/${exhibitionId}/wallColour`, colour)),
  onSearch: searchTerm => dispatch(setFormProperty('textures', 'search', searchTerm)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchTextures }) => onFetchTextures(),
};

const ExhibitionTexturesPanel = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionTexturesPanelView);

export default ExhibitionTexturesPanel;

import React from 'react';
import AdminPanelWrapper from './AdminPanelWrapper';
import { getApiResource, getApiStatus, getAuthUser, getAuthUserRoles } from '../../reducers';
import { apiDELETE, apiGET, setLocation } from '../../actions';
import { compose, withDispatch, withLifecycle, withState } from '../../enhancers';
import { Redirect } from 'react-router';
import { DataGrid } from '@material-ui/data-grid';
import { ExpandButton, StyledButton, Text } from '../../styles/GenericStyled';
import Box from '@material-ui/core/Box';
import colors from '../../styles/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import MyToolTip from '../generic/MyToolTip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import YesOrNo from '../generic/YesOrNo';

const ReportsAdminWrapperView = ({
  reports,
  currentUser,
  ready,
  isAdmin,
  onRedirect = () => Promise.resolve(false),
  onDelete = () => Promise.resolve(false),
}) => {

  const [deleteReport, setDeleteReport] = React.useState('');
  const [open, setOpen] = React.useState(false);

  if (!isAdmin) return <Redirect to="/" />;
  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({ row }) => <Text>{row.type}</Text>,
    },
    {
      field: 'createdAt',
      headerName: 'CreatedAt',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({ row }) => <Text>{new Date(row.createdAt).toLocaleString()}</Text>,
    },
    {
      field: 'exhibition',
      headerName: 'Exhibition',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <MyToolTip title="Visit" placement="top">
            <StyledButton
              type="tertiary"
              style={{ marginLeft: 'auto' }}
              onClick={() => onRedirect(`/view/${row.exhibition}`)}
              background="white"
              mycolor="black"
              bordercolor="gray"
            >
              <Text>{row.exhibition}</Text>
            </StyledButton>
          </MyToolTip>
          <MyToolTip title="Copy to clipboard" placement="top">
            <Box
              component={ExpandButton}
              boxShadow={3}
              mycolor={colors.ourBlack}
              mycolorhover={colors.ourWhite}
              background={colors.ourWhite}
              backgroundhover={colors.ourBlack}
              onClick={() => navigator.clipboard.writeText(row.exhibition)}
              style={{ marginLeft: '10px', marginRight: 'auto' }}
            >
              <FileCopyIcon />
            </Box>
          </MyToolTip>
        </>
      ),
    },
    {
      field: 'user',
      headerName: 'Reporter',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <MyToolTip title="Visit" placement="top">
            <StyledButton
              type="tertiary"
              style={{ marginLeft: 'auto' }}
              onClick={() => onRedirect(`/profile/${row.user}`)}
              background="white"
              mycolor="black"
              bordercolor="gray"
            >
              <Text>{row.user}</Text>
            </StyledButton>
          </MyToolTip>
          <MyToolTip title="Copy to clipboard" placement="top">
            <Box
              component={ExpandButton}
              boxShadow={3}
              mycolor={colors.ourBlack}
              mycolorhover={colors.ourWhite}
              background={colors.ourWhite}
              backgroundhover={colors.ourBlack}
              onClick={() => navigator.clipboard.writeText(row.user)}
              style={{ marginLeft: '10px', marginRight: 'auto' }}
            >
              <FileCopyIcon />
            </Box>
          </MyToolTip>
        </>
      ),
    },
    {
      field: '_id',
      headerName: 'Actions',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({ row }) => (
        <MyToolTip title="Delete" placement="top">
          <Box
            component={ExpandButton}
            boxShadow={3}
            mycolor={colors.ourBlack}
            mycolorhover={colors.ourWhite}
            background={colors.ourWhite}
            backgroundhover={colors.ourBlack}
            onClick={() => {
              setDeleteReport(row._id);
              setOpen(true);
            }}
            style={{ marginLeft: 'auto' }}
          >
            <DeleteIcon />
          </Box>
        </MyToolTip>
      ),
    },
  ];

  return (
    <AdminPanelWrapper>
      <div style={{ width: '100%' }}>
        <DataGrid
          getRowId={row => row._id}
          loading={!ready}
          autoHeight
          rowHeight={70}
          rows={reports}
          columns={columns}
          pageSize={40}
        />
      </div>
      <YesOrNo
        title="Delete report?"
        message={`Are you sure you want to delete this report ?`}
        optionA="No"
        optionB="Yes"
        open={open}
        setOpen={setOpen}
        handleYes={() => {
          onDelete(deleteReport);
          setOpen(false);
        }}
      />
    </AdminPanelWrapper>
  );
};

const ADMIN_ROLES = ['admin'];

const mapState = state => ({
  reports: Object.values(getApiResource(state, `reports`)),
  currentUser: getAuthUser(state),
  ready: getApiStatus(state, 'reports') !== 'fetching',
  isAdmin: getAuthUserRoles(state).some(role => ADMIN_ROLES.includes(role)),
});

const mapDispatch = dispatch => ({
  onFetchReports: () => dispatch(apiGET('reports')),
  onRedirect: location => dispatch(setLocation(location)),
  onDelete: report => dispatch(apiDELETE(`reports/${report}`)),
});
const lifecycleMap = {
  onWillMount: ({ onFetchReports }) => onFetchReports(),
};

const ReportsAdminWrapper = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ReportsAdminWrapperView);


export default ReportsAdminWrapper;

import React from 'react';
import { __ } from 'artsteps2-common';
import Link from '../../generic/Link';

import utils from '../../../utils';

export const FollowerView = ({ follower = { profile: {} } }) => (
  <div className="follower item">
    <img alt="" className="ui avatar mini image" src={utils.user.getUserImage(follower)} />
    <div className="content">
      <div className="header">
        <Link to={`/profile/${follower._id}`}>{(follower.profile || {}).name}</Link>
      </div>
      <div className="description">
        {follower.followingSince &&
          `${__('started_following')} ${utils.date.fromNow(follower.followingSince)}`}
      </div>
    </div>
  </div>
);

const Follower = FollowerView;

export default Follower;

import React, { useState, useRef } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import { ExpandButton } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';
import { PreviewDiv } from '../forms/FormStyled';
import ImagePreview from './ImagePreview';
import VideoPreview from './VideoPreview';
import ObjectPreview from './Object/ObjectPreview';
import utils from '../../../utils';
import { addMessage } from '../../../actions';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getAuthUser, getApiResource } from '../../../reducers';
import TextPreview from './TextPreview';
import AudioPreview from './AudioPreview';

// File uri will either be:
// base 64 string ⚾
// filename 📁
// url 🔗

const FilePreviewView = ({
  files,
  type,
  onRemove,
  showDelete,
  currentUserId,
  isSubscribed,
  setFiles,
  setThumbnail = () => Promise.resolve(false),
  uploadOption = 'file',
  textColor,
  textAlignment,
  onAddMessage = () => Promise.resolve(false),
  spaceSettings = {},
}) => {
  const [prevFiles, setPrevFiles] = useState([]);
  const previewRef = useRef();

  const didFilesChanged = () => {
    if (files && files.length > 0 && prevFiles.length === files.length) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].base64 !== prevFiles[i].base64 || files[i].uri !== prevFiles[i].uri)
          return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const loadCallBack = (element, uploadOption = 'file') => {
    setPrevFiles(files);
    if (didFilesChanged() || uploadOption === 'byPass') {
      utils.image.createThumbnail(files[0], element, uploadOption, currentUserId).then(image => {
        setThumbnail(image);
      });

      if (!element && uploadOption === 'file' && files[0].base64)
        utils.image
          .downScaleImage(files[0], spaceSettings.increasedQuality || isSubscribed)
          .then(image => {
            setFiles(oldFiles =>
              oldFiles.map(oldFile => ({ ...oldFile, bin: { ...image.bin, name: files[0].name } })),
            );
          });
    }
  };

  const onPreviewLoad = (element, uploadOption) => loadCallBack(element, uploadOption);

  return (
    <PreviewDiv ref={previewRef}>
      {showDelete && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            right: '16px',
            top: '16px',
          }}
        >
          <Box
            component={ExpandButton}
            boxShadow={3}
            mycolor={colors.ourBlack}
            mycolorhover={colors.ourWhite}
            background={colors.ourWhite}
            backgroundhover={colors.ourBlack}
            onClick={() => files && files.length > 0 && onRemove(files[0])}
            style={{ position: 'absolute', right: 0 }}
          >
            <DeleteIcon />
          </Box>
        </div>
      )}

      {files &&
        files.length > 0 &&
        files.map((file, idx) => (
          <React.Fragment key={idx}>
            {type === 'image' && (
              <ImagePreview
                key={idx}
                uri={utils.artifact.parseFileUri(file, currentUserId)}
                onPreviewLoad={onPreviewLoad}
              />
            )}

            {type === 'video' && (
              <VideoPreview
                key={idx}
                uri={utils.artifact.parseFileUri(file, currentUserId)}
                uploadOption={uploadOption}
                onPreviewLoad={onPreviewLoad}
              />
            )}
            {type === 'text' && (
              <TextPreview
                previewRef={previewRef.current}
                textColor={textColor}
                textAlignment={textAlignment}
                uploadOption={uploadOption}
                key={idx}
                bin={file.bin}
                onPreviewLoad={onPreviewLoad}
              />
            )}
            {type === 'audio' && (
              <AudioPreview
                key={idx}
                uri={utils.artifact.getArtifactAudio({ audio: file, user: currentUserId })}
              />
            )}
          </React.Fragment>
        ))}
      {files && files.length > 0 && type === 'object' && (
        <ObjectPreview
          showScreenShot={true}
          files={files}
          currentUserId={currentUserId}
          onPreviewLoad={onPreviewLoad}
          onAddMessage={onAddMessage}
        />
      )}
    </PreviewDiv>
  );
};

const mapState = state => ({
  isSubscribed: getAuthUser(state).subscribe,
  currentUserId: getAuthUser(state)._id,
  spaceSettings: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`).settings,
});

const mapDispatch = dispatch => ({
  onAddMessage: message => dispatch(addMessage(message)),
});

const FilePreview = compose(withState(mapState), withDispatch(mapDispatch))(FilePreviewView);

export default FilePreview;

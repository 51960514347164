"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAssets = exports.createDoorAssets = exports.createWallAssets = exports.createFrameAssets = exports.createDisplayCaseAssets = exports.createArtifactAssets = exports.createDefaultAssets = void 0;

var _constants = _interopRequireDefault(require("./constants"));

var createDefaultAssets = function createDefaultAssets() {
  return [{
    primitive: 'img',
    key: 'sky',
    id: 'sky',
    src: _constants["default"].ASSET.TEXTURE.SKY
  }, {
    primitive: 'img',
    key: 'wall-emission',
    id: 'wall-emission',
    src: _constants["default"].ASSET.TEXTURE.WALL
  }, {
    primitive: 'img',
    key: 'wall-emission-interior-door',
    id: 'wall-emission-interior-door',
    src: _constants["default"].ASSET.TEXTURE.INTERIOR_DOOR
  }, {
    primitive: 'img',
    key: 'floor-texture',
    id: 'floor-texture',
    src: _constants["default"].ASSET.TEXTURE.FLOOR
  }, {
    primitive: 'img',
    key: 'ground-texture',
    id: 'ground-texture',
    src: _constants["default"].ASSET.TEXTURE.GROUND
  }, {
    primitive: 'img',
    key: 'ceiling-texture',
    id: 'ceiling-texture',
    src: _constants["default"].ASSET.TEXTURE.CEILING
  }, {
    primitive: 'img',
    key: 'wall-top-texture',
    id: 'wall-top-texture',
    src: _constants["default"].ASSET.TEXTURE.WALL_TOP
  }, {
    primitive: 'a-asset',
    key: "entrance-frame",
    id: "entrance-frame",
    src: _constants["default"].ASSET.ENTRANCE.FRAME
  }, {
    primitive: 'a-asset',
    key: "entrance-door-right",
    id: "entrance-door-right",
    src: _constants["default"].ASSET.ENTRANCE.DOOR_RIGHT
  }, {
    primitive: 'a-asset',
    key: "entrance-door-left",
    id: "entrance-door-left",
    src: _constants["default"].ASSET.ENTRANCE.DOOR_LEFT
  }];
};

exports.createDefaultAssets = createDefaultAssets;

var createArtifactAssets = function createArtifactAssets() {
  var exhibition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var assets = [];
  (exhibition.artifacts || []).forEach(function (artifact) {
    if (!artifact) {
      return;
    }

    var id = "artifact-".concat(artifact.artifactId);

    if (assets.find(function (a) {
      return a.id === id;
    })) {
      return;
    }

    if (artifact.artifactType === 'image') {
      assets.push({
        primitive: 'img',
        key: "artifact-".concat(artifact.artifactId),
        crossOrigin: 'anonymous',
        id: "artifact-".concat(artifact.artifactId),
        src: artifact.uri
      });
    }

    if (artifact.artifactType === 'video') {
      assets.push({
        primitive: 'video',
        key: "artifact-".concat(artifact.artifactId),
        autoPlay: true,
        muted: true,
        loop: true,
        preload: 'auto',
        crossOrigin: 'anonymous',
        shader: 'flat',
        id: "artifact-".concat(artifact.artifactId),
        src: artifact.uri
      });
    }

    if (artifact.artifactType === 'object') {
      assets.push({
        primitive: 'a-asset',
        key: "artifact-".concat(artifact.artifactId),
        id: "artifact-".concat(artifact.artifactId),
        src: artifact.uri
      });
      var mtl = artifact.files.find(function (f) {
        return f.match(/\.mtl/);
      });

      if (mtl) {
        assets.push({
          primitive: 'a-asset',
          key: "artifact-".concat(artifact.artifactId, "-mtl"),
          id: "artifact-".concat(artifact.artifactId, "-mtl"),
          src: mtl.match(/\/\//) ? mtl : artifact.uri.replace(/\/[^/]+$/, "/".concat(mtl))
        });
      }
    }
  });
  return assets;
}; // Pre-loading ALL the display cases


exports.createArtifactAssets = createArtifactAssets;

var createDisplayCaseAssets = function createDisplayCaseAssets() {
  var exhibition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (exhibition.displayCases || []).map(function (displayCase) {
    return {
      primitive: 'a-asset',
      key: "display-case-asset-".concat(displayCase._id),
      id: "display-case-asset-".concat(displayCase._id),
      src: "".concat(_constants["default"].ASSET.DISPLAY_CASE.PATH, "/").concat(displayCase.type, ".glb")
    };
  });
}; // Pre-loading ALL the image frames


exports.createDisplayCaseAssets = createDisplayCaseAssets;

var createFrameAssets = function createFrameAssets() {
  return Object.keys(_constants["default"].ASSET.FRAMES).map(function (key) {
    return {
      primitive: 'a-asset',
      key: "frame-".concat(_constants["default"].ASSET.FRAMES[key].legacyId),
      id: "frame-".concat(_constants["default"].ASSET.FRAMES[key].legacyId),
      src: _constants["default"].ASSET.FRAMES[key].uri
    };
  });
};

exports.createFrameAssets = createFrameAssets;

var createWallAssets = function createWallAssets() {
  var exhibition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var assets = []; // Pre-loading the textures

  (exhibition.walls || []).forEach(function (wall, idx) {
    if (!wall) {
      return;
    }

    if (wall.textureFront) {
      assets.push({
        primitive: 'img',
        key: "wall-texture-front-".concat(idx),
        id: "wall-texture-front-".concat(idx),
        crossOrigin: 'anonymous',
        src: wall.textureFront
      });
    }

    if (wall.textureBack) {
      assets.push({
        primitive: 'img',
        key: "wall-texture-back-".concat(idx),
        id: "wall-texture-back-".concat(idx),
        crossOrigin: 'anonymous',
        src: wall.textureBack
      });
    }
  });
  return assets;
};

exports.createWallAssets = createWallAssets;

var createDoorAssets = function createDoorAssets() {
  var exhibition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var assets = [];
  (exhibition.doors || []).forEach(function (door, idx) {
    if (!door) {
      return;
    }

    if (door.hallTextureFront) {
      assets.push({
        primitive: 'img',
        key: "door-texture-front-".concat(idx),
        id: "door-texture-front-".concat(idx),
        crossOrigin: 'anonymous',
        src: door.hallTextureFront
      });
    }

    if (door.hallTextureBack) {
      assets.push({
        primitive: 'img',
        key: "door-texture-back-".concat(idx),
        id: "door-texture-back-".concat(idx),
        crossOrigin: 'anonymous',
        src: door.hallTextureBack
      });
    }

    if (door.topPart.textureFront) {
      assets.push({
        primitive: 'img',
        key: "door-toppart-texture-front-".concat(idx),
        id: "door-toppart-texture-front-".concat(idx),
        crossOrigin: 'anonymous',
        src: door.topPart.textureFront
      });
    }

    if (door.topPart.textureBack) {
      assets.push({
        primitive: 'img',
        key: "door-toppart-texture-back-".concat(idx),
        id: "door-toppart-texture-back-".concat(idx),
        crossOrigin: 'anonymous',
        src: door.topPart.textureBack
      });
    }
  });
  return assets;
};
/*
 * Pre-loads the assets, which causes the exhibition to load faster.
 *
 * @params {Exhibition} exhibition - The exhibition model
 * @return {array} - An array with the entities to preload.
 *
 * */


exports.createDoorAssets = createDoorAssets;

var createAssets = function createAssets(exhibition) {
  if (!exhibition) {
    return null;
  }

  return createDefaultAssets(exhibition).concat(createArtifactAssets(exhibition)).concat(createDisplayCaseAssets(exhibition)).concat(createFrameAssets(exhibition)).concat(createWallAssets(exhibition)).concat(createDoorAssets(exhibition));
};

exports.createAssets = createAssets;
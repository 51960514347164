import { push } from 'react-router-redux';
import qs from 'qs';

export const ROUTER_NAMESPACE = '@@router';
export const ROUTER_ACTION_LOCATION_CHANGE = 'LOCATION_CHANGE';

export const setLocation = location => (dispatch, getState) =>
  Promise.resolve(
    typeof location === 'string'
      ? dispatch(push(location))
      : dispatch(
          push({
            pathname: location.pathname,
            search: qs.stringify(location.query || {}),
            hash: location.hash,
          }),
        ),
  );

import React, { useEffect, useState } from 'react';
import publicConfig from 'artsteps2-config/public.json';
import { __ } from 'artsteps2-common';
import Fullscreen from 'react-full-screen';
import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { compose, withState, withDispatch, withLifecycle, withProps } from '../../../enhancers';
import { API_STATUS, getApiResource, getApiStatus, getUIProperty } from '../../../reducers';
import { apiGET, setUIProperty, setLocation } from '../../../actions';
import UnityWrapper from '../arvr/UnityWrapper';
import WebVRWrapper from '../arvr/WebVRWrapper';
import ExhibitionStoryPlayer from './ExhibitionStoryPlayer';
import ArtifactViewer from '../../artifacts/ArtifactViewer';
import BrowserIconsSection from '../../generic/BrowserIconsSection';
import Loader from '../../generic/Loader';
import ArtifactOverlay from '../../layouts/ArtifactOverlay';
import Dialog from '../../generic/Dialog';
import utils from '../../../utils';
import chatSrc from '../../../styles/images/tutorial/chat.jpg';
import interactSrc from '../../../styles/images/tutorial/interact.jpg';
import mapSrc from '../../../styles/images/tutorial/map.jpg';
import moveSrc from '../../../styles/images/tutorial/move.jpg';
import tourSrc from '../../../styles/images/tutorial/tour.jpg';
import wasdSrc from '../../../styles/images/tutorial/wasd.jpg';
import ExhibitionViewOptions from './ExhibitionViewOptions';
import { HorizontalItems, VerticalItems, Text } from '../../../styles/GenericStyled';
import YoutubeFrame from '../../generic/YoutubeFrame';
import DeviceWarning from './DeviceWarning';

const STEP_VIEW = 5;

export const ExhibitionViewFrameView = ({
  exhibition,
  storypoints = [],
  enabledAudio = ['', false],
  displayedArtifactId,
  artifacts,
  ready = false,
  webVR,
  embedded = false,
  fullscreen = false,
  agentWarning = false,
  width,
  height,
  tutorialParagraphs = [],
  onOverlayClose = () => Promise.resolve(false),
  onFullscreenChange = () => Promise.resolve(false),
  onCloseAgentWarning = () => Promise.resolve(false),
  onSetEnabledAudio,
  IFrameLink = undefined,
  setIFrameLink,
  setDisplayedArtifact,
}) => {
  const [deviceWarning, setDeviceWarning] = useState(false);

  useEffect(() => {
    setDeviceWarning(utils.navigator.isMobile() || utils.navigator.isIE());
  }, []);

  useEffect(() => {
    document.title = `artsteps | ${exhibition.title}`;
  }, [exhibition]);

  // An effect that monitor displayed artifact to see if it is a Youtube Link
  // If it is it will show an IFrame and will not open artifact overlay viewer
  useEffect(() => {
    if (displayedArtifactId) {
      const displayedArtifact =
        artifacts.find(artifact => artifact._id === displayedArtifactId) || {};

      if (
        displayedArtifact.files &&
        displayedArtifact.files.length > 0 &&
        displayedArtifact.files[0].uri
      ) {
        if (
          displayedArtifact.files[0].uri.includes('youtube.com') ||
          displayedArtifact.files[0].uri.includes('youtu.be')
        ) {
          setDisplayedArtifact(undefined);
          setIFrameLink(displayedArtifact.files[0].uri);
        }
      }
    }
  }, [displayedArtifactId]);

  return deviceWarning ? (
    <DeviceWarning exhibitionId={exhibition._id} setDeviceWarning={setDeviceWarning} />
  ) : (
    <Fullscreen enabled={fullscreen} onChange={onFullscreenChange}>
      <div style={{ position: 'relative' }}>
        {IFrameLink && (
          <YoutubeFrame
            absolute
            exhibitionId={exhibition._id}
            showTopBar
            src={IFrameLink}
            width="70%"
            height="70%"
          />
        )}

        <div className={`exhibition-view viewer ${embedded ? 'embedded' : ''}`}>
          {exhibition && exhibition._id && !webVR && (
            <UnityWrapper
              exhibitionId={exhibition._id}
              exhibitionTitle={exhibition.title}
              width={width}
              height={height}
              releaseWheel
            />
          )}
          {exhibition && exhibition._id && webVR && (
            <WebVRWrapper
              exhibitionId={exhibition._id}
              exhibitionTitle={exhibition.title}
              width={width}
              height={height}
              releaseWheel
            />
          )}
          <ExhibitionStoryPlayer
            exhibition={exhibition}
            storypoints={storypoints.filter(p =>
              (((exhibition || {}).model || {}).storyPoints || []).some(
                point => point.storyPoint === p._id,
              ),
            )}
          />
          {enabledAudio[1] ? (
            <div
              style={{
                position: 'absolute',
                top: '10px',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <VerticalItems
                style={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  width: '292px',
                  borderRadius: '7px',
                }}
              >
                <Text mycolor="white">{__('enable_audio')}</Text>
                <HorizontalItems>
                  <IconButton
                    onClick={() => {
                      enabledAudio[0].current.play();
                      onSetEnabledAudio(enabledAudio[0], false);
                    }}
                  >
                    <CheckIcon style={{ color: '#38e538' }} />
                  </IconButton>
                  <IconButton onClick={() => onSetEnabledAudio(enabledAudio[0], false)}>
                    <CloseIcon style={{ color: 'red' }} />
                  </IconButton>
                </HorizontalItems>
              </VerticalItems>
            </div>
          ) : (
            <></>
          )}
          <ExhibitionViewOptions exhibition={exhibition} paragraphs={tutorialParagraphs} />
          {!IFrameLink && (
            <ArtifactOverlay
              open={!!displayedArtifactId && !IFrameLink}
              position="left"
              clear
              onClose={onOverlayClose}
              transparent
              exhibitionId={exhibition._id}
            >
              <ArtifactViewer artifactId={displayedArtifactId} exhibitionId={exhibition._id} exhibitionTemplate={exhibition?.model?.template} />
            </ArtifactOverlay>
          )}
        </div>
        {ready || <Loader />}
        <Dialog
          type="warning"
          open={agentWarning}
          title={__('agent_warning')}
          message={__('agent_warning_description', exhibition)}
          onClose={onCloseAgentWarning}
        >
          <BrowserIconsSection />
        </Dialog>
      </div>
    </Fullscreen>
  );
};

const createArtifactFilter = exhibitions => ({ filter: { exhibitions } });
const createStorypointFilter = exhibition => ({
  filter: { exhibition },
  include: 'description',
});

const mapState = (state, { exhibitionId, unsupportedDevice, unsupportedAgent }) => ({
  IFrameLink: getUIProperty(state, `exhibitions/${exhibitionId}/IFrameLink`),
  exhibition: window.location.href.includes('currentUser')
    ? getApiResource(state, `exhibitionsUserProfile/${exhibitionId}`)
    : getApiResource(
        state,
        `exhibitions${
          !publicConfig.spaceNames.includes(window.location.hostname.split('.')[0]) ? 'Private' : ''
        }/${exhibitionId}`,
      ),
  artifacts: Object.values(getApiResource(state, 'artifacts', createArtifactFilter(exhibitionId))),
  displayedArtifactId: getUIProperty(state, `exhibitions/${exhibitionId}/displayedArtifact`),
  storypoints: Object.values(
    getApiResource(state, 'storypoints', createStorypointFilter(exhibitionId)),
  ).sort((pointA, pointB) => pointA.order - pointB.order),
  ready:
    getApiStatus(state, `exhibitions/${exhibitionId}`) === API_STATUS.IDLE &&
    getApiStatus(state, 'artifacts', createArtifactFilter(exhibitionId)) === API_STATUS.IDLE &&
    getApiStatus(state, 'storypoints', createStorypointFilter(exhibitionId)) === API_STATUS.IDLE,
  exhibitionImported: !!getUIProperty(state, `exhibitions/${exhibitionId}/imported`),
  fullscreen: getUIProperty(state, `exhibitions/${exhibitionId}/fullscreen`),
  agentWarning:
    unsupportedAgent &&
    !unsupportedDevice &&
    !getUIProperty(state, `exhibitions/${exhibitionId}/agentWarningClosed`),
  deviceWarning:
    unsupportedDevice && !getUIProperty(state, `exhibitions/${exhibitionId}/deviceWarningClosed`),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  enabledAudio: getUIProperty(state, `exhibitions/${exhibitionId}/enabledAudio`),
});

const mapDispatch = (dispatch, { exhibitionId, isPrivateSpace }) => ({
  onFetchExhibition: () => {
    let endpoint = isPrivateSpace ? 'exhibitionsPrivate' : 'exhibitions';
    if (window.location.href.includes('currentUser')) {
      endpoint = 'exhibitionsUserProfile';
    }
    return dispatch(
      apiGET(`${endpoint}/${exhibitionId}`, {
        include: ['model', '_v1Views', 'categories'],
        populate: ['user'],
      }),
    );
  },
  setIFrameLink: word => dispatch(setUIProperty(`exhibitions/${exhibitionId}/IFrameLink`, word)),
  onFetchTemplates: () => dispatch(apiGET('templates')),
  onFetchArtifacts: () => dispatch(apiGET('artifacts', createArtifactFilter(exhibitionId))),
  onFetchStorypoints: () => dispatch(apiGET('storypoints', createStorypointFilter(exhibitionId))),
  setDisplayedArtifact: artifactId =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayedArtifact`, artifactId)),
  onOverlayClose: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayedArtifact`, null)),
  onImportExhibition: ({ exhibition, artifacts, storypoints, templates }) =>
    dispatch(
      setUIProperty(
        `exhibitions/${exhibitionId}/import`,
        utils.exhibition.exportExhibition(exhibition, artifacts, storypoints, templates),
      ),
    ).then(() => dispatch(setUIProperty(`exhibitions/${exhibitionId}/currentStep`, STEP_VIEW))),
  onFullscreenChange: fullscreen =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/fullscreen`, fullscreen)),
  onCloseDeviceWarning: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/deviceWarningClosed`, true)),
  onCloseAgentWarning: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/agentWarningClosed`, true)),
  onRedirect: location => dispatch(setLocation(location)),
  onSetEnabledAudio: (ref, show) =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/enabledAudio`, [ref, show])),
});

const onInitialization = ({
  onFetchExhibition,
  onFetchArtifacts,
  onFetchStorypoints,
  onFetchTemplates,
  onImportExhibition,
  onRedirect,
  isPrivateSpace,
}) =>
  Promise.all([
    onFetchExhibition(),
    onFetchArtifacts(),
    onFetchStorypoints(),
    onFetchTemplates(),
  ]).then(
    ([
      { response: exhibition },
      { response: artifacts },
      { response: storypoints },
      { response: templates },
    ]) => {
      if (exhibition.error) return onRedirect(!isPrivateSpace ? '/explore' : '/');
      return onImportExhibition({
        exhibition,
        artifacts: Object.values(artifacts),
        storypoints: Object.values(storypoints),
        templates: Object.values(templates),
      });
    },
  );

const mapProps = () => ({
  tutorialParagraphs: [
    [
      { text: __('tutorials.mouse'), icon: moveSrc, class: 'movement' },
      { text: __('tutorials.keyboard'), icon: wasdSrc, class: 'keyboard' },
      { text: __('tutorials.interact'), icon: interactSrc, class: 'interact' },
    ],
    [
      { text: __('tutorials.map'), icon: mapSrc, class: 'map' },
      { text: __('tutorials.tour'), icon: tourSrc, class: 'tour' },
      { text: __('tutorials.chat'), icon: chatSrc, class: 'chat' },
    ],
  ],
});

const lifecycleMap = {
  onWillMount: props => onInitialization(props),
  onDidUpdate: ({ exhibitionId }, props) => {
    if (exhibitionId !== props.exhibitionId) {
      onInitialization(props);
    }
  },
};

const ExhibitionViewFrame = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withProps(mapProps),
  withLifecycle(lifecycleMap),
)(ExhibitionViewFrameView);

export default ExhibitionViewFrame;

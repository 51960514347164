import React from 'react';
import { apiPOST, apiHEAD } from '../../../actions';
import { getApiMeta, getApiResource } from '../../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';

export const ExhibitionViewsView = ({ totalViews = 0 }) => <React.Fragment>{totalViews}</React.Fragment>;

const createQuery = exhibition => ({ filter: { exhibition } });

const mapState = (state, { exhibitionId }) => ({
  totalViews:
    (getApiMeta(state, 'views', createQuery(exhibitionId)).totalCount || 0) +
    (getApiResource(state, `exhibitions/${exhibitionId}`)._v1Views || 0),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onFetchViews: () => dispatch(apiHEAD('views', createQuery(exhibitionId))),
  onAddView: () => dispatch(apiPOST('views', { exhibition: exhibitionId })),
});

const lifecycleMap = {
  onDidMount: ({ onFetchViews, onAddView }) => onAddView().then(() => onFetchViews()),
};

const ExhibitionViews = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionViewsView);

export default ExhibitionViews;

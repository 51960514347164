/* eslint-disable no-nested-ternary */
// Should not nest ternary operators
import { __ } from 'artsteps2-common';
import React, { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import utils from '../../utils';
import CreateWithTemplatesNew from '../buttons/CreateWithTemplatesNew';
import * as header from '../../styles/styledComponents/header';
import * as generic from '../../styles/GenericStyled';
import { getUIProperty, getApiResource } from '../../reducers';
import { ReactComponent as LoginIcon } from '../../styles/images/loginIcon.svg';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { setUIProperty } from '../../actions';
import useWindowSize from '../../hooks/windowSize';
import colors from '../../styles/colors';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const DefaultButtonsView = ({
  currentUser,
  space,
  hasChanges,
  onClickedPathLink,
  onOpenDialog,
  isPrivateSpace,
  onToggleCreatorView = () => Promise.resolve(false),
  onToggleNavbar = () => Promise.resolve(false),
  onCloseEditing = () => Promise.resolve(false),
  spaceSettings,
  onClickedLink,
  navbarView,
  handleMenu,
  setOpenLogin,
  setOpenRegister,
  activeLink,
}) => {
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 960) onToggleNavbar(true);
  }, [size.width]);

  return (
    <header.ToggledGrid navbarview={+navbarView}>
      <Box style={{ marginLeft: 'auto' }}>
        {!currentUser && (
          <generic.StyledButton
            mycolor="white"
            background={colors.ourBlue}
            type="secondary"
            onClick={() => setOpenRegister(true)}
          >
            {size.width < 1280 ? (
              <PersonAddIcon style={{ fill: 'white', width: '30px', height: '30px' }} />
            ) : (
              <header.Span signin={`${!!currentUser}`}>{__('register')}</header.Span>
            )}
          </generic.StyledButton>
        )}
        {currentUser && size.width > 1400 && (
          <generic.Text
            type="body1"
            mycolor="#343a40"
            active={`${activeLink === 'My Exhibitions'}`}
            onClick={() => {
              onCloseEditing(false);
              onClickedLink('My Exhibitions');
            }}
          >
            <generic.HeaderLink mycolor="rgb(16 147 123)" to={`/profile/${currentUser._id}`}>
              My Exhibitions
            </generic.HeaderLink>
          </generic.Text>
        )}
      </Box>
      <Box style={{ marginLeft: '10px' }}>
        {!isPrivateSpace ? (
          <>
            <generic.StyledButton
              href={currentUser ? '/curate/new/1' : ''}
              mycolor="white"
              background={`${currentUser ? '#12ad8f' : '#12AD8F'}`}
              type="secondary"
              onClick={e => {
                if (!currentUser) setOpenLogin(true);
                else {
                  if (hasChanges) {
                    e.preventDefault();
                    onClickedPathLink('/curate/new/1');
                    onOpenDialog();
                  } else {
                    onToggleCreatorView(true);
                  }
                }
              }}
            >
              {currentUser && (
                <AddIcon
                  style={{
                    opacity: '0.5',
                    fontSize: '1.9rem',
                    position: 'relative',
                    left: size.width >= 1281 ? '-6px' : 0,
                  }}
                />
              )}
              <header.Span signin={`${!!currentUser}`}>
                {currentUser ? __('create') : __('login')}
              </header.Span>
              {size.width < 1280 && !currentUser && (
                <LoginIcon style={{ fill: 'white', width: '30px', height: '30px' }} />
              )}
            </generic.StyledButton>
          </>
        ) : !currentUser ? (
          <generic.StyledButton
            mycolor="white"
            background="#12AD8F"
            type="secondary"
            onClick={() => setOpenLogin(true)}
          >
            {size.width > 1280 ? (
              <header.Span signin={`${!!currentUser}`}>{__('login')}</header.Span>
            ) : (
              <LoginIcon style={{ fill: 'white', width: '30px', height: '30px' }} />
            )}
          </generic.StyledButton>
        ) : (
          (!spaceSettings || spaceSettings.allowCreate) && (
            <generic.StyledButton mycolor="white" type="secondary" background="#12ad8f">
              <CreateWithTemplatesNew templatesIdsList={space.templates} />
            </generic.StyledButton>
          )
        )}
      </Box>
      <header.AccountButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={e => {
          handleMenu(e);
        }}
        color="inherit"
        logged_out={`${currentUser === '' || currentUser === undefined}`}
      >
        {currentUser ? (
          <header.AvatarIcon src={utils.user.getUserImage(currentUser)} />
        ) : (
          <header.AvatarIcon />
        )}
      </header.AccountButton>
    </header.ToggledGrid>
  );
};

const mapState = (state, { exhibitionId }) => ({
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  spaceSettings: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`).settings,
  contributionBannerInteraction: getUIProperty(state, 'contributionBannerInteraction'),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  hasChanges: getUIProperty(state, `exhibitions/${exhibitionId}/hasChanges`),
  activeLink: getUIProperty(state, 'activeLink'),
});

const mapDispatch = (dispatch, { exhibitionId, currentUser }) => ({
  onOpenDialog: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/dialog`, true)),
  onClickedPathLink: link => dispatch(setUIProperty('activeLinkPath', link)),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  onToggleCreatorView: view => dispatch(setUIProperty('creatorView', view)),
  onToggleCuratorView: view => dispatch(setUIProperty('curatorView', view)),
  onToggleSwitchSubViewView: view => dispatch(setUIProperty('switchsubview', view)),
  onShowContributionModal: () => dispatch(setUIProperty('contributionModalOpen', true)),
  onToggleNavbar: view => dispatch(setUIProperty(`navbarView`, view)),
  onClickedLink: link => dispatch(setUIProperty('activeLink', link)),
  onCloseEditing: () => dispatch(setUIProperty(`profiles/${currentUser._id}/editing`, false)),
});

const lifecycleMap = {};

const DefaultButtons = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(DefaultButtonsView);

export default DefaultButtons;

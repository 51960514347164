import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles(theme => ({
  popper: {
    zIndex: '99999',
    color: theme.palette.common.black,
    fontSize: '1em',
    fontFamily: 'Muli',
  },
  arrow: {
    color: theme.palette.common.black,
    fontSize: '1em',
    fontFamily: 'Muli',
  },
  tooltip: {
    zIndex: '99999',
    backgroundColor: theme.palette.common.black,
    fontSize: '1em',
    fontFamily: 'Muli',
  },
}));

const MyToolTip = ({ placement, title, children }) => {
  const classes = useStylesBootstrap();

  return (
    <Tooltip title={title} placement={placement} arrow classes={classes}>
      {children}
    </Tooltip>
  );
};

export default MyToolTip;

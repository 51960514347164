"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTextureURI = exports.getTextureImage = exports.getTextureFileURI = exports.getTextureBlobURI = void 0;

var _buffer = require("./buffer");

var PATH_TEXTURES = '/api/textures';
var PATH_TEXTURE_FILE = 'file';
var PATH_TEXTURE_IMAGE = 'image';

var getRootURL = function getRootURL() {
  return typeof window === 'undefined' ? process.env.ROOT_URL || 'http://localhost:3000' : "".concat(window.location.protocol, "//").concat(window.location.host);
};

var setProtocol = function setProtocol(url) {
  if (!url) {
    return url;
  }

  return url.replace(/^https?:\/\//, getRootURL().match(/^[a-zA-Z:]+\/\//));
};

var getTextureBlobURI = function getTextureBlobURI(texture, bin, path) {
  if (texture && texture._id) {
    return "".concat(getRootURL()).concat(PATH_TEXTURES, "/").concat(texture._id, "/").concat(path);
  }

  if (bin && bin.content && bin.contentType) {
    return (0, _buffer.toDataURL)(bin);
  }

  return undefined;
};

exports.getTextureBlobURI = getTextureBlobURI;

var getTextureFileURI = function getTextureFileURI(texture) {
  if (!texture.file || !texture.file.bin || !texture.file.bin.contentType) {
    return undefined;
  }

  return getTextureBlobURI(texture, texture.file.bin, "".concat(PATH_TEXTURE_FILE, "/bin"));
};

exports.getTextureFileURI = getTextureFileURI;

var getTextureImage = function getTextureImage(texture) {
  if (!texture.image) {
    return null;
  }

  if (typeof texture.image === 'string') {
    return texture.image;
  }

  return texture.image.uri || getTextureBlobURI(texture, texture.image.bin, "".concat(PATH_TEXTURE_IMAGE, "/bin"));
};

exports.getTextureImage = getTextureImage;

var getTextureURI = function getTextureURI(texture) {
  return getTextureFileURI(texture) || setProtocol((texture.file || {}).uri) || getTextureImage(texture);
};

exports.getTextureURI = getTextureURI;
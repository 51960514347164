import React from 'react';
import Texture from './Texture';
import Loader from '../../generic/Loader';
import { ItemList } from '../../exhibitions/editor/ExhibitionEditStyled';

export const TextureListView = ({
  textures = [],
  controls = false,
  ready = true,
  exhibitionId,
}) => (
  <ItemList>
    {textures.map(
      texture =>
        texture._id && (
          <Texture
            key={texture._id}
            texture={texture}
            controls={controls}
            exhibitionId={exhibitionId}
          />
        ),
    )}
    {ready || <Loader inverted={false} />}
  </ItemList>
);

const TextureList = TextureListView;

export default TextureList;

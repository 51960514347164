const convertToBlob = (space, dbField, setImageSrc) => {
  //db field is either background or logo
  const headers = new Headers({
    Accept: 'image/*',
    'Content-Type': 'application/json',
  });

  const url = `/api/spacesOpen/${space}/${dbField}/0/bin`;

  if (space && dbField) {
    fetch(url, {
      headers,
      method: 'GET',
    })
      .then(response => response.blob())
      .then(blob => {
        const objectURL = URL.createObjectURL(blob);
        setImageSrc(objectURL);
      });
  }
};

export default convertToBlob;

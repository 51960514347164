import React, { useEffect, useRef } from 'react';

const ImagePreview = ({ uri, onPreviewLoad }) => {
  const previewRef = useRef();

  useEffect(() => {
    if (previewRef && previewRef.current) {
      previewRef.current.src = uri;
      previewRef.current.onload = e => onPreviewLoad();
      previewRef.current.crossOrigin = 'Anonymous';
    }
  }, [previewRef, uri, onPreviewLoad]);

  return (
    <img
      alt="image-preview"
      name="image-preview"
      ref={previewRef}
      style={{ width: '100%', maxHeight: '221px', objectFit: 'cover' }}
    />
  );
};

export default ImagePreview;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAudioStream = exports.createVideoStream = exports.createStream = void 0;
var STREAMABLE_STREAMING_PATH = '/api/media/streamable/stream';
var MEDIA_PARAM = 'v';
var TYPE_PARAM = 't';

var getRootURL = function getRootURL() {
  return typeof window === 'undefined' ? process.env.ROOT_URL : "".concat(window.location.protocol, "//").concat(window.location.host);
};

var createStream = function createStream(uri) {
  var video = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  if (uri && uri.match(/streamable/)) {
    var params = "".concat(MEDIA_PARAM, "=").concat(encodeURIComponent(uri), "&").concat(TYPE_PARAM, "=").concat(video ? 'video' : 'audio');
    return "".concat(getRootURL()).concat(STREAMABLE_STREAMING_PATH, "?").concat(params);
  }

  return uri;
};

exports.createStream = createStream;

var createVideoStream = function createVideoStream(uri) {
  return createStream(uri, true);
};

exports.createVideoStream = createVideoStream;

var createAudioStream = function createAudioStream(uri) {
  return createStream(uri, false);
};

exports.createAudioStream = createAudioStream;
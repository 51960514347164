import React, { Component } from 'react';
import { __ } from 'artsteps2-common';
import { ToolbarContent } from '../exhibitions/editor/ExhibitionEditStyled';

const MAX_SCROLL_DELTA = 50;
const MIN_SCROLL_DELTA = 20;

export default class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.elements = {};
    this.state = {
      position: this.props.initialPosition,
      top: null,
      left: null,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.movelistener);
    window.removeEventListener('mouseup', this.uplistener);
  }

  pullLeft(event) {
    event.stopPropagation();
    this.setState({ position: 'left', top: null, left: null });
  }

  pullRight(event) {
    event.stopPropagation();
    this.setState({ position: 'right', top: null, left: null });
  }

  pullTop(event) {
    event.stopPropagation();
    this.setState({ position: 'top', top: null, left: null });
  }

  pullBottom(event) {
    event.stopPropagation();
    this.setState({ position: 'bottom', top: null, left: null });
  }

  drag(event) {
    if (event.button) {
      return;
    }
    const eventY = event.screenY;
    const eventX = event.screenX;
    const toolbarTop = this.elements.toolbar.offsetTop;
    const toolbarLeft =
      this.state.position === 'float' ? this.elements.toolbar.offsetLeft : event.clientX - 32;
    this.setState(
      {
        position: 'float',
        top: toolbarTop,
        left: toolbarLeft,
        dragging: true,
      },
      () => {
        window.removeEventListener('mouseup', this.uplistener);
        window.removeEventListener('mousemove', this.movelistener);
        this.movelistener = e => {
          this.setState({
            top: Math.max(toolbarTop + (e.screenY - eventY), 0),
            left: Math.max(toolbarLeft + (e.screenX - eventX), 0),
          });
        };
        this.uplistener = () => {
          window.removeEventListener('mousemove', this.movelistener);
          window.removeEventListener('mouseup', this.uplistener);
          this.setState({ dragging: false });
        };
        window.addEventListener('mousemove', this.movelistener);
        window.addEventListener('mouseup', this.uplistener);
      },
    );
  }

  scroll(event) {
    event.preventDefault();
    if (!event.deltaY) {
      return;
    }
    const delta =
      event.deltaY < 0
        ? Math.min(Math.max(event.deltaY, -MAX_SCROLL_DELTA), -MIN_SCROLL_DELTA)
        : Math.max(Math.min(event.deltaY, MAX_SCROLL_DELTA), MIN_SCROLL_DELTA);
    if (this.state.position === 'top' || this.state.position === 'bottom') {
      this.elements.content.scrollLeft += delta;
    } else {
      this.elements.content.scrollTop += delta;
    }
  }

  renderControls() {
    if (!this.props.controls) {
      return null;
    }

    return (
      <div className="toolbar-controls">
        <button
          onClick={event => this.pullLeft(event)}
          onKeyDown={event => event.keyCode === 13 && this.pullLeft(event)}
          className={`${this.state.position === 'left' ? 'disabled' : ''} anchor`}
          data-tooltip={__('pull_left')}
          data-inverted
          data-position="top center"
        >
          <i className="ui icon caret left" />
        </button>
        <button
          onClick={event => this.pullRight(event)}
          onKeyDown={event => event.keyCode === 13 && this.pullRight(event)}
          className={`${this.state.position === 'right' ? 'disabled' : ''} anchor`}
          data-tooltip={__('pull_right')}
          data-inverted
          data-position="top center"
        >
          <i className="ui icon caret right" />
        </button>
        <button
          onClick={event => this.pullTop(event)}
          onKeyDown={event => event.keyCode === 13 && this.pullTop(event)}
          className={`${this.state.position === 'top' ? 'disabled' : ''} anchor`}
          data-tooltip={__('pull_up')}
          data-inverted
          data-position="top center"
        >
          <i className="ui icon caret up" />
        </button>
        <button
          onClick={event => this.pullBottom(event)}
          onKeyDown={event => event.keyCode === 13 && this.pullBottom(event)}
          className={`${this.state.position === 'bottom' ? 'disabled' : ''} anchor`}
          data-tooltip={__('pull_down')}
          data-inverted
          data-position="top center"
        >
          <i className="ui icon caret down" />
        </button>
        <div
          className="toolbar-handle"
          role="button"
          tabIndex={0}
          onMouseDown={event => this.drag(event)}
        />
      </div>
    );
  }

  render() {
    return <ToolbarContent>{this.props.children}</ToolbarContent>;
  }
}

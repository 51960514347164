import React from 'react';
import { ShareButtons, generateShareIcon } from 'react-share';
import utils from '../../../utils';
import { withProps } from '../../../enhancers';

const ICON_SIZE = 50;

export const ExhibitionSharingView = ({ exhibition = {}, getLocation = () => '' }) => {
  const {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
  } = ShareButtons;
  const FacebookIcon = generateShareIcon('facebook');
  const TwitterIcon = generateShareIcon('twitter');
  const LinkedinIcon = generateShareIcon('linkedin');
  const PinterestIcon = generateShareIcon('pinterest');

  return (
    <div className="exhibitionsharing">
      <FacebookShareButton className="shareButtons" url={getLocation()} quote={exhibition.title}>
        <FacebookIcon size={ICON_SIZE} />
      </FacebookShareButton>
      <TwitterShareButton className="shareButtons" url={getLocation()} title={exhibition.title}>
        <TwitterIcon size={ICON_SIZE} />
      </TwitterShareButton>
      <LinkedinShareButton
        className="shareButtons"
        url={getLocation()}
        title={exhibition.title}
        windowWidth={750}
        windowHeight={600}
      >
        <LinkedinIcon size={ICON_SIZE} />
      </LinkedinShareButton>
      <PinterestShareButton
        className="shareButtons"
        url={getLocation()}
        media={utils.exhibition.getExhibitionImage(exhibition)}
        windowWidth={1000}
        windowHeight={730}
      >
        <PinterestIcon size={ICON_SIZE} />
      </PinterestShareButton>
    </div>
  );
};

const propsMap = { getLocation: () => window.location.href };

const ExhibitionSharing = withProps(propsMap)(ExhibitionSharingView);

export default ExhibitionSharing;

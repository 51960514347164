"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var DEBUG = process.env.NODE_ENV === 'development';
var _default = {
  DEBUG: {
    SHOW_BBS: false && DEBUG,
    OPAQUE_WALLS: false && DEBUG,
    STATS: DEBUG
  },
  DEFAULT_GROUND_EXTENT: {
    minX: -20,
    minZ: -20,
    maxX: 20,
    maxZ: 20
  },
  DEFAULT_ARTIFACT_WIDTH: 0.02,
  DEFAULT_WALL_HEIGHT: 4,
  DEFAULT_WALL_WIDTH: 0.02,
  DEFAULT_WALL_DEPTH: 0.02,
  DEFAULT_ASSET_TIMEOUT: 0,
  COLOUR: {
    SKY: '#7ea8ea',
    FLOOR: '#fcfcfc',
    CEILING: '#e6e6e6',
    STRUCTURE: '#ffffff',
    WALL_TOP: '#dbdbdb',
    GROUND: '#ececec'
  },
  ASSET: {
    DISPLAY_CASE: {
      PATH: '/models/cases/'
    },
    ENTRANCE: {
      FRAME: '/models/doors/Frame.glb',
      DOOR_RIGHT: '/models/doors/Door_right.glb',
      DOOR_LEFT: '/models/doors/Door_left.glb',
      PATH: '/models/doors/'
    },
    TEXTURE: {
      SKY: '/images/sky.jpg',
      WALL: '/images/emission3.png',
      FLOOR: '/images/floor.jpg',
      GROUND: '/images/ground.jpg',
      CEILING: '/images/emission0.png',
      WALL_TOP: '/images/wall-top.jpg',
      INTERIOR_DOOR: '/images/emission-interior-door.png'
    },
    FRAMES: {
      BLACK: {
        uri: '/models/frames/blacktexture.glb',
        legacyId: 3
      },
      DARK_BROWN: {
        uri: '/models/frames/darkbrown.glb',
        legacyId: 1
      },
      GOLD: {
        uri: '/models/frames/goldtexture.glb',
        legacyId: 2
      },
      GREY: {
        uri: '/models/frames/grey.glb',
        legacyId: 5
      },
      MARBLE: {
        uri: '/models/frames/marble.glb',
        legacyId: 10
      },
      MEDIUM_BROWN: {
        uri: '/models/frames/mediumbrown.glb',
        legacyId: 9
      },
      PRESSED_WOOD: {
        uri: '/models/frames/pressedwood.glb',
        legacyId: 7
      },
      WHITE: {
        uri: '/models/frames/white.glb',
        legacyId: 6
      },
      LIGHT_BROWN: {
        uri: '/models/frames/lightbrown.glb',
        legacyId: 4
      },
      WHITE_WOOD: {
        uri: '/models/frames/whitewood.glb',
        legacyId: 8
      }
    },
    FONT: {
      FONT_URL: '/fonts/Lato-Regular-msdf.json',
      FONT_IMAGE_URL: '/fonts/Lato-Regular.png',
      FONT_SCALING_FACTOR: 95,
      X_OFFSET: 0.0575
    }
  },
  ENTRANCE_WIDTH: 3.6,
  DOOR_SIDE_WIDTH: 0.85,
  INTERIOR_DOOR_HEIGHT: 1.2,
  DRAGGING_DELAY: 250 // in ms

};
exports["default"] = _default;
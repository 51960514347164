export const settings = [
  { value: 'publicView', text: 'Public', defaultValue: true },
  { value: 'isActive', text: 'Active', defaultValue: true },
  { value: 'increasedQuality', text: 'Increased Quality', defaultValue: false },
  { value: 'disableComments', text: 'Disable comments', defaultValue: false },
  { value: 'allowRightClick', text: 'Allow right click', defaultValue: true },
  { value: 'allowCreate', text: 'Allow create', defaultValue: true },
  { value: 'allowLinks', text: 'Allow links', defaultValue: false },
  { value: 'showArtstepsRedirect', text: 'Show Artsteps redirect', defaultValue: true },
  { value: 'showExhibitions', text: 'Show Exhibition Tab', defaultValue: true },
  { value: 'showTour', text: 'Show tour section', defaultValue: false },
];

import React, { useState, useEffect, useLayoutEffect } from 'react';
import config from 'artsteps2-config/public.json';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { ReactComponent as InstagramIcon } from '../../styles/images/instagram.svg';
import {getAuthUser, getUIProperty} from '../../reducers';
import { compose, withState, withDispatch } from '../../enhancers';
import { setUIProperty } from '../../actions';
import Link from '../generic/Link';
import AppIconsSection from '../generic/AppIconsSection';
import logo from '../../styles/images/logo.png';
import Grid from '@material-ui/core/Grid';
import * as footer from '../../styles/styledComponents/footer';
import * as generic from '../../styles/GenericStyled';
import Newsletter from '../subscribers/forms/Newsletter';
import privateConfig from 'artsteps2-config/private.json';

// import CategoryList from '../articles/lists/CategoryList';

const GOOGLE_PLAY_URI = config.applications.playURI;
const APP_STORE_URI = config.applications.itunesURI;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const social = [
  {
    path: 'https://www.facebook.com/Artsteps/',
    icon: <FacebookIcon style={{ fontSize: 40, color: '#3c5a9a' }} />,
  },
  {
    path:
      'https://www.instagram.com/artsteps_stories/?utm_source=ig_embed&ig_mid=C536EFFA-D7BF-45E6-BB52-7E3E4A926088',
    icon: (
      <InstagramIcon
        style={{
          fontSize: 40,
          width: '49px',
          height: '49px',
          transform: 'translateY(-4px)',
        }}
      />
    ),
  },
  {
    path: 'https://www.linkedin.com/company/artsteps/',
    icon: <LinkedInIcon color="primary" style={{ fontSize: 40, color: '#006699' }} />,
  },
  {
    path: 'https://twitter.com/artsteps_?lang=en',
    icon: <TwitterIcon color="primary" style={{ fontSize: 40, color: '#1da1f2' }} />,
  },
  {
    path: 'https://www.youtube.com/user/ArtstepsCom',
    icon: <YouTubeIcon color="primary" style={{ fontSize: 40, color: '#ff0000' }} />,
  },
];

const footerItems = [
  {
    items: [
      { name: 'About', path: '/article/contact-us' },
      { name: 'Exhibitions', path: '/explore' },
      { name: 'Marketplace', path: '/marketplace' },
      { name: 'Blog', path: 'https://blog.artsteps.com' },
      { name: 'Contact', path: '/contact' },
      { name: 'Terms of Service', path: '/article/terms-of-service' },
      { name: 'Privacy Policy', path: '/article/privacy-policy' },
    ],
  },
  {
    items: [
      { name: 'Professional Services', heading: true },
      { name: 'Private Web Space', path: '/privatespaces', secondary: true },
      { name: 'Custom 3D Design', path: '/custom-space-3d', secondary: true },
      { name: 'Curation', path: '/curatorial-service', secondary: true },
      { name: '3D Virtual Event', path: '/virtual-events', secondary: true },
      { name: 'Help', path: '/categories/help' },
      { name: 'FAQ', path: '/faq' },
    ],
  },
];

const List = ({isPrivateSpace}) => {
  const footerItems = [
    {
      items: [
        { name: 'About', path: '/article/contact-us' },
        { name: 'Exhibitions', path: '/explore' },
        { name: 'Marketplace', path: '/marketplace' },
        { name: 'Blog', path: 'https://blog.artsteps.com' },
        { name: 'Contact', path: '/contact' },
        { name: 'Terms of Service', path: '/article/terms-of-service' },
        { name: 'Privacy Policy', path: '/article/privacy-policy' },
      ],
    },
    {
      items: [
        { name: 'Professional Services', heading: true },
        { name: 'Private Web Space', path: '/privatespaces', secondary: true },
        { name: 'Custom 3D Design', path: '/custom-space-3d', secondary: true },
        { name: 'Curation', path: '/curatorial-service', secondary: true },
        { name: '3D Virtual Event', path: '/virtual-events', secondary: true },
        { name: 'Help', path: '/categories/help' },
        { name: 'FAQ', path: '/faq' },
      ],
    },
  ];
  return (
    <>
      {footerItems.map((element, index) => (
        <Grid item md={6} key={index}>
          <footer.ListSection index={index}>
            <footer.StyledList>
              {element.items.map(item => {
                if (item.name === 'Marketplace') {
                  if (
                    privateConfig.accounts.services.paddle.marketplaceEnabled === false ||
                    isPrivateSpace === true
                  )
                    return <></>;
                }
                return (
                  <footer.SubList
                    key={index + item.name}
                    secondary={item.secondary}
                    heading={item.heading}
                  >
                    {item.name !== 'Blog' ? (
                      <Link to={item.path}>
                        <generic.Text
                          margin={'0 0 0 7px'}
                          component="span"
                          type={item.secondary ? '' : 'body1'}
                          mycolor="#343a40b8"
                          weight="600"
                        >
                          {item.name}
                        </generic.Text>
                      </Link>
                    ) : (
                      <a href={item.path} target={'_blank'}>
                        <generic.Text
                          margin={'0 0 0 7px'}
                          component="span"
                          type={item.secondary ? '' : 'body1'}
                          mycolor="#343a40b8"
                          weight="600"
                        >
                          {item.name}
                        </generic.Text>
                      </a>
                    )}
                  </footer.SubList>
                );
              })}
            </footer.StyledList>
          </footer.ListSection>
        </Grid>
      ))}
    </>
  );
};

export const DefaultFooterView = ({ onContributeModalOpen = Promise.resolve(false), isPrivateSpace }) => {
  const [width] = useWindowSize();
  const [textAlign, setTextAlign] = useState('left');

  useEffect(() => {
    if (width >= 960) setTextAlign('left');
    else setTextAlign('center');
  }, [width]);

  return (
    <footer.StyledFooter>
      <Grid container wrap="wrap">
        <footer.Section item xs={12} md={3} textalign={textAlign}>
          <footer.BodyWithPadding>
            <generic.VerticalItems justify="flex-start">
              <img src={logo} alt="logo" width="176px" height="59px" />
              <generic.Text
                type="body1"
                mycolor="#343a40b8"
                weight="600"
                style={{ marginTop: '18px' }}
              >
                Artsteps provides you with a platform to create, design & share your own VR
                exhibitions and stories
              </generic.Text>
              <footer.IconsSection>
                <AppIconsSection googlePlayURI={GOOGLE_PLAY_URI} appStoreURI={APP_STORE_URI} />
              </footer.IconsSection>
              {width >= 960 && (
                <>
                  <generic.Text
                    type="body2"
                    mycolor="#343A40"
                    weight="600"
                    style={{ marginTop: '20px' }}
                  >
                    © 2019-{new Date().getFullYear()}
                    <a
                      href="http://www.dataverse.gr/"
                      target="_blank"
                      style={{ color: 'initial!important' }}
                    >
                      <generic.Text component="span" type="body2" mycolor="#4f99e4" weight="600">
                        {' '}
                        Dataverse Ltd{' '}
                      </generic.Text>
                    </a>
                  </generic.Text>
                  <generic.Text
                    type="body2"
                    mycolor="#343A40"
                    weight="600"
                    style={{ marginTop: '6px' }}
                  >
                    All rights reserved
                  </generic.Text>
                </>
              )}
            </generic.VerticalItems>
          </footer.BodyWithPadding>
        </footer.Section>
        <Grid
          item
          xs={12}
          md={5}
          textalign={textAlign}
          container
          justify={width < 960 ? 'space-evenly' : 'center'}
          style={{ margin: width < 960 ? '30px 0' : 0 }}
        >
          <List isPrivateSpace={isPrivateSpace} />
          {/* <generic.VerticalItems justify="flex-start" style={{ padding: '0 20px' }}> */}
          {/* <footer.ListAlign> */}
          {/** ****** Fetch and Edit Footer Catergories ****** */}
          {/* <div className="ui four wide column">
                <CategoryList header={4} displayChildren={1} fetchResources />
              </div> */}
          {/* </footer.ListAlign> */}
          {/* </generic.VerticalItems> */}
        </Grid>
        <footer.Section item xs={12} md={4} textalign={textAlign}>
          <Newsletter width={width} social={social} />
          {width < 960 && (
            <>
              <generic.Text
                type="body2"
                mycolor="#343A40"
                weight="600"
                style={{ marginTop: '20px' }}
              >
                © 2019-{new Date().getFullYear()}
                <a
                  href="http://www.dataverse.gr/"
                  target="_blank"
                  style={{ color: 'initial!important' }}
                >
                  <generic.Text component="span" type="body2" mycolor="#4f99e4" weight="600">
                    {' '}
                    Dataverse Ltd{' '}
                  </generic.Text>
                </a>
              </generic.Text>
              <generic.Text
                type="body2"
                mycolor="#343A40"
                weight="600"
                style={{ marginTop: '6px' }}
              >
                All rights reserved
              </generic.Text>
            </>
          )}
        </footer.Section>

        {/* NewsLetter SECTION */}
        {/* <div className="ui four wide column">
        <CategoryList header={4} displayChildren={1} fetchResources />
      </div>

      {!isPrivateSpace && (
        <div className="ui three wide column">
          <h4>{__('subscribe')}</h4>
          <Newsletter />
        </div>
      )} */}
      </Grid>
    </footer.StyledFooter>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
});

const mapDispatch = dispatch => ({
  onContributeModalOpen: () => dispatch(setUIProperty('contributionModalOpen', true)),
});

const DefaultFooter = compose(withState(mapState), withDispatch(mapDispatch))(DefaultFooterView);

export default DefaultFooter;

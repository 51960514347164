import React from 'react';
/***************** REDUX IMPORTS *********************/
import { compose, withState, withDispatch } from '../../../enhancers';
import { getUIProperty, API_STATUS, getApiStatus } from '../../../reducers';
import { setLocation } from '../../../actions';
/***************** COMPONENTS IMPORTS *********************/
import AdminPanelWrapper from '../AdminPanelWrapper';

import ProfileEditor from '../../users/forms/ProfileEditor';

const EditUserPageView = ({ onRedirect, userId }) => {
  return (
    <AdminPanelWrapper>
      <ProfileEditor
        userId={userId}
        isAdminEditing={true}
        onClose={() => onRedirect('/admin-panel/users')}
      />
    </AdminPanelWrapper>
  );
};

const mapState = state => ({
  usersReady: getApiStatus(state, 'users') === API_STATUS.IDLE,
  editUser: getUIProperty(state, 'editUser'),
});

const mapDispatch = dispatch => ({
  onRedirect: location => dispatch(setLocation(location)),
});

const EditUserPage = compose(withState(mapState), withDispatch(mapDispatch))(EditUserPageView);

export default EditUserPage;

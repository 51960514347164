import React from 'react';
import { __ } from 'artsteps2-common';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { setUIProperty } from '../../../actions';
import { getUIProperty } from '../../../reducers';
import utils from '../../../utils';
import MyToolTip from '../../generic/MyToolTip';
import { OptionButton } from '../../../styles/GenericStyled';

export const ExhibitionTutorialView = ({
  title,
  expanded,
  currentParagrah = 0,
  paragraphs = [],
  onChangeExpanded = () => Promise.resolve(undefined),
  onSetCurrentParagraph = () => Promise.resolve(undefined),
  isMobile = utils.navigator.isMobile(),
  openArtifact,
  left,
}) => (
  <div>
    {expanded === 'tutorial' && (
      <div className="exhibition-tutorial" style={{ left }}>
        <h2>{title || __('tutorial')}</h2>
        {paragraphs.map(
          // if is Mobile other paragraphs
          (sections, idx) =>
            currentParagrah === idx && (
              <div className="paragraph" key={idx}>
                {sections.map(section => (
                  <div
                    className={`section ${section.class} ${section.icon ? '' : 'noicon'}`}
                    key={section.class}
                  >
                    <span className="text">{section.text}</span>
                    {section.icon && <img className="image" src={section.icon} alt="" />}
                  </div>
                ))}
              </div>
            ),
        )}
        {paragraphs.length > 1 && (
          <div className="controls">
            <div className="ui buttons">
              <button
                disabled={currentParagrah === 0}
                className={`ui ${
                  currentParagrah === 0 ? 'basic disabled' : ''
                } compact secondary icon button`}
                onClick={() => onSetCurrentParagraph(currentParagrah - 1)}
              >
                <i className="ui icon chevron left" />
                {__('previous')}
              </button>
              <button
                disabled={currentParagrah >= paragraphs.length - 1}
                className={`ui ${
                  currentParagrah >= paragraphs.length - 1 ? 'basic disabled' : ''
                } compact secondary icon button`}
                onClick={() => onSetCurrentParagraph(currentParagrah + 1)}
              >
                {__('next')}
                <i className="ui icon chevron right" />
              </button>
            </div>
          </div>
        )}
      </div>
    )}
    <MyToolTip placement="right" title={__('tutorial')}>
      <OptionButton
        onClick={() =>
          expanded === 'tutorial' ? onChangeExpanded(undefined) : onChangeExpanded('tutorial')
        }
      >
        <i style={{ paddingLeft: '4px' }} className="ui info icon" />
      </OptionButton>
    </MyToolTip>
  </div>
);

const mapState = (state, { exhibition }) => ({
  expanded: getUIProperty(state, `exhibitions/${exhibition._id}/expanded`),
  disabled: !!getUIProperty(state, 'tutorials/disabled'),
  currentParagrah: getUIProperty(state, 'tutorials/currentParagrah'),
});

const mapDispatch = (dispatch, { exhibition }) => ({
  onDisable: () => dispatch(setUIProperty('tutorials/disabled', true, true)),
  onEnable: () => dispatch(setUIProperty('tutorials/disabled', false, true)),
  onClose: () => dispatch(setUIProperty('tutorials/open', false)),
  onChangeExpanded: option =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/expanded`, option)),
  onSetCurrentParagraph: index => dispatch(setUIProperty('tutorials/currentParagrah', index)),
});

const lifecycleMap = {
  onDidUpdate: ({ paragraphs: prevParagraphs }, { paragraphs, onSetCurrentParagraph }) =>
    JSON.stringify(prevParagraphs) !== JSON.stringify(paragraphs) && onSetCurrentParagraph(0),
};

const ExhibitionTutorial = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionTutorialView);

export default ExhibitionTutorial;

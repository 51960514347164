import React, { useState, useEffect } from 'react';
import { __ } from 'artsteps2-common';
import publicConfig from 'artsteps2-config/public.json';

import Dialog from '../../../generic/Dialog';
import { getApiResource, getUIProperty } from '../../../../reducers';
import { setUIProperty, apiGET, setLocation } from '../../../../actions';
import {
  compose,
  withState,
  withDispatch,
  withLifecycle,
  withLocalState,
} from '../../../../enhancers';
import createYourOwnImage from '../../../../styles/images/templates/create-your-own.jpg';
import premium from '../../../../styles/images/templates/premium.jpg';
import usePrevious from '../../../../hooks/usePrevious';
import { ImageButton, TemplateList } from '../ExhibitionEditStyled';
import MyToolTip from '../../../generic/MyToolTip';
import { StyledButton, Text } from '../../../../styles/GenericStyled';
import Button from '@material-ui/core/Button';
import ButtonGroup from "@material-ui/core/ButtonGroup";


export const API_URI = `${publicConfig.api.hostname}`;

export const ExhibitionTemplatePanelView = ({
  templates,
  importedAt,
  hasChanges,
  selectedTemplate,
  setSelectedTemplate = () => Promise.resolve(false),
  isExhibitionEmpty,
  displayDialog = false,
  onCloseDialog = () => Promise.resolve(false),
  openDialog = () => Promise.resolve(false),
  requestIsEmpty = () => Promise.resolve(false),
  onTogglePlacement = () => Promise.resolve(false),
  onRedirect = () => Promise.resolve(false),
}) => {
  const [templateClicked, setTemplateClicked] = useState(undefined);

  const prevTemplateClicked = usePrevious(templateClicked);

  const [tab, setTab] = useState('paid');
  const [displayRedirect, setDisplayRedirect] = useState(false);

  useEffect(() => {
    if (templateClicked !== undefined && prevTemplateClicked !== templateClicked) {
      if (isExhibitionEmpty) {
        setSelectedTemplate(templateClicked);
        requestIsEmpty();
        onTogglePlacement(); //not sure if we keep this
      } else {
        if (selectedTemplate !== templateClicked) openDialog();
      }
    }
  }, [
    isExhibitionEmpty,
    onTogglePlacement,
    openDialog,
    prevTemplateClicked,
    requestIsEmpty,
    setSelectedTemplate,
    templateClicked,
  ]);

  useEffect(() => {
    if (selectedTemplate !== templateClicked) setTemplateClicked(selectedTemplate);
  }, [selectedTemplate]);
  return (
    <div className="exhibition-space-editor">
      <div
        style={{
          position: 'relative',
          paddingLeft: '6px',
          paddingRight: '6px',
          marginLeft: '6px',
          marginRight: '6px',
          paddingTop: '10px',
          backgroundColor: '#e5e5e5',
          paddingBottom: '20px',
        }}
      >
        <TemplateList>
          <Text type="h6">Use a prebuilt 3D space template</Text>
          <ButtonGroup variant="contained" style={{ width: '100%' }}>
            <Button
              style={{ width: '50%', backgroundColor: tab === 'paid' ? '#12AD8F' : '#F3F3F3' }}
              onClick={() => setTab('paid')}
            >
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: tab === 'paid' ? 'white' : 'black',
                  textTransform: 'none',
                }}
              >
                Premium
              </Text>
            </Button>
            <Button
              style={{ width: '50%', backgroundColor: tab === 'free' ? '#12AD8F' : '#F3F3F3' }}
              onClick={() => setTab('free')}
            >
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: tab === 'free' ? 'white' : 'black',
                  textTransform: 'none',
                }}
              >
                Free
              </Text>
            </Button>
          </ButtonGroup>

          {tab === 'free' ? (
            <Text type="body2">Please note that the free prebuilt 3D space templates below don't allow you to connect your content with your website or any other webpage</Text>
          ) : (
            <Text type="body2">
              To buy and use premium, photorealistic prebuilt 3D space templates with caption hyperlinks, visit the Marketplace
            </Text>
          )}

          {templates
            .filter(t => t.public === true)
            .filter(t => t.marketplace === (tab !== 'free') )
            .map(t => (
              <div style={{ position: 'relative', marginBottom: '10px' }} key={t._id}>
                <ImageButton
                  height="150px"
                  width="100%"
                  key={t._id}
                  style={{ backgroundImage: `url(${t?.image})`, maxHeight: '150px' }}
                  selected={+(templateClicked === t)}
                  onKeyPress={event => event.keyCode === 13 && setTemplateClicked(t)}
                  onClick={() => {
                    setTemplateClicked(t);
                  }}
                />
                <div style={{ position: 'absolute', bottom: '8px', left: '8px', zIndex: '10' }}>
                  <Text
                    style={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      backgroundColor:
                        t.marketplace === true
                          ? 'rgba(255, 215, 0, 0.3)'
                          : 'rgba(255, 255, 255, 0.5)',
                      borderRadius: '8px',
                      padding: '5px',
                    }}
                  >
                    {t?.title} {t?.size && `(${t?.size})`}
                  </Text>
                </div>
              </div>
            ))}

          {templates.filter(t => t.public === true).filter(t => t.marketplace === true).length ===
            0 && (
            <MyToolTip title="Visit the marketplace" placement="top">
              <ImageButton
                height="150px"
                width="100%"
                key="redirect"
                style={{ backgroundImage: `url(${premium})`, maxHeight: '150px' }}
                onClick={() => {
                  if (isExhibitionEmpty || (!hasChanges || !importedAt)) {
                    onRedirect('/marketplace');
                  } else {
                    setDisplayRedirect(true);
                  }
                }}
              />
            </MyToolTip>
          )}
          <StyledButton
            background="#12AD8F"
            type="secondary"
            onClick={() => {
              if (isExhibitionEmpty || (!hasChanges || !importedAt)) {
                onRedirect('/marketplace');
              } else {
                setDisplayRedirect(true);
              }
            }}
          >
            Marketplace
          </StyledButton>

          <Dialog
            open={displayDialog}
            title={__('content_deletion')}
            message={__('confirm_content_deletion')}
            type="warning"
            onConfirm={() => {
              onTogglePlacement();
              setSelectedTemplate(templateClicked);
              onCloseDialog();
              requestIsEmpty();
            }}
            onReject={() => {
              setTemplateClicked(undefined);
              onCloseDialog();
            }}
          />
          <Dialog
            open={displayRedirect}
            title={__('exhibition_leave')}
            message={__('exhibition_leave_message')}
            type="warning"
            onConfirm={() => {
              onRedirect('/marketplace');
            }}
            onReject={() => {
              setDisplayRedirect(false);
            }}
          />
        </TemplateList>
      </div>
    </div>
  );
};

const mapState = (state, { exhibitionId }) => ({
  selectedTemplate: getUIProperty(state, `exhibitions/${exhibitionId}/selectedTemplate`),
  templates: Object.values(getApiResource(state, 'templates/templatePanel')),
  displayDialog: getUIProperty(state, `exhibitions/${exhibitionId}/displayDialog`),
  exhibitionNotEmpty:
    !!(
      getUIProperty(state, `exhibitions/${exhibitionId}/export/artifacts`) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/import/artifacts`) ||
      []
    ).length ||
    !!(
      getUIProperty(state, `exhibitions/${exhibitionId}/export/displayCases`) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/import/displayCases`) ||
      []
    ).length ||
    !!(
      getUIProperty(state, `exhibitions/${exhibitionId}/export/walls`) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/import/walls`) ||
      []
    ).length,
  isExhibitionEmpty: !!getUIProperty(state, `exhibitions/${exhibitionId}/isEmpty`),
  importedAt: getUIProperty(state, `exhibitions/${exhibitionId}/importedAt`),
  hasChanges: getUIProperty(state, `exhibitions/${exhibitionId}/hasChanges`),
});

const mapDispatch = (dispatch, { exhibitionId, requestingIsEmpty }) => ({
  fetchTemplates: () => dispatch(apiGET('templates/templatePanel')),
  setSelectedTemplate: template => {
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/selectedTemplate`, template));
  },
  onCloseDialog: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayDialog`, false)),
  openDialog: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayDialog`, true)),
  onTogglePlacement: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/placingStructure`, undefined)),
  requestIsEmpty: () => {
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/requestingIsEmpty`, !requestingIsEmpty));
  },
  onRedirect: location => dispatch(setLocation(location)),
});

const lifecycleMap = {
  onDidMount: ({ fetchTemplates }) => fetchTemplates(),
};

const ExhibitionTemplatePanel = compose(
  withLocalState('templateClicked', 'onTemplateClicked', false),
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionTemplatePanelView);

export default ExhibitionTemplatePanel;

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import React from 'react';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getAuthUserRoles, getApiResource } from '../../../reducers';
import { apiGET, apiDELETE, setLocation } from '../../../actions';
import { StyledButton, ExpandButton } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';
import MyToolTip from '../../generic/MyToolTip';

export const ExhibitionPickerView = ({
  editable = false,
  onDeleteExhibition,
  onDeleteReport,
  exhibitionId,
  onRedirect,
  redirectionURL = '../viewReports',
  reports,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteReports = () => {
    reports.forEach(reportObj => {
      if (reportObj.exhibition === exhibitionId) onDeleteReport(reportObj._id);
    });
  };

  return (
    editable && (
      <div>
        <MyToolTip title="Delete" placement="top">
          <Box
            component={ExpandButton}
            boxShadow={3}
            mycolor={colors.ourBlack}
            mycolorhover={colors.ourWhite}
            background={colors.ourWhite}
            backgroundhover={colors.ourBlack}
            onClick={handleClickOpen}
          >
            <DeleteForeverIcon />
          </Box>
        </MyToolTip>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete this exhibition?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this exhibition forever?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleClose} color="primary">
              Disagree
            </StyledButton>
            <StyledButton
              onClick={() => {
                onDeleteExhibition(exhibitionId);
                deleteReports();
                onRedirect(redirectionURL);
                handleClose();
              }}
              color="primary"
              autoFocus
            >
              Agree
            </StyledButton>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

const EDITOR_ROLES = ['admin'];

const mapState = state => ({
  editable: getAuthUserRoles(state).some(role => EDITOR_ROLES.includes(role)),
  reports: Object.values(getApiResource(state, `reports`)),
});

const mapDispatch = dispatch => ({
  onFetchReports: () => dispatch(apiGET('reports')),
  onDeleteExhibition: exId => dispatch(apiDELETE(`exhibitionsUserProfile/${exId}`)),
  onDeleteReport: reportId => dispatch(apiDELETE(`reports/${reportId}`)),
  onRedirect: location => dispatch(setLocation(location)),
});

const ExhibitionPicker = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionPickerView);

export default ExhibitionPicker;

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { compose, withState, withDispatch } from '../../enhancers';
import { getApiResource } from '../../reducers';
import { apiPOST, apiDELETE, apiPATCH } from '../../actions';
import { AdminButton, AdminButtonPanel } from '../../styles/styledComponents/FAQStyled';

import YesOrNo from '../generic/YesOrNo';
import OurModal from '../generic/OurModal';
import FaqEditor from './FaqEditor';
import COLORS from '../../styles/colors';

export const AdminFaqView = ({
  faq = { _id: null },
  onCreateFaq,
  onUpdateFaq,
  onDeleteFaq,
  isEmpty,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  return isEmpty ? (
    <AdminButtonPanel>
      <OurModal width="60%" height="50%" openModal={openCreate} setOpenModal={setOpenCreate}>
        <FaqEditor action="Create" onCreateFaq={onCreateFaq} setOpen={setOpenCreate} />
      </OurModal>
      <AdminButton
        variant="contained"
        background={COLORS.ourGreen}
        onClick={() => setOpenCreate(true)}
      >
        <AddIcon />
        Create
      </AdminButton>
    </AdminButtonPanel>
  ) : (
    <AdminButtonPanel>
      <YesOrNo
        title="Delete faq?"
        message={`Are you sure you want to delete ${faq._id}`}
        optionA="No"
        optionB="Yes"
        open={openDelete}
        setOpen={setOpenDelete}
        handleYes={() => onDeleteFaq(faq._id)}
      />
      <OurModal width="60%" height="50%" openModal={openCreate} setOpenModal={setOpenCreate}>
        <FaqEditor action="Create" onCreateFaq={onCreateFaq} setOpen={setOpenCreate} />
      </OurModal>
      <OurModal width="60%" height="50%" openModal={openUpdate} setOpenModal={setOpenUpdate}>
        <FaqEditor faq={faq} action="Update" onUpdateFaq={onUpdateFaq} setOpen={setOpenUpdate} />
      </OurModal>
      <AdminButton
        variant="contained"
        background={COLORS.ourGreen}
        onClick={() => setOpenCreate(true)}
      >
        <AddIcon />
        Create
      </AdminButton>
      <AdminButton
        variant="contained"
        background={COLORS.ourBlue}
        onClick={() => setOpenUpdate(true)}
      >
        <EditIcon />
        Edit
      </AdminButton>
      <AdminButton
        variant="contained"
        background={COLORS.ourRed}
        onClick={() => setOpenDelete(true)}
      >
        <DeleteIcon />
        Delete
      </AdminButton>
    </AdminButtonPanel>
  );
};

const mapState = state => ({
  reports: Object.values(getApiResource(state, `reports`)),
});

const mapDispatch = (dispatch, props) => ({
  onCreateFaq: faq => dispatch(apiPOST('faqs', faq)),
  onUpdateFaq: (faqId, updatedFaq) => dispatch(apiPATCH(`faqs/${faqId}`, updatedFaq)),
  onDeleteFaq: faqId => dispatch(apiDELETE(`faqs/${faqId}`)),
});

const AdminFaq = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(AdminFaqView);

export default AdminFaq;

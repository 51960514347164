import React from 'react';
import { __ } from 'artsteps2-common';
import DropDown from '../header/DropDown';
import { apiGET, apiPOST, setUIProperty, setLocation } from '../../actions';
import { compose, withDispatch, withState, withLifecycle, withLocalState } from '../../enhancers';
import { getApiStatus, getApiResource, API_STATUS, getAuthUser } from '../../reducers';
import { getUIProperty } from '../../reducers/ui';
import colors from '../../styles/colors';

const CreateWithTemplatesViewNew = ({
  isPrivateSpace,
  exhibitionTemplates,
  currentUser,
  ready = false,
  loading = false,
  onRedirect = () => Promise.resolve(false),
  onCloneTemplateAndInitialize = () => Promise.resolve(false),
  onToggleCreatorView = () => Promise.resolve(false),
  setLoading = () => Promise.resolve(false),
}) => (
  <DropDown
    currentUser={currentUser}
    create
    mycolor={colors.ourWhite}
    createdropdown={'true'}
    exhibitionTemplates={exhibitionTemplates}
    defaultOption={loading ? 'Loading...' : __('create_exhibition')}
    staticOption={__('create')}
    onRedirect={onRedirect}
    onCloneTemplateAndInitialize={onCloneTemplateAndInitialize}
    setLoading={setLoading}
    ready={ready}
    onToggleCreatorView={onToggleCreatorView}
  />
);

const mapDispatch = (dispatch, { templatesIdsList }) => ({
  onFetchTemplateNames: () =>
    dispatch(apiGET('templates/exhibitionTemplates', { templatesIdsList })),
  onRedirect: location => dispatch(setLocation(location)),
  onToggleCreatorView: view => dispatch(setUIProperty('creatorView', view)),
  onCloneTemplateAndInitialize: (exhbTemplateId, onRedirect, setLoading) => {
    setLoading(true);
    dispatch(apiPOST(`templates/initializeExhibition/${exhbTemplateId}`, {})).then(data => {
      setLoading(false);
      if (!data.response.error) onRedirect(`/curate/${data.response.exhibitionId}/3`);
    });
  },
});

const mapState = state => ({
  ready: getApiStatus(state, `templates/exhibitionTemplates`) === API_STATUS.IDLE,
  currentUser: getAuthUser(state),
  exhibitionTemplates: getApiResource(state, `templates/exhibitionTemplates`),
  isPrivateSpaceApp: getUIProperty(state, 'isPrivateSpace'),
});

const lifecycleMap = {
  onDidUpdate: (prevProps, props) => {
    if (prevProps.templatesIdsList !== props.templatesIdsList) props.onFetchTemplateNames();
  },
};

const CreateWithTemplatesNew = compose(
  withDispatch(mapDispatch),
  withState(mapState),
  withLifecycle(lifecycleMap),
  withLocalState('loading', 'setLoading', false),
)(CreateWithTemplatesViewNew);

export default CreateWithTemplatesNew;

import React from 'react';
import { __ } from 'artsteps2-common';
import Form from '../../generic/forms/Form';
import Dialog from '../../generic/Dialog';
import Loader from '../../generic/Loader';
import {
  apiDELETE,
  authLogout,
  setUIProperty,
  setLocation,
  clearFormData,
  addMessage,
} from '../../../actions';
import { compose, withState, withDispatch } from '../../../enhancers';
import { API_STATUS, getApiStatus, getAuthUser, getUIProperty, getForm } from '../../../reducers';

const AccountRemovalView = ({
  ready = true,
  dialogOpen = false,
  form = { data: {} },
  currentUser = {},
  redirectionUri = '/',
  onClearForm = () => Promise.resolve(false),
  onOpenDialog = () => Promise.resolve(false),
  onCloseDialog = () => Promise.resolve(false),
  onRemove = () => Promise.resolve({ response: { error: false } }),
  onLogout = () => Promise.resolve(false),
  onRedirect = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
}) => {
  if (!currentUser._id) {
    return <p>{__('youre_logged_out')}</p>;
  }

  const onConfirmRemoval = () =>
    onClearForm().then(() =>
      onRemove(form.data.password).then(({ response }) =>
        response.error
          ? onCloseDialog().then(() =>
              onAddMessage({
                title: __(response.error),
                type: 'error',
              }),
            )
          : onCloseDialog()
              .then(() => onLogout())
              .then(() => onRedirect(redirectionUri)),
      ),
    );

  return (
    <div className="remove-account">
      <Form
        form={form}
        onSubmit={onOpenDialog}
        fields={{
          password: {
            type: 'password',
            label: __('password'),
            autoComplete: 'off',
          },
        }}
        buttons={[
          {
            colour: 'primary',
            label: __('submit'),
            onClick: onOpenDialog,
          },
        ]}
      />
      <Dialog
        type="warning"
        title={__('remove_account')}
        message={__('remove_account_confirmation')}
        open={dialogOpen}
        onConfirm={onConfirmRemoval}
        onReject={onCloseDialog}
      />
      {ready || <Loader />}
    </div>
  );
};

const mapState = state => {
  const currentUser = getAuthUser(state) || {};
  return {
    currentUser,
    form: getForm(state, 'settings'),
    dialogOpen: getUIProperty(state, 'settings/privacy/dialog'),
    ready: getApiStatus(state, `users/${currentUser._id}`) === API_STATUS.IDLE,
  };
};

const mapDispatch = (dispatch, { currentUser = {} }) => ({
  onOpenDialog: () => dispatch(setUIProperty('settings/privacy/dialog', true)),
  onCloseDialog: () => dispatch(setUIProperty('settings/privacy/dialog', false)),
  onLogout: () => dispatch(authLogout()),
  onRemove: password => dispatch(apiDELETE(`users/${currentUser._id}`, { password })),
  onRedirect: uri => dispatch(setLocation(uri)),
  onAddMessage: message => dispatch(addMessage(message)),
  onClearForm: () => dispatch(clearFormData('settings')),
});

const AccountRemoval = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(AccountRemovalView);

export default AccountRemoval;

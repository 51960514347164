import Paper from '@material-ui/core/Paper';
import React from 'react';
// import { __ } from 'artsteps2-common';
import Modal from '@material-ui/core/Modal';
import config from 'artsteps2-config/public.json';
import styled from 'styled-components';
import { Text, StyledButton } from '../../../styles/GenericStyled';
import utils from '../../../utils';
import COLORS from '../../../styles/colors';

const StyledPaper = styled(Paper)`
  overflow: hidden !important;
  z-index: 2000;
  position: fixed;
  overflow: hidden;
  display: flex;
  justify-content: center;
  bottom: 0;
  align-items: center;
  flex-direction: column;
  max-height: 100vh;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  outline: none;
  background-color: ${COLORS.ourGray};
`;

const StyledDiv = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const openInApp = exhibitionId => {
  setTimeout(() => {
    window.location =
      utils.navigator.getMobileOperatingSystem() === 'iOS'
        ? config.applications.itunesURI
        : config.applications.playURI;
  }, 250);
  window.location = `artsteps://view/${exhibitionId}`;
};

const DeviceWarning = ({ exhibitionId, setDeviceWarning }) => (
  <Modal open={true}>
    <StyledPaper>
      <StyledDiv>
        <Text align="center" type="h7">
          For a smoother navigation experience, use the ArtSteps mobile app
        </Text>
        <StyledButton
          style={{ marginTop: '20px', minWidth: '210px' }}
          onClick={() => openInApp(exhibitionId)}
          background={COLORS.ourGreen}
          type="secondary"
        >
          Open in App
        </StyledButton>
        <Text style={{ marginTop: '20px' }} type="h7" align="center">
          Otherwise
        </Text>
        <StyledButton
          style={{ marginTop: '20px', minWidth: '210px' }}
          onClick={() => setDeviceWarning(false)}
          mycolor="white"
          background={COLORS.ourBlue}
          type="secondary"
        >
          Continue in Browser
        </StyledButton>
      </StyledDiv>
    </StyledPaper>
  </Modal>
);

export default DeviceWarning;

import React from 'react';
import { __ } from 'artsteps2-common';
import Link from '../../generic/Link';
import utils from '../../../utils';
import Loader from '../../generic/Loader';
import { apiGET } from '../../../actions';
import { API_STATUS, getApiStatus, getApiResource } from '../../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';

export const UserCommentListView = ({ ready = true, comments = [] }) => {
  if (!ready) {
    return (
      <div className="ui comments segment basic">
        <Loader />
      </div>
    );
  }

  if (!comments.length) {
    return <div className="ui comments segment basic">{__('no_comments_found')}</div>;
  }

  return (
    <div className="ui comments segment basic">
      {comments.map(comment => (
        <div key={comment._id} className="ui comment center aligned">
          <div className="ui avatar circular image">
            {comment.exhibition && comment.exhibition.image && (
              <img alt="" src={utils.exhibition.getExhibitionImage(comment.exhibition)} />
            )}
          </div>

          <div className="ui content">
            {comment.exhibition && (
              <span>
                {__('at').toLowerCase()} {__('exhibition').toLowerCase()}
                &nbsp;
                <Link className="author" to={`/view/${comment.exhibition._id}`}>
                  {comment.exhibition.title}
                </Link>
              </span>
            )}
            <div className="metadata">
              <span className="date">{utils.date.fromNow(comment.createdAt)}</span>
            </div>
          </div>

          <div className="ui content">
            <span>{comment.body}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

const createQuery = user => ({
  filter: { user },
  include: 'body',
  populate: ['user', 'exhibition'],
});

const mapState = (state, { userId }) => ({
  comments: Object.values(getApiResource(state, 'comments', createQuery(userId))),
  ready: getApiStatus(state, 'comments', createQuery(userId)) === API_STATUS.IDLE,
});

const mapDispatch = (dispatch, { userId }) => ({
  onFetchComments: () => dispatch(apiGET('comments', createQuery(userId))),
});

const lifecycleMap = {
  onDidMount: ({ onFetchComments }) => onFetchComments(),
  onDidUpdate: (props, { userId, onFetchComments }) => userId !== props.userId && onFetchComments(),
};

const UserCommentList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(UserCommentListView);

export default UserCommentList;

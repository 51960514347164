import React, { useState } from 'react';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SceneComponent from './SceneComponent';
import {
  ArcRotateCamera,
  // DirectionalLight,
  HemisphericLight,
  // Mesh,
  SceneLoader,
  Tools,
  Vector3,
} from '@babylonjs/core';
import '@babylonjs/loaders/OBJ';
import '@babylonjs/loaders/glTF';
import { __ } from 'artsteps2-common';
import utils from '../../../../utils';
import MyToolTip from '../../../generic/MyToolTip';
// import { debounce } from 'debounce';

// const DEFAULT_WIDTH = 420;
// const DEFAULT_HEIGHT = 221;

const rgx = /(?:\.([^.]+))?$/;
const findSuffix = file =>
  rgx.exec(
    (file.uri && file.uri.toLowerCase()) ||
      (file.name && file.name.toLowerCase()) ||
      (file.file && file.file.toLowerCase()),
  )[1];

const onSceneReady = async (scene, onPreviewLoad, files, currentUserId, onAddMessage) => {
  // This creates and positions a free camera (non-mesh)
  let camera = new ArcRotateCamera('Camera', 0, 0, 10, new Vector3(0, 0, 0), scene);

  camera.useFramingBehavior = true;
  camera.framingBehavior.framingTime = 0.1;
  camera.framingBehavior.elevationReturnTime = -1;
  camera.useAutoRotationBehavior = true;

  const engine = scene.getEngine();

  const canvas = engine.getRenderingCanvas();

  // This attaches the camera to the canvas
  // false means that the canvas won't transmit to window events handle by the camera but only INSIDE the canvas

  camera.attachControl(canvas, false);
  camera.lowerRadiusLimit = 5;
  camera.radius = 10;
  // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  let light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
  // let light1 = new DirectionalLight('DirectionalLight', new Vector3(-1, -1, 0));
  // let light2 = new DirectionalLight('DirectionalLight', new Vector3(1, 1, 0));

  light.intensity = 0.7;

  let isBlob = false;
  let objectToLoad;
  //check if the files loaded are blobs or is a single url link

  if (files.length > 0) {
    objectToLoad = files.find(f => {
      let suffix = findSuffix(f);
      if (suffix === 'obj' || suffix === 'gltf' || suffix === 'glb') {
        return true;
      }
    });
  }

  //when object is loaded call the executionCallback with the rendering canvas as property to create thumbnail
  const sceneLoaded = res => {
    if (scene.meshes.length > 0) {
      const worldExtends = scene.getWorldExtends(mesh => mesh.isVisible && mesh.isEnabled());
      camera.framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);
      setTimeout(() => onPreviewLoad(engine.getRenderingCanvas()), 1024); // wait for the animation to end
    }
  };

  const sceneError = (scn, msg, e) => {
    onAddMessage({
      type: 'error',
      title: __('Import mesh error'),
      description: msg,
    });
  };

  //if files are blobs load the obj, gltf, glb one, else load the object using the provided url
  if (objectToLoad) {
    if (objectToLoad.base64) isBlob = true;

    Tools.PreprocessUrl = url => {
      if (!isBlob) {
        return url;
      }
      //if files are blobs we need to override the urls for the materials and textures
      for (let i = 0; i < files.length; i++) {
        if (!files[i].name) {
          continue;
        }
        let suffix = findSuffix(files[i]);
        if (suffix === 'obj' || suffix === 'gltf' || suffix === 'glb') {
          continue;
        }
        if (url.includes(files[i].name)) {
          //based on the base64 content of the file crete a new blob to be loaded in its place

          let raw_content = Tools.DecodeBase64(files[i].base64);
          let blob = new Blob([raw_content]);
          url = URL.createObjectURL(blob);
        }
      }
      return url;
    };

    let uri = '';
    let path = '';
    let name = '';
    if (isBlob) {
      let raw_content = Tools.DecodeBase64(objectToLoad.base64);
      let blob = new Blob([raw_content]);
      uri = URL.createObjectURL(blob);
    } else {
      uri = utils.artifact.parseFileUri(objectToLoad, currentUserId);

      path = uri.substr(0, uri.lastIndexOf('/') + 1);
      name = uri.substr(uri.lastIndexOf('/') + 1);
    }

    SceneLoader.ImportMesh(
      '',
      isBlob ? '' : path,
      isBlob ? uri : name,
      scene,
      sceneLoaded,
      undefined,
      sceneError,
      '.' + findSuffix(objectToLoad),
    );
  }
};

const onRender = scene => {};

const ObjectPreview = ({
  files,
  currentUserId,
  showScreenShot = false,
  onPreviewLoad = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
}) => {
  const [canvasScene, setCanvasScene] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      {canvasScene && showScreenShot && (
        <MyToolTip placement="top" title="Take screenshot for thumbnail">
          <PhotoCameraIcon
            style={{ cursor: 'pointer', position: 'absolute', top: '4px', left: '4px' }}
            onClick={() => {
              onPreviewLoad(canvasScene, 'byPass');

              onAddMessage({
                type: 'success',
                title: 'Success',
                description: 'Thumbnail created',
              });
            }}
          />
        </MyToolTip>
      )}
      <SceneComponent
        antialias={true}
        onSceneReady={scene => {
          setCanvasScene(scene.getEngine().getRenderingCanvas());
          onSceneReady(scene, onPreviewLoad, files, currentUserId, onAddMessage);
        }}
        files={files}
        onRender={onRender}
        id="object-preview"
        crossOrigin="Anonymous"
      />
    </div>
  );
};

export default ObjectPreview;

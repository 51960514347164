"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createArtifacts = exports.createArtifact = exports.createFrame = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _constants = _interopRequireDefault(require("./constants"));

var _tools = require("./tools");

var _math = require("../math");

var createFrame = function createFrame(artifact, idx) {
  var scale = [0.125, 10, 0.1];

  if (artifact.frameType > 0) {
    return {
      className: 'frame',
      key: "artifact-frame-".concat(artifact.artifactInstanceId || idx),
      primitive: 'a-entity',
      'gltf-model': "#frame-".concat(artifact.frameType),
      rotation: '90 0 0',
      scale: scale.join(' '),
      position: '0.01 0 -8.4'
    };
  }

  return null;
};

exports.createFrame = createFrame;

var createArtifact = function createArtifact() {
  var artifact = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var idx = arguments.length > 1 ? arguments[1] : undefined;
  var events = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var opts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var entity = {
    className: 'artifact',
    key: artifact.artifactInstanceId || idx,
    events: (0, _tools.createEvents)(events.artifacts, artifact, opts),
    rotation: (0, _math.toEulerianAngle)((0, _math.mirror)((0, _math.parseQuaternion)(artifact.rotation), opts.invertAxisRot)).join(' '),
    position: (0, _math.mirror)((0, _math.parseQuaternion)(artifact.position), opts.invertAxisPos).join(' '),
    scale: [artifact.scaleX, artifact.scaleY, artifact.scaleZ || _constants["default"].DEFAULT_ARTIFACT_WIDTH].join(' '),
    material: {
      shader: 'flat',
      side: 'front',
      transparent: true
    },
    'move-to-artifact': '',
    'click-manager': {
      draggingDelay: _constants["default"].DRAGGING_DELAY
    }
  };

  if (artifact.artifactType === 'image') {
    // All images (and videos) arrive flipped.
    var newRotation = (0, _math.toEulerianAngle)((0, _math.mirror)((0, _math.parseQuaternion)(artifact.rotation), opts.invertAxisRot));
    newRotation[1] += 180;
    return Object.assign(entity, {
      primitive: artifact.hasCanvas ? 'a-box' : 'a-plane',
      src: "#artifact-".concat(artifact.artifactId),
      children: artifact.hasCanvas ? [createFrame(artifact, idx)] : undefined,
      rotation: newRotation.join(' ')
    });
  }

  if (artifact && artifact.artifactType === 'video') {
    var _newRotation = (0, _math.toEulerianAngle)((0, _math.mirror)((0, _math.parseQuaternion)(artifact.rotation), opts.invertAxisRot));

    _newRotation[1] += 180;
    return Object.assign(entity, {
      primitive: 'a-video',
      src: "#artifact-".concat(artifact.artifactId),
      children: artifact.hasCanvas ? [createFrame(artifact, idx)] : undefined,
      rotation: _newRotation.join(' ')
    });
  }

  if (artifact.artifactType === 'object' && artifact.files.some(function (f) {
    return f.match(/\.obj/);
  })) {
    return Object.assign(entity, {
      primitive: 'a-obj-model',
      'static-body': true,
      src: "#artifact-".concat(artifact.artifactId),
      'auto-position': 'scale: 1 1 1',
      mtl: artifact.files.some(function (f) {
        return f.match(/\.mtl/);
      }) ? "#artifact-".concat(artifact.artifactId, "-mtl") : undefined
    });
  }

  if (artifact.artifactType === 'object' && artifact.files.some(function (f) {
    return f.match(/\.gltf/);
  })) {
    return Object.assign(entity, {
      primitive: 'a-entity',
      'static-body': true,
      'auto-position': 'scale: 1 1 1',
      'gltf-model': "#artifact-".concat(artifact.artifactId)
    });
  }

  if (artifact.artifactType === 'text') {
    var letters = Math.max.apply(Math, (0, _toConsumableArray2["default"])(artifact.uri.split('\n').map(function (l) {
      return l.length;
    })));
    return Object.assign(entity, {
      primitive: 'a-text',
      value: artifact.uri,
      font: _constants["default"].ASSET.FONT.FONT_URL,
      color: (0, _tools.toRGB)(artifact.colorTint),
      anchor: 'left',
      side: 'double',
      shader: 'msdf',
      negate: false,
      scale: [artifact.scaleX * _constants["default"].ASSET.FONT.FONT_SCALING_FACTOR, artifact.scaleY * _constants["default"].ASSET.FONT.FONT_SCALING_FACTOR, artifact.scaleZ || _constants["default"].DEFAULT_ARTIFACT_WIDTH].join(' '),
      'x-offset': Math.sign(artifact.scaleX) * letters * _constants["default"].ASSET.FONT.X_OFFSET
    });
  }

  return null;
};
/*
 *  Creates the entities for the artifacts
 *
 */


exports.createArtifact = createArtifact;

var createArtifacts = function createArtifacts() {
  var exhibition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return (exhibition.artifacts || []).map(function () {
    var artifact = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var idx = arguments.length > 1 ? arguments[1] : undefined;
    return createArtifact(artifact, idx, events, opts);
  });
};

exports.createArtifacts = createArtifacts;
import React from 'react';
import { __ } from 'artsteps2-common';
import utils from '../../utils';
import { compose, withState, withDispatch } from '../../enhancers';
import { handleFormPropertyChange, clearFormData, setUIProperty, apiGET } from '../../actions';
import { getFormProperty, getUIProperty } from '../../reducers';

export const ChatbotView = ({
  dialog = [],
  input = '',
  onInputChange = () => Promise.resolve(true),
  onInputSubmit = () => Promise.resolve(true),
}) => (
  <div className="chatbot">
    <div className="chatbot-text">
      {dialog.map((message, idx) => (
        <div className="message" key={idx}>
          <span className="message-owner">{message.owner}</span>
          <span className="message-text">{utils.text.fromHtml(message.text)}</span>
        </div>
      ))}
    </div>
    <form
      className="ui form"
      onSubmit={event => {
        event.preventDefault();
        onInputSubmit();
      }}
    >
      <div className="ui field chatbot-input">
        <input
          type="text"
          className="ui input"
          name="input"
          value={input}
          onChange={onInputChange}
        />
      </div>
    </form>
  </div>
);

const FORM_NAME = 'chatbot';

const mapState = state => ({
  input: getFormProperty(state, FORM_NAME, 'input'),
  dialog: getUIProperty(state, `${FORM_NAME}/dialog`) || [],
});

const mapDispatch = (dispatch, { input, dialog }) => ({
  onInputChange: event => dispatch(handleFormPropertyChange(FORM_NAME, event)),
  onInputSubmit: () =>
    utils.dialogflow
      .getIntent(input)
      .then(reply => {
        dispatch(apiGET(reply.intent || '')).then(({ response }) =>
          dispatch(
            setUIProperty(`${FORM_NAME}/dialog`, [
              ...dialog,
              { owner: __('me'), text: input },
              {
                owner: __('bot'),
                text: `${reply.speech || ''}${
                  typeof response === 'string' ? `<br />${response}` : ''
                }`,
              },
            ]),
          ),
        );
      })
      .then(() => dispatch(clearFormData(FORM_NAME))),
});

const Chatbot = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ChatbotView);

export default Chatbot;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toRelativeAngle = exports.toPositiveAngle = exports.getHorizontalAngle = exports.toEulerianAngle = exports.mirror = exports.getMagnitude = exports.getMidpoint3d = exports.getMidpoint = exports.parseQuaternion = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var parseQuaternion = function parseQuaternion(str) {
  return (str || '').substring(1, (str || '').length - 1).split(',').map(function (f) {
    return f.replace(/\s+/g, '');
  }).map(parseFloat);
};

exports.parseQuaternion = parseQuaternion;

var getMidpoint = function getMidpoint(a, b) {
  return a + (b - a) / 2;
};

exports.getMidpoint = getMidpoint;

var getMidpoint3d = function getMidpoint3d(a, b) {
  return [getMidpoint(a[0], b[0]), getMidpoint(a[1], b[1]), getMidpoint(a[2], b[2])];
};

exports.getMidpoint3d = getMidpoint3d;

var getMagnitude = function getMagnitude(v) {
  return Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
};

exports.getMagnitude = getMagnitude;

var mirror = function mirror(input) {
  var invertAxisIndexes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return input.map(function (value, idx) {
    return invertAxisIndexes.indexOf(idx) < 0 ? value : -value;
  });
};

exports.mirror = mirror;

var toEulerianAngle = function toEulerianAngle(_ref) {
  var _ref2 = (0, _slicedToArray2["default"])(_ref, 4),
      x = _ref2[0],
      y = _ref2[1],
      z = _ref2[2],
      w = _ref2[3];

  var test = x * y + z * w;
  var pitch;
  var yaw;
  var roll;

  if (test > 0.499) {
    pitch = 2 * Math.atan2(x, w);
    yaw = Math.PI / 2;
    roll = 0;
  } else if (test < -0.499) {
    pitch = -2 * Math.atan2(x, w);
    yaw = -Math.PI / 2;
    roll = 0;
  } else {
    pitch = Math.atan2(2 * y * w - 2 * x * z, 1 - 2 * y * y - 2 * z * z);
    yaw = Math.asin(2 * test);
    roll = Math.atan2(2 * x * w - 2 * y * z, 1 - 2 * x * x - 2 * z * z);
  }

  return [roll * (360 / Math.PI), pitch * (180 / Math.PI), yaw * (360 / Math.PI)];
};

exports.toEulerianAngle = toEulerianAngle;

var getHorizontalAngle = function getHorizontalAngle(v1, v2) {
  var angle = Math.atan2(v2[2] - v1[2], v2[0] - v1[0]);
  return 90 - angle * (180 / Math.PI);
};

exports.getHorizontalAngle = getHorizontalAngle;

var toPositiveAngle = function toPositiveAngle(angle) {
  return (angle + 720) % 360;
};

exports.toPositiveAngle = toPositiveAngle;

var toRelativeAngle = function toRelativeAngle(angle) {
  return angle > 180 ? angle - 360 : angle;
};

exports.toRelativeAngle = toRelativeAngle;
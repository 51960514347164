import React from 'react';
import { compose, withState, withDispatch } from '../../enhancers';
import { deleteMessage } from '../../actions';
import { getAllMessages } from '../../reducers';
import toast from '../toast/toast';

// const ICONS = {
//   debug: 'info',
//   info: 'info circle',
//   warning: 'warning sign',
//   error: 'remove circle',
//   success: 'check circle',
// };

export const MessageListView = ({ messages = [], onDelete = () => false }) => {
  // eslint-disable-next-line array-callback-return
  messages.map(message => {
    if (message.type === 'error') {
      toast.error(message.description, message.title);
    } else if (message.type === 'success') {
      toast.success(message.description, message.title);
    } else if (message.type === 'warning') {
      toast.warning(message.description, message.title);
    } else {
      toast.update(message.description, message.title);
    }
    onDelete(message);
  });
  return <></>;
};

const mapState = state => ({ messages: getAllMessages(state) });

const mapDispatch = dispatch => ({
  onDelete: message => dispatch(deleteMessage(message)),
});

const MessageList = compose(withState(mapState), withDispatch(mapDispatch))(MessageListView);

export default MessageList;

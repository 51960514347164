import React from 'react';
import MetaTags from '../../layouts/MetaTags';
import { withState } from '../../../enhancers';
import { getApiResource } from '../../../reducers';
import utils from '../../../utils';

export const ExhibitionMetaView = ({ exhibition = {} }) => (
  <MetaTags
    title={exhibition.title}
    description={exhibition.description}
    image={utils.exhibition.getExhibitionImage(exhibition)}
  />
);

const mapState = (state, { exhibitionId }) => ({
  exhibition: getApiResource(state, `exhibitions/${exhibitionId}`),
});

const ExhibitionMeta = withState(mapState)(ExhibitionMetaView);

export default ExhibitionMeta;

import React from 'react';
import utils from '../../utils';

const PanoramaViewerView = ({ uri }) => (
  <canvas
    className="panorama-viewer"
    ref={canvas => canvas && utils.webgl.createPanoramicView(canvas, uri)}
  />
);

const PanoramaViewer = PanoramaViewerView;

export default PanoramaViewer;

import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import React from 'react';
import { __ } from 'artsteps2-common';
import Overlay from '../generic/Overlay';
import TextureEditor from './forms/TextureEditor';
import { compose, withState, withDispatch } from '../../enhancers';
import { setUIProperty } from '../../actions';
import { getUIProperty } from '../../reducers';
import { StyledButton } from '../../styles/GenericStyled';
import { UploadContainer } from '../exhibitions/editor/ExhibitionEditStyled';

const TextureUploaderView = ({
  children,
  editorOpen = false,
  onEditorOpen = () => Promise.resolve(false),
  onEditorClose = () => Promise.resolve(false),
}) => (
  <UploadContainer>
    <StyledButton
      type="secondary"
      data-tooltip={__('texture_add_tooltip')}
      data-inverted
      onClick={onEditorOpen}
    >
      <CropOriginalIcon style={{ marginRight: '5px' }} /> {__('add_texture')}
    </StyledButton>
    {children}
    <Overlay fixed open={editorOpen} onClose={onEditorClose}>
      <TextureEditor onSave={onEditorClose} textureId="new" />
    </Overlay>
  </UploadContainer>
);

const mapState = state => ({
  editorOpen: getUIProperty(state, 'texture:new/editor'),
});

const mapDispatch = dispatch => ({
  onEditorOpen: () => dispatch(setUIProperty('texture:new/editor', true)),
  onEditorClose: () => dispatch(setUIProperty('texture:new/editor', false)),
});

const TextureUploader = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(TextureUploaderView);

export default TextureUploader;

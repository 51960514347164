import constants from './constants';
import utils from '../';

export const registerDoorGeometry = AFRAME => {
  /*
   *   Creates the following geometric shape.
   *
   *   1---3-----------6---7
   *   |   |           |   |
   *   |   4-----------5   |
   *   |   |           |   |
   *   |   |           |   |
   *   0---2           9---8
   *
   *
   * */

  const { THREE } = AFRAME;
  const doorGeometry = {
    schema: {
      size: {
        default: constants.DEFAULT_DOOR_DIMENSIONS.join(' '),
      },
      position: {
        default: '0 0 0',
      },
    },

    init(data) {
      const size = data.size.split(' ').map(x => parseFloat(x));
      const xRelativePositions = [
        -size[0] / 2,
        -constants.DOOR_WIDTH_OPENING_SIZE / 2,
        constants.DOOR_WIDTH_OPENING_SIZE / 2,
        size[0] / 2,
      ];
      const yRelativePositions = [0, size[1] * constants.DOOR_HEIGHT_OPENING_FACTOR, size[1]];

      const startPosition = data.position.split(' ').map(x => parseFloat(x));

      const vertices = [
        new THREE.Vector3(
          xRelativePositions[0] + startPosition[0],
          yRelativePositions[0] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[0] + startPosition[0],
          yRelativePositions[2] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[1] + startPosition[0],
          yRelativePositions[0] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[1] + startPosition[0],
          yRelativePositions[2] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[1] + startPosition[0],
          yRelativePositions[1] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[2] + startPosition[0],
          yRelativePositions[0] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[2] + startPosition[0],
          yRelativePositions[2] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[2] + startPosition[0],
          yRelativePositions[1] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[3] + startPosition[0],
          yRelativePositions[0] + startPosition[1],
          0,
        ),
        new THREE.Vector3(
          xRelativePositions[3] + startPosition[0],
          yRelativePositions[2] + startPosition[1],
          0,
        ),
      ];

      const geometry = new THREE.Geometry();
      geometry.vertices = vertices;

      geometry.computeBoundingBox();
      geometry.faces.push(new THREE.Face3(0, 2, 1));
      geometry.faces.push(new THREE.Face3(1, 2, 3));

      geometry.faces.push(new THREE.Face3(3, 4, 6));
      geometry.faces.push(new THREE.Face3(4, 7, 6));

      geometry.faces.push(new THREE.Face3(6, 8, 9));
      geometry.faces.push(new THREE.Face3(6, 5, 8));
      geometry.mergeVertices();
      geometry.computeFaceNormals();
      geometry.computeVertexNormals();

      // A face's value must be [0,1]
      const uvXPositions = [
        0,
        constants.DOOR_WIDTH_OPENING_FACTOR,
        1 - constants.DOOR_WIDTH_OPENING_FACTOR,
        1,
      ];
      const uvYPositions = [0, constants.DOOR_HEIGHT_OPENING_FACTOR, 1];

      geometry.faceVertexUvs[0].push([
        new THREE.Vector2(uvXPositions[0], uvYPositions[0]),
        new THREE.Vector2(uvXPositions[1], uvYPositions[0]),
        new THREE.Vector2(uvXPositions[0], uvYPositions[2]),
      ]);
      geometry.faceVertexUvs[0].push([
        new THREE.Vector2(uvXPositions[0], uvYPositions[2]),
        new THREE.Vector2(uvXPositions[1], uvYPositions[0]),
        new THREE.Vector2(uvXPositions[1], uvYPositions[2]),
      ]);

      geometry.faceVertexUvs[0].push([
        new THREE.Vector2(uvXPositions[1], uvYPositions[2]),
        new THREE.Vector2(uvXPositions[1], uvYPositions[1]),
        new THREE.Vector2(uvXPositions[2], uvYPositions[2]),
      ]);
      geometry.faceVertexUvs[0].push([
        new THREE.Vector2(uvXPositions[1], uvYPositions[1]),
        new THREE.Vector2(uvXPositions[2], uvYPositions[1]),
        new THREE.Vector2(uvXPositions[2], uvYPositions[2]),
      ]);
      geometry.faceVertexUvs[0].push([
        new THREE.Vector2(uvXPositions[2], uvYPositions[2]),
        new THREE.Vector2(uvXPositions[3], uvYPositions[0]),
        new THREE.Vector2(uvXPositions[3], uvYPositions[2]),
      ]);
      geometry.faceVertexUvs[0].push([
        new THREE.Vector2(uvXPositions[2], uvYPositions[2]),
        new THREE.Vector2(uvXPositions[2], uvYPositions[0]),
        new THREE.Vector2(uvXPositions[3], uvYPositions[0]),
      ]);

      geometry.uvsNeedUpdate = true;

      this.geometry = geometry;
    },
  };

  if (!AFRAME.geometries || !AFRAME.geometries.door) {
    AFRAME.registerGeometry('door', doorGeometry);
  }

  return Promise.resolve(doorGeometry);
};

export const registerEvents = (AFRAME, instanceId) => {
  /*
   * Function that registers a click event on the door leaf 3D object and listens for a click event to open it.
   *
   * */
  const openOnClick = {
    schema: {
      doorAttributes: {
        default: {
          open: false,
          locked: false,
          position: '0 0 0',
        },
      },
      rotation: {
        default: '0 0 0',
      },
      position: {
        default: '0 0 0',
      },
    },

    init: function init() {
      const { data, el } = this;
      const collider = el.parentElement.getElementsByClassName('collider-door')[0];
      el.addEventListener('non-dragging-click', () => {
        const animation = {
          property: 'rotation',
          to: el.getAttribute('door-attributes').open ? '0 0 0' : data.rotation,
          easing: 'easeInSine',
          dur: constants.DEFAULT_DOOR_ANIMATION_SPEED,
        };
        el.setAttribute('animation', animation);

        collider.setAttribute('bbox-attributes', {
          open:
            collider.getAttribute('bbox-attributes').open +
            (!el.getAttribute('door-attributes').open ? 1 : -1),
        });

        if (!el.getAttribute('door-attributes').open) {
          collider.removeAttribute('static-body');
        } else if (collider.getAttribute('bbox-attributes').open < 1) {
          collider.setAttribute('static-body', collider.getAttribute('bbox-attributes') < 1);
        }
        el.setAttribute('door-attributes', {
          open: !el.getAttribute('door-attributes').open,
          locked: el.getAttribute('door-attributes').locked,
          position: el.getAttribute('door-attributes').position,
        });
      });
    },
  };
  const { THREE } = AFRAME;

  // Function that positions a 3D model show that its center is the meshes center.
  // It does not center the bounding box properly though...
  const autoposition = {
    schema: { scale: { default: [1, 1, 1] } },
    init: function init() {
      this.scale();
      this.el.addEventListener('object3dset', () => this.scale());
    },
    scale: function scale() {
      const { el } = this;
      const mesh = el.getObject3D('mesh');

      if (!mesh) {
        return;
      }
      const bbox = new THREE.Box3().setFromObject(mesh);
      const newPosition = [
        bbox.getCenter().x,
        -bbox.getCenter().y + bbox.getSize().y,
        bbox.getCenter().z,
      ];
      el.setAttribute('position', newPosition.join(' '));
      const currentRotation = el.getAttribute('rotation');
      el.setAttribute(
        'rotation',
        [currentRotation.x, 180 + currentRotation.y, currentRotation.z].join(' '),
      );
    },
  };

  // Listens for click events on the floor and fires an animation to move there.
  const groundListener = {
    init() {
      const { el } = this;
      const player = document.querySelector(`#${instanceId} a-entity[camera]`);
      const speed = constants.ANIMATION_SPEED;

      el.addEventListener('non-dragging-click', evt => {
        const pointX = evt.detail.intersection.point.x;
        const pointZ = evt.detail.intersection.point.z;
        const dist = evt.detail.intersection.distance;
        const time = dist / speed;
        if (dist > 0.9) {
          const animation = {
            property: 'position',
            dir: 'forward',
            to: [pointX, constants.CAMERA_HEIGHT, pointZ].join(' '),
            easing: 'easeInSine',
            dur: time,
          };
          player.setAttribute('animation', animation);
        }
      });

      el.addEventListener('animationend', evt => {
        player.removeAttribute('animation');
      });
    },
  };

  // Listens for click events on an artifact and moves the camera close to it.
  const moveToArtifact = {
    schema: {
      distanceFromArtifact: {
        default: constants.DEFAULT_DISTANCE_FROM_ARTIFACT,
      },
    },
    init({ distanceFromArtifact = constants.DEFAULT_DISTANCE_FROM_ARTIFACT } = {}) {
      const { el } = this;
      const player = document.querySelector(`#${instanceId} a-entity[camera]`);
      const speed = constants.ANIMATION_SPEED;

      el.addEventListener('non-dragging-click', evt => {
        const mesh = el.getObject3D('mesh');
        if (!mesh) {
          return;
        }
        const dist = evt.detail.intersection.distance;
        const time = dist / speed;
        const artifactPosition = el.getAttribute('position');
        const playerPosition = player.getAttribute('position');
        if (dist > 0.9) {
          let lamda = distanceFromArtifact;
          if (playerPosition.z < artifactPosition.z) {
            lamda = -lamda;
          }

          const direction = new THREE.Vector3(0, 0, 0);
          mesh.getWorldDirection(direction);
          direction.add(new THREE.Vector3(0, 0, lamda));
          direction.add(mesh.getWorldPosition());

          const animation = {
            property: 'position',
            dir: 'forward',
            to: [direction.x, constants.CAMERA_HEIGHT, direction.z].join(' '),
            easing: 'easeInSine',
            dur: time,
          };

          player.setAttribute('animation', animation);
        }
      });

      el.addEventListener('animationend', evt => {
        player.removeAttribute('animation');
      });
    },
  };

  // Listens for click events and, essentially, has a lower drag threshold.
  // Sends custom events which are caught by the other click listeners.
  // Note that on mobile there is a noticeable but unexplainable delay.
  const clickManager = {
    schema: {},
    init() {
      const { el } = this;
      let startPoint = {};

      const onMouseDown = ({ detail: { intersection: { point } = {} } = {} }) => {
        startPoint = point || {};
      };

      const onMouseUp = ({ detail = {} }) => {
        const { point = {} } = detail.intersection || {};
        if (point.x !== startPoint.x || point.y !== startPoint.y || point.z !== startPoint.z) {
          return;
        }

        el.dispatchEvent(
          new CustomEvent('non-dragging-click', {
            detail,
            bubbles: true,
            cancelable: true,
          }),
        );
      };

      el.addEventListener('mousedown', onMouseDown);
      el.addEventListener('touchstart', onMouseDown);
      el.addEventListener('mouseup', onMouseUp);
      el.addEventListener('touchend', onMouseUp);
    },
  };

  // Fires an animation to move the camera to the next story point.
  const moveToPoint = {
    schema: {},
    init() {
      const { el } = this;
      const player = document.querySelector(`#${instanceId} a-entity[camera]`);
      const speed = constants.ANIMATION_SPEED;
      let pointId = -1;
      el.addEventListener('storypoint-change', evt => {
        const watchPosition = utils.math.mirror(
          utils.math.parseQuaternion(evt.detail.watchPosition),
          (evt.detail.options || {}).invertAxisPos,
        );
        ({ pointId } = evt.detail);

        const dist = Math.sqrt(
          watchPosition[0] * watchPosition[0] + watchPosition[2] * watchPosition[2],
        );
        const time = dist / speed;
        const animation = {
          property: 'position',
          dir: 'forward',
          to: [watchPosition[0], constants.CAMERA_HEIGHT, watchPosition[2]].join(' '),
          easing: 'easeInSine',
          dur: time,
        };
        player.setAttribute('animation__storypoint', animation);
      });
      player.addEventListener('animationcomplete', evt => {
        if (evt.detail.name === 'animation_storypoint') {
          el.dispatchEvent(
            new CustomEvent('storypoint-movement-end', {
              detail: {
                pointId,
              },
              bubbles: true,
            }),
          );
        }
      });
    },
  };

  if (!AFRAME.components || !AFRAME.components['auto-position']) {
    AFRAME.registerComponent('auto-position', autoposition);
  }
  if (!AFRAME.components || !AFRAME.components['open-on-click']) {
    AFRAME.registerComponent('open-on-click', openOnClick);
  }
  if (!AFRAME.components || !AFRAME.components['ground-listener']) {
    AFRAME.registerComponent('ground-listener', groundListener);
  }
  if (!AFRAME.components || !AFRAME.components['click-manager']) {
    AFRAME.registerComponent('click-manager', clickManager);
  }
  if (!AFRAME.components || !AFRAME.components['move-to-artifact']) {
    AFRAME.registerComponent('move-to-artifact', moveToArtifact);
  }
  if (!AFRAME.components || !AFRAME.components['move-to-point']) {
    AFRAME.registerComponent('move-to-point', moveToPoint);
  }

  return Promise.resolve([
    openOnClick,
    autoposition,
    clickManager,
    groundListener,
    moveToArtifact,
    moveToPoint,
  ]);
};

// Custom attributes used by the functions.
export const registerAttribute = AFRAME => {
  const doorAttributes = {
    schema: {
      open: {
        default: false,
      },
      locked: {
        default: false,
      },
      position: {
        default: '0 0 0',
      },
    },
  };

  const doorBoundingBoxAttributes = {
    schema: {
      open: {
        default: 0,
      },
    },
  };

  if (!AFRAME.components || !AFRAME.components['bbox-attributes']) {
    AFRAME.registerComponent('bbox-attributes', doorBoundingBoxAttributes);
  }
  if (!AFRAME.components || !AFRAME.components['door-attributes']) {
    AFRAME.registerComponent('door-attributes', doorAttributes);
  }
  return Promise.resolve(doorAttributes, doorBoundingBoxAttributes);
};

import React from 'react';
import HtmlParser from 'html-react-parser';
import sanitize from 'sanitize-html-react';
import IFrameButton from './IFrameButton';

export const toMultiline = text =>
  (text || '').split(/[\n\r]+/g).map((line, idx) =>
    line ? (
      <span key={idx}>
        {line}
        <br />
      </span>
    ) : null,
  );

// hurray stack overflow!
export const isLink = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\=\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const toMultilineWithLink = (text, exhibitionId) =>
  (text || '').split(/[\n\r]+/g).map((line, idx) =>
    line ? (
      <span key={idx}>
        {line
          .split(' ')
          .map((word, id) =>
            isLink(word) ? (
              <IFrameButton exhibitionId={exhibitionId} word={word} key={word} id={id} />
            ) : (
              word.concat(' ')
            ),
          )}
        <br />
      </span>
    ) : null,
  );

export const toMultilineArtifactViewer = text =>
  (text || '')
    .replaceAll('\n', '&nbsp;')
    .replaceAll('\r', '&nbsp;')
    .split('&nbsp;')
    .map((line, idx) =>
      line ? (
        <span key={idx}>
          {line}
          <br />
        </span>
      ) : <br />,
    );

export const sanitizeHtml = (html = '') =>
  sanitize(html, {
    allowedTags: sanitize.defaults.allowedTags,
  });

export const fromHtml = (html = '') => HtmlParser(sanitizeHtml(html));

import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { DataGrid } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import PrivateSpaceEditor from '../../../components/artifacts/forms/PrivateSpaceEditor';
import { getAuthUser, getAuthUserRoles, getApiResource, getApiStatus } from '../../../reducers';
import { withState, withDispatch, compose } from '../../../enhancers';
import { apiPOST, setFormData, addMessage, apiGET, setUIProperty } from '../../../actions';
import { BodyWithPadding, Text } from '../../../styles/GenericStyled';
import Overlay from '../../../components/generic/Overlay';
import { gridStyles, ThreeDots } from '../AdminStyled';
import colors from '../../../styles/colors';
import PSrow from './PSrow';
import { AdminButton, AdminButtonPanel } from '../../../styles/styledComponents/FAQStyled';
import Logo from '../../privateSpaces/Logo';
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";

//https://material-ui.com/api/data-grid/

const width = 150;

export const PrivateSpacesPanelView = ({
  ready,
  onGenerateCode,
  setEditingSpace = () => Promise.resolve(false),
  spaces = [],
  onSetFormData,
  setRenderedView,
  onFetchSpaces,
  isAdmin,
}) => {
  const classes = gridStyles();
  const [openEditor, setOpenEditor] = useState(false);
  const [rows, setRows] = useState([]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    setRenderedView('private-spaces');
  }, []);

  useEffect(() => {
    if (isAdmin !== undefined) onFetchSpaces();
  }, [isAdmin]);

  useEffect(() => {
    if (spaces && spaces.length > 0) {
      setRows(
        spaces.map(space => ({
          ...space,
          id: space._id,
          align: 'center',
        })),
      );
    }
  }, [spaces]);

  const columns = [
    {
      field: 'logo',
      sortable: false,
      headerName: 'Logo',
      headerAlign: 'center',
      width: 350,
      renderCell: ({ row }) => {
        const onRedirect = () => {
          const redirectURL = `http://${row.subdomain}.${window.location.hostname
            .replace('www2', '')
            .replace('www', '')
            .replace('.', '')}`.concat(
            window.location.hostname.split('.')[0] === 'localhost' ? ':3000' : '',
          );

          window.open(redirectURL);
        };

        return (
          <Logo
            onRedirect={onRedirect}
            height="68px"
            maxWidth="200px"
            maxHeight="68px"
            minWidth="200px"
            minHeight="68px"
            space={row}
          />
        );
      },
    },
    {
      field: 'subdomain',
      headerName: 'Subdomain',
      headerAlign: 'center',
      width: 296,
    },
    { field: 'title', headerName: 'Title', headerAlign: 'center', width },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      sortComparator: (v1, v2, param1, param2) => {
        let settings1 = param1.api.getCellValue(param1.id, 'settings');
        let settings2 = param2.api.getCellValue(param2.id, 'settings');

        return settings1.isActive - settings2.isActive;
      },
      renderCell: ({ row }) => (
        <Text mycolor={(row.settings || {}).isActive ? colors.ourGreen : colors.ourRed}>
          {(row.settings || {}).isActive ? 'Active' : 'Inactive'}
        </Text>
      ),
      width,
    },
    {
      field: 'privacy',
      headerName: 'Privacy',
      headerAlign: 'center',
      width: 200,
      sortComparator: (v1, v2, param1, param2) => {
        let settings1 = param1.api.getCellValue(param1.id, 'settings');
        let settings2 = param2.api.getCellValue(param2.id, 'settings');

        return settings1.publicView - settings2.publicView;
      },
      renderCell: ({ row }) => (
        <Text mycolor={(row.settings || {}).publicView ? colors.ourGreen : colors.ourRed}>
          {(row.settings || {}).publicView ? 'Public' : 'Private'}
        </Text>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      headerAlign: 'center',
      width,
      valueFormatter: params =>
        params.value ? new Date(params.value.split('T')[0]).toLocaleDateString() : '',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      sortable: false,
      width: 250,
      renderCell: ({ row }) => <PSrow space={row} setOpenEditor={setOpenEditor} />,
    },
  ];

  return (
    <>
      <BodyWithPadding padding="24px">
        <AdminButtonPanel>
          <Overlay fixed open={openEditor} onClose={() => setOpenEditor(false)}>
            <PrivateSpaceEditor
              setOpen={setOpenEditor}
              onSetFormData={onSetFormData}
              ready={ready}
            />
          </Overlay>
          <AdminButton
            variant="contained"
            style={{ height: '53.63px' }}
            mycolor={colors.ourWhite}
            background={colors.ourGreen}
            onClick={() => {
              setEditingSpace();
              onGenerateCode();
              return setOpenEditor(true);
            }}
          >
            New Space
            <AddIcon />
          </AdminButton>
          <TextField
            id="search"
            label="Search by Subdomain"
            value={search}
            onChange={e => setSearch(e.target.value)}
            variant="outlined"
            style={{ width: '300px' }}
          />
        </AdminButtonPanel>
        {ready ? (
          <div style={{ width: '100%' }} className={classes.root}>
            <DataGrid
              loading={!ready}
              autoHeight
              rowHeight={68}
              rows={rows.filter(r => (search === '' ? true : r.subdomain.includes(search)))}
              columns={columns}
              pageSize={10}
              components={{
                ColumnMenuIcon: ThreeDots,
              }}
            />
          </div>
        ) : (
          <Grid container align="center" justify="center">
            <Grid item>Fetching spaces</Grid>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </BodyWithPadding>
    </>
  );
};

const mapState = state => ({
  spaces: Object.values(getApiResource(state, 'spaces')),
  currentUser: getAuthUser(state),
  ready: !getApiStatus(state, 'spaces'),
  isAdmin: getAuthUserRoles(state).some(role => ADMIN_ROLES.includes(role)),
});

const filter = { populate: ['spaceAdmins'], include: 'emails' };
const ADMIN_ROLES = ['admin'];

const query = (psAdmin = undefined, isAdmin = undefined) => {
  // if (psAdmin && psAdmin !== undefined) return `spaces/${psAdmin.spaceDomains[0].subdomain}`;
  // else
  if (isAdmin) return 'spaces';
};

const mapDispatch = (dispatch, { psAdmin, isAdmin }) => {
  const onSetFormData = data => dispatch(setFormData('new', data));
  return {
    setEditingSpace: () => dispatch(setUIProperty('editingSpace', 'new')),
    setRenderedView: view => dispatch(setUIProperty('renderedView', view)),
    onFetchSpaces: () => dispatch(apiGET(query(psAdmin, isAdmin), filter)),
    onUpsert: data => dispatch(apiPOST('spaces', data)),
    onSetFormData,
    onAddMessage: message => dispatch(addMessage(message)),
    onGenerateCode: () => {
      fetch('/api/generateUniquePassword').then(res =>
        res.json().then(result => onSetFormData(result)),
      );
    },
  };
};

const PrivateSpacesPanel = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(PrivateSpacesPanelView);

export default PrivateSpacesPanel;

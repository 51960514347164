import React from 'react';
// import { __ } from 'artsteps2-common';
import Overlay from '../../generic/Overlay';
import TextureEditor from '../forms/TextureEditor';
import utils from '../../../utils';
import { compose, withState, withDispatch } from '../../../enhancers';
import { addMessage, setUIProperty, setUIData } from '../../../actions';
import { getUIProperty, getAuthUser } from '../../../reducers';
import { Item } from '../../exhibitions/editor/ExhibitionEditStyled';

import EditItem from '../../exhibitions/editor/EditItem';

export const TextureView = ({
  texture = {},
  placing = false,
  controls = false,
  disabled = false,
  editorOpen = false,
  currentUser = {},
  onStopPlacing = () => Promise.resolve(false),
  onPlaceTexture = () => Promise.resolve(false),
  onRemoveTexture = () => Promise.resolve({ response: { error: 'error' } }),
  onEditorClose = () => Promise.resolve(false),
  onEditorOpen = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
}) => (
  <Item
    onDoubleClick={() => controls && onEditorOpen()}
    onClick={() => (placing ? onStopPlacing() : onPlaceTexture())}
    src={utils.texture.getTextureImage(texture)}
    draggable={!disabled}
    onDragEnd={({ dataTransfer: { dropEffect = 'none' } = {} }) =>
      dropEffect !== 'none' && onStopPlacing().then(() => onPlaceTexture())
    }
    selected={+placing}
    inactive={+disabled}
  >
    {controls &&
      !disabled &&
      texture.user &&
      (texture.user._id || texture.user) === currentUser._id && (
        <EditItem onEditorOpen={onEditorOpen} />
      )}
    <Overlay fixed open={editorOpen} onClose={onEditorClose}>
      <TextureEditor onClose={onEditorClose} onSave={onEditorClose} textureId={texture._id} />
    </Overlay>
  </Item>
);

const mapState = (state, { exhibitionId, texture = {} }) => {
  const textureUri = utils.texture.getTextureURI(texture);
  const placingTexture = getUIProperty(state, `exhibitions/${exhibitionId}/placingTexture`);
  const currentUser = getAuthUser(state);
  return {
    textureUri,
    currentUser,
    editorOpen: getUIProperty(state, `textures/${texture._id}/editor`),
    placing: placingTexture === textureUri,
    disabled:
      (placingTexture && placingTexture !== textureUri) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/painting`),
  };
};

const mapDispatch = (dispatch, { texture = {}, exhibitionId, placing, textureUri }) => {
  const onStopPlacing = () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingTexture: undefined,
        painting: false,
      }),
    );
  return {
    onStopPlacing,
    onPlaceTexture: () =>
      dispatch(
        setUIData(`exhibitions/${exhibitionId}`, {
          placingTexture: textureUri,
          painting: false,
        }),
      ),
    onEditorOpen: () => dispatch(setUIProperty(`textures/${texture._id}/editor`, true)),
    onEditorClose: () => {
      onStopPlacing();
      return dispatch(setUIProperty(`textures/${texture._id}/editor`, false));
    },
    onAddMessage: message => dispatch(addMessage(message, 'textures')),
  };
};

const Texture = compose(withState(mapState), withDispatch(mapDispatch))(TextureView);

export default Texture;

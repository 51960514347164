import React from 'react';
import { NavLink } from 'react-router-dom';
import { __ } from 'artsteps2-common';

import MetaTags from '../components/layouts/MetaTags';
import FullLayout from '../components/layouts/FullLayout';

const DonateCompletedPage = () => (
  <FullLayout>
    <MetaTags title={__('sign_in')} description={__('sign_in_header')} />
    <div className="login-form">
      <div className="ui two column stackable grid">
        <div className="stretched column cover-column">
          <div className="ui basic segment">
            <h1 className="ui header white-text">{'Thank you!'}</h1>
            <h4 style={{ whiteSpace: 'pre-line', textAlign: 'left', padding: '2px' }}>
              {__('donation_thank_you')}
            </h4>
            <h4 style={{ whiteSpace: 'pre-line' }}>{__('donation_thank_you_ps')}</h4>
          </div>
        </div>
        <div className="column" style={{ display: 'block' }}>
          <NavLink to="/" className="logo" />
          <div className="ui horizontal divider">{__('Donation successful!')}</div>
          <div className="ui form">
            <a
              style={{ marginTop: '20px' }}
              className="ui teal button"
              href={`${window.location.protocol}//www.artsteps.com`}
            >
              {'Go back to Artsteps'}
            </a>
          </div>
        </div>
      </div>
    </div>
  </FullLayout>
);

export default DonateCompletedPage;

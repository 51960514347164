"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fromNow = exports.YEAR = exports.MONTH = exports.DAY = exports.HOUR = exports.MINUTE = exports.SECOND = void 0;

var _i18n = require("../i18n");

var SECOND = 1000;
exports.SECOND = SECOND;
var MINUTE = SECOND * 60;
exports.MINUTE = MINUTE;
var HOUR = MINUTE * 60;
exports.HOUR = HOUR;
var DAY = HOUR * 24;
exports.DAY = DAY;
var MONTH = DAY * 30;
exports.MONTH = MONTH;
var YEAR = DAY * 365;
exports.YEAR = YEAR;

var fromNow = function fromNow(date) {
  if (!date) {
    return null;
  }

  var span = Date.now() - new Date(date);

  if (span < SECOND) {
    return (0, _i18n.__)('datetime.just_now');
  }

  var frames = [{
    period: 'year',
    span: Math.floor(span / YEAR)
  }, {
    period: 'month',
    span: Math.floor(span % YEAR / MONTH)
  }, {
    period: 'day',
    span: Math.floor(span % MONTH / DAY)
  }, {
    period: 'hour',
    span: Math.floor(span % DAY / HOUR)
  }, {
    period: 'minute',
    span: Math.floor(span % HOUR / MINUTE)
  }, {
    period: 'second',
    span: Math.floor(span % MINUTE / SECOND)
  }];
  var frame = frames.find(function (f) {
    return f.span > 0;
  });
  frame.period = frame.span !== 1 ? "".concat(frame.period, "s") : frame.period;
  return "".concat(frame.span, " ").concat((0, _i18n.__)("datetime.".concat(frame.period)), " ").concat((0, _i18n.__)('ago'));
};

exports.fromNow = fromNow;
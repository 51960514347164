import React from 'react';
import { ItemList } from '../../exhibitions/editor/ExhibitionEditStyled';
import Storypoint from './Storypoint';

export const StorypointListView = ({
  storypoints = [],
  controls = false,
  storyId,
  exhibitionId,
}) => (
  <ItemList>
    {storypoints.map(
      storypoint =>
        storypoint._id && (
          <Storypoint
            key={storypoint._id}
            exhibitionId={exhibitionId}
            storypoint={storypoint}
            controls={controls}
            storyId={storyId}
          />
        ),
    )}
  </ItemList>
);

const StorypointList = StorypointListView;

export default StorypointList;

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { compose, withState, withDispatch } from '../../../enhancers';
import { API_STATUS, getApiMeta, getApiResource } from '../../../reducers';
import { apiGET } from '../../../actions';
import { StyledButton } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';

export const PagerView = ({
  labelMore = '',
  labelLess = '',
  hasMore = false,
  hasLess = false,
  pageSize = 32,
  pages = 1,
  ready = true,
  onMore = () => Promise.resolve(false),
  onLess = () => Promise.resolve(false),
}) =>
  ready && (
    <div className="list-pager">
      <div className="pager-buttons">
        {labelMore && hasMore && (
          <StyledButton
            type="secondary"
            mycolor={colors.ourBlack}
            background={colors.ourWhite}
            bordercolor={colors.ourBlack}
            onClick={event => {
              event.preventDefault();
              onMore();
            }}
            onKeyPress={event => event.keyCode === 13 && onMore()}
          >
            <ExpandMoreIcon />
            {labelMore}
          </StyledButton>
        )}
        {labelLess && hasLess && (
          <button
            className="pager-button large-button ui basic secondary button"
            onClick={event => {
              event.preventDefault();
              onLess();
            }}
            onKeyPress={event => event.keyCode === 13 && onLess()}
          >
            <i className="ui icon angle double up" />
            {labelLess}
          </button>
        )}
      </div>
    </div>
  );

const mapState = (state, { collection, query, pageSize }) => {
  const { totalCount, status } = getApiMeta(state, collection, query);
  const count = Object.keys(getApiResource(state, collection, query)).length;
  return {
    count,
    ready: status === API_STATUS.IDLE,
    hasMore: totalCount > count,
    hasLess: count > pageSize,
    pages: Math.ceil(count / pageSize) + 1,
  };
};

const mapDispatch = (dispatch, { collection, query, pageSize, count }) => ({
  onMore: () =>
    dispatch(
      apiGET(collection, {
        ...query,
        page: { limit: pageSize, offset: count },
      }),
    ),
});

const Pager = compose(withState(mapState), withDispatch(mapDispatch))(PagerView);

export default Pager;

export default {
  DEFAULT_DOOR_DIMENSIONS: [3.7, 4, 0.02],
  DOOR_WIDTH_OPENING_SIZE: 1.8,
  DOOR_HEIGHT_OPENING_FACTOR: 0.5875,
  DOOR_WIDTH_OPENING_FACTOR: 0.24,
  ANIMATION_SPEED: 0.005,
  CAMERA_HEIGHT: 1.6,
  DEFAULT_DISTANCE_FROM_ARTIFACT: 1.5,
  DEFAULT_DOOR_ANIMATION_SPEED: 1000,
};

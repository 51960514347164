import React, { useCallback, useState } from 'react';
import { __ } from 'artsteps2-common';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import Overlay from '../../generic/Overlay';
import ArtifactUploader from '../../artifacts/ArtifactUploader';
import ArtifactEditor from '../../artifacts/ArtifactEditor';

import utils from '../../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getUIProperty,
  getAuthUser,
  getFormProperty,
} from '../../../reducers';
import { apiGET, setFormProperty, setUIProperty, setUIData } from '../../../actions';

import { HorizontalItems, StyledTextField, Text } from '../../../styles/GenericStyled';
import { StyledFolderIcon, StyledListIcon } from '../../artifacts/lists/ArtifactsStyled';
import MyToolTip from '../../generic/MyToolTip';
import {
  ArtifactBadge,
  ArtifactTab,
  ArtifactTabs,
  ToolbarSection,
} from '../editor/ExhibitionEditStyled';
import COLORS from '../../../styles/colors';
import ArtifactLibrary from '../../artifacts/lists/ArtifactLibrary';

const ARTIFACT_TYPES = ['image', 'video', 'object', 'text'];

const TabPanel = ({ value, index, children }) => {
  return value === index && <div>{children}</div>;
};

const ArtifactsView = ({
  showInFolders = false,
  setShowInFolders,
  artifacts = [],
  searchTerm = '',
  ready = true,
  exhibitionId,
  editingArtifactId,
  editingArtifactType,
  onSearch = () => Promise.resolve(false),
  onEditorClose = () => Promise.resolve(false),
  onUpdateArtifact = () => Promise.resolve(false),
}) => {
  const [tabValue, setTabValue] = useState(0);

  const getArtifacts = useCallback(
    type => {
      const searchText = searchTerm.toLowerCase();
      return artifacts.filter(
        a =>
          (!type || a.type === type) &&
          (!searchText ||
            a.title.toLowerCase().indexOf(searchText) > -1 ||
            (a.caption && a.caption.toLowerCase().indexOf(searchText) > -1) ||
            (a.information && a.information.toLowerCase().indexOf(searchText) > -1) ||
            (Array.isArray(a.files) &&
              a.files.some(
                b =>
                  (b.name && b.name.toLowerCase().indexOf(searchText) > -1) ||
                  (b.meta && b.meta.text && b.meta.text.toLowerCase().indexOf(searchText) > -1),
              )) ||
            (Array.isArray(a.covers) &&
              a.covers.some(c => c.name && c.name.toLowerCase().indexOf(searchText) > -1)) ||
            (a.audio &&
              a.audio.bin &&
              a.audio.bin.name &&
              a.audio.bin.name.toLowerCase().indexOf(searchText) > -1) ||
            (a.audio && a.audio.uri && a.audio.uri.toLowerCase().indexOf(searchText) > -1)),
      );
    },
    [artifacts],
  );

  return (
    <ToolbarSection>
      <HorizontalItems style={{ flexWrap: 'wrap' }}>
        <Text type="h7">
          {__('artifacts')} ({artifacts.length})
        </Text>
        <span style={{ marginLeft: 'auto' }}>
          <MyToolTip title="Show in list" placement="top">
            <StyledListIcon
              selected={+!showInFolders}
              fontSize="large"
              onClick={() => setShowInFolders(false)}
            />
          </MyToolTip>
          <MyToolTip title="Show in folders" placement="top">
            <StyledFolderIcon
              selected={+showInFolders}
              fontSize="large"
              onClick={() => setShowInFolders(true)}
            />
          </MyToolTip>
        </span>
      </HorizontalItems>
      <HorizontalItems>
        <StyledTextField
          style={{ padding: '10px' }}
          fullWidth
          variant="outlined"
          type="text"
          placeholder={__('search')}
          onChange={event => onSearch(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </HorizontalItems>
      <ArtifactTabs
        // style={{ width: '109%', marginLeft: '-7%' }}
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
      >
        {ARTIFACT_TYPES.map((type, idx) => (
          <ArtifactTab
            key={type}
            label={
              <ArtifactBadge
                showZero
                mycolor={tabValue === idx ? COLORS.ourGreen : 'gray'}
                badgeContent={getArtifacts(type).length}
              >
                {__(`${type}s`)}
              </ArtifactBadge>
            }
          />
        ))}
      </ArtifactTabs>
      {ARTIFACT_TYPES.map((type, idx) => {
        const filteredArtifacts = getArtifacts(type);
        return (
          <TabPanel key={idx} value={tabValue} index={idx}>
            <ArtifactUploader type={type}>
              {artifacts.length === 0 && <div className="help-text">{__('help_artifacts')}</div>}
              {artifacts.length > 0 && filteredArtifacts.length === 0 && (
                <div className="help-text">{__(`no_artifact_${type}s_found`)}</div>
              )}
            </ArtifactUploader>
            <ArtifactLibrary
              showInFolders={showInFolders}
              artifacts={filteredArtifacts}
              controls
              ready={ready}
              exhibitionId={exhibitionId}
            />
          </TabPanel>
        );
      })}
      <Overlay fixed open={!!editingArtifactId} onClose={onEditorClose}>
        <ArtifactEditor
          onClose={onEditorClose}
          onSave={artifact => onUpdateArtifact(artifact).then(() => onEditorClose())}
          exhibitionId={exhibitionId}
          artifactId={editingArtifactId}
          type={editingArtifactType}
        />
      </Overlay>
    </ToolbarSection>
  );
};

const createQuery = ({ _id: user = null } = {}) => ({ filter: { user } });

const mapState = (state, { exhibitionId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    editingArtifactId: getUIProperty(state, 'artifacts/editingArtifactId'),
    editingArtifactType: getUIProperty(state, 'artifacts/editingArtifactType'),
    currentTab: getUIProperty(state, `exhibitions/${exhibitionId}/artifactTab`),
    artifacts: Object.values(getApiResource(state, 'artifacts', createQuery(currentUser))),
    ready: getApiStatus(state, 'artifacts', createQuery(currentUser)) === API_STATUS.IDLE,
    searchTerm: getFormProperty(state, 'artifacts', 'search'),
    showInFolders: getUIProperty(state, 'showInFolders'),
  };
};

const mapDispatch = (dispatch, { exhibitionId, currentUser }) => ({
  setShowInFolders: value => dispatch(setUIProperty('showInFolders', value)),
  onFetchArtifacts: () => dispatch(apiGET('artifacts', createQuery(currentUser))),
  onFetchLicenses: () => dispatch(apiGET('licenses')),
  onPlaceDisplayCase: displayCase =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingCase: displayCase,
        placingArtifact: undefined,
      }),
    ),
  onStopPlacing: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingCase: undefined,
        placingArtifact: undefined,
      }),
    ),
  onTabChange: tab => dispatch(setUIProperty(`exhibitions/${exhibitionId}/artifactTab`, tab)),
  onSearch: searchTerm => dispatch(setFormProperty('artifacts', 'search', searchTerm)),
  onEditorClose: () => dispatch(setUIProperty('artifacts/editingArtifactId', undefined)),
  onUpdateArtifact: artifact =>
    utils.exhibition
      .exportArtifact(artifact)
      .then(a => dispatch(setUIProperty(`exhibitions/${exhibitionId}/updatingArtifact`, a))),
});

const lifecycleMap = {
  onDidMount: ({ onFetchArtifacts, onFetchLicenses }) => {
    onFetchArtifacts();
    onFetchLicenses();
  },
};

const Artifacts = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ArtifactsView);

export default Artifacts;

import React from 'react';
import { __ } from 'artsteps2-common';
import { withDispatch } from '../../enhancers';
import { authLogout } from '../../actions';

export const LoggedInView = ({ onLogout }) => (
  <div className="logged-in-box">
    <div className="ui horizontal divider">{__('youre_logged_in')}</div>
    <div className="ui form">
      <button onClick={onLogout} className="ui teal button">
        {__('click_to_log_out')}
      </button>
    </div>
  </div>
);

const mapDispatch = dispatch => ({ onLogout: () => dispatch(authLogout()) });

const LoggedIn = withDispatch(mapDispatch)(LoggedInView);

export default LoggedIn;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStorypointAudio = exports.getStorypointBlobURI = void 0;

var _public = _interopRequireDefault(require("artsteps2-config/public.json"));

var _media = require("./media");

var _buffer = require("./buffer");

var PATH_STORYPOINTS = '/api/storypoints';
var PATH_STORYPOINT_AUDIO = 'audio';
var FILE_SYSTEM_ROOT_URL = _public["default"].usersFileSystemUrl;

var getRootURL = function getRootURL() {
  return typeof window === 'undefined' ? process.env.ROOT_URL || 'http://localhost:3000' : "".concat(window.location.protocol, "//").concat(window.location.host);
};

var getStorypointBlobURI = function getStorypointBlobURI(storypoint, bin, path) {
  if (!bin) {
    return undefined;
  }

  if (bin.preview) {
    return bin.preview;
  }

  if (bin.content && bin.contentType) {
    return (0, _buffer.toDataURL)(bin);
  }

  return "".concat(getRootURL()).concat(PATH_STORYPOINTS, "/").concat(storypoint._id, "/").concat(path);
};

exports.getStorypointBlobURI = getStorypointBlobURI;

var getStorypointAudio = function getStorypointAudio(storypoint) {
  if (!storypoint || !storypoint.audio) {
    return undefined;
  }

  if (typeof storypoint.audio === 'string') {
    return storypoint.audio;
  }

  if (storypoint.audio.uri) {
    return (0, _media.createAudioStream)(storypoint.audio.uri);
  } // getting file from file system


  var audioEntry = storypoint.audio;
  if (audioEntry && audioEntry.file) return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(storypoint.user, "/").concat(storypoint.audio.file);
  return getStorypointBlobURI(storypoint, storypoint.audio.bin, "".concat(PATH_STORYPOINT_AUDIO, "/bin"));
};

exports.getStorypointAudio = getStorypointAudio;
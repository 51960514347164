import { Engine, Scene, Color3 } from '@babylonjs/core';
import React, { useEffect, useRef } from 'react';

const SceneComponent = ({
  antialias,
  adaptToDeviceRatio,
  sceneOptions,
  onRender,
  onSceneReady,
  files,
  ...rest
}) => {
  const reactCanvas = useRef(null);

  useEffect(() => {
    if (reactCanvas.current && files) {
      const engineOptions = { preserveDrawingBuffer: true, stencil: true, audioEngine: false };
      const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio);
      const scene = new Scene(engine, sceneOptions);
      scene.clearColor = new Color3(0, 0.8, 1);

      if (scene.isReady()) {
        onSceneReady(scene);
      } else {
        scene.onReadyObservable.addOnce(scene => onSceneReady(scene));
      }
      engine.runRenderLoop(() => {
        if (typeof onRender === 'function') {
          onRender(scene);
        }
        scene.render();
      });
      const resize = () => {
        scene.getEngine().resize();
      };
      if (window) {
        window.addEventListener('resize', resize);
        window.addEventListener('scroll', e => e.preventDefault());
      }
      return () => {
        scene.getEngine().dispose();
        if (window) {
          window.removeEventListener('resize', resize);
          window.removeEventListener('scroll', e => e.preventDefault());
        }
      };
    }
  }, [adaptToDeviceRatio, antialias, onRender, reactCanvas, sceneOptions, files]);
  return <canvas style={{ width: '100%' }} ref={reactCanvas} {...rest} />;
};

export default SceneComponent;

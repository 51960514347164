import React from 'react';
import { __ } from 'artsteps2-common';
import config from 'artsteps2-config/public.json';
import { compose, withState, withDispatch } from '../../../enhancers';
import { setUIProperty } from '../../../actions';
import { getUIProperty } from '../../../reducers';
import MyToolTip from '../../generic/MyToolTip';
import { OptionButton } from '../../../styles/GenericStyled';

export const ExhibitionQualitySettingsView = ({
  expanded,
  quality = config.defaultQuality,
  onChangeExpanded = () => true,
  onQualitySettingChange = () => Promise.resolve(undefined),
}) => {
  const qualitySettings = [__('low'), __('normal'), __('high')];
  const onButtonClick = direction => {
    let sum = direction ? 1 : -1;
    if (quality === 0 && !direction) {
      sum = qualitySettings.length - 1;
    } else if (quality === qualitySettings.length - 1 && direction) {
      sum = -(qualitySettings.length - 1);
    }
    onQualitySettingChange(quality + sum);
  };
  return (
    <div>
      {expanded === 'quality' && (
        <div className="exhibition-quality-settings">
          <h2>{__('Quality Settings')}</h2>
          <div className="quality-text">Choose the graphics fidelity.</div>
          <div className="quality-buttons-container">
            <button className="quality-button" onClick={() => onButtonClick(false)}>
              <i className="left chevron icon quality-arrow" />
            </button>
            <h5>
              <p className="quality-text">{qualitySettings[quality]}</p>
            </h5>
            <button className="quality-button" onClick={() => onButtonClick(true)}>
              <i className="right chevron icon quality-arrow" />
            </button>
          </div>
        </div>
      )}
      <MyToolTip placement="right" title={__('quality')}>
        <OptionButton
          onClick={() =>
            expanded === 'quality' ? onChangeExpanded(undefined) : onChangeExpanded('quality')
          }
        >
          <i className="ui setting icon" />
        </OptionButton>
      </MyToolTip>
    </div>
  );
};

const mapState = (state, { exhibition }) => ({
  expanded: getUIProperty(state, `exhibitions/${exhibition._id}/expanded`),
  quality: getUIProperty(state, 'quality/value'),
});

// Should Close the info settings when opening quality settings
const mapDispatch = (dispatch, { exhibition }) => ({
  onChangeExpanded: option =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/expanded`, option)),
  onQualitySettingChange: quality => dispatch(setUIProperty(`quality/value`, quality)),
});

const ExhibitionQualitySettings = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionQualitySettingsView);

export default ExhibitionQualitySettings;

import React from 'react';
import { path } from 'ramda';
import io from 'socket.io-client';
import { __ } from 'artsteps2-common';
import publicConfig from 'artsteps2-config/public.json';
import Loader from '../../generic/Loader';
import Dialog from '../../generic/Dialog';
import utils from '../../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { setUIProperty, setUIData, addMessage } from '../../../actions';
import { getUIProperty, getAuthUser, getAuthToken } from '../../../reducers';

const UNITY_PROJECT = 'player';
const UNITY_BUILD = 'Build';
const SCREENSHOT_HEIGHT = 256;
const FILE_SYSTEM_ROOT_URL = `${publicConfig.usersFileSystemUrl}`;

export const UnityWrapperView = ({
  exhibitionTitle,
  ready = false,
  progress = 0,
  error,
  unityInstanceId,
  onErrorDismissed = () => Promise.resolve(false),
  onBlur = () => Promise.resolve(false),
  onFocus = () => Promise.resolve(false),
  onScroll = () => Promise.resolve(false),
  onDragOver = event => event.preventDefault(),
  onDrop = event => event.preventDefault(),
}) => (
  <div
    id="containter-unity-frame"
    className="container unity-frame"
    tabIndex={0}
    role="link"
    onFocusCapture={onFocus}
    onBlurCapture={onBlur}
    onWheel={onScroll}
    onDragOver={onDragOver}
    onDrop={onDrop}
  >
    <div id={unityInstanceId} />

    {ready || (
      <Loader
        message={`${__('loading')} ${exhibitionTitle || ''} ${Math.floor(progress * 100)}%`}
        description={
          progress === 1
            ? `${__('unity_compiling')}
            ${__('unity_compiling_message')}`
            : null
        }
      />
    )}
    <Dialog
      open={!!error}
      title={__('unity_player_err')}
      message={error}
      type="error"
      onClose={onErrorDismissed}
    />
  </div>
);

const sendEvent = (socket, channel, id, message, token, user = { profile: {} }) => {
  socket &&
    socket.emit(
      `${id}_${channel}`,
      JSON.stringify({
        user: { _id: user._id, profile: { name: user.profile.name } },
        body: { message },
      }),
    );
};

const mapState = (state, { exhibitionId }) => ({
  unityInstanceId: 'unity',
  currentUser: getAuthUser(state),
  authToken: getAuthToken(state),
  ...getUIProperty(state, `exhibitions/${exhibitionId}`),
  quality: getUIProperty(state, 'quality/value'),
  artifactState: getUIProperty(state, 'artifacts'),
  ready: !!getUIProperty(state, `exhibitions/${exhibitionId}/importedAt`),
  initialized: !!getUIProperty(state, `exhibitions/${exhibitionId}/initializedAt`),
  selectedTemplate: getUIProperty(state, `exhibitions/${exhibitionId}/selectedTemplate`),
  isEmpty: getUIProperty(state, `exhibitions/${exhibitionId}/isEmpty`),
  requestingIsEmpty: getUIProperty(state, `exhibitions/${exhibitionId}/requestingIsEmpty`),
  onDelete: getUIProperty(state, `exhibitions/${exhibitionId}/onDelete`),
  theme: getUIProperty(state, `exhibitions/${exhibitionId}/theme`),
});

const mapDispatch = (
  dispatch,
  {
    exhibitionId,
    unityInstanceId,
    initializedAt,
    keyboardCapture,
    authToken,
    currentUser,
    progress: currentProgress,
  },
) => ({
  onImportComplete: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        importedAt: Date.now(),
        initializedAt: initializedAt || Date.now(),
      }),
    ),
  onInitialization: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/initializedAt`, Date.now())),
  onExhibitionExport: model => dispatch(setUIProperty(`exhibitions/${exhibitionId}/export`, model)),
  onProgress: progress => {
    const nextProgress = Math.round(progress * 100) / 100;
    return nextProgress === currentProgress
      ? Promise.resolve(nextProgress)
      : dispatch(setUIProperty(`exhibitions/${exhibitionId}/progress`, nextProgress));
  },
  onError: error => dispatch(setUIProperty(`exhibitions/${exhibitionId}/error`, error)),
  onReset: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        keyboardCapture: false,
        initializedAt: undefined,
        importedAt: undefined,
        progress: 0,
        playing: false,
        nextStorypoint: undefined,
        currentStorypoint: undefined,
        changeTimestamp: undefined,
        saveTimestamp: undefined,
        painting: undefined,
        wallColour: undefined,
        placingStructure: undefined,
        placingTexture: undefined,
        placingCase: undefined,
        placingArtifact: undefined,
        selectedArtifact: undefined,
        selectedArtifactInstance: undefined,
      }),
    ),
  onChangeStep: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        playing: false,
        nextStorypoint: undefined,
        currentStorypoint: undefined,
        painting: undefined,
        wallColour: undefined,
        placingStructure: undefined,
        placingTexture: undefined,
        placingCase: undefined,
        placingArtifact: undefined,
        selectedArtifact: undefined,
        selectedArtifactInstance: undefined,
      }),
    ),
  onErrorDismissed: error => dispatch(setUIProperty(`exhibitions/${exhibitionId}/error`, null)),
  onChangeExpanded: option =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/expanded`, option)),
  onArtifactDisplayed: artifactId =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayedArtifact`, artifactId)),
  onArtifactExpanded: artifactId =>
    dispatch(setUIProperty('artifacts/editingArtifactId', artifactId)),
  onArtifactSelected: (artifactId, instanceId) =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        selectedArtifact: artifactId,
        selectedArtifactInstance: instanceId,
      }),
    ),
  onArtifactDoubleClicked: (artifactId, widthFirst) => {
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        fullscreenArtifactCard: true,
        widthFirst: widthFirst !== '0',
      }),
    );
  },
  onArtifactHover: artifactId =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/hoveredArtifact`, artifactId)),
  onPlacementComplete: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingTexture: undefined,
        placingCase: undefined,
        placingArtifact: undefined,
        placingStorypoint: undefined,
        currentStorypoint: undefined,
      }),
    ),
  onStorypointArrival: storypointId =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/currentStorypoint`, storypointId)),
  onStorypointSelection: storypointId =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/selectedStorypoint`, storypointId)),
  onScreenshotTaken: screenshot =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/screenshot`, screenshot)),
  onChatEntry: message =>
    sendEvent(
      window.unity.instances[unityInstanceId].channels.socketio,
      'chat',
      exhibitionId,
      message,
      authToken,
      currentUser,
    ),
  onPlayerMovement: message =>
    sendEvent(
      window.unity.instances[unityInstanceId].channels.socketio,
      'movement',
      exhibitionId,
      message,
      authToken,
      currentUser,
    ),
  onSpawn: message =>
    sendEvent(
      window.unity.instances[unityInstanceId].channels.socketio,
      'spawn',
      exhibitionId,
      message,
      authToken,
      currentUser,
    ),
  onFocus: () => {
    if (keyboardCapture) return Promise.resolve(true);
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/keyboardCapture`, true));
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/IFrameLink`, undefined));
  },
  onBlur: () =>
    keyboardCapture
      ? dispatch(setUIProperty(`exhibitions/${exhibitionId}/keyboardCapture`, false))
      : Promise.resolve(false),
  onExhibitionChange: timestamp =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/changeTimestamp`, timestamp)),
  onExhibitionHasChanges: hasChanges =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/hasChanges`, hasChanges)),
  onUploadCubemap: images =>
    Promise.all(
      images.map(image =>
        fetch(image)
          .then(res => res.blob())
          .then(blob => URL.createObjectURL(blob)),
      ),
    ).then(uris =>
      dispatch(
        addMessage({
          title: 'cubemap',
          description: (
            <div className="cubemap">
              {uris.map(uri => (
                <div className="cubemap-image">
                  <a href={uri} key={uri} target="_blank" rel="noopener noreferrer">
                    {uri}
                  </a>
                </div>
              ))}
            </div>
          ),
        }),
      ),
    ),
  isExhibitionEmpty: isEmpty => {
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/isEmpty`, isEmpty));
  },
});

const bindUnity = ({
  exhibitionId,
  unityInstanceId,
  bindEvents = true,
  currentUser = {},
  onProgress,
  onError,
  onInitialization,
  onImportComplete,
  onExhibitionExport,
  onArtifactDisplayed,
  onArtifactSelected,
  onArtifactDoubleClicked,
  onArtifactExpanded,
  onArtifactHover,
  onPlacementComplete,
  onChatEntry,
  onPlayerMovement,
  onSpawn,
  onStorypointArrival,
  onStorypointSelection,
  onScreenshotTaken,
  displayedArtifact,
  selectedStorypoint,
  selectedArtifactInstance,
  onFocus,
  onBlur,
  onUploadCubemap,
  onExhibitionChange,
  onExhibitionHasChanges,
  currentStep = 1,
  quality,
  isExhibitionEmpty,
  onChangeExpanded,
}) => {
  if (!window.unity || !window.unity.instances || !window.unity.instances[unityInstanceId]) {
    return Promise.resolve(null);
  }
  const instance = window.unity.instances[unityInstanceId];
  window.UnityLoader.Error = window.UnityLoader.Error || {};
  window.UnityLoader.Error.handler = err =>
    onError(err.message).then(() => {
      delete window.unity.instances[unityInstanceId];
    });

  instance.channels = instance.channels || {};
  instance.onProgress = (game, progress) => onProgress(progress);
  instance.onSelectStoryPoint = storypointId =>
    selectedStorypoint !== storypointId && onStorypointSelection(storypointId);
  instance.onSelectArtifact = (artifactId, instanceId) =>
    instanceId && instanceId === selectedArtifactInstance
      ? onArtifactExpanded(artifactId, instanceId)
      : onArtifactSelected(artifactId, instanceId);
  instance.onDoubleClickArtifact = (artifactId, widthFirst) => {
    displayedArtifact !== artifactId && onArtifactDisplayed(artifactId);
    onArtifactDoubleClicked(artifactId, widthFirst);
  };
  instance.onDisplayArtifact = artifactId => {
    onChangeExpanded(null);
    displayedArtifact !== artifactId && onArtifactDisplayed(artifactId);
  };
  instance.onPlacementComplete = onPlacementComplete;
  instance.onArriveAtStoryPoint = onStorypointArrival;
  instance.onChatEntry = bindEvents ? onChatEntry : () => false;
  instance.onChange = timestamp => {
    onExhibitionChange(timestamp);
    onExhibitionHasChanges(true);
  };
  instance.onPlayerMovement =
    bindEvents && currentUser._id ? message => onPlayerMovement(JSON.parse(message)) : () => false;
  instance.onSpawn = bindEvents && currentUser._id ? onSpawn : () => false;
  instance.onUploadCubemap = cubemap =>
    onUploadCubemap(cubemap.split('.').map(image => `data:image/jpeg;base64,${image}`));

  if (!instance.IsPlayerLoaded) {
    instance.SendMessage('SceneController', 'LoadMainScene');
    instance.IsPlayerLoaded = true;
  }

  instance.onImportComplete = () => {
    setTimeout(() => onImportComplete());
    instance.SendMessage('MainController', 'SetCurrentStepInstant', currentStep);
    instance.IsPlayerLoaded = true;

    if (quality !== undefined) {
      instance.SendMessage('GraphicsController', 'ChangeGraphics', quality);
    } else {
      instance.SendMessage('GraphicsController', 'ChangeGraphics', publicConfig.defaultQuality);
    }

    if (!instance.channels.socketio && bindEvents) {
      instance.channels.socketio = io(publicConfig.socketio.path, { transports: ['websocket'] });

      instance.channels.socketio.on(`${exhibitionId}_chat`, function(data) {
        const eventData = JSON.parse(data);
        instance.SendMessage(
          'ChatPanel',
          'OnNewChatMessage',
          JSON.stringify({
            UserId: eventData.user._id,
            Name: eventData.user.profile.name,
            Body: eventData.body.message,
          }),
        );
      });

      instance.channels.socketio.on(`${exhibitionId}_movement`, function(data) {
        const eventData = JSON.parse(data);
        if (currentUser._id !== eventData.user._id) {
          instance.SendMessage(
            'PlayerManager',
            'ReceiveNetworkMovement',
            JSON.stringify({
              UserId: eventData.user._id,
              Name: eventData.user.profile.name,
              Message: eventData.body.message,
            }),
          );
        }
      });

      instance.channels.socketio.on(`${exhibitionId}_spawn`, function(data) {
        const eventData = JSON.parse(data);
        if (currentUser._id !== eventData.user._id) {
          instance.SendMessage(
            'PlayerManager',
            'Avatar',
            JSON.stringify({
              UserId: eventData.user._id,
              Name: eventData.user.profile.name,
              Delete: eventData.body.message,
            }),
          );
        }
      });
    }

    if (!bindEvents) {
      instance.SendMessage('ChatPanel', 'DisableChat');
    }

    onExhibitionHasChanges(false);
  };
  instance.onScreenshotTaken = data => onScreenshotTaken(`data:image/jpeg;base64,${data}`);
  // OnExportComplete triggered by Unity and sets exhibition/export with exhibition import
  instance.onExportComplete = model =>
    onExhibitionExport(utils.exhibition.importExhibition(utils.obj.formatProperties(model, 'js')));
  instance.onInitialization = () => {
    instance.initialized = true;
    onInitialization();
  };
  instance.IsExhibitionEmpty = isEmpty => isExhibitionEmpty(isEmpty);

  return Promise.resolve(instance);
};

const unbindUnity = ({ unityInstanceId }) => {
  if (!window.unity || !window.unity.instances || !window.unity.instances[unityInstanceId]) {
    return Promise.resolve(null);
  }

  window.UnityLoader.Error = window.UnityLoader.Error || {};
  window.UnityLoader.Error.handler = () => true;
  const instance = window.unity.instances[unityInstanceId];

  instance.onProgress = () => false;
  instance.onCreateVideo = () => false;
  instance.onSelectStoryPoint = () => false;
  instance.onSelectArtifact = () => false;
  instance.onDoubleClickArtifact = () => false;
  instance.onHoverArtifact = () => false;
  instance.onDisplayArtifact = () => false;
  instance.onPlacementComplete = () => false;
  instance.onArriveAtStoryPoint = () => false;
  instance.onImportComplete = () => false;
  instance.onScreenshotTaken = () => false;
  instance.onExportComplete = () => false;
  instance.onInitialization = () =>
    instance.SendMessage('MainController', 'CaptureKeyboardEvent', 0);
  instance.onChatEntry = () => false;
  instance.onPlayerMovement = () => false;
  instance.onSpawn(true);
  instance.onSpawn = () => false;

  //Disconnect all socket.io connections/channels
  Object.values(instance.channels || {}).forEach(channel => channel.disconnect());
  instance.channels = {};

  if (instance.IsPlayerLoaded) {
    instance.SendMessage('SceneController', 'LoadPauseScene');
    instance.IsPlayerLoaded = false;
  }
  instance.IsExhibitionEmpty = () => false;
  return Promise.resolve(instance);
};

const loadUnity = ({ unityInstanceId, currentUser }) =>
  new Promise(resolve => {
    if (!window.UnityLoader) {
      if (window.document.getElementById('unity-loader')) {
        window.document
          .getElementById('unity-loader')
          .addEventListener('load', () => resolve(loadUnity({ unityInstanceId, currentUser })), {
            once: true,
          });
        return;
      }

      const loader = window.document.createElement('script');
      loader.type = 'text/javascript';
      loader.id = 'unity-loader';
      loader.async = true;
      loader.src =
        currentUser && currentUser._id && currentUser._id === '5e76c43f95652e17e50d6251'
          ? `https://files.artsteps.com/exports/player_v1.0.0/${UNITY_BUILD}/UnityLoader.js`
          : `${publicConfig.playerUrl}/${UNITY_BUILD}/UnityLoader.js`;
      loader.addEventListener('load', () => resolve(loadUnity({ unityInstanceId, currentUser })), {
        once: true,
      });
      window.document.body.appendChild(loader);
      return;
    }

    window.UnityLoader.compatibilityCheck = (module, next) => next();
    window.unity = window.unity || {};
    window.unity.instances = window.unity.instances || {};

    if (!window.unity.instances[unityInstanceId]) {
      window.unity.instances[unityInstanceId] = window.UnityLoader.instantiate(
        unityInstanceId,
        currentUser && currentUser._id && currentUser._id === '5e76c43f95652e17e50d6251'
          ? `https://files.artsteps.com/exports/player_v1.0.0/${UNITY_BUILD}/${UNITY_PROJECT}.json`
          : `${publicConfig.playerUrl}/${UNITY_BUILD}/${UNITY_PROJECT}.json`,
      );

      window.unity.instances[unityInstanceId].httpRequest = (method, uri, body, callback) =>
        fetch(uri, body ? { method, body } : { method })
          .then(res => res.arrayBuffer())
          .then(buffer => callback(null, buffer))
          .catch(err => callback(err.message));

      resolve(window.unity.instances[unityInstanceId]);
      return;
    }

    const container = window.document.getElementById(unityInstanceId);
    if (container && container !== window.unity.instances[unityInstanceId].container) {
      container.parentNode.replaceChild(
        window.unity.instances[unityInstanceId].container,
        container,
      );
    }

    resolve(window.unity.instances[unityInstanceId]);
  });

const handleEvents = (
  instance,
  {
    exhibitionId: prevExhibitionId,
    import: prevImportModel = {},
    initialized: wasInitialized = false,
    exportingAt: prevExportTimestamp,
    capturingAt: prevCaptureTimestamp,
    playing: wasPlaying = false,
    nextStorypoint: prevStorypoint,
    currentStep: prevStep = 1,
    keyboardCapture: wasKeyboardCaptured = false,
    fullscreen: wasFullscreen = false,
    placingStructure: prevPlacingStructure,
    placingTexture: prevPlacingTexture,
    placingCase: prevPlacingCase,
    placingArtifact: prevPlacingArtifact,
    placingStorypoint: prevPlacingStorypoint,
    removingArtifact: prevRemovingArtifact,
    updatingArtifact: prevUpdatingArtifact,
    draggingStorypoint: prevDraggingStorypoint,
    draggingOverStorypoint: prevDraggingOverStorypoint,
    painting: wasPainting = false,
    paintingColour: prevPaintingColour = '#000000',
    wallColour: prevWallColour,
    selectedStorypoint: prevSelectedStorypoint,
    artifactState: prevArtifactState = {},
    quality: prevQuality,
    selectedTemplate: prevSelectedTemplate,
    requestingIsEmpty: prevRequestingIsEmpty = false,
    onDelete: prevOnDelete,
    theme: prevTheme,
  },
  {
    instanceId,
    exhibitionId,
    import: importModel = {},
    initialized = false,
    exportingAt: exportTimestamp,
    capturingAt: captureTimestamp,
    playing = false,
    bindEvents = false,
    nextStorypoint,
    currentStorypoint,
    currentStep = 1,
    keyboardCapture: keyboardCaptured = false,
    fullscreen = false,
    placingStructure,
    placingTexture,
    placingCase,
    placingArtifact,
    placingStorypoint,
    removingArtifact,
    updatingArtifact,
    draggingStorypoint,
    draggingOverStorypoint,
    painting = false,
    paintingColour = '#000000',
    wallColour,
    selectedStorypoint,
    onChangeStep,
    onImportComplete,
    currentUser = {},
    artifactState = {},
    quality,
    onExhibitionChange,
    onExhibitionHasChanges,
    selectedTemplate,
    requestingIsEmpty,
    onDelete,
    theme,
  },
) => {
  if (!instance || !instance.SendMessage) {
    return;
  }

  if (keyboardCaptured !== wasKeyboardCaptured) {
    instance.SendMessage('MainController', 'CaptureKeyboardEvent', keyboardCaptured ? 1 : 0);
  }

  if (!instance.initialized) {
    return;
  }
  if (importModel.timestamp !== prevImportModel.timestamp || (initialized && !wasInitialized)) {
    instance.SendMessage('MainController', 'DeleteExhibition');
    instance.SendMessage(
      'MainController',
      'ImportExhibition',
      JSON.stringify(utils.obj.formatProperties(importModel, 'cs')),
    );
    instance.SendMessage('MainController', 'SetCurrentStepInstant', currentStep);

    if (quality) {
      instance.SendMessage('GraphicsController', 'ChangeGraphics', quality);
    }
    if (bindEvents) {
      instance.SendMessage('ChatPanel', 'ShowChat');
      instance.onSpawn(false);
    }
    if (currentUser._id) {
      instance.SendMessage('ChatPanel', 'EnableChat');
    }
    instance.SendMessage('MainController', 'CaptureKeyboardEvent', keyboardCaptured ? 1 : 0);
    return;
  }

  if (
    nextStorypoint &&
    nextStorypoint !== currentStorypoint &&
    (nextStorypoint !== prevStorypoint || (playing && !wasPlaying))
  ) {
    instance.SendMessage('StoryTellingController', 'MoveToStoryPoint', nextStorypoint);
  }

  if (wasPlaying && !playing) {
    instance.SendMessage('NavigationController', 'StopAgent');
  }

  if (currentStep !== prevStep) {
    instance.SendMessage('MaterialController', 'StopEverything');
    instance.SendMessage('ConstructionController', 'StopPlacement');
    instance.SendMessage('MainController', 'SetCurrentStepInstant', currentStep);
    onChangeStep();
    instance.SendMessage('MainController', 'IsExhibitionEmpty');
  }

  if (placingStructure !== prevPlacingStructure) {
    instance.SendMessage(
      'ConstructionController',
      placingStructure ? `Toggle${placingStructure}Placement` : 'StopPlacement',
    );
  }

  if (placingTexture !== prevPlacingTexture) {
    instance.SendMessage('MaterialController', 'StopEverything');
  }

  if (placingTexture !== prevPlacingTexture && placingTexture) {
    setTimeout(() =>
      instance.SendMessage('MaterialController', 'StartWallTexturing', placingTexture),
    );
  }

  if (placingCase !== prevPlacingCase) {
    instance.SendMessage('ArtifactController', 'StopPlacement');
  }

  if (placingCase !== prevPlacingCase && placingCase) {
    setTimeout(() =>
      instance.SendMessage('ArtifactController', 'StartDisplayPlacement', placingCase),
    );
  }

  if (placingArtifact !== prevPlacingArtifact) {
    instance.SendMessage('ArtifactController', 'StopPlacement');
  }

  if (placingArtifact !== prevPlacingArtifact && placingArtifact && placingArtifact.artifactId) {
    const methods = {
      image: 'GetPainting',
      video: 'GetVideo',
      object: 'Get3dObject',
      text: 'GetText',
    };
    const controllerMethod = methods[placingArtifact.artifactType];
    if (controllerMethod) {
      setTimeout(() =>
        instance.SendMessage(
          'ArtifactController',
          controllerMethod,
          JSON.stringify(utils.obj.formatProperties(placingArtifact, 'cs')),
        ),
      );
    }
  }

  if (quality !== prevQuality) {
    instance.SendMessage('GraphicsController', 'ChangeGraphics', quality);
  }

  if (selectedTemplate !== prevSelectedTemplate) {
    instance.SendMessage(
      'ConstructionController',
      'LoadTemplate',
      JSON.stringify(
        utils.obj.formatProperties(
          {
            ...selectedTemplate,
            templateId: selectedTemplate && selectedTemplate._id,
            uri: selectedTemplate && `${FILE_SYSTEM_ROOT_URL}${selectedTemplate.uri}`,
          },
          'cs',
        ),
      ),
    );
    onExhibitionChange(Date.now());
    onExhibitionHasChanges(true);
  }

  if (requestingIsEmpty !== prevRequestingIsEmpty || !prevRequestingIsEmpty) {
    instance.SendMessage('MainController', 'IsExhibitionEmpty');
  }

  if (onDelete !== prevOnDelete) {
    instance.SendMessage('MainController', 'DeleteExhibition');
  }

  if (
    selectedStorypoint !== prevSelectedStorypoint &&
    selectedStorypoint &&
    selectedStorypoint.storyPointId
  ) {
    instance.SendMessage('StoryTellingController', 'StopPlacement');
    instance.SendMessage('StoryTellingController', 'SelectPoint', selectedStorypoint.storyPointId);
  }

  if (
    placingStorypoint !== prevPlacingStorypoint &&
    placingStorypoint &&
    placingStorypoint.storyPointId
  ) {
    instance.SendMessage('StoryTellingController', 'StopPlacement');
    setTimeout(() =>
      instance.SendMessage(
        'StoryTellingController',
        'AddStoryPoint',
        JSON.stringify(utils.obj.formatProperties(placingStorypoint, 'cs')),
      ),
    );
  }

  if (prevDraggingStorypoint && !draggingStorypoint && draggingOverStorypoint) {
    instance.SendMessage(
      'StoryTellingController',
      'EditStoryPoint',
      JSON.stringify(
        utils.obj.formatProperties(
          {
            ...prevDraggingStorypoint,
            pointOrder: draggingOverStorypoint.pointOrder,
          },
          'cs',
        ),
      ),
    );
    instance.SendMessage(
      'StoryTellingController',
      'EditStoryPoint',
      JSON.stringify(
        utils.obj.formatProperties(
          {
            ...draggingOverStorypoint,
            pointOrder: prevDraggingStorypoint.pointOrder,
          },
          'cs',
        ),
      ),
    );
  }

  if (wasPainting && !painting) {
    instance.SendMessage('MaterialController', 'StopEverything');
  }

  if (painting && (!wasPainting || paintingColour !== prevPaintingColour)) {
    instance.SendMessage('MaterialController', 'StartPainting', `${paintingColour}00`);
  }

  // Appyling theme
  if (prevTheme !== theme) {
    instance.SendMessage(
      'MaterialController',
      'ApplyColorTheme',
      JSON.stringify(utils.obj.formatProperties(theme, 'cs')),
    );
  }

  if (wallColour && wallColour !== prevWallColour) {
    instance.SendMessage('MaterialController', 'ChangeEveryWallColor', `${wallColour}00`);
    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
      .exec(wallColour)
      .splice(1, 3)
      .map(c => parseInt(c, 16));
    const theme = 2 - Math.floor(rgb.reduce((a, b) => a + b, 0) / 256);
    instance.SendMessage('MaterialController', 'ChangeColorTheme', theme);
  }

  // Export exhibition when exportingAt value changes
  if (exportTimestamp && exportTimestamp !== prevExportTimestamp) {
    instance.SendMessage('MainController', 'ExportExhibition');
  }

  if (captureTimestamp && captureTimestamp !== prevCaptureTimestamp) {
    const canvas = instance.container;
    const height = SCREENSHOT_HEIGHT;
    const width = parseInt(height * (canvas.offsetWidth / canvas.offsetHeight), 10);
    instance.SendMessage('MainController', 'FpCameraScreenShot', `${width}x${height}`);
  }

  if (removingArtifact && removingArtifact.timestamp !== (prevRemovingArtifact || {}).timestamp) {
    instance.SendMessage(
      'MainController',
      'DeleteArtifact',
      JSON.stringify(utils.obj.formatProperties(removingArtifact, 'cs')),
    );
  }

  if (updatingArtifact && updatingArtifact.timestamp !== (prevUpdatingArtifact || {}).timestamp) {
    instance.SendMessage(
      'MainController',
      'UpdateArtifact',
      JSON.stringify(utils.obj.formatProperties(updatingArtifact, 'cs')),
    );
  }

  Object.keys(artifactState)
    .filter(
      id =>
        path([id, 'playback'], artifactState) !== path([id, 'playback'], prevArtifactState) ||
        path([id, 'volume'], artifactState) !== path([id, 'volume'], prevArtifactState),
    )
    .forEach(id =>
      instance.SendMessage(
        'ArtifactController',
        'SetVideoState',
        JSON.stringify({
          ArtifactId: id,
          Volume: artifactState[id].volume,
          Play: artifactState[id].playback || artifactState[id].playback === undefined,
        }),
      ),
    );
};

const lifecycleMap = {
  onDidMount: props =>
    props
      .onReset()
      .then(() => loadUnity(props))
      .then(() => bindUnity(props)),
  onDidUpdate: (prevProps, props) =>
    loadUnity(props)
      .then(() => bindUnity(props))
      .then(instance => handleEvents(instance, prevProps, props)),
  onWillUnmount: props => props.onBlur().then(() => unbindUnity(props)),
};

const UnityWrapper = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(UnityWrapperView);

export default UnityWrapper;

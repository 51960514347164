import React from 'react';
import { __ } from 'artsteps2-common';
import i18nextInstance from 'artsteps2-common';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { ReactComponent as LoginIcon } from '../../styles/images/loginIcon.svg';
import UserDropDown from './UserDropDown';
import CuratorButtons from './DefaultButtons';
import PaymentIcon from '@material-ui/icons/Payment';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    top: '68px!important',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '200px',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

export default function SideButtons(props) {
  const loggedinOptions = (currentUser, action) => [
    {
      path: `/profile/${currentUser._id}`,
      name: 'My Exhibitions',
      action: action[0],
      icon: <FolderOpenIcon />,
    },
    {
      path: `/edit-profile/${currentUser._id}`,
      name: 'Edit Profile',
      action: action[0],
      icon: <AccountCircleIcon />,
    },
    {
      path: `/change-password`,
      name: __('change_password'),
      action: action[0],
      icon: <VpnKeyIcon />,
    },
    {
      path: `/privacy-settings`,
      name: __('delete_account'),
      action: action[0],
      icon: <DeleteForeverIcon />,
    },
    {
      name: `Lang: ${i18nextInstance.language === 'en' ? 'FR' : 'EN'}`,
      action: () => {
        action[0]();
        action[1]();
      },
      icon: <LanguageIcon />,
    },
    {
      name: 'Contribute',
      action: () => props.onToggleDonateModal(true),
      icon: <PaymentIcon />,
      fillcolor: '#d56d69',
    },
    {
      path: `/logout`,
      name: __('logout'),
      action: action[0],
      icon: <LoginIcon />,
    },
  ];

  const loggedOutOptions = action => [
    {
      name: 'Donate',
      action: () => props.onToggleDonateModal(true),
      icon: <PaymentIcon />,
    },
    {
      name: `Lang: ${i18nextInstance.language === 'en' ? 'FR' : 'EN'}`,
      action: () => {
        action[0]();
        action[1]();
      },
      icon: <LanguageIcon />,
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState();

  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid
      item
      direction="row"
      alignItems="center"
      xl={6}
      lg={!props.curatorView ? 3 : 6}
      md={!props.curatorView ? 2 : 12}
      sm={!props.curatorView ? 12 : 12}
      container
      justify="flex-end"
      wrap="nowrap"
      style={{ width: 'auto', minWidth: 'fit-content' }}
    >
      <CuratorButtons
        exhibitionId={props.exhibitionId}
        handleMenu={handleMenu}
        navbarView={props.navbarView}
        width={props.width}
        currentUser={props.currentUser}
      />

      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <UserDropDown
          loggedinOptions={loggedinOptions}
          loggedOutOptions={loggedOutOptions}
          handleClose={handleClose}
          currentUser={props.currentUser}
          isPrivateSpace={props.isPrivateSpace}
        />
      </StyledMenu>
    </Grid>
  );
}

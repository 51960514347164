import React, { useEffect, useState } from 'react';

import Artifact from './Artifact';
import { Text, VerticalItems } from '../../../styles/GenericStyled';
import ArtifactFolder from './ArtifactFolder';
import { compose, withState } from '../../../enhancers';
import { getUIProperty } from '../../../reducers';
import { ItemList } from '../../exhibitions/editor/ExhibitionEditStyled';

export const ArtifactLibraryView = ({
  artifacts = [],
  controls = false,
  exhibitionId,
  showInFolders,
}) => {
  const [folders, setFolders] = useState({ 'no tag': [] });

  useEffect(() => {
    // Making an object that has the tag as the object key
    // and an aray of artifact-objects as the value
    const tmpFolders = { 'no tag': [] };

    if (artifacts && artifacts.length > 0) {
      artifacts.forEach(artifact => {
        if ((artifact.tags && artifact.tags.length === 0) || !artifact.tags)
          tmpFolders['no tag'] = [...tmpFolders['no tag'], artifact];
        else
          artifact.tags.forEach(tag => {
            tmpFolders[tag] =
              tmpFolders[tag] instanceof Array ? [...tmpFolders[tag], artifact] : [artifact];
          });
      });
      setFolders(tmpFolders);
    }
  }, [artifacts]);

  return showInFolders ? (
    <VerticalItems>
      {Object.keys(folders).length > 0 ? (
        Object.keys(folders)
          .sort()
          .sort((x, y) => (x === 'no tag' ? -1 : y === 'no tag' ? 1 : 0))
          .map(
            (folder, idx) =>
              folders[folder].length > 0 && (
                <ArtifactFolder
                  key={idx}
                  folder={folder}
                  artifacts={folders[folder]}
                  controls={controls}
                  exhibitionId={exhibitionId}
                />
              ),
          )
      ) : (
        <Text type="body2">
          No folders yet. Folders are created when you add tags to an artifact!
        </Text>
      )}
    </VerticalItems>
  ) : (
    <ItemList>
      {artifacts.map(
        artifact =>
          artifact._id && (
            <Artifact
              key={artifact._id}
              artifact={artifact}
              controls={controls}
              exhibitionId={exhibitionId}
            />
          ),
      )}
    </ItemList>
  );
};

const mapState = state => ({
  selectedFolder: getUIProperty(state, 'selectedFolder'),
});

const ArtifactLibrary = compose(withState(mapState))(ArtifactLibraryView);

export default ArtifactLibrary;

import React from 'react';
import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';
import { BlueSwitch } from '../forms/FormStyled';

const CanvasField = ({ handleChange, checked = true, formData }) => {
  return (
    <HorizontalItems style={{ width: '100%', marginTop: '-16px' }}>
      <Text>Image has canvas</Text>
      <BlueSwitch
        checked={checked}
        onChange={e =>
          handleChange({
            target: { name: 'model', value: { ...formData.model, hasCanvas: e.target.checked } },
          })
        }
        name="model.hasCanvas"
      />
      <Text style={{ marginLeft: '10px' }} mycolor={colors.ourRed}>
        {checked ? '(Image will have canvas)' : '(Image will not have canvas)'}
      </Text>
    </HorizontalItems>
  );
};

export default CanvasField;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "i18next", {
  enumerable: true,
  get: function get() {
    return _i18next["default"];
  }
});
exports.__ = exports.init = void 0;

var _i18next = _interopRequireDefault(require("i18next"));

var _en = _interopRequireDefault(require("../i18n/en.json"));

var _fr = _interopRequireDefault(require("../i18n/fr.json"));

var _en2 = _interopRequireDefault(require("../i18n/datetime/en.json"));

var _fr2 = _interopRequireDefault(require("../i18n/datetime/fr.json"));

var _en3 = _interopRequireDefault(require("../i18n/intro/en.json"));

var _fr3 = _interopRequireDefault(require("../i18n/intro/fr.json"));

var _en4 = _interopRequireDefault(require("../i18n/regions/en.json"));

var _en5 = _interopRequireDefault(require("../i18n/emails/en.json"));

var _fr4 = _interopRequireDefault(require("../i18n/emails/fr.json"));

var _en6 = _interopRequireDefault(require("../i18n/tutorials/en.json"));

var _fr5 = _interopRequireDefault(require("../i18n/tutorials/fr.json"));

var _i18nextBrowserLanguagedetector = _interopRequireDefault(require("i18next-browser-languagedetector"));

var init = function init(options, callback) {
  return _i18next["default"].use(_i18nextBrowserLanguagedetector["default"]).init(Object.assign({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: Object.assign({}, _en["default"], {
          intro: _en3["default"],
          datetime: _en2["default"],
          regions: _en4["default"],
          emails: _en5["default"],
          tutorials: _en6["default"]
        })
      },
      fr: {
        translation: Object.assign({}, _fr["default"], {
          intro: _fr3["default"],
          datetime: _fr2["default"],
          regions: _en4["default"],
          emails: _fr4["default"],
          tutorials: _fr5["default"]
        })
      }
    }
  }, options), callback);
};

exports.init = init;

var __ = function __(key, options) {
  return _i18next["default"].t(key, options);
};

exports.__ = __;
import React from 'react';
import { __ } from 'artsteps2-common';
import MetaTags from '../components/layouts/MetaTags';
import FullLayout from '../components/layouts/FullLayout';
import { withState } from '../enhancers';
import { getUIProperty } from '../reducers';
import { BodyWithPadding } from '../styles/GenericStyled';
import RegisterForm from '../modal_content/RegisterForm';
import useWindowSize from '../hooks/windowSize';

const RegistrationPageView = () => {
  const size = useWindowSize();
  return (
    <FullLayout>
      <MetaTags title={__('register')} description={__('register_subheader')} />
      <div className="login-form">
        <div className="ui two column stackable grid">
          {size.width >= 942 && (
            <div className="stretched column cover-column">
              <div className="ui basic segment">
                <h1 className="ui header white-text">{__('register_subheader')}</h1>
                <h4>{__('register_header')}</h4>
              </div>
            </div>
          )}
          <BodyWithPadding padding="30px" style={{ margin: 'auto', scroll: 'auto' }}>
            <RegisterForm page />
          </BodyWithPadding>
        </div>
      </div>
    </FullLayout>
  );
};

const mapState = state => ({ isPrivateSpace: getUIProperty(state, 'isPrivateSpace') });

const RegistrationPage = withState(mapState)(RegistrationPageView);

export default RegistrationPage;

import React from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import ExhibitionAccessibilityFrame from '../components/exhibitions/viewer/ExhibitionAccessibilityFrame';
import ExhibitionMeta from '../components/exhibitions/viewer/ExhibitionMeta';
import ExhibitionInfo from '../components/exhibitions/viewer/ExhibitionInfo';

const ExhibitionAccessibilityPage = ({ exhibitionId }) => (
  <DefaultLayout exhibitionId={exhibitionId}>
    <ExhibitionMeta exhibitionId={exhibitionId} />
    <div className="exhibition-outer-container">
      <ExhibitionAccessibilityFrame exhibitionId={exhibitionId} />
      <div className="ui divider" />
      <ExhibitionInfo exhibitionId={exhibitionId} />
    </div>
  </DefaultLayout>
);

export default ExhibitionAccessibilityPage;

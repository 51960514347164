import React, { useEffect, useRef, useState } from 'react';
import { __ } from 'artsteps2-common';
import { Redirect } from 'react-router';

import config from 'artsteps2-config/public.json';
import MetaTags from '../components/layouts/MetaTags';
import Link from '../components/generic/Link';
import DefaultLayout from '../components/layouts/DefaultLayout';
import FeaturedSection from '../components/generic/FeaturedSection';
import AppIconsSection from '../components/generic/AppIconsSection';

import PanoramaViewer from '../components/generic/PanoramaViewer';
import ExhibitionList from '../components/exhibitions/lists/ExhibitionList';
import {
  API_KEYS,
  getUIProperty,
  getApiResource,
  getAuthUser,
  getApiStatus,
  API_STATUS,
} from '../reducers';
import { withState, compose, withDispatch } from '../enhancers';
import { setUIProperty } from '../actions';
import designSampleSrc from '../styles/images/features/samples/design-virtual-exhibitions.png';
import inspireSampleSrc from '../styles/images/features/samples/inspire-and-get-inspired.png';
import panorama1 from '../styles/images/panorama-covers/cover1.jpg';
import panorama2 from '../styles/images/panorama-covers/cover2.jpg';
import panorama3 from '../styles/images/panorama-covers/cover3.jpg';
import panorama4 from '../styles/images/panorama-covers/cover4.jpg';
import panorama5 from '../styles/images/panorama-covers/cover5.jpg';

import MaintenanceBanner from '../components/banners/maintenanceBanner';
import UverifiedNotificationModal from '../components/banners/UnverifiedNotificationModal';

import Background from '../components/privateSpaces/Background';
import { FullLine, StyledButton, Text } from '../styles/GenericStyled';
import colors from '../styles/colors';
import VerifyModal from '../components/modals/VerifyModal';
import { Grid } from '@material-ui/core';
import useWindowSize from '../hooks/windowSize';

const GOOGLE_PLAY_URI = config.applications.playURI;
const APP_STORE_URI = config.applications.itunesURI;

const EXHIBITION_LIMIT = 8;
const HomePageView = ({
  isPrivateSpace,
  space = {},
  unverifiedOpen = false,
  currentUser,
  ready = false,
  onUnverifiedNotificationClosed = () => Promise.resolve(false),
  setOpenLogin,
  setOpenRegister,
  spaceSettings,
}) => {
  const from = new URL(window.location.href).searchParams.get('from');
  const { width, height } = useWindowSize();

  const [panoramaSrc, setPanoramaSrc] = useState(null);
  const panoramas = [panorama1, panorama2, panorama3, panorama4, panorama5];

  const randomPanorama = () =>
    setPanoramaSrc(panoramas[Math.floor(Math.random() * panoramas.length)]);

  useEffect(randomPanorama, []);

  const showBlack = !(height > 710 && width > 767);

  let exploreRef = useRef(null);
  let createRef = useRef(null);
  let expandRef = useRef(null);
  let connectRef = useRef(null);

  if (ready && isPrivateSpace && !currentUser && !space.title) {
    return <Redirect to="/login" />;
  }

  return (
    <DefaultLayout>
      <MetaTags title={__('intro.preview_header1')} description={__('intro.preview_header2')} />
      {from === 'register' && !currentUser && <VerifyModal />}
      {isPrivateSpace && (
        <div style={{ width: '100%' }} className="homepage-private-container">
          <Background space={space} />
        </div>
      )}
      {!isPrivateSpace ? (
        <FeaturedSection
          setOpenRegister={setOpenRegister}
          isPrivateSpace={isPrivateSpace}
          exploreRef={exploreRef}
          createRef={createRef}
          expandRef={expandRef}
          connectRef={connectRef}
          showBlack={showBlack}
          width={width}
          title={__('intro.preview_header1')}
          label={__('intro.preview_header2')}
          button={{
            text: __('get_started'),
            href: '/register',
          }}
        >
          <AppIconsSection googlePlayURI={GOOGLE_PLAY_URI} appStoreURI={APP_STORE_URI} />
          <PanoramaViewer uri={panoramaSrc} />
        </FeaturedSection>
      ) : (
        <div className="homepage-header-container">
          <h1 className="ui inverted header homepage-header-title">{space.title}</h1>
          <h3 className="ui inverted header homepage-header-subtitle">{space.subtitle}</h3>
        </div>
      )}

      {process.env.REACT_APP_SHOW_BANNER === 'TRUE' && <MaintenanceBanner />}

      {!isPrivateSpace && showBlack && (
        <div
          style={{
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '40px',
            marginTop: '40px',
          }}
        >
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={12}
              style={{ textShadow: '0 0 black', cursor: 'pointer' }}
              onClick={() => exploreRef.current.scrollIntoView()}
            >
              <Text type="sub1" align="center" mycolor="black">
                {__('intro.feature_explore')}
              </Text>
              <div
                className="features"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '10px',
                  marginBottom: '0px',
                  width: '84px',
                  paddingLeft: '5px',
                }}
              >
                <div className="feature explore" />
              </div>
              <Text type="bod1" align="center" mycolor="black" style={{ fontSize: '15px' }}>
                {__('intro.feature_explore_text')}
              </Text>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={12}
              style={{ textShadow: '0 0 black', cursor: 'pointer' }}
              onClick={() => createRef.current.scrollIntoView()}
            >
              <Text type="sub1" align="center" mycolor="black">
                {__('intro.feature_create')}
              </Text>
              <div
                className="features"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '10px',
                  marginBottom: '0px',
                  width: '84px',
                  paddingLeft: '5px',
                }}
              >
                <div className="feature create" />
              </div>
              <Text type="bod1" align="center" mycolor="black" style={{ fontSize: '15px' }}>
                {__('intro.feature_create_text')}
              </Text>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={12}
              style={{ textShadow: '0 0 black', cursor: 'pointer' }}
              onClick={() => expandRef.current.scrollIntoView()}
            >
              <Text type="sub1" align="center" mycolor="black">
                {__('intro.feature_expand')}
              </Text>
              <div
                className="features"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '10px',
                  marginBottom: '0px',
                  width: '84px',
                  paddingLeft: '5px',
                }}
              >
                <div className="feature expand" />
              </div>
              <Text type="bod1" align="center" mycolor="black" style={{ fontSize: '15px' }}>
                {__('intro.feature_expand_text')}
              </Text>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={12}
              style={{ textShadow: '0 0 black', cursor: 'pointer' }}
              onClick={() => connectRef.current.scrollIntoView()}
            >
              <Text type="sub1" align="center" mycolor="black">
                {__('intro.feature_socialize')}
              </Text>
              <div
                className="features"
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '10px',
                  marginBottom: '0px',
                  width: '84px',
                  paddingLeft: '5px',
                }}
              >
                <div className="feature socialize" />
              </div>
              <Text type="bod1" align="center" mycolor="black" style={{ fontSize: '15px' }}>
                {__('intro.feature_socialize_text')}
              </Text>
            </Grid>
          </Grid>
          <br />
          <br />
        </div>
      )}

      <div ref={exploreRef} />
      <br />
      <br />

      <div
        className="ui segment basic home-page"
        style={{ marginTop: isPrivateSpace ? '5rem' : '1rem' }}
      >
        <div className="ui padded segment basic vertical">
          <h2 className="ui header">{__('intro.header_explore')}</h2>
          <ExhibitionList
            wide
            usernames
            sort="-trending"
            filter={!isPrivateSpace ? { published: true } : { publishedPrivateSpace: true }}
            // if we populate categories we get nothing
            include={[
              'categories',
              'description',
              `views.${API_KEYS.META}.totalCount`,
              `likes.${API_KEYS.META}.totalCount`,
              'visits',
              'rating',
              '_v1Rating',
            ]}
            populate={['user']}
            currentUser={currentUser}
            limit={EXHIBITION_LIMIT}
          />

          <div className="pager-buttons">
            <Link to="/explore">
              <StyledButton
                type="secondary"
                mycolor={colors.ourBlack}
                background={colors.ourWhite}
                backgroundhover={colors.ourBlack}
                mycolorhover={colors.ourWhite}
                bordercolor="black"
              >
                {__('explore_exhibitions')}
              </StyledButton>
            </Link>
          </div>
        </div>

        {(!spaceSettings || spaceSettings.showTour) && (
          <>
            <div ref={createRef} />
            <FullLine />
            <br />
            <br />
            <h2 className="ui header">{__('intro.header_design')}</h2>
            <div className="ui padded segment basic vertical section design">
              <div className="ui relaxed stackable grid">
                <div className="ui six wide column feature-text-left">
                  <div className="features">
                    <div className="feature space">
                      <h3 className="ui header">{__('intro.feature_space')}</h3>
                      <p>{__('intro.feature_space_text')}</p>
                    </div>
                    <div className="feature content">
                      <h3 className="ui header">{__('intro.feature_content')}</h3>
                      <p>{__('intro.feature_content_text')}</p>
                    </div>
                    <div className="feature stories">
                      <h3 className="ui header">{__('intro.feature_stories')}</h3>
                      <p>{__('intro.feature_stories_text')}</p>
                    </div>
                  </div>
                </div>
                <div className="ui ten wide column">
                  <img className="ui image image-right" src={designSampleSrc} alt="" />
                </div>
              </div>
            </div>
            <br/>
            <div className="pager-buttons">
              <Link to="/tour">
                <StyledButton
                  type="secondary"
                  background={colors.ourWhite}
                  mycolor={colors.ourBlack}
                  backgroundhover={colors.ourBlack}
                  mycolorhover={colors.ourWhite}
                  bordercolor={colors.ourBlack}
                >
                  {__('Take the Tour')}
                </StyledButton>
              </Link>
            </div>

            <div ref={expandRef} />
            <br />
            <FullLine />
            <br />
            <br />

            <div
              style={{
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '40px',
                marginTop: '20px',
              }}
            >
              <h2 className="ui header">{__('intro.header_customize')}</h2>
              <br />
              <br />
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ textShadow: '0 0 black', minWidth: '210px' }}
                >
                  <Link to="/privatespaces">
                    <Text type="sub2" align="center" mycolor="black">
                      {__('intro.private-space-lock_title')}
                    </Text>
                    <div
                      className="features"
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '84px',
                      }}
                    >
                      <div className="feature private-space-lock" />
                    </div>
                    <Text type="body1" align="center" mycolor="black">
                      {__('intro.private-space-lock_text')}
                    </Text>
                  </Link>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ textShadow: '0 0 black', minWidth: '210px' }}
                >
                  <Link to="custom-space-3d">
                    <Text type="sub2" align="center" mycolor="black">
                      {__('intro.custom-3d-space_title')}
                    </Text>
                    <div
                      className="features"
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '84px',
                      }}
                    >
                      <div className="feature custom-3d-space" />
                    </div>
                    <Text type="body1" align="center" mycolor="black">
                      {__('intro.custom-3d-space_text')}
                    </Text>
                  </Link>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ textShadow: '0 0 black', minWidth: '210px' }}
                >
                  <Link to="curatorial-service">
                    <Text type="sub2" align="center" mycolor="black">
                      {__('intro.curatorial_title')}
                    </Text>
                    <div
                      className="features"
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '84px',
                      }}
                    >
                      <div className="feature curatorial" />
                    </div>
                    <Text type="body1" align="center" mycolor="black">
                      {__('intro.curatorial_text')}
                    </Text>
                  </Link>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ textShadow: '0 0 black', minWidth: '210px' }}
                >
                  <Link to="virtual-events">
                    <Text type="sub2" align="center" mycolor="black">
                      {__('intro.virtual-events_title')}
                    </Text>
                    <div
                      className="features"
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '84px',
                      }}
                    >
                      <div className="feature virtual-events" />
                    </div>
                    <Text type="body1" align="center" mycolor="black">
                      {__('intro.virtual-events_text')}
                    </Text>
                  </Link>
                </Grid>
              </Grid>
            </div>

            <br />
            <div ref={connectRef} />

            <FullLine />

            <br />
            <br />

            <div className="ui padded segment basic vertical section inspire">
              <div className="ui relaxed stackable grid mobile vertically reversed">
                <div className="ui ten wide column">
                  <img className="ui image image-left" src={inspireSampleSrc} alt="" />
                </div>
                <div className="ui six wide column feature-text-right">
                  <h2 className="ui header">{__('intro.header_inspire')}</h2>
                  <div className="features">
                    <div className="feature share">
                      <h3 className="ui header">{__('intro.feature_share')}</h3>
                      <p>{__('intro.feature_share_text')}</p>
                    </div>
                    <div className="feature social">
                      <h3 className="ui header">{__('intro.feature_social')}</h3>
                      <p>{__('intro.feature_social_text')}</p>
                    </div>
                    <div className="feature engage">
                      <h3 className="ui header">{__('intro.feature_engage')}</h3>
                      <p>{__('intro.feature_engage_text')}</p>
                    </div>
                    <div className="feature embed">
                      <h3 className="ui header">{__('intro.feature_embed')}</h3>
                      <p>{__('intro.feature_embed_text')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pager-buttons">
                <Link to="/tour">
                  <StyledButton
                    type="secondary"
                    background={colors.ourWhite}
                    mycolor={colors.ourBlack}
                    backgroundhover={colors.ourBlack}
                    mycolorhover={colors.ourWhite}
                    bordercolor={colors.ourBlack}
                  >
                    {__('Take the Tour')}
                  </StyledButton>
                </Link>
              </div>
            </div>
          </>
        )}
        {!isPrivateSpace && (
          <div className="call-to-action message">
            <div className="ui container">
              <Text style={{ marginRight: '10px' }} type="h5" mycolor={colors.ourWhite}>
                {__('intro.call_to_action')}
              </Text>
              <br />
              <StyledButton
                onClick={() => setOpenLogin(true)}
                type="secondary"
                mycolor={colors.ourWhite}
                mycolorhover={colors.ourBlack}
                background={colors.ourBlack}
                backgroundhover={colors.ourWhite}
                bordercolor={colors.ourWhite}
              >
                {__('get_started')}
              </StyledButton>
            </div>
          </div>
        )}
      </div>
      <UverifiedNotificationModal
        open={
          !!(
            unverifiedOpen &&
            currentUser &&
            currentUser.services.password &&
            currentUser.emails &&
            !currentUser.emails[0].verified
          )
        }
        onClose={onUnverifiedNotificationClosed}
      />
    </DefaultLayout>
  );
};

const mapState = state => ({
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  spaceSettings: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`).settings,
  unverifiedOpen: getUIProperty(state, 'unverifiedNotificationOpen'),
  currentUser: getAuthUser(state),
  ready:
    getApiStatus(state, `spaces/${window.location.hostname.split('.')[0]}`) === API_STATUS.IDLE,
});

const mapDispatch = dispatch => ({
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  onUnverifiedNotificationClosed: () =>
    dispatch(setUIProperty('unverifiedNotificationOpen', false)),
});

const HomePage = compose(withState(mapState), withDispatch(mapDispatch))(HomePageView);

export default HomePage;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortKeys = exports.formatProperties = exports.formatProperty = exports.toJSProp = exports.toCSProp = exports.merge = exports.collapse = exports.setProperty = exports.getProperty = exports.assign = exports.filter = exports.expand = exports.isObject = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var isObject = function isObject(obj) {
  return obj && Object.getPrototypeOf(obj) === Object.prototype;
};

exports.isObject = isObject;

var expand = function expand(obj) {
  var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.';
  var prefix = arguments.length > 2 ? arguments[2] : undefined;
  return Object.keys(obj).reduce(function (expanded, key) {
    return Object.assign(expanded, isObject(obj[key]) ? expand(obj[key], separator, "".concat(prefix || '').concat(key).concat(separator)) : (0, _defineProperty2["default"])({}, "".concat(prefix || '').concat(key), obj[key]));
  }, {});
};

exports.expand = expand;

var filter = function filter(obj, match) {
  return Object.keys(obj).reduce(function (filtered, key) {
    var prop = match(key, obj[key]);

    if (!prop && !isObject(obj[key])) {
      return filtered;
    }

    prop = typeof prop === 'string' ? prop : key;
    var value = isObject(obj[key]) ? filter(obj[key], match) : obj[key];
    return Object.assign(filtered, !isObject(value) || Object.keys(value).length ? (0, _defineProperty2["default"])({}, prop, value) : {});
  }, {});
};

exports.filter = filter;

var assign = function assign(target) {
  for (var _len = arguments.length, sources = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    sources[_key - 1] = arguments[_key];
  }

  sources.forEach(function (source) {
    Object.keys(source || {}).forEach(function (k) {
      if (isObject(source[k])) {
        target[k] = assign(target[k] || {}, source[k]);
        return;
      }

      target[k] = source[k];
    });
  });
  return target;
};

exports.assign = assign;

var getProperty = function getProperty(source, property) {
  if (typeof property === 'string') {
    return getProperty(source, property.split('.'));
  }

  if (!isObject(source) && !Array.isArray(source)) {
    return undefined;
  }

  var _property = (0, _toArray2["default"])(property),
      head = _property[0],
      path = _property.slice(1);

  if (path.length) {
    return getProperty(source[head], path);
  }

  return source[head];
};

exports.getProperty = getProperty;

var setProperty = function setProperty(source, property, value) {
  if (!isObject(source) && !Array.isArray(source)) {
    return source;
  }

  if (typeof property === 'string') {
    return setProperty(source, property.split('.'), value);
  }

  var _property2 = (0, _toArray2["default"])(property),
      head = _property2[0],
      path = _property2.slice(1);

  if (path.length) {
    source[head] = source[head] || {};
    setProperty(source[head], path, value);
    return source;
  }

  source[head] = value;
  return source;
};

exports.setProperty = setProperty;

var collapse = function collapse(obj) {
  var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.';
  return Object.keys(obj).reduce(function (collapsed, key) {
    return setProperty(collapsed, key.split(separator), obj[key]);
  }, {});
};

exports.collapse = collapse;

var merge = function merge() {
  for (var _len2 = arguments.length, sources = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    sources[_key2] = arguments[_key2];
  }

  return collapse(Object.assign.apply(Object, (0, _toConsumableArray2["default"])(sources.map(function (s) {
    return expand(s, '\0');
  }))), '\0');
};

exports.merge = merge;

var toCSProp = function toCSProp(prop) {
  return prop[0].toUpperCase() + prop.substring(1);
};

exports.toCSProp = toCSProp;

var toJSProp = function toJSProp(prop) {
  return prop[0].toLowerCase() + prop.substring(1);
};

exports.toJSProp = toJSProp;

var formatProperty = function formatProperty(property, convention) {
  if (['cs', 'c#', 'pascal'].indexOf((convention || '').toLowerCase()) > -1) {
    return toCSProp(property);
  }

  if (['js', 'java', 'javascript'].indexOf((convention || '').toLowerCase()) > -1) {
    return toJSProp(property);
  }

  return property;
};

exports.formatProperty = formatProperty;

var formatProperties = function formatProperties(obj, format) {
  var item = obj && obj.toObject ? obj.toObject() : obj;

  if (Array.isArray(item)) {
    return item.map(function (i) {
      return formatProperties(i, format);
    });
  }

  if (!isObject(item)) {
    return item;
  }

  return Object.keys(item).reduce(function (target, k) {
    return Object.assign(target, (0, _defineProperty2["default"])({}, formatProperty(k, format), formatProperties(item[k], format)));
  }, {});
};

exports.formatProperties = formatProperties;

var sortKeys = function sortKeys() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.keys(obj).sort().reduce(function (sorted, key) {
    return Object.assign(sorted, (0, _defineProperty2["default"])({}, key, obj[key] && (0, _typeof2["default"])(obj[key]) === 'object' ? sortKeys(obj[key]) : obj[key]));
  }, {});
};

exports.sortKeys = sortKeys;
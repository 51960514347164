import React from 'react';
import MetaTags from '../layouts/MetaTags';
import { withState } from '../../enhancers';
import { getApiResource } from '../../reducers';

const getImageSrc = (article, { uri, bin = {} }, index) =>
  uri || bin.preview || `/api/articles/${article._id}/images/0/bin`;

export const ArticleMetaView = ({ article = {} }) => (
  <MetaTags
    title={article.title}
    image={article.images && article.images[0] && getImageSrc(article, article.images[0])}
  />
);

const mapState = (state, { articleId }) => ({
  article: getApiResource(state, `articles/${articleId}`),
});

const ArticleMeta = withState(mapState)(ArticleMetaView);

export default ArticleMeta;

import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import logo from '../styles/images/logo-artsteps.png';
import {
  Text,
  TextButton,
  StyledButton,
  StyledTextField,
  VerticalItems,
  HorizontalItems,
  UnderLinedLink,
} from '../styles/GenericStyled';

import { authLogin, setLocation, addMessage, setUIProperty } from '../actions';

import { withState, withDispatch, compose } from '../enhancers';
import { getAuthToken, getUIProperty, getApiResource, getAuthUser } from '../reducers';
import MetaTags from '../components/layouts/MetaTags';
import Logo from '../components/privateSpaces/Logo';

import OAuthButton from '../components/users/forms/OAuthButton';
import colors from '../styles/colors';

const services = ['google'];

const LoginFormView = ({
  openLogin,
  setOpenLogin,
  setOpenRegister,
  space,
  isPrivateSpace,
  onLogin,
  onAddMessage,
  page,
  onRedirect,
}) => {
  const [data, setData] = useState({ email: '', password: '' });
  const [isValid, setIsValid] = useState([true, true]); // for form validation
  const [verified, setVerified] = useState(true); // must set verified
  const [ready, setReady] = useState(true);

  const onChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    setReady(false);
    setIsValid([data.email, data.password]);
    onLogin(data.email, data.password).then(({ response }) => {
      setReady(true);
      if (!response.error) {
        if (page) onRedirect('');
        else setOpenLogin(false);
        return;
      }
      if (response.error && response.error === 'not verified') {
        setVerified(false);
      }

      if (response.error && response.error === 'user_not_found') {
        setIsValid([false, true]);
      }

      if (response.error && response.error === 'invalid_password') {
        setIsValid([true, false]);
      }

      onAddMessage({ title: __(response.error), type: 'error' });
    });
  };

  return (
    <VerticalItems margin="20px">
      <MetaTags title={__('sign_in')} description={__('sign_in_header')} />

      {isPrivateSpace ? (
        <Logo space={space} width="100px" maxWidth="100px" maxHeight="100px" />
      ) : (
        <img style={{ width: '80px', height: '80px' }} src={logo} alt={__('brand_name')} />
      )}

      {verified ? (
        <Text type="h6">What will you create today?</Text>
      ) : (
        <>
          <Text type="h6" mycolor={colors.ourRed} align="center">
            Please verify your account.
          </Text>
          <Text type="h7" align="center">
            {__('no_verification_email')} Click{' '}
            <a href="/verification-recovery" style={{ textDecoration: 'underline' }}>
              here
            </a>
          </Text>
        </>
      )}
      <StyledTextField
        onKeyDown={e => e.keyCode === 13 && onSubmit()}
        fullWidth
        variant="outlined"
        placeholder="Email"
        onChange={e => onChange(e)}
        value={data.email}
        id="email"
        name="email"
        key="input-name"
        required
        error={!isValid[0]}
      />
      <StyledTextField
        onKeyDown={e => e.keyCode === 13 && onSubmit()}
        fullWidth
        variant="outlined"
        placeholder="Password"
        onChange={e => onChange(e)}
        value={data.password}
        id="password"
        name="password"
        type="password"
        required
        error={!isValid[1]}
      />
      <UnderLinedLink bold={1} to="/reset-password" onClick={() => setOpenLogin(false)}>
        I forgot my password
      </UnderLinedLink>
      <Text type="body3">
        By continuing you agree to Artsteps' &nbsp;
        <UnderLinedLink to="/article/privacy-policy" onClick={() => setOpenLogin(false)}>
          Privacy Policy
        </UnderLinedLink>
        &nbsp; and use of cookies.
      </Text>

      <StyledButton
        onClick={() => onSubmit()}
        minwidth="150px"
        background="#FE7670"
        mycolor="white"
        type="secondary"
      >
        <HorizontalItems>
          Sign In{' '}
          {!ready && (
            <CircularProgress
              style={{
                display: 'block',
                padding: '5px',
                width: '30px',
                height: '30px',
                marginLeft: '10px',
              }}
            />
          )}
        </HorizontalItems>
      </StyledButton>
      <Text type="h6">Or</Text>
      {services.map(service => (
        <OAuthButton
          setOpenLogin={setOpenLogin}
          service={service}
          text="Sign In"
          //  redirectionURL={redirectionURL}
          key={service}
        />
      ))}
      <TextButton
        onClick={() => {
          if (page) {
            onRedirect('/register');
          } else {
            setOpenLogin(false);
            setOpenRegister(true);
          }
        }}
      >
        No account yet? Sign Up.
      </TextButton>
    </VerticalItems>
  );
};

const mapState = state => ({
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  token: getAuthToken(state),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  currentUser: getAuthUser(state),
});

const mapDispatch = dispatch => ({
  onRedirect: page => dispatch(setLocation(page)),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  onLogin: (username, password) => dispatch(authLogin(username, password)),
  onAddMessage: message => dispatch(addMessage(message, 'login')),
});

const LoginForm = compose(withState(mapState), withDispatch(mapDispatch))(LoginFormView);

export default LoginForm;

import React from 'react';
import colours from '../../../styles/colours.json';

const COLOURS = colours.random;

export const MultiSelectInputView = ({
  options = {},
  value = [],
  name,
  onChange = () => Promise.resolve(false),
}) => {
  const getStyle = (option, idx) =>
    value.find(o => o === option) && {
      backgroundColor: COLOURS[idx % COLOURS.length],
      color: 'white',
    };

  const onToggle = option =>
    onChange({
      target: {
        name,
        value: value.find(o => o === option) ? value.filter(o => o !== option) : [...value, option],
      },
    });
  return (
    <div className="ui field multiselect-input">
      {Object.keys(options).map((option, idx) => (
        <button
          type="button"
          className="tag"
          key={option}
          style={getStyle(option, idx)}
          onClick={() => onToggle(option)}
        >
          {options[option]}
        </button>
      ))}
    </div>
  );
};

const MultiSelectInput = MultiSelectInputView;

export default MultiSelectInput;

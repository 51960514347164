import React from 'react';
import MetaTags from '../layouts/MetaTags';
import { withState } from '../../enhancers';
import { getApiResource } from '../../reducers';
import utils from '../../utils';

export const UserMetaView = ({ user = {} }) => (
  <MetaTags title={(user.profile || {}).name} image={utils.user.getUserImage(user)} />
);

const mapState = (state, { userId }) => ({
  user: getApiResource(state, `users/${userId}`),
});

const UserMeta = withState(mapState)(UserMetaView);

export default UserMeta;

import React from 'react';
// import { __ } from 'artsteps2-common';
import { compose, withState } from '../../../../enhancers';
import { API_STATUS, getApiStatus, getAuthUser } from '../../../../reducers';

import Artifacts from '../../lists/Artifacts';
import Cases from '../../lists/Cases';

export const ExhibitionArtifactPanelView = ({ exhibitionId }) => {
  return (
    <>
      <Cases exhibitionId={exhibitionId} />
      <Artifacts exhibitionId={exhibitionId} />
    </>
  );
};

const createQuery = ({ _id: user = null } = {}) => ({ filter: { user } });

const mapState = state => {
  const currentUser = getAuthUser(state);
  return {
    ready: getApiStatus(state, 'artifacts', createQuery(currentUser)) === API_STATUS.IDLE,
  };
};

const ExhibitionArtifactPanel = compose(withState(mapState))(ExhibitionArtifactPanelView);

export default ExhibitionArtifactPanel;

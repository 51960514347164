import utils from './index';
import publicConfig from 'artsteps2-config/public.json';

const THUMBNAIL_HEIGHT = publicConfig.uploader.fallBackThumbnailHeight;
const THUMBNAIL_WIDTH = publicConfig.uploader.fallBackThumbnailWidth;

const IMAGE_WIDTH = publicConfig.artifact.image.fileWidth;
const IMAGE_HEIGHT = publicConfig.artifact.image.fileHeight;
const IMAGE_QUALITY = publicConfig.artifact.image.quality;

// const FILE_WIDTH = publicConfig.artifact.image.fileWidth;
// const FILE_HEIGHT = publicConfig.artifact.image.fileHeight;

const SUBSCRIBER_FILE_WIDTH = publicConfig.artifact.image.subscriberFileWidth;
const SUBSCRIBER_FILE_HEIGHT = publicConfig.artifact.image.subscriberFileHeight;
const SUBSCRIBER_IMAGE_QUALITY = publicConfig.artifact.image.subscriberQuality;

const DEFAULT_WIDTH = 2048;
const DEFAULT_HEIGHT = 2048;
const DEFAULT_QUALITY = 0.8;
const DEFAULT_TYPE = 'image/jpeg';

export const createThumbnail = (file, element, uploadOption, currentUserId) => {
  let uri = utils.artifact.parseFileUri(file, currentUserId);
  if (uploadOption === 'youtube') uri = `/api/youtube/${utils.youtube.parseYoutubeVideoID(uri)}`;

  return utils.image
    .toDataURL({
      uri,
      element,
      text: file.text,
      height: THUMBNAIL_HEIGHT,
      width: THUMBNAIL_WIDTH,
      quality: 0.5,
      type: file.type || DEFAULT_TYPE,
    })
    .then(dataUri => Promise.resolve({ bin: utils.buffer.fromDataURL(dataUri) }))
    .catch(e => Promise.resolve(file));
};

export const downScaleImage = (file, increasedQuality) => {
  let uri = file.uri || file.base64;

  if (uri)
    return utils.image
      .toDataURL({
        uri,
        width: increasedQuality ? SUBSCRIBER_FILE_WIDTH : IMAGE_WIDTH,
        height: increasedQuality ? SUBSCRIBER_FILE_HEIGHT : IMAGE_HEIGHT,
        quality: increasedQuality ? SUBSCRIBER_IMAGE_QUALITY : IMAGE_QUALITY,
        type: file.type || DEFAULT_TYPE,
      })
      .then(dataUri => Promise.resolve({ bin: utils.buffer.fromDataURL(dataUri) }))
      .catch(e => Promise.resolve(file));
};

const createCanvasFromElement = ({ element, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) =>
  new Promise(resolve => {
    if (element && element.tagName.toLowerCase() === 'canvas') {
      resolve(element);
      return;
    }

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(element, 0, 0, canvas.width, canvas.height);
    resolve(canvas);
  });

const createCanvas = ({ uri, element, text, width, height }) => {
  if (element) {
    const elwidth = Math.min(element.offsetWidth, width || DEFAULT_WIDTH);
    const elheight = Math.min(element.offsetHeight, height || DEFAULT_HEIGHT);
    const mult = Math.min(elwidth / element.offsetWidth, elheight / element.offsetHeight);
    return createCanvasFromElement({
      element,
      width: element.offsetWidth * mult,
      height: element.offsetHeight * mult,
    });
  }

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const imwidth = Math.min(image.width, width || DEFAULT_WIDTH);
      const imheight = Math.min(image.height, height || DEFAULT_HEIGHT);
      const mult = Math.min(imwidth / image.width, imheight / image.height);
      resolve(
        createCanvasFromElement({
          element: image,
          width: image.width * mult,
          height: image.height * mult,
        }),
      );
    };
    image.onerror = err => reject(err);
    image.crossOrigin = 'anonymous';
    image.src = uri;
  });
};

export const toBlob = ({ uri, element, text, width, height }) =>
  createCanvas({
    uri,
    element,
    text,
    width,
    height,
  }).then(canvas => new Promise(resolve => canvas.toBlob(blob => resolve(blob))));

export const toObjectURL = ({ uri, element, text, width, height }) =>
  toBlob({
    uri,
    element,
    text,
    width,
    height,
  }).then(blob => Promise.resolve(URL.createObjectURL(blob)));

export const toDataURL = ({ uri, element, text, quality, width, height, type }) => {
  return createCanvas({
    uri,
    element,
    text,
    width,
    height,
  }).then(canvas => canvas.toDataURL(type || DEFAULT_TYPE, quality || DEFAULT_QUALITY));
};

export const toArrayBuffer = ({ uri, element, text, quality, width, height, type }) =>
  toBlob({
    uri,
    element,
    text,
    width,
    height,
  }).then(
    blob =>
      new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsArrayBuffer(blob);
      }),
  );

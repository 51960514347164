import React, { useState } from 'react';
import BrushIcon from '@material-ui/icons/Brush';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { compose, withState, withDispatch } from '../../../../../enhancers';
import { setUIProperty } from '../../../../../actions';
import { getAuthUser, getUIProperty } from '../../../../../reducers';
import { ExpandButton, Text } from '../../../../../styles/GenericStyled';
import { ColorSquare, TextureSquare, MaterialSquare } from './ExhibitionThemeStyled';
import colors from '../../../../../styles/colors';
import MyToolTip from '../../../../generic/MyToolTip';
import Dialog from '../../../../generic/Dialog';
import configPublic from 'artsteps2-config/public.json';

const FILE_SYSTEM_ROOT_URL = configPublic.usersFileSystemUrl;

const themeMaterials = [
  'ceilingMaterial',
  'wallCorniceMaterial',
  'interiorWallMaterial',
  'exteriorWallMaterial',
  'floorMaterial',
  'groundMaterial',
];

const convertValueToName = mat => {
  let name = mat.substring(0, 1).toUpperCase() + mat.substring(1);
  return name.match(/[A-Z][a-z]+/g).join(' ');
};

export const ThemeView = ({ setTheme, theme, title, selectedTheme }) => {
  const [displayDialog, setDisplayDialog] = useState(false);

  return theme ? (
    <div style={{ padding: '16px 0px 16px 0px', borderTop: `1px solid gray` }}>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid container xs={8} item direction="column" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <Text type="h6">{title}</Text>
          </Grid>
          <Grid item container direction="row" justify="flex-start" alignItems="center">
            {themeMaterials.map((mat, idx) => (
              <Grid key={idx} item>
                <MyToolTip key={idx} placement="top" title={convertValueToName(mat)}>
                  <MaterialSquare>
                    {theme[mat].textureUrl && (
                      <TextureSquare
                        src={
                          theme[mat].textureUrl.includes(FILE_SYSTEM_ROOT_URL)
                            ? `${theme[mat].textureUrl}`
                            : `${FILE_SYSTEM_ROOT_URL}${theme[mat].textureUrl}`
                        }
                      />
                    )}
                    <ColorSquare
                      style={{ opacity: theme[mat].textureUrl ? 0.5 : 1 }}
                      background={theme[mat].colorString}
                    />
                  </MaterialSquare>
                </MyToolTip>
              </Grid>
            ))}

            <Dialog
              open={displayDialog}
              title="Irreversible Action"
              message="This action will paint your whole exhibition. Are you sure you want to apply the theme?"
              type="warning"
              onConfirm={() => {
                setTheme(theme);
                setDisplayDialog(false);
              }}
              onReject={() => {
                setDisplayDialog(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <MyToolTip placement="top" title={`Use ${title}`}>
            <Box
              style={{ marginTop: '2px' }}
              disabled={theme === selectedTheme}
              component={ExpandButton}
              boxShadow={3}
              mycolor={colors.ourBlack}
              mycolorhover={colors.ourWhite}
              background={colors.ourWhite}
              backgroundhover={colors.ourBlack}
              onClick={() => setDisplayDialog(true)}
            >
              <BrushIcon />
            </Box>
          </MyToolTip>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div></div>
  );
};

const mapState = (state, { exhibitionId }) => ({
  currentUser: getAuthUser(state),
  selectedTheme: getUIProperty(state, `exhibitions/${exhibitionId}/theme`),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  setTheme: theme => {
    const mapTheme = { ...theme };
    themeMaterials.forEach(
      material =>
        (mapTheme[material].textureUrl =
          mapTheme[material].textureUrl &&
          !mapTheme[material].textureUrl.includes(FILE_SYSTEM_ROOT_URL)
            ? `${FILE_SYSTEM_ROOT_URL}${mapTheme[material].textureUrl}`
            : mapTheme[material].textureUrl),
    );

    return dispatch(setUIProperty(`exhibitions/${exhibitionId}/theme`, theme));
  },
});

const Theme = compose(withState(mapState), withDispatch(mapDispatch))(ThemeView);

export default Theme;

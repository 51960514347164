import React from 'react';
import { __ } from 'artsteps2-common';
import LoggedIn from '../LoggedIn';
import Form from '../../generic/forms/Form';
import { compose, withState, withDispatch } from '../../../enhancers';
import { apiPOST, addMessage, clearMessages, clearFormData, setLocation } from '../../../actions';
import { API_STATUS, getApiStatus, getAuthUser, getForm } from '../../../reducers';

export const PasswordReseterView = ({
  form = { data: {} },
  ready = true,
  redirectionURL = '/login',
  currentUser = {},
  onRedirect = () => Promise.resolve(false),
  onResetPassword = () => Promise.resolve({ response: { error: 'error' } }),
  onAddMessage = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onClearForm = () => Promise.resolve(false),
}) => {
  if (currentUser._id) {
    return (
      <div className="ui basic segment">
        <LoggedIn />
      </div>
    );
  }

  const onSubmit = () =>
    onResetPassword(form.data.email).then(({ response }) => {
      if (!response.error) {
        onClearMessages().then(() =>
          onAddMessage({
            title: __('email_sent'),
            description: __('check_your_inbox_for_further_instructions'),
            type: 'success',
          }),
        );
        onClearForm();
        onRedirect(redirectionURL);
        return;
      }
      onAddMessage({ title: __(response.error), type: 'error' });
    });

  return (
    <div className="ui segment basic password-form">
      <div className="ui horizontal divider">{__('reset_password')}</div>
      <Form
        form={form}
        onSubmit={onSubmit}
        ready={ready}
        fields={{
          email: {
            type: 'text',
            label: __('email_username'),
            required: true,
            autoComplete: 'off',
          },
        }}
        buttons={[{ label: __('send_reset_link'), colour: 'teal' }]}
      />
    </div>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  form: getForm(state, 'passreset'),
  ready: getApiStatus(state, 'auth/artsteps/recovery') === API_STATUS.IDLE,
});

const mapDispatch = dispatch => ({
  onRedirect: path => dispatch(setLocation(path)),
  onAddMessage: message => dispatch(addMessage(message, 'passreset')),
  onClearMessages: () => dispatch(clearMessages('passreset')),
  onClearForm: () => dispatch(clearFormData('passreset')),
  onResetPassword: username => dispatch(apiPOST('auth/artsteps/recovery', { username })),
});

const PasswordReseter = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(PasswordReseterView);

export default PasswordReseter;

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteIconBorder from '@material-ui/icons/FavoriteBorder';
import Box from '@material-ui/core/Box';
import React, { useState, useEffect } from 'react';
import { API_STATUS, getApiStatus, getAuthUser, getApiResource } from '../../../reducers';
import { apiHEAD, apiGET, apiPOST, apiDELETE, setLocation, addMessage } from '../../../actions';
import { compose, withState, withDispatch } from '../../../enhancers';
import { Text, ExpandButton } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';
import MyTooltip from '../../../components/generic/MyToolTip';
import { setUIProperty } from '../../../actions/ui';

export const ExhibitionLikesView = ({
  postOrDel,
  onFetchLike,
  onFetchLikes,
  currentUserId,
  onRedirect,
  iconClass,
  setOpenLogin,
  onAddMessage = () => Promise.resolve(false),
}) => {
  // having as a useSate to set Like Unlike
  const [userLiked, setUserLiked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0);
  const [busy, setBusy] = useState(false);
  // Used as the componentDidMount for initializing the starting value of the total likes
  // and whether the user has already liked the exhibition or not
  useEffect(() => {
    Promise.all([onFetchLikes(), onFetchLike()])
      .then(data => {
        if (data.length > 0) {
          setTotalLikes(data[0].meta.totalCount);
          currentUserId && setUserLiked(data[1].meta.totalCount !== 0);
        }
      })
      .catch(err => onAddMessage({ type: 'error', title: 'Like error', description: err }));
  }, []);

  // having a useEffect hooked to the busy variable
  useEffect(() => {
    // if the component will unmount we have to abort the requests.
    //const ac = new AbortController();
    if (busy) {
      postOrDel(userLiked, setBusy);
    }
    //return () => ac.abort(); // Abort both fetches on unmount
  }, [busy]);

  const likeClick = () => {
    if (currentUserId) {
      setUserLiked(prevUserLiked => !prevUserLiked);
      setTotalLikes(prevLikes => (userLiked ? prevLikes - 1 : prevLikes + 1));
      !busy && setBusy(true); // if there arent any requests happening start a request
    } else {
      setOpenLogin(true);
    }
  };
  return (
    <>
      <MyTooltip placement="top" title={`${userLiked ? 'Unlike' : 'Like'}`}>
        <Box
          component={ExpandButton}
          boxShadow={3}
          mycolor={colors.ourBlack}
          mycolorhover={colors.ourWhite}
          background={colors.ourWhite}
          backgroundhover={colors.ourBlack}
          onClick={likeClick}
        >
          <Text style={{ opacity: '0.8', marginRight: '5px' }} type="body2">
            {totalLikes}
          </Text>
          {userLiked ? (
            <FavoriteIcon className={iconClass} style={{ color: 'red' }} />
          ) : (
            <FavoriteIconBorder className={iconClass} />
          )}
        </Box>
      </MyTooltip>
    </>
  );
};
const createQuery = (exhibition, user) => ({ filter: { exhibition, user } });
const mapState = (state, { exhibitionId }) => {
  const { _id: currentUserId = null } = getAuthUser(state) || {};
  return {
    currentUserId,
    likes: Object.values(getApiResource(state, 'likes', createQuery(exhibitionId, currentUserId))),
    ready: getApiStatus(state, 'likes', createQuery(exhibitionId)) === API_STATUS.IDLE,
  };
};
const mapDispatch = (dispatch, { exhibitionId, currentUserId, likes }) => {
  const onFetchLikes = () => Promise.resolve(dispatch(apiHEAD('likes', createQuery(exhibitionId))));
  const onFetchLike = () =>
    currentUserId
      ? Promise.resolve(dispatch(apiGET('likes', createQuery(exhibitionId, currentUserId))))
      : Promise.resolve(`User${undefined}`);

  const postOrDel = (userLiked, setBusy, lastReq) => {
    // if the user likes(POST) and last request isnt like(POST)
    if (userLiked && lastReq !== 'POST') {
      dispatch(apiPOST('likes', { exhibition: exhibitionId }));
      onFetchLike().then(() => {
        doubleCheck('POST', setBusy, userLiked);
      });
    } else if (!userLiked && lastReq !== 'DEL') {
      if (likes.length > 0) {
        dispatch(apiDELETE(`likes/${likes[0]._id}`));
        onFetchLike().then(() => {
          doubleCheck('DEL', setBusy, userLiked);
        });
      }
    } else {
      setBusy(false);
    }
  };
  // Check if the current state of the like matches the type of previous request
  // If it doesnt perfom an extra request and so on
  const doubleCheck = (lastReq, setBusy, userLiked) => {
    if (lastReq === 'POST' && !userLiked) {
      postOrDel(userLiked, setBusy, lastReq);
    } else if (lastReq === 'DEL' && userLiked) {
      postOrDel(userLiked, setBusy, lastReq);
    } else {
      setBusy(false);
    }
  };
  return {
    postOrDel,
    onFetchLikes,
    onFetchLike,
    onRedirect: location => dispatch(setLocation(location)),
    setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
    onAddMessage: message => dispatch(addMessage(message)),
  };
};
const ExhibitionLikes = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionLikesView);
export default ExhibitionLikes;

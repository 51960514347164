import React from 'react';
import { __ } from 'artsteps2-common';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { apiGET, setLocation } from '../../actions';
import { API_KEYS, getApiResource, getAuthUser } from '../../reducers';
import Pager from '../generic/lists/Pager';
import ExhibitionList from '../exhibitions/lists/ExhibitionList';
import UserCommentList from '../comments/lists/UserCommentList';
import FollowList from '../follows/lists/FollowList';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { StyledButton, Text } from '../../styles/GenericStyled';
import DescriptionIcon from '@material-ui/icons/Description';
import MyToolTip from '../generic/MyToolTip';

const LIMIT = 12;

const UserPanelView = ({
  userId,
  currentUser,
  tab = 'exhibitions',
  openTab = Promise.resolve(false),
  payments = [],
  onRedirect,
}) => (
  <div
    style={{
      paddingRight: '20px',
      paddingTop: '80px',
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
    className="user-panel"
  >
    <div
      className="ui secondary pointing menu stackable"
      style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <button
        className={`anchor item ${tab === 'exhibitions' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('exhibitions')}
        onClick={() => openTab('exhibitions')}
        style={{
          align: 'center',
          marginLeft: 'auto',
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor:
            tab === 'exhibitions' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('exhibitions')}
      </button>
      {currentUser && userId === currentUser._id && payments && payments.length > 0 && (
        <button
          className={`anchor item ${tab === 'myTemplates' ? 'active' : null}`}
          onKeyPress={event => event.keyCode === 13 && openTab('myTemplates')}
          onClick={() => openTab('myTemplates')}
          style={{
            font: 'normal normal bold 18px/23px Muli',
            backgroundColor:
              tab === 'myTemplates' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
          }}
        >
          My Templates
        </button>
      )}
      <button
        className={`anchor item ${tab === 'liked' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('liked')}
        onClick={() => openTab('liked')}
        style={{
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor: tab === 'liked' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('liked')}
      </button>
      <button
        className={`anchor item ${tab === 'comments' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('comments')}
        onClick={() => openTab('comments')}
        style={{
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor: tab === 'comments' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('comments')}
      </button>
      <button
        className={`anchor item ${tab === 'followers' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('followers')}
        onClick={() => openTab('followers')}
        style={{
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor:
            tab === 'followers' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('followers')}
      </button>
      <button
        className={`anchor item ${tab === 'following' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('following')}
        onClick={() => openTab('following')}
        style={{
          align: 'center',
          marginRight: 'auto',
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor:
            tab === 'following' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('following')}
      </button>
    </div>
    {tab === 'exhibitions' && (
      <div className="tab-body">
        <ExhibitionList
          wide={false}
          filter={{ user: userId }}
          limit={LIMIT}
          include={[
            'description',
            'categories',
            `views.${API_KEYS.META}.totalCount`,
            `likes.${API_KEYS.META}.totalCount`,
            'visits',
            'rating',
            '_v1Rating',
          ]}
          populate="user"
          controls={currentUser && userId === currentUser._id}
          currentUser={currentUser}
          userId={userId}
        />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection={
            currentUser && userId === currentUser._id ? 'exhibitionsUserProfile' : 'exhibitions'
          }
          query={{ filter: { user: userId }, populate: 'user' }}
        />
      </div>
    )}
    {tab === 'liked' && (
      <div className="tab-body">
        <ExhibitionList
          include={[
            'description',
            'categories',
            `views.${API_KEYS.META}.totalCount`,
            `likes.${API_KEYS.META}.totalCount`,
            'visits',
            'rating',
            '_v1Rating',
          ]}
          usernames
          wide={false}
          filter={{ followers: userId }}
          limit={LIMIT}
          populate="user"
          controls={currentUser && userId === currentUser._id}
        />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="exhibitions"
          query={{ filter: { followers: userId }, populate: 'user' }}
        />
      </div>
    )}
    {tab === 'comments' && (
      <div className="tab-body">
        <UserCommentList userId={userId} />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="comments"
          query={{ filter: { user: userId }, populate: 'user' }}
        />
      </div>
    )}
    {tab === 'followers' && (
      <div className="tab-body">
        <FollowList userId={userId} />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="follows"
          query={{ filter: { user: userId }, populate: ['user', 'follower'] }}
        />
      </div>
    )}
    {tab === 'following' && (
      <div className="tab-body">
        <FollowList followerId={userId} />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="follows"
          query={{ filter: { follower: userId }, populate: 'user' }}
        />
      </div>
    )}
    {currentUser &&
      userId === currentUser._id &&
      tab === 'myTemplates' &&
      payments &&
      payments.length > 0 && (
        <div className="tab-body">
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
            {payments.map(pay => (
              <Grid item key={pay._id}>
                <div key={pay._id} style={{ width: '300px', marginTop: '40px' }}>
                  <MyToolTip title="View template" placement="top">
                    <img
                      style={{
                        width: '100%',
                        borderRadius: '5px 5px 0 0',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                      src={pay?.template?.image}
                      alt={pay?.template?.title}
                      onClick={() => onRedirect(`/marketplace/${pay?.template?.bundle}`)}
                    />
                  </MyToolTip>
                  <div style={{ padding: '10px' }}>
                    <Text style={{ fontWeight: 600, fontSize: '18px', marginBottom: '15px' }}>
                      {pay?.template?.title} {pay?.template?.size && `(${pay?.template?.size})`}
                    </Text>
                    <a href={pay?.receiptUrl} target="_blank" rel="noopener noreferrer">
                      <MyToolTip title="View Invoice" placement="right">
                        <StyledButton background="#12AD8F" type="secondary">
                          <DescriptionIcon />
                          <Text style={{ marginLeft: '10px' }}>Invoice</Text>
                        </StyledButton>
                      </MyToolTip>
                    </a>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
  </div>
);

const mapState = state => ({
  currentUser: getAuthUser(state),
  payments: getAuthUser(state) && Object.values(getApiResource(state, 'templates/payments')),
});

const mapDispatch = (dispatch, { profilePath, currentUser, userId }) => ({
  openTab: tab => dispatch(setLocation(`${profilePath}/${tab}`)),
  fetchPayments: () =>
    currentUser?._id && userId === currentUser._id && dispatch(apiGET('templates/payments')),
  onRedirect: location => dispatch(setLocation(location)),
});

const lifecycleMap = {
  onDidMount: ({ fetchPayments }) => {
    fetchPayments();
  },
};

const UserPanel = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(UserPanelView);

export default UserPanel;

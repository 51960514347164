import { MESSAGES_NAMESPACE, ACTION_ADD, ACTION_DELETE, ACTION_CLEAR } from '../actions/messages';

export default (state = {}, { type = '', group = '', message = {} }) => {
  switch (type) {
    case `${MESSAGES_NAMESPACE}/${ACTION_ADD}`: {
      return {
        ...state,
        [group]: [...(state[group] || []), message],
      };
    }
    case `${MESSAGES_NAMESPACE}/${ACTION_DELETE}`: {
      return Object.keys(state).reduce(
        (newState, g) => ({
          ...newState,
          [g]: (state[g] || []).filter(m => m.timestamp !== message.timestamp),
        }),
        {},
      );
    }
    case `${MESSAGES_NAMESPACE}/${ACTION_CLEAR}`: {
      return {
        ...state,
        [group]: [],
      };
    }
    default: {
      return state;
    }
  }
};

export const getMessages = (state, group) => state[group] || [];

export const getAllMessages = state =>
  Object.keys(state).reduce((messages, group) => [...messages, ...state[group]], []);

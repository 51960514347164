import React from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import ProfileFrame from '../components/users/ProfileFrame';
import UserMeta from '../components/users/UserMeta';

const ProfilePage = ({ userId, tab }) => (
  <DefaultLayout>
    <UserMeta userId={userId} />
    <ProfileFrame userId={userId} tab={tab} />
  </DefaultLayout>
);

export default ProfilePage;

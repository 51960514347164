import React from 'react';
import { __ } from 'artsteps2-common';
import utils from '../../utils';

const LoaderView = ({ message, description, children, inverted = true }) => (
  <div className={`ui active loading ${inverted ? 'inverted' : ''} dimmer`}>
    <div className="ui text loader">
      {children}
      {message === undefined ? __('loading') : message}
      {description && <div className="text small">{utils.text.toMultiline(description)}</div>}
    </div>
  </div>
);

const Loader = LoaderView;

export default Loader;

import React from 'react';
import { __ } from 'artsteps2-common';
import Form from '../../generic/forms/Form';
import LoggedIn from '../LoggedIn';
import { compose, withState, withDispatch } from '../../../enhancers';
import { API_STATUS, getForm, getApiStatus, getAuthUser } from '../../../reducers';
import { apiPATCH, addMessage, clearMessages, clearFormData, setLocation } from '../../../actions';

export const PasswordRecoveryView = ({
  currentUser = {},
  ready = true,
  redirectionURL = '/login',
  form = { data: {}, name: 'password' },
  onRedirect = () => Promise.resolve(false),
  onChangePassword = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onClearForm = () => Promise.resolve(false),
}) => {
  if (currentUser._id) {
    return (
      <div className="recover-password">
        <LoggedIn />
      </div>
    );
  }

  const onSubmit = () => {
    if (!form.data.password) {
      return onAddMessage({ title: __('empty_field_password'), type: 'error' });
    }
    if (!form.data.confirmation) {
      return onAddMessage({ title: __('confirm_password'), type: 'error' });
    }
    if (form.data.password !== form.data.confirmation) {
      return onAddMessage({ title: __('password_mismatch'), type: 'error' });
    }

    return onChangePassword(form.data.password).then(({ response }) => {
      if (!response.error) {
        onClearMessages();
        onClearForm();
        onAddMessage({ type: 'success', title: __('change_password_success') });
        onRedirect(redirectionURL);
        return;
      }
      onAddMessage({ title: __(response.error), type: 'error' });
    });
  };

  return (
    <div className="ui segment basic password-form">
      <div className="ui horizontal divider">{__('recover_password')}</div>
      <Form
        form={form}
        ready={ready}
        onSubmit={onSubmit}
        fields={{
          password: {
            type: 'password',
            required: true,
            label: __('your_new_pass'),
            autoComplete: 'off',
          },
          confirmation: {
            type: 'password',
            required: true,
            label: __('confirm_your_new_pass'),
            autoComplete: 'off',
          },
        }}
        buttons={[{ label: __('change_password'), colour: 'teal' }]}
      />
    </div>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  form: getForm(state, 'password'),
  ready: getApiStatus(state, 'users') === API_STATUS.IDLE,
});

const mapDispatch = (dispatch, { token, userId, authToken }) => ({
  onRedirect: path => dispatch(setLocation(path)),
  onChangePassword: password =>
    dispatch(apiPATCH(`users/${userId}`, { services: { password } }, { token }, { authToken })),
  onAddMessage: message => dispatch(addMessage(message, 'password')),
  onClearMessages: message => dispatch(clearMessages('password')),
  onClearForm: () => dispatch(clearFormData('password')),
});

const PasswordRecovery = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(PasswordRecoveryView);

export default PasswordRecovery;

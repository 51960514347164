import React from 'react';
import useWindowSize from '../../hooks/windowSize';
/***************** REDUX IMPORTS *********************/
import { compose, withState, withDispatch } from '../../enhancers';
import { getUIProperty } from '../../reducers';
import { setUIProperty, setLocation } from '../../actions';
/***************** MATERIAL IMPORTS *********************/
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import colors from '../../styles/colors';
import { HorizontalItems, Text } from '../../styles/GenericStyled';
import { DrawerStyled, ListItemStyled, Line } from './AdminStyled';

const AdminPanelWrapperView = ({
  options,
  onRedirect,
  setRenderedView,
  renderedView = 'users',
}) => {
  const size = useWindowSize();

  return (
    <DrawerStyled
      background={colors.ourBlack}
      paperwidth="inherit"
      variant="permanent"
      position="unset"
      height="100%"
    >
      <List>
        <HorizontalItems style={{ padding: '8px 0', justifyContent: 'center' }}>
          <Text type="h7" mycolor={colors.ourWhite}>
            {size.width >= 1000 ? 'Admin Panel' : 'AP'}
          </Text>
        </HorizontalItems>
        <Line />
        {options.map((option, index) => (
          <ListItemStyled
            checked={option.path === renderedView}
            button
            key={index}
            onClick={() => {
              setRenderedView(option.path);
              onRedirect(`/admin-panel/${option.path}`);
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>
              <Text mycolor="inherit">{option.label}</Text>
            </ListItemText>
          </ListItemStyled>
        ))}
      </List>
      <Divider />
    </DrawerStyled>
  );
};
const mapState = state => ({
  renderedView: getUIProperty(state, 'renderedView'),
});

const mapDispatch = dispatch => ({
  onRedirect: location => dispatch(setLocation(location)),
  setRenderedView: view => dispatch(setUIProperty('renderedView', view)),
});
const AdminPanelWrapper = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(AdminPanelWrapperView);

export default AdminPanelWrapper;

import React, { useState, useEffect } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import InputField from '../../generic/forms/InputField';
import { __ } from 'artsteps2-common';
import { DimensionsDiv, BlueSwitch } from '../forms/FormStyled';
import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import MyToolTip from '../../generic/MyToolTip';
import { toMeters, toInches } from '../../../reusableFunctions/dimensionsConvertions';
import COLORS from '../../../styles/colors';

const MIN_VALUE = 0.01;
const MAX_VALUE = 3;

const Dimensions = ({ formData, dimensions, handleChange }) => {
  const [metric, setMetric] = useState('meters');
  const [minValue, setMinValue] = useState(MIN_VALUE);
  const [maxValue, setMaxValue] = useState(MAX_VALUE);

  useEffect(() => {
    setMinValue(metric === 'meters' ? MIN_VALUE : toInches(MIN_VALUE));
    setMaxValue(metric === 'meters' ? MAX_VALUE : toInches(MAX_VALUE));
    handleChange({ target: { name: 'metric', value: metric } });
  }, [metric]);

  return (
    <>
      <HorizontalItems style={{ width: '100%', marginTop: '-20px', marginBottom: '-8px' }}>
        <Text>Metric System</Text>
        <BlueSwitch
          checked={metric === 'meters'}
          name="metric"
          onChange={() => {
            if (formData.dimensions)
              handleChange({
                target: {
                  name: 'dimensions',
                  value:
                    metric === 'meters'
                      ? {
                          width: toInches(formData.dimensions.width),
                          height: toInches(formData.dimensions.height),
                          depth: toInches(formData.dimensions.depth),
                        }
                      : {
                          width: toMeters(formData.dimensions.width),
                          height: toMeters(formData.dimensions.height),
                          depth: toMeters(formData.dimensions.depth),
                        },
                },
              });

            setMetric(metric === 'meters' ? 'inches' : 'meters');
          }}
          style={{ marginRight: '8px' }}
        />
        <Text mycolor={COLORS.ourRed}>({__(metric)})</Text>
        <MyToolTip placement="right" title={`Max value is ${maxValue}`}>
          <HelpIcon style={{ marginLeft: '8px' }} />
        </MyToolTip>
      </HorizontalItems>
      <DimensionsDiv>
        {dimensions.map((dim, i) => (
          <InputField
            name={__(dim)}
            key={i}
            label={`${__(dim)}`}
            min={minValue}
            max={maxValue}
            isNumber
            style={{ marginRight: '8px' }}
            value={formData.dimensions && formData.dimensions[dim]}
            handleChange={e => {
              let value = parseFloat(e.target.value);

              if (value > maxValue) value = maxValue;
              else if (value < minValue) value = undefined;

              return handleChange({
                target: {
                  name: 'dimensions',
                  value: {
                    ...formData.dimensions,
                    [dim]: value,
                  },
                },
              });
            }}
          />
        ))}
      </DimensionsDiv>
    </>
  );
};

export default Dimensions;

export const FORMS_NAMESPACE = 'forms';
export const FORMS_ACTION_SET_DATA = 'SET_DATA';
export const FORMS_ACTION_CLEAR_DATA = 'CLEAR_DATA';

export const setFormData = (form, data) => dispatch =>
  Promise.resolve(
    dispatch({
      type: `${FORMS_NAMESPACE}/${form}/${FORMS_ACTION_SET_DATA}`,
      data,
    }),
  );

export const setFormProperty = (form, property, value) => setFormData(form, { [property]: value });

export const handleFormPropertyChange = (form, event) =>
  event.target.type === 'checkbox'
    ? setFormProperty(form, event.target.name, !!event.target.checked)
    : setFormProperty(form, event.target.name, event.data || event.target.value);

export const clearFormData = form => dispatch =>
  Promise.resolve(
    dispatch({
      type: `${FORMS_NAMESPACE}/${form}/${FORMS_ACTION_CLEAR_DATA}`,
    }),
  );

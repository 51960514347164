import React from 'react';
import { __ } from 'artsteps2-common';
import Link from '../../generic/Link';
import utils from '../../../utils';
import {
  apiGET,
  apiPOST,
  setUIProperty,
  handleFormPropertyChange,
  addMessage,
} from '../../../actions';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getAuthUserRoles,
  getFormProperty,
  getUIProperty,
} from '../../../reducers';
import { compose, withState, withDispatch } from '../../../enhancers';

export const ArticleListView = ({
  ready = true,
  articles = [],
  editable = false,
  isEditing = false,
  categoryId = null,
  newArticleTitle = '',
  onArticleTitleChange = () => Promise.resolve(false),
  onCreateArticle = () => Promise.resolve({ response: { error: 'error' } }),
  onAddMessage = () => Promise.resolve(false),
  onStartEditing = () => Promise.resolve(false),
  onStopEditing = () => Promise.resolve(false),
}) => {
  const onSubmit = () => {
    const slug = newArticleTitle
      .toLowerCase()
      .replace(/\W+$/, '')
      .replace(/\W+/g, '-');
    return onCreateArticle({
      slug,
      title: newArticleTitle,
      category: categoryId,
    }).then(({ response }) => {
      if (response.error) {
        return onAddMessage({
          type: 'error',
          title: __('article_insert_err'),
          description: __(response.error),
        });
      }
      utils.dialogflow.createIntent(`articles/${slug}/text`, [newArticleTitle]);
      return onStopEditing();
    });
  };

  const renderForm = () => (
    <div className="item">
      <div className="field">
        <div className="ui action input">
          <input
            type="text"
            name="title"
            placeholder={__('title')}
            value={newArticleTitle}
            onChange={onArticleTitleChange}
          />
          <button
            className="ui secondary icon button"
            data-tooltip={__('discard_changes')}
            data-inverted
            onClick={onStopEditing}
          >
            <i className="undo icon" />
          </button>
          <button
            className="ui teal icon button"
            data-tooltip={__('save_changes')}
            data-inverted
            onClick={onSubmit}
          >
            <i className="save icon" />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="ui list ">
      {articles
        .filter(
          article =>
            article.category &&
            (article.category === categoryId || article.category._id === categoryId),
        )
        .map(article => (
          <div className="item " key={article._id}>
            <Link exact to={`/article/${article.slug || article._id}`}>
              {article.title}
            </Link>
          </div>
        ))}
      {editable && !isEditing && (
        <div className="item">
          <button className="bare icon" onClick={onStartEditing}>
            <i className="add circle icon" />
            {__('add_article')}
          </button>
        </div>
      )}
      {editable && isEditing && renderForm()}
    </div>
  );
};

const EDITOR_ROLES = ['editor', 'admin'];

const mapState = (state, { categoryId, editable }) => ({
  articles: Object.values(getApiResource(state, 'articles')),
  editable: editable && getAuthUserRoles(state).some(role => EDITOR_ROLES.includes(role)),
  isEditing: getUIProperty(state, `article-categorys/${categoryId}/edit`),
  newArticleTitle: getFormProperty(state, `article-category:${categoryId}`, 'title'),
  ready: getApiStatus(state, 'articles') === API_STATUS.IDLE,
});

const mapDispatch = (dispatch, { categoryId }) => ({
  onFetchArticles: () => dispatch(apiGET('articles', { filter: { category: categoryId } })),
  onCreateArticle: article => dispatch(apiPOST('articles', article)),
  onStartEditing: () => dispatch(setUIProperty(`article-categorys/${categoryId}/edit`, true)),
  onStopEditing: () => dispatch(setUIProperty(`article-categorys/${categoryId}/edit`, false)),
  onAddMessage: message => dispatch(addMessage(message)),
  onArticleTitleChange: event =>
    dispatch(handleFormPropertyChange(`article-category:${categoryId}`, event)),
});

const ArticleList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ArticleListView);

export default ArticleList;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getYoutubeEmbed = exports.getYoutubeImage = exports.parseYoutubeVideoID = void 0;

var parseYoutubeVideoID = function parseYoutubeVideoID(url) {
  var videoID = '';

  if (url.includes('list')) {
    var params = url.split('?')[1].split('&');
    videoID = params instanceof Array ? params.find(function (param) {
      return param.split('=')[0] === 'list';
    }) : 'not_found';
    videoID = "videoseries?".concat(videoID);
  } else {
    videoID = url.replace('http://', '').replace('https://', '').replace('www.', '').replace('youtu.be/', 'youtube.com/watch?v=').split('/').splice(1).join('');
    videoID = videoID.substring(8, 19);
  }

  return videoID;
};

exports.parseYoutubeVideoID = parseYoutubeVideoID;

var getYoutubeImage = function getYoutubeImage(url) {
  return url.includes('list=') ? '/images/playlistYT.png' : "https://img.youtube.com/vi/".concat(parseYoutubeVideoID(url), "/mqdefault.jpg");
};

exports.getYoutubeImage = getYoutubeImage;

var getYoutubeEmbed = function getYoutubeEmbed(url) {
  return "https://www.youtube.com/embed/".concat(parseYoutubeVideoID(url));
};

exports.getYoutubeEmbed = getYoutubeEmbed;
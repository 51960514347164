import React from 'react';
import ProfileEditor from '../components/users/forms/ProfileEditor';
import DefaultLayout from '../components/layouts/DefaultLayout';
import { compose, withState } from '../enhancers';
import { getAuthUser } from '../reducers';
import { Redirect } from 'react-router';

const EditProfilePageView = ({ userId, currentUser }) => {
  return (
    <DefaultLayout>
      {currentUser && currentUser._id === userId ? (
        <ProfileEditor userId={userId} resetStateOnCancel={true} />
      ) : (
        <Redirect to="/" />
      )}
    </DefaultLayout>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
});

const EditProfilePage = compose(withState(mapState))(EditProfilePageView);

export default EditProfilePage;

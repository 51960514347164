import React from 'react';
import { __ } from 'artsteps2-common';

import MetaTags from '../components/layouts/MetaTags';
import DefaultLayout from '../components/layouts/DefaultLayout';
import PasswordEditor from '../components/users/forms/PasswordEditor';

const ChangePasswordPage = () => (
  <DefaultLayout>
    <MetaTags title={__('change_password')} />
    <div className="ui main container text">
      <div className="ui segment vertical">
        <h1 className="ui header">{__('change_password')}</h1>
        <PasswordEditor />
      </div>
    </div>
  </DefaultLayout>
);

export default ChangePasswordPage;

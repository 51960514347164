import React from 'react';
import { __ } from 'artsteps2-common';

import { compose, withState, withDispatch } from '../../../enhancers';
import { getUIProperty } from '../../../reducers';
import { setUIData } from '../../../actions';

import case1Image from '../../../styles/images/cases/case1.jpg';
import case2Image from '../../../styles/images/cases/case2.jpg';
import case3Image from '../../../styles/images/cases/case3.jpg';
import case4Image from '../../../styles/images/cases/case4.jpg';
import case5Image from '../../../styles/images/cases/case5.jpg';
import case6Image from '../../../styles/images/cases/case6.jpg';
import case7Image from '../../../styles/images/cases/case7.jpg';
import case8Image from '../../../styles/images/cases/case8.jpg';
import { Text } from '../../../styles/GenericStyled';
import { ImageButton, ItemList, ToolbarSection } from '../editor/ExhibitionEditStyled';

const DISPLAY_CASES = [
  { type: 1, image: case1Image },
  { type: 2, image: case2Image },
  { type: 3, image: case3Image },
  { type: 4, image: case4Image },
  { type: 5, image: case5Image },
  { type: 6, image: case6Image },
  { type: 7, image: case7Image },
  { type: 8, image: case8Image },
];

const CasesView = ({
  placingCase,
  placingArtifact,
  onPlaceDisplayCase = () => Promise.resolve(false),
  onStopPlacing = () => Promise.resolve(false),
}) => {
  return (
    <ToolbarSection>
      <Text type="h7">
        {__('display_cases')} ({DISPLAY_CASES.length})
      </Text>
      <ItemList>
        {DISPLAY_CASES.map(({ type, image }) => (
          <ImageButton
            key={type}
            style={{ backgroundImage: `url(${image})` }}
            draggable={!placingArtifact && !placingCase}
            onDragEnd={({ dataTransfer: { dropEffect = 'none' } = {} }) =>
              onStopPlacing().then(
                () => dropEffect !== 'none' && placingCase !== type && onPlaceDisplayCase(type),
              )
            }
            inactive={+(placingArtifact || (placingCase && placingCase !== type)) || +false}
            selected={+(placingCase === type)}
            onKeyPress={event =>
              event.keyCode === 13 &&
              (placingCase === type ? onStopPlacing() : onPlaceDisplayCase(type))
            }
            onClick={() => (placingCase === type ? onStopPlacing() : onPlaceDisplayCase(type))}
          />
        ))}
      </ItemList>
    </ToolbarSection>
  );
};

const mapState = (state, { exhibitionId }) => ({
  placingCase: getUIProperty(state, `exhibitions/${exhibitionId}/placingCase`),
  placingArtifact: getUIProperty(state, `exhibitions/${exhibitionId}/placingArtifact`),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onPlaceDisplayCase: displayCase =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingCase: displayCase,
        placingArtifact: undefined,
      }),
    ),
  onStopPlacing: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingCase: undefined,
        placingArtifact: undefined,
      }),
    ),
});

const Cases = compose(withState(mapState), withDispatch(mapDispatch))(CasesView);

export default Cases;

import React from 'react';
import Grid from '@material-ui/core/Grid';
// import { __ } from 'artsteps2-common';
import InputField from '../../generic/forms/InputField';
import TagsInput from '../fields/TagsInput';
import InputSelect from '../../generic/forms/InputSelect';
import { VerticalItems } from './FormStyled';
import FilePreview from '../previews/FilePreview';
import utils from '../../../utils';
import InteractiveField from '../fields/InteractiveField';
import AudioField from '../fields/AudioField';

const alignments = [
  { value: 1, name: 'Left' },
  { value: 2, name: 'Center' },
  { value: 3, name: 'Right' },
];

const TextEditor = ({
  formData,
  handleChange,
  files,
  audioFiles,
  setFiles = () => Promise.resolve(true),
  setAudioFiles = () => Promise.resolve(true),
  setThumbnail = () => Promise.resolve(true),
}) => {
  const toFormValue = text => {
    setFiles([{ bin: utils.buffer.fromUTF8String(text, 'text.txt') }]);
  };

  const addColorToModel = e =>
    handleChange({
      target: { name: 'model', value: { ...formData.model, [e.target.name]: e.target.value } },
    });

  const addAlignmentToModel = e =>
    handleChange({
      target: {
        name: 'model',
        value: { ...formData.model, textProperties: { alignment: e.target.value - 1 } },
      },
    });

  return (
    <>
      <Grid item xs={12} md={6}>
        <VerticalItems>
          <InputField
            required
            handleChange={e => toFormValue(e.target.value)}
            value={files && files.length > 0 ? utils.buffer.toUTF8String(files[0].bin) : ''}
            name="text"
            textArea
            label="Text"
          />
          <input
            style={{ width: '100%' }}
            type="color"
            name="colourTint"
            onChange={addColorToModel}
            value={formData.model ? formData.model.colourTint : '#ffffff'}
          />
          <InputSelect
            handleChange={addAlignmentToModel}
            value={
              formData.model && formData.model.textProperties
                ? formData.model.textProperties.alignment + 1
                : alignments[0].value
            }
            label="Alignment"
            hint="Text alignment"
            options={alignments}
          />
          <FilePreview
            textColor={formData.model ? formData.model.colourTint : ''}
            textAlignment={
              formData.model && formData.model.textProperties
                ? formData.model.textProperties.alignment
                : alignments[0].value - 1
            }
            uploadOption="text"
            setThumbnail={setThumbnail}
            setFiles={setFiles}
            showDelete={false}
            files={files}
            type={formData.type}
          />
        </VerticalItems>
      </Grid>
      <Grid item xs={12} md={6}>
        <VerticalItems>
          <InputField
            handleChange={handleChange}
            value={formData.title}
            name="title"
            label="Title"
            required
          />
          <InteractiveField
            handleChange={handleChange}
            formData={formData}
            checked={formData.model && formData.model.nonInteractive}
          />
          <AudioField setAudioFiles={setAudioFiles} audioFiles={audioFiles} />
          <InputField
            handleChange={handleChange}
            value={formData.caption}
            name="caption"
            label={
              <>
                Description{' '}
                <small style={{ fontSize: '12px', color: 'red' }}>
                  (Use hyperlinks by acquiring a premium 3D space template)
                </small>
              </>
            }
            textArea
          />
          <TagsInput
            tags={formData.tags}
            handleChange={handleChange}
            label="Artifact Tags"
            hint="Categorize your artifacts"
          />
        </VerticalItems>
      </Grid>
    </>
  );
};

export default TextEditor;

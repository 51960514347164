export const MESSAGES_NAMESPACE = 'messages';

export const ACTION_ADD = 'ADD';
export const ACTION_DELETE = 'DELETE';
export const ACTION_CLEAR = 'CLEAR';
export const ACTION_INVALIDATE = 'INVALIDATE';

export const MESSAGE_DELETION_TIMEOUT = 4000;

export const addMessage = ({ title, type, description }, group) => (dispatch, getState) => {
  const message = { title, type, description, timestamp: Date.now() };

  setTimeout(
    () =>
      dispatch({
        type: `${MESSAGES_NAMESPACE}/${ACTION_DELETE}`,
        group,
        message,
      }),
    MESSAGE_DELETION_TIMEOUT,
  );

  return Promise.resolve(
    dispatch({
      type: `${MESSAGES_NAMESPACE}/${ACTION_ADD}`,
      group,
      message,
    }),
  );
};

export const deleteMessage = (message, group) => (dispatch, getState) =>
  Promise.resolve(
    dispatch({
      type: `${MESSAGES_NAMESPACE}/${ACTION_DELETE}`,
      group,
      message,
    }),
  );

export const clearMessages = group => (dispatch, getState) =>
  Promise.resolve(
    dispatch({
      type: `${MESSAGES_NAMESPACE}/${ACTION_CLEAR}`,
      group,
    }),
  );

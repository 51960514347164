import React from 'react';
import RichTextEditor from 'react-rte';
import { __ } from 'artsteps2-common';
import utils from '../../../utils';
import Link from '../../generic/Link';
import Dialog from '../../generic/Dialog';
import Loader from '../../generic/Loader';
import Uploader from '../../generic/forms/Uploader';
import {
  apiGET,
  apiPATCH,
  apiDELETE,
  setFormData,
  handleFormPropertyChange,
  setUIProperty,
  addMessage,
  setLocation,
} from '../../../actions';
import {
  API_STATUS,
  getApiStatus,
  getApiResource,
  getFormProperty,
  getUIProperty,
  getAuthUserRoles,
} from '../../../reducers';
import {
  compose,
  withState,
  withDispatch,
  withLifecycle,
  withLocalState,
} from '../../../enhancers';

export const ArticleView = ({
  article = {},
  categories = [],
  ready = true,
  editable = false,
  isEditing = false,
  isDialogOpen = false,
  editedText = '',
  editedTitle = '',
  editedImages = [],
  editedCategory = '',
  editorState = null,
  redirectionURL = '/categories',
  onEditorStateChange = () => false,
  onArticleTextChange = () => Promise.resolve(false),
  onArticleTitleChange = () => Promise.resolve(false),
  onArticleImageChange = () => Promise.resolve(false),
  onArticleCategoryChange = () => Promise.resolve(false),
  onStartEditing = () => Promise.resolve(false),
  onStopEditing = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  onOpenDialog = () => Promise.resolve(false),
  onCloseDialog = () => Promise.resolve(false),
  onDelete = () => Promise.resolve({ response: { error: 'error' } }),
  onUpdate = () => Promise.resolve({ response: { error: 'error' } }),
  onRedirect = () => Promise.resolve(false),
}) => {
  if (!ready) {
    return <Loader />;
  }

  const createPath = (cref = {}) =>
    cref._id
      ? [
          ...createPath(
            categories.find(c => cref.parent && c._id === (cref.parent._id || cref.parent)),
          ),
          cref,
        ]
      : [];

  const onSubmit = () =>
    onUpdate({
      title: editedTitle,
      text: editedText,
      category: editedCategory,
      images: editedImages,
    }).then(({ response }) => {
      if (response.error) {
        return onAddMessage({
          type: 'error',
          title: __('article_update_err'),
          description: __(response.error),
        });
      }

      utils.dialogflow.updateIntent(`articles/${article._id}/text`, [editedTitle]);
      return onStopEditing().then(() => onEditorStateChange(null));
    });

  const onConfirmDelete = () =>
    onDelete().then(response => {
      if (!response.error) {
        onCloseDialog();
        return onRedirect(redirectionURL);
      }
      return onAddMessage({
        title: __('article_deletion'),
        description: __(response.error),
        type: 'error',
      });
    });

  const getImageSrc = ({ uri, bin = {} }, index) =>
    uri || bin.preview || `/api/articles/${article._id}/images/${index}/bin`;

  const renderText = () => (
    <div className="ui main segment vertical form">
      {article.images && article.images.length > 0 && (
        <div className="ui segment basic center aligned">
          {article.images.map((image, index) => (
            <img
              key={index}
              className="ui image centered"
              src={getImageSrc(image, index)}
              alt={article.title}
            />
          ))}
        </div>
      )}
      {utils.text.fromHtml(article.text)}
    </div>
  );

  const renderEditableText = () => (
    <div className="ui main segment vertical form">
      <div className="ui field inline">
        <label htmlFor="images">{__('images')}</label>
        <Uploader
          name="images"
          type="image"
          accept="image/*"
          multiple
          allowURI={false}
          fixedUploadType="file"
          value={editedImages}
          preview={getImageSrc}
          onChange={onArticleImageChange}
        />
      </div>
      <RichTextEditor
        name="text"
        value={editorState || RichTextEditor.createValueFromString(editedText, 'html')}
        onChange={value => {
          onEditorStateChange(value);
          onArticleTextChange({
            target: { name: 'text', value: value.toString('html') },
          });
        }}
      />
      <div className="ui segment vertical right aligned">
        <button
          className="ui compact secondary button small"
          data-tooltip={__('discard_changes')}
          data-inverted
          onClick={() => onStopEditing().then(() => onEditorStateChange(null))}
        >
          <i className="undo icon" />
          {__('cancel')}
        </button>
        <button
          className="ui compact secondary button small"
          data-tooltip={__('save_changes')}
          data-inverted
          onClick={onSubmit}
        >
          <i className="save icon" />
          {__('save')}
        </button>
      </div>
    </div>
  );

  return (
    <div className="ui segment vertical">
      <h1>
        <div className="section">
          {editable && isEditing && (
            <div className="ui input">
              <input type="text" name="title" value={editedTitle} onChange={onArticleTitleChange} />
            </div>
          )}
          {(!editable || !isEditing) && article.title}
        </div>
        {editable && !isEditing && (
          <div className="ui section">
            <button
              className="bare icon"
              data-tooltip={__('edit_this_article')}
              data-inverted=""
              onClick={onStartEditing}
            >
              <i className="edit icon small" />
            </button>
            <button
              className="bare icon"
              data-tooltip={__('delete_this_article')}
              data-inverted=""
              onClick={onOpenDialog}
            >
              <i className="trash alternate outline icon small" />
            </button>
          </div>
        )}
      </h1>
      <div className="ui breadcrumb">
        {editable && isEditing && (
          <div className="section">
            <select name="category" value={editedCategory} onChange={onArticleCategoryChange}>
              {categories.map(c =>
                !c.parent ? (
                  <option key={c._id} value={c._id}>
                    {c.title}
                  </option>
                ) : (
                  <option key={c._id} value={c._id}>
                    {createPath(c)
                      .map(cat => cat.title)
                      .join('/')}
                  </option>
                ),
              )}
            </select>
            <div className="divider">/</div>
          </div>
        )}
        {(!editable || !isEditing) &&
          createPath(
            categories.find(
              c =>
                article.category && (c._id === article.category || c._id === article.category._id),
            ),
          ).map(c => (
            <div className="section" key={c._id}>
              <Link exact to={`/categories/${c.slug || c._id}`}>
                {c.title}
              </Link>
              <div className="divider">/</div>
            </div>
          ))}
        <div className="section">
          {editable && isEditing && (
            <div className="ui input">
              <input type="text" name="title" value={editedTitle} onChange={onArticleTitleChange} />
            </div>
          )}
          {/*{(!editable || !isEditing) && article.title}*/}
        </div>
      </div>

      {isEditing ? renderEditableText() : renderText()}
      <Dialog
        open={editable && isDialogOpen}
        title={__('article_deletion')}
        message={__('confirm_article_deletion', article)}
        type="warning"
        onConfirm={onConfirmDelete}
        onReject={onCloseDialog}
      />
    </div>
  );
};

const EDITOR_ROLES = ['editor', 'admin'];

const mapState = (state, { articleId, editable }) => ({
  article: getApiResource(state, `articles/${articleId}`),
  categories: Object.values(getApiResource(state, 'articleCategories')),
  ready:
    getApiStatus(state, `articles/${articleId}`) === API_STATUS.IDLE &&
    getApiStatus(state, 'categories') === API_STATUS.IDLE,
  editable: editable && getAuthUserRoles(state).some(role => EDITOR_ROLES.includes(role)),
  isEditing: getUIProperty(state, `articles/${articleId}/edit`),
  isDialogOpen: getUIProperty(state, `articles/${articleId}/displayDialog`),
  editedText: getFormProperty(state, `article:${articleId}`, 'text'),
  editedTitle: getFormProperty(state, `article:${articleId}`, 'title'),
  editedImages: getFormProperty(state, `article:${articleId}`, 'images'),
  editedCategory: getFormProperty(state, `article:${articleId}`, 'category'),
});

const mapDispatch = (dispatch, { articleId, article }) => ({
  onFetchArticle: () => dispatch(apiGET(`articles/${articleId}`)),
  onArticleTextChange: e => dispatch(handleFormPropertyChange(`article:${articleId}`, e)),
  onArticleTitleChange: e => dispatch(handleFormPropertyChange(`article:${articleId}`, e)),
  onArticleImageChange: e => dispatch(handleFormPropertyChange(`article:${articleId}`, e)),
  onArticleCategoryChange: e => dispatch(handleFormPropertyChange(`article:${articleId}`, e)),
  onStartEditing: () =>
    dispatch(setFormData(`article:${articleId}`, article)).then(() =>
      dispatch(setUIProperty(`articles/${articleId}/edit`, true)),
    ),
  onStopEditing: () => dispatch(setUIProperty(`articles/${articleId}/edit`, false)),
  onAddMessage: message => dispatch(addMessage(message)),
  onOpenDialog: () => dispatch(setUIProperty(`articles/${articleId}/displayDialog`, true)),
  onCloseDialog: () => dispatch(setUIProperty(`articles/${articleId}/displayDialog`, false)),
  onDelete: () => dispatch(apiDELETE(`articles/${articleId}`)),
  onUpdate: data => dispatch(apiPATCH(`articles/${articleId}`, data)),
  onRedirect: location => dispatch(setLocation(location)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchArticle }) => {
    onFetchArticle();
    // add class to body to show cookie script settings
    if (window.location.href.indexOf('privacy-policy') > -1) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('show_cookie_settings');
    }
  },
  onDidUpdate: (props, { articleId, onFetchArticle }) =>
    articleId !== props.articleId && onFetchArticle(),
};

const Article = compose(
  withLocalState('editorState', 'onEditorStateChange', null),
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ArticleView);

export default Article;

import React from 'react';
import { HorizontalItems, Text, StyledFormLabel } from '../../../styles/GenericStyled';
import { StyledTextArea, StyledTextField } from '../../artifacts/forms/FormStyled';
import HelpIcon from '@material-ui/icons/Help';
import MyToolTip from '../MyToolTip';

const InputField = ({
  id,
  name,
  value,
  label,
  textArea,
  hint,
  min,
  max,
  isNumber,
  handleChange,
  disabled,
  required,
}) => {
  const extraProps = isNumber ? { inputProps: { min, max, step: '0.1' }, type: 'number' } : {};
  return (
    <div style={{ width: '100%', flexDirection: 'column' }}>
      {label && (
        <HorizontalItems>
          <StyledFormLabel required={required}>
            <Text>{label}</Text>
          </StyledFormLabel>
          {hint && (
            <MyToolTip placement="right" title={hint}>
              <HelpIcon style={{ marginLeft: '8px' }} />
            </MyToolTip>
          )}
        </HorizontalItems>
      )}
      {textArea ? (
        <StyledTextArea
          {...extraProps}
          disabled={disabled}
          id={id}
          name={name}
          value={value || ''}
          onChange={handleChange}
        />
      ) : (
        <StyledTextField
          {...extraProps}
          disabled={disabled}
          id={id}
          name={name}
          value={value || ''}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default InputField;

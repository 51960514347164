import React from 'react';
import Overlay from '../../generic/Overlay';
import StorypointEditor from '../forms/StorypointEditor';
import utils from '../../../utils';
import { compose, withState, withDispatch } from '../../../enhancers';
import { apiPATCH, addMessage, setUIProperty, setUIData } from '../../../actions';
import { getUIProperty, getAuthUser } from '../../../reducers';
import { Item } from '../../exhibitions/editor/ExhibitionEditStyled';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EditItem from '../../exhibitions/editor/EditItem';

const DEFAULT_COLOUR = '#cccccc';

export const StorypointView = ({
  storypoint = {},
  placing = false,
  dragging = false,
  draggingOver = false,
  controls = false,
  disabled = false,
  selected = false,
  editorOpen = false,
  currentUser = {},
  onStorypointPlacement = () => Promise.resolve(false),
  onStorypointSelection = () => Promise.resolve(false),
  onStorypointDragStart = () => Promise.resolve(false),
  onStorypointDragEnd = () => Promise.resolve(false),
  onStorypointDragEnter = () => Promise.resolve(false),
  onStorypointDragLeave = () => Promise.resolve(false),
  onStorypointDrop = () => Promise.resolve(false),
  onEditorClose = () => Promise.resolve(false),
  onEditorOpen = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
}) => (
  <Item
    selected={+(selected || placing)}
    inactive={+disabled}
    dragging={+dragging}
    draggingOver={+draggingOver}
    background={storypoint.colour || DEFAULT_COLOUR}
    draggable
    onClick={() => onStorypointPlacement().then(() => onStorypointSelection())}
    onKeyPress={event =>
      event.keyCode === 13 &&
      !disabled &&
      onStorypointPlacement().then(() => onStorypointSelection())
    }
    onDoubleClick={() => controls && onEditorOpen()}
    onDragStart={event => {
      const dragEvent = event;
      dragEvent.dataTransfer.effectAllowed = 'move';
      dragEvent.dataTransfer.setData('storypointId', storypoint._id);
      dragEvent.dataTransfer.setData('storypointOrder', storypoint.order);
      return onStorypointDragStart();
    }}
    onDragEnd={onStorypointDragEnd}
    onDragOver={e => {
      e.preventDefault();
    }}
    onDragEnter={onStorypointDragEnter}
    onDragLeave={onStorypointDragLeave}
    onDrop={event =>
      onStorypointDrop(
        event.dataTransfer.getData('storypointId'),
        event.dataTransfer.getData('storypointOrder'),
      )
    }
    data-order={storypoint.order}
  >
    <LocationOnIcon fontSize="large" />
    {controls && <EditItem onEditorOpen={onEditorOpen} />}

    <Overlay fixed open={editorOpen} onClose={onEditorClose}>
      <StorypointEditor
        onClose={onEditorClose}
        onSave={onEditorClose}
        storypointId={storypoint._id}
      />
    </Overlay>
  </Item>
);

const mapState = (state, { exhibitionId, storypoint = {} }) => {
  const placingStorypoint = getUIProperty(state, `exhibitions/${exhibitionId}/placingStorypoint`);
  const selectedStorypoint = getUIProperty(state, `exhibitions/${exhibitionId}/selectedStorypoint`);
  const draggingStorypoint = getUIProperty(state, `exhibitions/${exhibitionId}/draggingStorypoint`);
  const draggingOverStorypoint = getUIProperty(
    state,
    `exhibitions/${exhibitionId}/draggingOverStorypoint`,
  );
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    editorOpen: getUIProperty(state, `storypoints/${storypoint._id}/editor`),
    placing:
      placingStorypoint && (placingStorypoint.storyPointId || placingStorypoint) === storypoint._id,
    disabled:
      (draggingStorypoint && draggingStorypoint._id !== storypoint._id) ||
      (placingStorypoint &&
        (placingStorypoint.storyPointId || placingStorypoint) !== storypoint._id),
    selected:
      selectedStorypoint &&
      (selectedStorypoint.storyPointId || selectedStorypoint) === storypoint._id,
    dragging: draggingStorypoint && draggingStorypoint.storyPointId === storypoint._id,
    draggedOver: draggingStorypoint,
    draggingOver: draggingOverStorypoint && draggingOverStorypoint.storyPointId === storypoint._id,
  };
};

const mapDispatch = (dispatch, { storypoint = {}, exhibitionId, placing, currentUser }) => ({
  onStorypointPlacement: () =>
    utils.exhibition
      .exportStorypoint(storypoint)
      .then(exportedStorypoint =>
        dispatch(
          setUIProperty(
            `exhibitions/${exhibitionId}/placingStorypoint`,
            placing ? undefined : exportedStorypoint,
          ),
        ),
      ),
  onStorypointSelection: () =>
    utils.exhibition
      .exportStorypoint(storypoint)
      .then(exportedStorypoint =>
        dispatch(
          setUIProperty(`exhibitions/${exhibitionId}/selectedStorypoint`, exportedStorypoint),
        ),
      ),
  onEditorOpen: () => dispatch(setUIProperty(`storypoints/${storypoint._id}/editor`, true)),
  onEditorClose: () => dispatch(setUIProperty(`storypoints/${storypoint._id}/editor`, false)),
  onAddMessage: message => dispatch(addMessage(message, 'storypoints')),
  onStorypointDragStart: () =>
    utils.exhibition
      .exportStorypoint(storypoint)
      .then(exportedStorypoint =>
        dispatch(
          setUIProperty(`exhibitions/${exhibitionId}/draggingStorypoint`, exportedStorypoint),
        ),
      ),
  onStorypointDragEnd: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/draggingStorypoint`, undefined)),
  onStorypointDragEnter: () =>
    utils.exhibition
      .exportStorypoint(storypoint)
      .then(exportedStorypoint =>
        dispatch(
          setUIProperty(`exhibitions/${exhibitionId}/draggingOverStorypoint`, exportedStorypoint),
        ),
      ),
  onStorypointDragLeave: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/draggingOverStorypoint`, undefined)),
  onStorypointDrop: (storypointId, storypointOrder) =>
    dispatch(apiPATCH(`storypoints/${storypointId}`, { order: storypoint.order }))
      .then(() => dispatch(apiPATCH(`storypoints/${storypoint._id}`, { order: storypointOrder })))
      .then(() =>
        dispatch(
          setUIData(`exhibitions/${exhibitionId}`, {
            draggingStorypoint: undefined,
            draggingOverStorypoint: undefined,
          }),
        ),
      ),
});

const Storypoint = compose(withState(mapState), withDispatch(mapDispatch))(StorypointView);

export default Storypoint;

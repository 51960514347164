import React, { useEffect } from 'react';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getUIProperty } from '../../../reducers';
import { setUIProperty } from '../../../actions/ui';

const MonitorProgressView = ({ audioRef, progress = 0, onSetEnabledAudio }) => {
  useEffect(() => {
    if (progress === 1 && !audioRef.current.isPlaying)
      audioRef.current
        .play()
        .then(() => {})
        .catch(() => onSetEnabledAudio(audioRef, true));
  }, [progress, audioRef]);

  return <></>;
};

const mapState = (state, { exhibitionId }) => ({
  progress: getUIProperty(state, `exhibitions/${exhibitionId}/progress`),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onSetEnabledAudio: (ref, show) =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/enabledAudio`, [ref, show])),
});

const MonitorProgress = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(MonitorProgressView);

export default MonitorProgress;

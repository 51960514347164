import React from 'react';

import DefaultHeader from './RedesignedHeader';
// import DefaultHeader from './DefaultHeader';
import DefaultFooter from './RedesignedFooter';
import ContributeModal from '../modals/ContributeModal';

export const DefaultLayoutPresenter = ({
  children,
  exhibitionId,
  exhibition,
  curatorView = false,
}) => (
  <div className="layout default">
    <DefaultHeader exhibitionId={exhibitionId} exhibition={exhibition} curatorView={curatorView} />
    {/* <DefaultHeader /> */}
    <main className="layout-body">{children}</main>
    <ContributeModal />

    <DefaultFooter />
  </div>
);

const DefaultLayout = DefaultLayoutPresenter;

export default DefaultLayout;

import React, { useState } from 'react';
import OurModal from '../generic/OurModal';
import { BodyWithPadding, Text } from '../../styles/GenericStyled';
import { history } from '../../store';
import { __ } from 'artsteps2-common';
import COLORS from '../../styles/colors';

const VerifyModal = () => {
  const [openVerify, setOpenVerify] = useState(true);

  return (
    <OurModal
      width="25%"
      height="15%"
      minWidth="300px"
      minHeight="200px"
      openModal={openVerify}
      setOpenModal={setOpenVerify}
      customClose={() => history.push('/')}
    >
      <BodyWithPadding padding="40px">
        <Text type="h6" align="center" style={{ paddingBottom: '16px' }}>
          Registration successfull!
        </Text>
        <Text type="h7" mycolor={COLORS.ourRed} align="center">
          Please verify your account.
        </Text>
        <Text type="h7" align="center">
          {__('no_verification_email')} Click{' '}
          <a href="/verification-recovery" style={{ textDecoration: 'underline' }}>
            here
          </a>
        </Text>
      </BodyWithPadding>
    </OurModal>
  );
};

export default VerifyModal;

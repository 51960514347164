import React, { useState, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Grid from '@material-ui/core/Grid';
import { __ } from 'artsteps2-common';
import utils from '../../utils';
import ImageEditor from './forms/ImageEditor';
import VideoEditor from './forms/VideoEditor';
import ObjectEditor from './forms/ObjectEditor';
import TextEditor from './forms/TextEditor';
import Loader from '../generic/Loader';
import Dialog from '../generic/Dialog';
import { compose, withState, withDispatch } from '../../enhancers';
import {
  API_STATUS,
  getApiStatus,
  getUIProperty,
  getForm,
  getAuthUser,
  getApiResource,
} from '../../reducers';
import {
  apiPOST,
  apiPATCH,
  apiGET,
  apiDELETE,
  addMessage,
  clearMessages,
  setUIProperty,
} from '../../actions';
import { BodyWithPadding, HorizontalItems, StyledButton } from '../../styles/GenericStyled';
import colors from '../../styles/colors';
import ArtifactEditorLeft from './forms/ArtifcactEditorLeft';
import { toMeters } from '../../reusableFunctions/dimensionsConvertions';
import publicConfig from 'artsteps2-config/public.json';

const SIZE_LIMIT = publicConfig.uploader.payloadSize;

export const ArtifactEditorView = ({
  type,
  licenses,
  form = { data: {} },
  saving = false,
  ready = false,
  removeDialogOpen = false,
  isNew = false,
  onUpsert = () => Promise.resolve({ response: { error: 'error' } }),
  onSave = () => Promise.resolve(false),
  onClose = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  onRemoveDialogOpen = () => Promise.resolve(false),
  onRemoveDialogClose = () => Promise.resolve(false),
  onRemove = () => Promise.resolve(false),
  onClearFormData = () => Promise.resolve(false),
  onFetchArtifact = () => Promise.resolve(false),
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({ type });
  const [files, setFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState();
  const [audioFiles, setAudioFiles] = useState([]);
  const [uploadOption, setUploadOption] = useState('file');
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [uploadOptions, setUploadOptions] = useState([
    { value: 'file', name: 'File', _id: 0 },
    { value: 'url', name: 'Url', _id: 1 },
  ]);
  // Upload options

  useEffect(() => {
    switch (formData.type) {
      case 'image':
        setAcceptedFiles(['.jpg', '.jpeg', '.png']);
        setUploadOptions(prev => [...prev, { value: 'flickr', name: 'Flickr', _id: 3 }]);
        return;
      case 'video':
        setAcceptedFiles(['.mp4', '.webm', '.ogg']);
        setUploadOptions(prev => [
          ...prev,
          { value: 'youtube', name: 'Youtube', _id: 3 },
          { value: 'streamable', name: 'Streamable', _id: 4 },
        ]);
        return;
      case 'object':
        setAcceptedFiles([
          '.obj',
          '.mtl',
          '.bin',
          '.gltf',
          '.glb',
          '.tiff',
          '.jpg',
          '.jpeg',
          '.png',
        ]);
        return;
    }
  }, [formData.type]);

  // On initialization
  useEffect(() => {
    onFetchArtifact().then(({ response: artifact }) => {
      if (artifact) {
        setFiles(artifact.files);
        setAudioFiles(artifact.audio ? [artifact.audio] : undefined);
        setThumbnail(artifact.image);
        setFormData({
          ...artifact,
          files: undefined,
          audio: undefined,
        });
        setUploadOption(artifact.uploadOption);
      }
    });
  }, []); //Must be empty

  useEffect(() => {
    if (formData) setIsFormValid(formData.title && files && files.length > 0 && thumbnail);
  }, [formData.title, files, thumbnail, formData]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: type === 'checkbox' ? !checked : value,
    }));
  };

  const mapFormData = data => {
    return {
      ...data,
      audioFiles: undefined,
      thumbnail: undefined,
      files:
        data.files &&
        data.files.map(file => ({
          bin: file.bin && file.bin.content && { ...file.bin, preview: undefined },
          uri: file.uri,
          file: file.file,
        })),
      covers:
        data.covers &&
        data.covers.map(cover => ({
          bin: cover.bin && cover.bin.content && cover.bin,
          uri: cover.uri,
        })),
      externalPreview: data.externalPreview,
      audio: data.audioFiles &&
        data.audioFiles.length > 0 && { ...data.audioFiles[0], base64: undefined },
      dimensions:
        data.metric === 'meters' || !data.metric
          ? data.dimensions
          : {
              width: toMeters(data.dimensions ? data.dimensions.width : undefined),
              height: toMeters(data.dimensions ? data.dimensions.height : undefined),
              depth: toMeters(data.dimensions ? data.dimensions.depth : undefined),
            },
      image: data.thumbnail,
    };
  };

  const onSubmit = () => {
    setIsFormValid(false);
    const dataToSend = mapFormData({ ...formData, files, audioFiles, uploadOption, thumbnail });

    //const size = new TextEncoder().encode(JSON.stringify(dataToSend)).length;
    //const filesSize = files?.map(f => f.size).reduce((prev, next) => prev + next, 0);
    let filesSize = 0;
    if (formData.type === 'image') {
      filesSize = new TextEncoder().encode(JSON.stringify(files?.map(f => f?.bin?.content))).length;
    } else {
      filesSize = files?.map(f => f.size).reduce((prev, next) => prev + next, 0);
    }
    const audiofilesSize = audioFiles?.map(f => f.size).reduce((prev, next) => prev + next, 0);
    const size = audiofilesSize + filesSize;

    if (filesSize > SIZE_LIMIT / 2) {
      onAddMessage({
        title: `Maximum file upload size: ${(SIZE_LIMIT / 2000000).toFixed(2)}MB`,
        description: `Your file payload size is ${(filesSize / 1000000).toFixed(2)}MB.`,
        type: 'error',
      });
    } else if (audiofilesSize > SIZE_LIMIT / 2) {
      onAddMessage({
        title: `Maximum audio upload size: ${(SIZE_LIMIT / 2000000).toFixed(2)}MB`,
        description: `Your audio payload size is ${(audiofilesSize / 1000000).toFixed(2)}MB.`,
        type: 'error',
      });
    } else if (size > SIZE_LIMIT) {
      onAddMessage({
        title: `Maximum total upload size: ${(SIZE_LIMIT / 1000000).toFixed(2)}MB`,
        description: `Your total payload size is ${(size / 1000000).toFixed(2)}MB.`,
        type: 'error',
      });
    } else {
      return onUpsert(dataToSend).then(({ response }) => {
        setIsFormValid(true);
        return response.error
          ? onAddMessage({ title: __(response.error), type: 'error' })
          : onClearFormData().then(() => {
              onSave(response);
              onAddMessage({ title: 'Saved Artifact', type: 'success' });
            });
      });
    }
  };

  return (
    <>
      <HorizontalItems style={{ marginRight: '20px' }}>
        <h2>
          {__(isNew ? 'add' : 'edit')}
          &nbsp;
          {__(formData.type)}
        </h2>
        <div style={{ marginLeft: 'auto' }}>
          {!isNew && (
            <StyledButton
              mycolor={colors.ourOrange}
              background={colors.ourWhite}
              bordercolor={colors.ourOrange}
              onClick={isNew ? undefined : onRemoveDialogOpen}
            >
              <DeleteForeverIcon style={{ marginRight: '4px' }} />
              Delete
            </StyledButton>
          )}
          <StyledButton
            background={colors.ourGreen}
            disabled={!isFormValid}
            style={{ marginLeft: '8px' }}
            onClick={onSubmit}
          >
            <SaveIcon style={{ marginRight: '4px' }} />
            Save
          </StyledButton>
        </div>
      </HorizontalItems>
      <div className="artifact-info">
        <BodyWithPadding padding="24px">
          {ready ? (
            <Grid
              container
              spacing={5}
              direction="row"
              justify="space-evenly"
              alignItems="flex-start"
            >
              {formData.type !== 'text' && (
                <ArtifactEditorLeft
                  key={formData.type}
                  uploadOption={uploadOption}
                  setUploadOption={setUploadOption}
                  type={formData.type}
                  files={files}
                  setFiles={setFiles}
                  setThumbnail={setThumbnail}
                  audioFiles={audioFiles}
                  setAudioFiles={setAudioFiles}
                  options={uploadOptions}
                  maxFiles={formData.type === 'object' ? 6 : 1}
                  acceptedFiles={acceptedFiles}
                />
              )}
              {formData.type === 'image' && (
                <ImageEditor
                  file={files.length > 0 ? files[0] : {}}
                  formData={formData}
                  handleChange={handleChange}
                  licenses={licenses}
                  onClose={onClose}
                />
              )}
              {formData.type === 'video' && (
                <VideoEditor
                  formData={formData}
                  handleChange={handleChange}
                  licenses={licenses}
                  onClose={onClose}
                />
              )}
              {formData.type === 'object' && (
                <ObjectEditor
                  formData={formData}
                  handleChange={handleChange}
                  licenses={licenses}
                  onClose={onClose}
                />
              )}
              {formData.type === 'text' && (
                <>
                  <TextEditor
                    audioFiles={audioFiles}
                    setAudioFiles={setAudioFiles}
                    setThumbnail={setThumbnail}
                    files={files}
                    setFiles={setFiles}
                    formData={formData}
                    handleChange={handleChange}
                    licenses={licenses}
                    onClose={onClose}
                  />
                </>
              )}
            </Grid>
          ) : (
            <Loader />
          )}
        </BodyWithPadding>
        {saving && <Loader message={`${__('saving')} ${saving}`} />}
        <Dialog
          open={removeDialogOpen}
          title={__('artifact_deletion', form.data)}
          message={__('confirm_artifact_deletion', form.data)}
          type="warning"
          onReject={onRemoveDialogClose}
          onConfirm={() =>
            onRemoveDialogClose()
              .then(() => onRemove())
              .then(({ response: { error } = {} }) =>
                error ? onAddMessage({ type: 'error', title: __(error) }) : Promise.resolve(true),
              )
              .then(() => onClose())
          }
        />
      </div>
    </>
  );
};
const toFormName = artifactId => `artifact:${artifactId}`;
const createQuery = ({ _id: user } = {}) => ({ filter: { user } });

const mapState = (state, { artifactId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    ready:
      getApiStatus(state, `artifacts/${artifactId}`) === API_STATUS.IDLE &&
      getApiStatus(state, 'artifacts', createQuery(currentUser)) === API_STATUS.IDLE,
    form: getForm(state, toFormName(artifactId)),
    isNew: artifactId && artifactId.match(/^new/),
    removeDialogOpen: getUIProperty(state, `artifacts/${artifactId}/removeDialog`),
    licenses: Object.values(getApiResource(state, 'licenses')),
  };
};

const mapDispatch = (
  dispatch,
  { artifactId, type = 'image', currentUser, exhibitionId, form, isNew },
) => ({
  onAddMessage: message => dispatch(addMessage(message, 'artifacts')),
  onClearMessages: () => dispatch(clearMessages('artifacts')),
  onUpsert: data =>
    isNew
      ? dispatch(apiPOST('artifacts', data, createQuery(currentUser)))
      : dispatch(apiPATCH(`artifacts/${artifactId}`, data)),
  onFetchArtifact: () =>
    isNew
      ? Promise.resolve({})
      : dispatch(
          apiGET(`artifacts/${artifactId}`, {
            include: type === 'text' && 'files.bin.content',
          }),
        ),
  onRemoveDialogOpen: () => dispatch(setUIProperty(`artifacts/${artifactId}/removeDialog`, true)),
  onRemoveDialogClose: () => dispatch(setUIProperty(`artifacts/${artifactId}/removeDialog`, false)),
  onRemove: () =>
    utils.exhibition
      .exportArtifact(form.data)
      .then(exportedArtifact =>
        dispatch(setUIProperty(`exhibitions/${exhibitionId}/removingArtifact`, exportedArtifact)),
      )
      .then(() => dispatch(apiDELETE(`artifacts/${artifactId}`))),
});

const ArtifactEditor = compose(withState(mapState), withDispatch(mapDispatch))(ArtifactEditorView);

export default ArtifactEditor;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createScene = exports.createOptions = exports.createCamera = exports.createLight = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _math = require("../math");

var _constants = _interopRequireDefault(require("./constants"));

var _tools = require("./tools");

var _assets = require("./assets");

var _artifacts = require("./artifacts");

var _polygons = require("./polygons");

var _cases = require("./cases");

var _room = require("./room");

var createLight = function createLight() {
  return {
    primitive: 'a-light',
    type: 'ambient',
    key: 'light-ambient',
    intensity: '1'
  };
};

exports.createLight = createLight;

var createCamera = function createCamera(exhibition) {
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var position = function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 3),
        x = _ref2[0],
        z = _ref2[2];

    return [x, 1.6, z];
  }((0, _math.mirror)((0, _math.parseQuaternion)(exhibition.startingPosition), opts.invertAxisPos));

  var rotation = function (_ref3) {
    var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
        pitch = _ref4[1];

    return [0, pitch + 180, 0];
  }((0, _math.toEulerianAngle)((0, _math.parseQuaternion)(exhibition.startingRotation)));

  return {
    primitive: 'a-entity',
    events: (0, _tools.createEvents)(events.camera, undefined, opts),
    key: 'exhibition-camera',
    position: position.join(' '),
    rotation: rotation.join(' '),
    camera: true,
    fov: 110,
    'mouse-cursor': true,
    'universal-controls': {
      movementControls: 'keyboard, hdm, gamepad, checkpoint',
      rotationControls: 'hdm, gamepad',
      rotationEnabled: false
    },
    'look-controls': {
      reverseMouseDrag: true,
      enabled: true
    },
    'kinematic-body': {
      radius: 0.5
    }
  };
};

exports.createCamera = createCamera;

var createOptions = function createOptions(exhibition) {
  if (!exhibition) {
    return undefined;
  }

  var startingRotation = (0, _math.toEulerianAngle)((0, _math.parseQuaternion)(exhibition.startingRotation));
  var invertByX = startingRotation[1] > 90 && startingRotation[1] < 270;
  return {
    invertAxisPos: invertByX ? [0] : [2],
    invertAxisRot: invertByX ? [0, 3] : [2, 3],
    invertScale: !invertByX
  };
};

exports.createOptions = createOptions;

var createScene = function createScene(exhibition) {
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (!exhibition) {
    return null;
  }

  var opts = createOptions(exhibition);
  return {
    primitive: 'a-scene',
    cursor: {
      rayOrigin: 'mouse'
    },
    key: 'exhibition-scene',
    embedded: true,
    stats: _constants["default"].DEBUG.STATS,
    background: "color: ".concat(_constants["default"].COLOUR.SKY),
    events: (0, _tools.createEvents)(events.scene, undefined, opts),
    'move-to-point': '',
    children: [{
      primitive: 'a-assets',
      key: 'exhibition-assets',
      timeout: _constants["default"].DEFAULT_ASSET_TIMEOUT,
      children: (0, _assets.createAssets)(exhibition, events, opts)
    }, {
      primitive: 'a-entity',
      key: 'exhibition-room',
      children: (0, _room.createRoom)(exhibition, events, opts)
    }, {
      primitive: 'a-entity',
      key: 'exhibition-building-polygons',
      children: (0, _polygons.createBuildingPolygons)(exhibition, events, opts)
    }, {
      primitive: 'a-entity',
      key: 'exhibition-artifacts',
      children: (0, _artifacts.createArtifacts)(exhibition, events, opts)
    }, {
      primitive: 'a-entity',
      key: 'exhibition-display-cases',
      children: (0, _cases.createDisplayCases)(exhibition, events, opts)
    }, createCamera(exhibition, events, opts), createLight()]
  };
};

exports.createScene = createScene;
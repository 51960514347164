import { compose, withLifecycle, withDispatch } from '../enhancers';
import { authLogout, setLocation } from '../actions';

const mapDispatch = dispatch => ({
  onLogout: () => dispatch(authLogout()),
  onRedirect: () => dispatch(setLocation('/')),
});

const LogoutPage = compose(
  withDispatch(mapDispatch),
  withLifecycle({
    onDidMount: ({ onLogout, onRedirect }) => {
      onLogout();
      onRedirect();
    },
  }),
)(() => null);

export default LogoutPage;

import React from 'react';
import useWindowSize from '../../hooks/windowSize';

/***************** REDUX IMPORTS *********************/
import { getAuthUserRoles, getAuthUser } from '../../reducers';
import { compose, withState, withDispatch } from '../../enhancers';
import { setUIProperty } from '../../actions';
/***************** MATERIAL IMPORTS *********************/
import Grid from '@material-ui/core/Grid';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
/***************** OPTION ICONS *********************/
import GroupIcon from '@material-ui/icons/Group';
/***************** Admin Panel Views *********************/
import AdminOptionsList from './AdminOptionsList';
import { StyledButton, Text, VerticalItems } from '../../styles/GenericStyled';
import DefaultLayout from '../layouts/DefaultLayout';
import UserAdminPanel from './panels/UserAdminPanel';
import PrivateSpacesPanel from './private-spaces/PrivateSpacesPanel';

/* options of panel list */
const ADMIN_ROLES = ['admin'];
const options = [
  // {
  //   path: 'users',
  //   icon: <PeopleIcon />,
  //   label: 'Users',
  // },
  { path: 'private-spaces', icon: <GroupIcon />, label: 'Private Spaces' },
  { path: 'payments', icon: <ReceiptIcon />, label: 'Payments' },
  { path: 'template-sales', icon: <TrendingUpIcon />, label: 'Template Sales' },
  { path: 'reports', icon: <ReportProblemIcon />, label: 'Reports' },
];

// const isPrivateSpaceAdmin = user => {
//   if (user === undefined) return false;
//   return (
//     user &&
//     user.spaceDomains &&
//     user.spaceDomains[0].spaceAdmins &&
//     user.spaceDomains[0].spaceAdmins.includes(user._id)
//   );
// };

const AdminPanelWrapperView = ({
  isAdmin = undefined,
  // psAdmin = undefined,
  currentUser = undefined,
  // setPSAdmin,
  setOpenLogin,
  // setIsSystemAdmin,
  children,
}) => {
  const size = useWindowSize();

  // useEffect(() => {
  //   // if (currentUser && isPrivateSpaceAdmin(currentUser)) setPSAdmin(currentUser);
  //   // else setPSAdmin(false);
  //   if (isAdmin) setIsSystemAdmin(true);
  //   else setIsSystemAdmin(false);
  // }, [currentUser]);

  return (
    <DefaultLayout>
      {currentUser && isAdmin && (
        <Grid container wrap="nowrap" style={{ minHeight: 'inherit' }}>
          <AdminOptionsList options={options} />
          <Grid
            item
            style={{
              width: size.width >= 1000 ? 'calc(99% - 207px)' : 'calc(99% - 50px)',
              margin: '15px auto',
            }}
          >
            {children || <PrivateSpacesPanel />}
          </Grid>
        </Grid>
      )}
      {!isAdmin && (
        <VerticalItems>
          <Text>You are not Admin</Text>
          <StyledButton type="secondary" onClick={() => setOpenLogin(true)} className="item">
            Log in
          </StyledButton>
        </VerticalItems>
      )}
    </DefaultLayout>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  // psAdmin: getUIProperty(state, 'psAdmin'),
  isAdmin: getAuthUserRoles(state).some(role => ADMIN_ROLES.includes(role)),
});

const mapDispatch = (dispatch, { authUser }) => ({
  setOpenLogin: value => dispatch(setUIProperty('openLogin', value)),
  // setIsSystemAdmin: isAdmin => dispatch(setUIProperty('isSystemAdmin', isAdmin)),
  // setPSAdmin: view => dispatch(setUIProperty('psAdmin', view)),
});

const AdminPanelWrapper = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(AdminPanelWrapperView);
export default AdminPanelWrapper;

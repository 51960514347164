import React from 'react';
import { Text, HorizontalItems, StyledDropZoneArea } from '../../styles/GenericStyled';
import * as generic from '../../styles/GenericStyled';

const MAX_FILES = 5;

const ModalUploader = ({ data, setData, onAddMessage, spinner, maxSizeReached, currentSize }) => {
  return (
    <>
      <HorizontalItems style={{ justifyContent: 'space-between' }}>
        <Text type="h7">Attach samples of your work</Text>
        <Text type="h7">{`${data.files.length}/${MAX_FILES}`}</Text>
      </HorizontalItems>

      <StyledDropZoneArea
        Icon="no-icon"
        maxFileSize={4000000}
        filesLimit={MAX_FILES}
        required
        onChange={(files, e) => {
          let fileArray = [];
          files.map(file => {
            let fileAttributes = {
              name: file.name,
              size: file.size,
              type: file.type,
            };

            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = event => {
                fileAttributes = { ...fileAttributes, content: event.target.result };
                fileArray.push(fileAttributes);
              };
              // Wait till complete
              reader.onloadend = e => {
                resolve(setData({ ...data, files: fileArray }));
              };
              // Make sure to handle error states
              reader.onerror = e => {
                reject(e);
              };

              reader.readAsDataURL(file);
            });
          });
        }}
        onDelete={file => {
          let newArray = [...data.files];
          newArray = newArray.filter(f => f.name !== file.name);

          setData({ ...data, files: [...newArray] });
        }}
      />
      {maxSizeReached && (
        <generic.Text type="h6" mycolor="red">
          The total size of your files must not exceed 7,8 MB. Current Total Size: {currentSize} MB
        </generic.Text>
      )}
    </>
  );
};

export default ModalUploader;

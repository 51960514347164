"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserImage = exports.getUserBlobURI = void 0;

var _buffer = require("./buffer");

var PATH_DEFAULT_IMG = '/images/user.png';
var PATH_USERS = '/api/users';
var PATH_USER_IMAGE = 'profile/image';

var getRootURL = function getRootURL() {
  return typeof window === 'undefined' ? process.env.ROOT_URL || 'http://localhost:3000' : "".concat(window.location.protocol, "//").concat(window.location.host);
};

var getUserBlobURI = function getUserBlobURI(user, bin, path) {
  if (!bin) {
    return undefined;
  }

  if (bin.content && bin.contentType) {
    return (0, _buffer.toDataURL)(bin);
  }

  return "".concat(getRootURL()).concat(PATH_USERS, "/").concat(user._id, "/").concat(path);
};

exports.getUserBlobURI = getUserBlobURI;

var getUserImage = function getUserImage(user) {
  var fallbackToDefault = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var defaultImage = "".concat(getRootURL()).concat(PATH_DEFAULT_IMG);
  var image = user.image || (user.profile || {}).image || {};

  if (typeof image === 'string') {
    return image;
  }

  return image.uri || getUserBlobURI(user, image.bin, "".concat(PATH_USER_IMAGE, "/bin")) || (fallbackToDefault ? defaultImage : undefined);
};

exports.getUserImage = getUserImage;
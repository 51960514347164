import React from 'react';
import { __ } from 'artsteps2-common';
import utils from '../../../utils';
import {
  compose,
  withState,
  withDispatch,
  withLifecycle,
  withLocalState,
} from '../../../enhancers';
import { setUIProperty } from '../../../actions';
import { getUIProperty } from '../../../reducers';

const POINT_TIMEOUT = 6000;
const CHARS_PER_MS = 0.05;

export const ExhibitionStoryPlayerView = ({
  ready = true,
  playing = false,
  storypoints = [],
  currentPointId,
  storypointDescriptionVisible = false,
  storypointsVisible = false,
  onMoveToPreviousStorypoint = () => false,
  onMoveToNextStorypoint = () => false,
  onMoveToStorypoint = () => false,
  onPause = () => false,
  onPlay = () => false,
  onStorypointListShow = () => Promise.resolve(false),
  onStorypointListHide = () => Promise.resolve(false),
  onCloseDescription = () => Promise.resolve(false),
}) =>
  ready &&
  storypoints.length > 0 && (
    <div className="exhibition-story-player">
      {currentPointId && storypointDescriptionVisible && (
        <div className="exhibition-story-content">
          <div className="exhibition-story-text">
            <div>
              <h3>{storypoints.find(point => point._id === currentPointId).title}</h3>
              <button
                className="ui basic square inverted tiny icon button"
                onClick={onCloseDescription}
              >
                <i className="icon remove" />
              </button>
            </div>
            <div className="exhibition-story-description">
              {utils.text.toMultiline(
                storypoints.find(point => point._id === currentPointId).description,
              )}
            </div>
          </div>
        </div>
      )}
      <div className="exhibition-stories">
        <div className="exhibition-controls">
          <button
            data-tooltip={__('previous_storypoint')}
            data-inverted
            onClick={() => onMoveToPreviousStorypoint(currentPointId)}
          >
            <i className="ui step backward icon" />
          </button>
          {playing && (
            <button data-tooltip={__('stop_story')} data-inverted onClick={() => onPause()}>
              <i className="pause icon" />
            </button>
          )}
          {!playing && (
            <button
              data-tooltip={__('start_story')}
              data-inverted
              onClick={() => onPlay(currentPointId)}
            >
              <i className="play icon" />
            </button>
          )}
          <button
            data-tooltip={__('goto_storypoint')}
            data-inverted
            onClick={storypointsVisible ? onStorypointListHide : onStorypointListShow}
            className="storypoint-selector"
          >
            <i className={`ui caret ${storypointsVisible ? 'up' : 'down'} icon`} />
          </button>
          {storypointsVisible && (
            <div className="exhibition-storypoint-list">
              {storypoints
                .filter(p => !p.hidden)
                .map(p => (
                  <div
                    key={p._id}
                    className={`storypoint ${currentPointId === p._id ? 'selected' : ''}`}
                  >
                    <button
                      onClick={() => onStorypointListHide().then(() => onMoveToStorypoint(p._id))}
                    >
                      {p.title}
                    </button>
                  </div>
                ))}
            </div>
          )}
          <button
            data-tooltip={__('next_storypoint')}
            data-inverted
            onClick={() => onMoveToNextStorypoint(currentPointId)}
          >
            <i className="ui step forward icon" />
          </button>
        </div>
      </div>
    </div>
  );

const modulo = (i, n) => ((i % n) + n) % n;

const mapState = (state, { exhibition = {} }) => ({
  ready: !!exhibition._id,
  currentPointId: getUIProperty(state, `exhibitions/${exhibition._id}/currentStorypoint`),
  storypointDescriptionVisible: !!getUIProperty(
    state,
    `exhibitions/${exhibition._id}/storypointDescription`,
  ),
  displayedArtifatId: getUIProperty(state, `exhibitions/${exhibition._id}/displayedArtifact`),
  playing: getUIProperty(state, `exhibitions/${exhibition._id}/playing`),
  playbackTimeout: getUIProperty(state, `exhibitions/${exhibition._id}/playbackTimeout`),
  storypointsVisible: getUIProperty(state, `exhibitions/${exhibition._id}/storypointsVisible`),
});

const mapDispatch = (
  dispatch,
  { exhibition = {}, storypoints = [], audio, onAudioSourceChange, playbackTimeout, playing },
) => {
  const onCloseDescription = () =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/storypointDescription`, false));
  const onOpenDescription = () =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/storypointDescription`, true));
  const onPlayStatusChange = status =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/playing`, status));
  const onStorypointListShow = () =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/storypointsVisible`, true));
  const onStorypointListHide = () =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/storypointsVisible`, false));
  const onPlaybackTimeout = timeout =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/playbackTimeout`, timeout));
  const onAudioEnd = (pointId, duration) => continueStory(pointId, -duration * 1000);
  const onAudioPause = () => Promise.resolve(audio && audio.pause());
  const onAudioResume = (pointId, stream) => {
    const promise = stream.play();
    return promise && promise.catch
      ? promise.catch(() => onAudioEnd(pointId, 0))
      : Promise.resolve(false);
  };
  const continueStory = (pointId, offset = 0) => {
    if (playing) {
      const pointIndex = storypoints.findIndex(point => point._id === pointId);
      const point = storypoints[pointIndex];
      const timeout =
        POINT_TIMEOUT + Math.ceil((point.description || '').length / CHARS_PER_MS) + offset;
      clearTimeout(playbackTimeout);
      return onPlaybackTimeout(
        setTimeout(
          () =>
            pointIndex === storypoints.length - 1
              ? onPause().then(() =>
                  dispatch(setUIProperty(`exhibitions/${exhibition._id}/currentStorypoint`, null)),
                )
              : onMoveToNextStorypoint(pointId),
          Math.max(0, timeout),
        ),
      );
    }
    return Promise.resolve(false);
  };
  const onPause = () =>
    onPlayStatusChange(false)
      .then(() => onAudioPause())
      .then(() => clearTimeout(playbackTimeout));
  const onPlay = pointId =>
    onPlayStatusChange(true).then(() =>
      audio ? onAudioResume(pointId, audio) : onMoveToNextStorypoint(pointId),
    );
  const onMoveToStorypoint = pointId =>
    onAudioPause()
      .then(() => onCloseDescription())
      .then(() => dispatch(setUIProperty(`exhibitions/${exhibition._id}/nextStorypoint`, pointId)));
  const onMoveToNextStorypoint = pointId => {
    const pointIndex = storypoints.findIndex(point => point._id === pointId);
    const idx = modulo((pointIndex || 0) + 1, storypoints.length);
    return onMoveToStorypoint(storypoints[idx]._id);
  };
  const onMoveToPreviousStorypoint = pointId => {
    const pointIndex = storypoints.findIndex(point => point._id === pointId);
    const idx = modulo((pointIndex || 0) - 1, storypoints.length);
    return onMoveToStorypoint(storypoints[idx]._id);
  };
  const onStorypointReached = pointId => {
    onAudioPause();
    return new Promise(resolve =>
      onAudioSourceChange(null, () => {
        const pointIndex = storypoints.findIndex(point => point._id === pointId);
        const src = utils.storypoint.getStorypointAudio(storypoints[pointIndex]);
        if (src) {
          const audioStream = new Audio(src);
          audioStream.addEventListener('ended', () => onAudioEnd(pointId, audioStream.duration));
          audioStream.addEventListener('error', () => onAudioEnd(pointId, 0));
          onAudioSourceChange(audioStream, () => onAudioResume(pointId, audioStream));
          return resolve();
        }
        return resolve(continueStory(pointId));
      }),
    );
  };

  return {
    onPlay,
    onPause,
    onMoveToStorypoint,
    onMoveToNextStorypoint,
    onMoveToPreviousStorypoint,
    onStorypointListShow,
    onStorypointListHide,
    onStorypointReached,
    onCloseDescription,
    onOpenDescription,
  };
};

const lifecycleMap = {
  onWillUnmount: ({ onPause }) => onPause(),
  onDidUpdate: (
    props,
    { currentPointId, displayedArtifatId, onStorypointReached, onOpenDescription, onPause },
  ) => {
    if (currentPointId && currentPointId !== props.currentPointId) {
      onOpenDescription().then(() => onStorypointReached(currentPointId));
    }
    if (displayedArtifatId && displayedArtifatId !== props.displayedArtifatId) {
      onPause();
    }
  },
};

const ExhibitionStoryPlayer = compose(
  withLocalState('audio', 'onAudioSourceChange', null),
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionStoryPlayerView);

export default ExhibitionStoryPlayer;

import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import publicConfig from 'artsteps2-config/public.json';
import { __ } from 'artsteps2-common';
import UnityWrapper from '../arvr/UnityWrapper';
import ExhibitionSpacePanel from './panels/ExhibitionSpacePanel';
import ExhibitionTemplatePanel from './panels/ExhibitionTemplatePanel';
import ExhibitionTexturesPanel from './panels/ExhibitionTexturesPanel';
import ExhibitionArtifactPanel from './panels/ExhibitionArtifactPanel';
import ExhibitionStoryPanel from './panels/ExhibitionStoryPanel';
import ExhibitionInfoPanel from './panels/ExhibitionInfoPanel';
import ExhibitionTutorial from '../viewer/ExhibitionTutorial';
import Loader from '../../generic/Loader';
import Toolbar from '../../generic/Toolbar';
import Dialog from '../../generic/Dialog';
import utils from '../../../utils';

import { compose, withDispatch, withState, withProps, withLifecycle } from '../../../enhancers';
import {
  apiGET,
  apiPOST,
  apiPATCH,
  setLocation,
  setUIProperty,
  addMessage,
} from '../../../actions';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getUIProperty,
  getAuthUser,
  getAuthCurrentSpace,
} from '../../../reducers';

import addWallsSrc from '../../../styles/images/tutorial/create-wall.gif';
import removeWallsSrc from '../../../styles/images/tutorial/delete-wall.gif';
import addDoorsSrc from '../../../styles/images/tutorial/create-door.gif';
import removeDoorsSrc from '../../../styles/images/tutorial/delete-door.gif';
import colourWallsSrc from '../../../styles/images/tutorial/color-wall.gif';
import colourCeilingSrc from '../../../styles/images/tutorial/color-ceiling.gif';
import addTexturesSrc from '../../../styles/images/tutorial/add-texture.gif';
import lightIntensitySrc from '../../../styles/images/tutorial/luminosity.gif';
import addImagesSrc from '../../../styles/images/tutorial/place-artifact.gif';
import addObjectsSrc from '../../../styles/images/tutorial/poly-api.gif';
import moveRotateScaleSrc from '../../../styles/images/tutorial/scale-object.gif';
import placePointsSrc from '../../../styles/images/tutorial/place-point.gif';
import movePointsSrc from '../../../styles/images/tutorial/rotate-point.gif';
import { TextButton } from '../../../styles/GenericStyled';

const STEP_KEY_SPACE = 1;
const STEP_KEY_TEXTURES = 2;
const STEP_KEY_ARTIFACTS = 3;
const STEP_KEY_STORYTELLING = 4;
const STEP_KEY_PUBLISH = 5;
export const API_URI = `${publicConfig.api.hostname}`;

export const ExhibitionEditFrameView = ({
  exhibition = {},
  initialized = false,
  saving = false,
  currentStep = STEP_KEY_SPACE,
  currentUser,
  error,
  tutorialTitle = undefined,
  tutorialParagraphs = [],
  onErrorDismissed = () => Promise.resolve(false),
  setOpenLogin,
  setOpenRegister,
}) => {
  useEffect(() => {
    document.title = `artsteps | ${exhibition.title || 'new'}`;
  }, [exhibition]);

  if (!currentUser) {
    return (
      <div className="exhibition-frame ui container">
        <div className="curate-disabled">
          {__('curate_disabled_auth')}
          &nbsp;
          <TextButton onClick={() => setOpenLogin(true)}>{__('login')}</TextButton>
          &nbsp;
          {__('or')}
          &nbsp;
          <TextButton onClick={() => setOpenRegister(true)}>{__('register')}</TextButton>
        </div>
      </div>
    );
  }

  const renderHelpers = () => {
    switch (currentStep) {
      case STEP_KEY_SPACE: {
        return (
          <div>
            <ExhibitionSpacePanel exhibitionId={exhibition._id} />
            <ExhibitionTemplatePanel exhibitionId={exhibition._id} />
          </div>
        );
      }
      case STEP_KEY_TEXTURES: {
        return (
          <div>
            <ExhibitionTexturesPanel exhibitionId={exhibition._id} />
          </div>
        );
      }
      case STEP_KEY_ARTIFACTS: {
        return <ExhibitionArtifactPanel exhibitionId={exhibition._id} />;
      }
      case STEP_KEY_STORYTELLING: {
        return <ExhibitionStoryPanel exhibitionId={exhibition._id} />;
      }
      case STEP_KEY_PUBLISH: {
        return <ExhibitionInfoPanel exhibition={exhibition} currentUser={currentUser} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="stretch"
      spacing={1}
      style={{ marginTop: '61px', flexWrap: 'nowrap' }}
    >
      <Grid style={{ minWidth: '290px', maxWidth: '320px' }} item xs={4} md={2}>
        <Toolbar controls={false} initialPosition="left">
          <div style={{ position: 'relative' }}>
            {!initialized && <Loader />}
            {renderHelpers()}
          </div>
        </Toolbar>
      </Grid>
      <Grid item xs={8} md={10} style={{ position: 'relative', maxHeight: 'calc(100vh - 130px)' }}>
        <UnityWrapper
          bindEvents={false}
          exhibitionId={exhibition._id}
          exhibitionTitle={exhibition.title}
        />
        <div style={{ position: 'absolute', top: '0.6rem', left: '0.6rem' }}>
          <ExhibitionTutorial
            left="3.6rem"
            exhibition={exhibition}
            title={tutorialTitle}
            paragraphs={tutorialParagraphs}
          />
        </div>
      </Grid>
      {saving && <Loader message={`${__('saving')} ${exhibition.title || ''}`} />}
      <Dialog
        open={!!error}
        type="error"
        title={__('exhibition_creation_err')}
        message={error}
        onClose={onErrorDismissed}
      />
    </Grid>
  );
};

const createArtifactFilter = exhibitions => ({ filter: { exhibitions } });
const createStorypointFilter = exhibition => ({ filter: { exhibition } });

const mapState = (state, { exhibitionId = 'new' }) => ({
  artifacts: getApiResource(state, 'artifacts'),
  storypoints: getApiResource(state, 'storypoints'),
  currentUser: getAuthUser(state),
  currentStep: getUIProperty(state, `exhibitions/${exhibitionId}/currentStep`),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  export: getUIProperty(state, `exhibitions/${exhibitionId}/export`),
  screenshot: getUIProperty(state, `exhibitions/${exhibitionId}/screenshot`),
  exhibition: {
    _id: exhibitionId,
    title: `Untitled (${new Date().toDateString()} ${new Date().getHours()}:${new Date().getMinutes()})`,
    space: getAuthCurrentSpace(state) || undefined,
    ...getApiResource(state, `exhibitionsUserProfile/${exhibitionId}`),
  },
  ready: getApiStatus(state, `exhibitions/${exhibitionId}`) === API_STATUS.IDLE,
  initialized: !!getUIProperty(state, `exhibitions/${exhibitionId}/initializedAt`),
  hasChanges: getUIProperty(state, `exhibitions/${exhibitionId}/hasChanges`),
  templates: getApiResource(state, 'templates'),
});

const mapDispatch = (dispatch, { exhibitionId = 'new', currentStep }) => ({
  setSelectedTemplate: template =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/selectedTemplate`, template)),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  onChangeStep: step => dispatch(setUIProperty(`exhibitions/${exhibitionId}/currentStep`, step)),
  onFetchTemplates: () => dispatch(apiGET('templates')),
  onFetchArtifacts: () =>
    exhibitionId === 'new'
      ? Promise.resolve({ response: {} })
      : dispatch(apiGET('artifacts', createArtifactFilter(exhibitionId))),
  onFetchStorypoints: () =>
    exhibitionId === 'new'
      ? Promise.resolve({ response: {} })
      : dispatch(apiGET('storypoints', createStorypointFilter(exhibitionId))),
  onFetchExhibition: () =>
    exhibitionId === 'new'
      ? Promise.resolve(undefined)
      : dispatch(
          apiGET(`exhibitionsUserProfile/${exhibitionId}`, {
            include: 'model',
            populate: ['categories', 'user'],
          }),
        ),
  onExhibitionExport: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/exportingAt`, Date.now())),
  onExhibitionUpsert: exhibition => {
    return (exhibitionId === 'new'
      ? dispatch(apiPOST('exhibitionsUserProfile', exhibition))
      : dispatch(apiPATCH(`exhibitionsUserProfile/${exhibitionId}`, exhibition))
    ).then(({ response }) => {
      // Must import exhibition for themes to work
      if (response.error) dispatch(addMessage({ title: __(response.error), type: 'error' }));
      else {
        if (exhibitionId === 'new') {
          dispatch(setLocation(`/curate/${response._id}/${currentStep}`));
        }
        dispatch(
          addMessage({
            title: __('exhibition_save_success', { title: exhibition.title }),
            type: 'success',
          }),
        );
      }
    });
  },
  // Import the exhibition assigns the export of the exhibition to the exhibition/import redux value
  // export Exhibition is used to map the exhibition model and send it to Unity
  onImportExhibition: ({ exhibition, artifacts, storypoints, templates }) => {
    dispatch(
      setUIProperty(
        `exhibitions/${exhibitionId}/import`,
        utils.exhibition.exportExhibition(exhibition, artifacts, storypoints, templates),
      ),
    );
  },
  onExhibitionHasChanges: hasChanges =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/hasChanges`, hasChanges)),
  onAddMessage: message => dispatch(addMessage(message)),
  onSetCurrentExhibitionId: () => dispatch(setUIProperty('currentExhibitionId', exhibitionId)),
});

const mapProps = ({
  onFetchExhibition,
  onFetchArtifacts,
  onFetchStorypoints,
  onFetchTemplates,
  setSelectedTemplate,
  onSetCurrentExhibitionId,
  onImportExhibition,
  onChangeStep,
  step,
}) => ({
  onInitialization: () =>
    onChangeStep(step)
      .then(() =>
        Promise.all([
          onFetchExhibition(),
          onFetchArtifacts(),
          onFetchStorypoints(),
          onFetchTemplates(),
        ]),
      )
      .then(
        ([
          { response: exhibition } = {},
          { response: artifacts },
          { response: storypoints },
          { response: templates },
        ]) => {
          if (exhibition && exhibition.model && exhibition.model.template) {
            setSelectedTemplate(exhibition.model.template);
          }
          onSetCurrentExhibitionId();
          onImportExhibition({
            exhibition,
            artifacts: Object.values(artifacts),
            storypoints: Object.values(storypoints),
            templates: Object.values(templates),
          });
        },
      ),
  tutorialParagraphs: {
    [STEP_KEY_SPACE]: [
      [
        {
          text: __('tutorials.add_walls'),
          icon: addWallsSrc,
          class: 'add-walls',
        },
        {
          text: __('tutorials.remove_walls'),
          icon: removeWallsSrc,
          class: 'remove-walls',
        },
      ],
      [
        {
          text: __('tutorials.add_doors'),
          icon: addDoorsSrc,
          class: 'add-doors',
        },
        {
          text: __('tutorials.remove_doors'),
          icon: removeDoorsSrc,
          class: 'remove-doors',
        },
      ],
    ],
    [STEP_KEY_TEXTURES]: [
      [
        {
          text: __('tutorials.colour_walls'),
          icon: colourWallsSrc,
          class: 'colour-walls',
        },
        {
          text: __('tutorials.colour_surfaces'),
          icon: colourCeilingSrc,
          class: 'colour-surfaces',
        },
      ],
      [
        {
          text: __('tutorials.add_textures'),
          icon: addTexturesSrc,
          class: 'add-textures',
        },
        {
          text: __('tutorials.light_intensity'),
          icon: lightIntensitySrc,
          class: 'light-intensity',
        },
      ],
    ],
    [STEP_KEY_ARTIFACTS]: [
      [
        {
          text: __('tutorials.add_images'),
          icon: addImagesSrc,
          class: 'add-images',
        },
        {
          text: __('tutorials.add_objects'),
          icon: addObjectsSrc,
          class: 'add-objects',
        },
      ],
      [
        {
          text: __('tutorials.move_rotate_scale'),
          icon: moveRotateScaleSrc,
          class: 'move-rotate-scale',
        },
      ],
    ],
    [STEP_KEY_STORYTELLING]: [
      [
        {
          text: __('tutorials.add_points'),
          class: 'add-points',
        },
        {
          text: __('tutorials.place_points'),
          icon: placePointsSrc,
          class: 'place-points',
        },
        {
          text: __('tutorials.move_points'),
          icon: movePointsSrc,
          class: 'move-points',
        },
      ],
    ],
    [STEP_KEY_PUBLISH]: [
      [
        {
          text: __('tutorials.publish'),
          class: 'publish',
        },
      ],
    ],
  }[step],
  tutorialTitle: {
    [STEP_KEY_SPACE]: __('exhibition_space'),
    [STEP_KEY_TEXTURES]: __('exhibition_textures'),
    [STEP_KEY_ARTIFACTS]: __('exhibition_artifacts'),
    [STEP_KEY_STORYTELLING]: __('exhibition_storytelling'),
    [STEP_KEY_PUBLISH]: __('exhibition_publish'),
  }[step],
});

const handleUpdates = (
  {
    exhibitionId: prevExhibitionId,
    step: prevStep,
    currentStep: prevSetStep,
    export: prevModel = {},
    screenshot: prevScreenshot,
  },
  {
    exhibitionId,
    step,
    exhibition,
    export: model = {},
    screenshot,
    currentStep,
    onChangeStep,
    onExhibitionExport,
    onExhibitionUpsert,
    onInitialization,
    onAddMessage,
    hasChanges,
    onExhibitionHasChanges,
  },
) => {
  if (prevExhibitionId !== exhibitionId) {
    onInitialization();
  }
  if (prevStep !== step) {
    onChangeStep(step);
  }
  if (model.timestamp && model.timestamp !== prevModel.timestamp) {
    // safety check
    // do not save if:
    // model is {} or if no template used and no walls and no artifacts
    if (
      (Object.keys(model).length === 0 && model.constructor === Object) ||
      (model.template === null &&
        model.displayCases &&
        model.displayCases.length === 0 &&
        model.artifacts &&
        model.artifacts.length === 0 &&
        model.walls &&
        model.walls.length === 0)
    ) {
      // something is wrong with the model, do nothing
      onAddMessage({
        title: __('Exhibition not saved'),
        description: __('autosave_failure'),
        type: 'error',
      });
    } else {
      onExhibitionUpsert({ title: exhibition.title, model }).then(() =>
        onExhibitionHasChanges(false),
      );
    }
  }
  if (screenshot && screenshot !== prevScreenshot) {
    onExhibitionUpsert({
      title: exhibition.title,
      image: screenshot.match(/^data:/)
        ? {
            uri: null,
            bin: utils.buffer.fromDataURL(screenshot),
          }
        : { uri: screenshot, bin: null },
    }).then(() => {
      onAddMessage({
        title: __('exhibition_save_cover_success', { title: exhibition.title }),
        type: 'success',
      });
    });
  }
  if (
    hasChanges &&
    currentStep &&
    currentStep !== prevSetStep &&
    currentStep !== STEP_KEY_SPACE &&
    exhibitionId === 'new'
  ) {
    onExhibitionExport(); // sets value of exportingAt handled by Unity Wrapper ~790 as exportTimestamp
  }
};

const lifecycleMap = {
  onDidMount: ({ onInitialization }) => onInitialization(),
  onDidUpdate: (prevProps, props) => handleUpdates(prevProps, props),
};

const ExhibitionEditFrame = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withProps(mapProps),
  withLifecycle(lifecycleMap),
)(ExhibitionEditFrameView);

export default ExhibitionEditFrame;

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import Field from './Field';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { getFormProperty } from '../../../reducers';
import { handleFormPropertyChange, setFormData } from '../../../actions';
import { StyledButton } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';

const COLUMNS = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight'];

export const FormView = ({
  name = 'default',
  fields = {},
  buttons = [],
  ready = true,
  getFieldValue = () => undefined,
  onSubmit = () => undefined,
  onFieldChange = () => true,
  children,
  recaptcha,
  recaptchaSubmitButtonLabel = 'Submit',
  verified,
  onChange,
  contact = '',
  artifactId = 'new',
}) => (
  <form
    className={`ui form${!ready ? ' loading' : ''}`}
    onSubmit={event => {
      event.preventDefault();
      return onSubmit(event);
    }}
  >
    <div className={`ui stackable grid ${COLUMNS[fields.length] || 'one'} column wide`}>
      {(Array.isArray(fields) ? fields : [fields])
        .map(f => {
          Object.keys(f).forEach(key => f[key] == null && delete f[key]);
          return f;
        })
        .map((column, idx) => (
          <div className={`ui column ${contact}`} key={idx}>
            {Object.keys(column).map(
              field =>
                field && (
                  <Field
                    artifactId={artifactId}
                    key={field}
                    onChange={onChange || onFieldChange}
                    id={`field.${name}.${field}`}
                    name={field}
                    value={getFieldValue(field)}
                    {...column[field]}
                  />
                ),
            )}
          </div>
        ))}
    </div>
    {children}
    {
      <div className="ui segment vertical form-buttons">
        {recaptcha && recaptcha}
        {recaptcha && (
          <button
            label={recaptchaSubmitButtonLabel}
            key="contact"
            type="submit"
            className="ui submit button secondary"
            disabled={!verified}
            id="circled-submit"
          >
            {recaptchaSubmitButtonLabel}
          </button>
        )}
        {!recaptcha &&
          buttons.map(({ label, type, colour, icon, disabled, onClick }) => (
            <StyledButton
              style={{ marginLeft: '10px' }}
              key={label}
              type={type || 'submit'}
              disabled={disabled}
              onClick={onClick}
              mycolor={icon === 'trash' ? colors.ourOrange : colors.ourWhite}
              background={icon === 'trash' ? colors.ourWhite : colors.ourBlack}
              bordercolor={icon === 'trash' ? colors.ourOrange : colors.ourBlack}
              border={icon === 'trash' ? 1 : 0}
            >
              {icon === 'trash' && <DeleteForeverIcon style={{ marginRight: '5px' }} />}
              {icon === 'save' && <SaveIcon style={{ marginRight: '5px' }} />}
              {label}
            </StyledButton>
          ))}
      </div>
    }
  </form>
);

const mapState = (state, { form = { name: 'default' } }) => ({
  getFieldValue: field => getFormProperty(state, form.name, field),
  name: form.name,
});

const mapDispatch = (dispatch, { form = { name: 'default', data: {} } }) => ({
  onFieldChange: event => dispatch(handleFormPropertyChange(form.name, event)),
  onInitializeForm: data => dispatch(setFormData(form.name, { ...data, ...form.data })),
});

const lifecycleMap = {
  onDidMount: ({ onInitializeForm, fields = {} }) =>
    onInitializeForm(
      (Array.isArray(fields) ? fields : [fields])
        .map(f => {
          Object.keys(f).forEach(key => f[key] == null && delete f[key]);
          return f;
        })
        .reduce(
          (formData, column) => ({
            ...formData,
            ...Object.keys(column).reduce(
              (columnData, fieldName) => ({
                ...columnData,
                ...(column[fieldName].default ? { [fieldName]: column[fieldName].default } : {}),
              }),
              {},
            ),
          }),
          {},
        ),
    ),
};

const Form = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(FormView);

export default Form;

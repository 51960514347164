import React, { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import logo from '../styles/images/logo.png';
import { ReactComponent as Send } from '../styles/images/send-24px.svg';
import { apiPOST, addMessage, setUIProperty } from '../actions';
import { compose, withState, withDispatch, withLifecycle } from '../enhancers';
import { getAuthUser, getUIProperty } from '../reducers';
import * as PrivateComponent from '../styles/PrivateSpacesPageStyled';
import DefaultLayout from '../components/layouts/DefaultLayout';
import useWindowSize from '../hooks/windowSize';
import PSbanner from '../components/privateSpaces/PSbanner';
import { BodyWithPadding, StyledButton, Text } from '../styles/GenericStyled';

import image1 from '../styles/images/pages/custom-3d-1.jpg';
import image2 from '../styles/images/pages/custom-3d-2.jpg';
import image3 from '../styles/images/pages/custom-3d-3.jpg';
import image4 from '../styles/images/pages/custom-3d-4.jpg';
import image5 from '../styles/images/pages/custom-3d-5.jpg';
import image6 from '../styles/images/pages/custom-3d-6.jpg';
import ServicesContent from '../components/privateSpaces/ServicesContent';
import videoPreview from '../styles/images/privateSpaces/custom-3d-video.mp4';

const { getNames } = require('country-list');

const breakPoint = 1515;

const isDisabled = state => {
  let disabled = false;
  for (const [key, value] of Object.entries(state)) {
    if (key !== 'company' && value === '') disabled = true;
  }
  return disabled;
};

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 5px;
  display: none;
  ${props =>
    props.visible &&
    css`
      display: flex;
    `}
`;

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '300px',
    width: '357px',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

const initialState = {
  username: '',
  email: '',
  country: '',
  company: '',
  licence: '',
};

const licences = ['Commercial Licence', 'Academic Licence', 'Other/Not sure'];

const CustomSpace3DPageView = props => {
  const [icon, setIcon] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [inputData, setInputData] = useState({ ...initialState });
  const [countryInput, setCountryInput] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    document.title = `Artsteps | Custom 3D Design`;
  }, []);

  const size = useWindowSize();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputData({ ...initialState });
  };

  const handleSubmit = e => {
    props.onCreateContactForm({ ...inputData }).then(({ response }) => {
      if (!response.error) {
        props.onAddMessage({ title: 'Your message has been sent', type: 'success' });
      } else {
        props.onAddMessage({ title: response.error, type: 'error' });
      }
    });
    handleClose();
  };

  const handleChange = (event, option) => {
    let name;
    let value;
    if (option === null) {
      value = '';
      name = 'country';
    } else if (!option) {
      name = event.target.name ? event.target.name : event.target.getAttribute('name');
      value = event.target.value !== 0 ? event.target.value : event.target.getAttribute('value');
    } else {
      value = option;
      name = 'country';
    }
    inputData[name] = value;
    setInputData({ ...inputData });
  };

  return (
    <DefaultLayout>
      <PSbanner
        title="Custom 3D Design"
        sub1="A virtual reflection of everything you see or imagine, tailor-made to satisfy your every need"
        images={[image1, image2, image3, image4, image5, image6]}
        button={
          <StyledButton onClick={handleOpen} background="#12AD8F">
            Request a quote
          </StyledButton>
        }
      />
      {/*<IconsSection />*/}

      <BodyWithPadding padding="100px 5% 0% 5%">
        <ServicesContent video={videoPreview} size={size}>
          <Text type={'h4'} align="left">
            What We Offer
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            Our Custom 3D Design services give you the freedom to shape your VR space in any way you
            want
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Virtual photorealistic replica of an existing space</Text>
            </li>
            <li>
              <Text type={'sub2'}>Exclusive 3D creation of an imagined space</Text>
            </li>
            <li>
              <Text type={'sub2'}>Modification of a pre-designed gallery space on ArtSteps</Text>
            </li>
          </ul>
          <br />
          <br />
          <Text type={'h4'} align="left">
            How It Works
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            We will create a Custom 3D Design for any VR space you wish and migrate it into your
            Private Web Space or your free ArtSteps account. You only have to:
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>
                Submit pictures, floorplans, section or elevation drawings of an existing space or...
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Send a simple sketch or design an outline on ArtSteps of an imagined space and
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Provide details of the textures, reference images and other elements that you want
                incorporated into your VR space
              </Text>
            </li>
          </ul>
          <br />
          <br />
          <Text type={'h4'} align="left">
            Chosen By
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            Custom 3D Spaces are ideal for those who want a unique VR space that will resemble their
            real life venue or reflect their personal taste
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Museums</Text>
            </li>
            <li>
              <Text type={'sub2'}>Corporations</Text>
            </li>
            <li>
              <Text type={'sub2'}>Art Galleries</Text>
            </li>
            <li>
              <Text type={'sub2'}>Art Institutions</Text>
            </li>
            <li>
              <Text type={'sub2'}>Art & NFT Collectors</Text>
            </li>
            <li>
              <Text type={'sub2'}>Educational Organizations</Text>
            </li>
          </ul>
        </ServicesContent>
      </BodyWithPadding>

      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={4}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Grid item>
          <Text type="h5" mycolor="black" align="center">
            <b>Interested in this Service?</b>
          </Text>
        </Grid>
        <Grid item>
          <StyledButton onClick={handleOpen} background="#12AD8F">
            Request a quote
          </StyledButton>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        style={{ overflow: 'auto' }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PrivateComponent.ModalGrid container direction="column" justify="center" spacing={2}>
            <Box component={Grid} item textAlign="center">
              <img alt="" src={logo} width="177px" height="59px" />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.CustomTypography variant="h4" modalhead="true">
                Request Details
              </PrivateComponent.CustomTypography>
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="username-input">
                Name
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="username-input"
                onChange={e => handleChange(e)}
                name="username"
                value={inputData.username}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="email-input">
                Email
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="email-input"
                onChange={handleChange}
                name="email"
                value={inputData.email}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="country-input">
                Country
              </PrivateComponent.InputsLabel>
              <PrivateComponent.CustomAutocomplete
                value={inputData.country}
                onChange={(e, value) => handleChange(e, value)}
                onInputChange={(event, newInputValue) => {
                  setCountryInput(newInputValue);
                }}
                inputValue={countryInput}
                autoComplete
                options={getNames()}
                clearOnEscape
                getOptionSelected={option => option}
                getOptionLabel={option => option}
                renderOption={option => <React.Fragment>{option}</React.Fragment>}
                renderInput={params => (
                  <PrivateComponent.CustomTextField
                    {...params}
                    placeholder="Select"
                    style={{ border: 0 }}
                    required
                  />
                )}
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="company-input">
                <PrivateComponent.CustomTypography company="true">
                  Company
                </PrivateComponent.CustomTypography>
                <PrivateComponent.CustomTypography optional="true">
                  (Optional)
                </PrivateComponent.CustomTypography>
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="company-input"
                onChange={handleChange}
                name="company"
                value={inputData.company}
              />
            </Box>
            <Box component={Grid} item textAlign="center" style={{ position: 'relative' }}>
              <PrivateComponent.InputsLabel htmlFor="licence-input">
                Licence
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="licence-input"
                aria-controls="licence-input"
                aria-haspopup="true"
                name="licence"
                value={inputData.licence}
                placeholder="Select"
                readOnly
                onClick={handleMenu}
                required
              />
              {openMenu ? <PrivateComponent.UpArrow /> : <PrivateComponent.DownArrow />}
              <StyledMenu
                id="licence-input"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                open={openMenu}
                onClose={handleCloseMenu}
              >
                {licences.map((licence, index) => (
                  <PrivateComponent.DropDownOptions
                    key={`space${index}`}
                    value={licence}
                    name="licence"
                    onClick={e => {
                      handleChange(e);
                      handleCloseMenu();
                    }}
                  >
                    {licence}
                  </PrivateComponent.DropDownOptions>
                ))}
              </StyledMenu>
            </Box>
            <Box component={CloseButton} onClick={handleClose} visible={size.width < breakPoint}>
              <CloseIcon />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.SendButton disabled={isDisabled(inputData)} onClick={handleSubmit}>
                Send your Request
                <SvgIcon>
                  <Send />
                </SvgIcon>
              </PrivateComponent.SendButton>
            </Box>
          </PrivateComponent.ModalGrid>
        </Fade>
      </Modal>
    </DefaultLayout>
  );
};

const lifecycleMap = {
  onDidMount: () => {
    ReactPixel.track('ViewContent');
  },
};

const mapState = state => ({
  requestOpen: getUIProperty('requestOpen'),
});
const mapDispatch = dispatch => ({
  onCreateContactForm: form =>
    dispatch(apiPOST('privatespaces', { ...form, requestQuoteFor: 'Custom 3D Space Service' })),
  onAddMessage: message => dispatch(addMessage(message, 'privatespaces')),
  setOpenRequest: status => dispatch(setUIProperty('requestOpen', status)),
});

const CustomSpace3DPage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(CustomSpace3DPageView);

export default CustomSpace3DPage;

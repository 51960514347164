import React from 'react';
import AdminPanelWrapper from './AdminPanelWrapper';
import PrivateSpacesPanel from './private-spaces/PrivateSpacesPanel';

const PrivateSpacesWrapper = () => (
  <AdminPanelWrapper>
    <PrivateSpacesPanel />
  </AdminPanelWrapper>
);

export default PrivateSpacesWrapper;

import React from 'react';
import { __ } from 'artsteps2-common';
import {
  handleFormPropertyChange,
  clearFormData,
  addMessage,
  setLocation,
  apiDELETE,
} from '../../../actions';
import { API_STATUS, getApiStatus, getFormProperty } from '../../../reducers';
import { compose, withState, withDispatch } from '../../../enhancers';

export const UnsubscriptionView = ({
  ready = true,
  email = '',
  redirectionPage = '/',
  onEmailChange = () => Promise.resolve(false),
  onAcceptTermsChange = () => Promise.resolve(false),
  onUnsubscribe = () => Promise.resolve({ response: { error: true } }),
  onAddMessage = () => Promise.resolve(false),
  onClearEmail = () => Promise.resolve(false),
  onRedirect = () => Promise.resolve(false),
}) => {
  const onSubmit = () =>
    onUnsubscribe(email).then(({ response }) =>
      response.error
        ? onAddMessage({ title: __(response.error), type: 'error' })
        : onAddMessage({
            title: __('unsubscription_success'),
            type: 'success',
          })
            .then(() => onClearEmail())
            .then(() => onRedirect(redirectionPage)),
    );

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        onSubmit();
      }}
      className="ui form unsubscribe"
    >
      <div className="ui field">
        <label htmlFor="unsubscription.email">{__('email')}</label>
        <input
          className="ui input required"
          required
          type="email"
          name="email"
          id="unsubscription.email"
          placeholder={__('enter_your_mail_here')}
          value={email}
          onChange={onEmailChange}
        />
      </div>
      <div className="ui segment vertical form-buttons">
        <button className="ui teal button">{__('unsubscribe')}</button>
      </div>
    </form>
  );
};

const mapState = (state, { email }) => ({
  ready: getApiStatus(state, 'subscribers') !== API_STATUS.IDLE,
  email: getFormProperty(state, 'unsubscription', 'email') || email,
});

const mapDispatch = (dispatch, { email, subscriptionId }) => ({
  onEmailChange: event => dispatch(handleFormPropertyChange('unsubscription', event)),
  onAcceptTermsChange: event => dispatch(handleFormPropertyChange('unsubscription', event)),
  onUnsubscribe: mail => dispatch(apiDELETE(`subscribers/${subscriptionId}`, { mail })),
  onAddMessage: message => dispatch(addMessage(message, 'unsubscription')),
  onClearEmail: () => dispatch(clearFormData('unsubscription')),
  onRedirect: page => dispatch(setLocation(page)),
});

const Unsubscription = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(UnsubscriptionView);

export default Unsubscription;

import { withStyles, makeStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import colors from '../../styles/colors';

export const gridStyles = makeStyles({
  root: {
    '& .MuiDataGrid-cell': {
      fontSize: 19,
      fontFamily: 'Muli',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-colCell': {
      backgroundColor: colors.ourBlack,
      color: colors.ourWhite,
      fontSize: 19,
      fontFamily: 'Muli',
    },

    '& .MuiDataGrid-sortIcon': {
      color: colors.ourWhite,
    },
  },
});

const colorMixinToChilds = typeOfChild => css`
  &:hover {
    & > ${typeOfChild} {
      color: ${colors.ourCyan};
    }
  }
  & > ${typeOfChild} {
    color: ${colors.ourWhite};
  }
`;

export const Line = styled.hr`
  border: 1px solid ${colors.ourCyan};
`;

export const DrawerStyled = styled(Drawer)`
  white-space: nowrap;
  width: 50px;
  @media (min-width: 1000px) {
    width: 207px;
  }
  ${props =>
    css`
      & > div {
        // aply styles to child element (Paper)
        background: ${props.background};
        width: ${props.paperwidth};
        top: ${props.top}!important;
        /* height: ${props.paperheight}; */
        position: ${props.position ? props.position : 'absolute'};
        height: ${
          props.height ? props.height : 'calc(100% - 73px);'
        }; // 73px are the pixels that drawer differs from header
        overflow: hidden;
      }
    `}
`;

export const ThreeDots = styled(MoreVertIcon)`
  color: white;
`;

export const ListItemStyled = styled(ListItem)`
  border-radius: 8px;
  opacity: 1;
  ${colorMixinToChilds('div')}

  ${props =>
    props.checked &&
    css`
      color: ${colors.ourCyan};
      background: #1f1f1f;
    `}
`;

export const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: colors.ourBlack,
    color: colors.ourWhite,
    fontSize: 18,
  },
  body: {
    fontFamily: 'Muli',
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: colors.ourGray,
    },
  },
}))(TableRow);

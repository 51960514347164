import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getApiResource } from '../../../reducers';
import { apiPOST, apiGET, apiDELETE } from '../../../actions';
import { Text, StyledButton, HorizontalItems, VerticalItems } from '../../../styles/GenericStyled';
import ExhibitionDeletion from '../viewer/ExhibitionDeletion';

export const ExhibitionListView = ({
  exhibitionId,
  sortedReports,
  onDeleteExhibition,
  onFetchExhibitions,
  onFetchReports,
  onDeleteReport,
  onSendWarningMail,
  userId,
  reports,
}) => {
  const [open, setOpen] = React.useState([false, false, false]);

  const handleOpen = index => {
    const tmp = [false, false, false];
    tmp[index] = true;
    setOpen(tmp);
  };

  const handleClose = () => {
    setOpen([false, false, false]);
  };

  const deleteReports = () => {
    reports.forEach(reportObj => {
      if (reportObj.exhibition === exhibitionId) onDeleteReport(reportObj._id);
    });
  };

  return (
    <div
      style={{
        width: '100%',
        margin: 0,
        padding: '0px 20px 20px 20px',
        border: '1px solid black',
        borderTop: 'none',
      }}
    >
      <VerticalItems>
        <Dialog
          disableScrollLock
          open={open[1]}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Warn the user?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to warn the user by email?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleClose} mycolor="white" background="red">
              No
            </StyledButton>
            <StyledButton
              onClick={() => {
                onSendWarningMail({ reasons: sortedReports, user: userId });
                // we should make an endpoint that takes the userId and sends email?
                handleClose();
              }}
              mycolor="white"
              background="green"
              autoFocus
            >
              Yes
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Dialog
          disableScrollLock
          open={open[2]}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Clear reports?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure the exhibition is not violating any terms?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleClose} mycolor="white" background="red">
              No
            </StyledButton>
            <StyledButton
              onClick={() => {
                deleteReports(exhibitionId);
                onFetchExhibitions();
                onFetchReports();
                handleClose();
              }}
              mycolor="white"
              background="green"
              autoFocus
            >
              Yes
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Text type="h5">Reasons</Text>
        {Object.keys(sortedReports[exhibitionId]).map(key => (
          <Text type="sub2" key={key}>
            {`${key} : ${sortedReports[exhibitionId][key]}`}
          </Text>
        ))}
        <Text type="sub2"> {`user : ${userId} `}</Text>
        <HorizontalItems
          style={{ width: '100%', marginTop: '20px', justifyContent: 'space-evenly' }}
        >
          <ExhibitionDeletion exhibitionId={exhibitionId} />
          <StyledButton
            primary={1}
            mycolor="black"
            background="yellow"
            onClick={() => handleOpen(1)}
          >
            <ReportProblemOutlinedIcon />
          </StyledButton>

          <StyledButton
            primary={1}
            mycolor="white"
            background="green"
            onClick={() => handleOpen(2)}
          >
            <CheckCircleOutlineIcon />
          </StyledButton>
        </HorizontalItems>
      </VerticalItems>
    </div>
  );
};

const mapState = state => ({
  reports: Object.values(getApiResource(state, `reports`)),
});

const createQuery = ({ filter, limit, offset, sort, include, populate }) => ({
  filter,
  sort,
  page: { limit, offset },
  include,
  populate,
});
const mapDispatch = (dispatch, props) => {
  const endpoint =
    props.currentUser && props.userId && props.currentUser._id === props.userId
      ? 'exhibitionsUserProfile'
      : 'exhibitions';
  return {
    onSendWarningMail: form => dispatch(apiPOST('viewReportPage', form)),
    onFetchReports: () => dispatch(apiGET('reports')),
    onFetchExhibitions: () => dispatch(apiGET(endpoint, createQuery(props))),
    onFetchCategories: () => dispatch(apiGET('categories')),
    onDeleteExhibition: exhibitionId =>
      dispatch(apiDELETE(`exhibitionsUserProfile/${exhibitionId}`)),
    onDeleteReport: reportId => dispatch(apiDELETE(`reports/${reportId}`)),
  };
};

const ExhibitionList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionListView);
export default ExhibitionList;

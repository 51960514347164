import React from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import ArticleMeta from '../components/articles/ArticleMeta';
import Article from '../components/articles/lists/Article';

const ArticlePage = ({ articleId }) => (
  <DefaultLayout>
    <ArticleMeta articleId={articleId} />
    <div className="article ui main container">
      <Article articleId={articleId} editable />
    </div>
  </DefaultLayout>
);

export default ArticlePage;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDisplayCases = exports.createDisplayCase = void 0;

var _math = require("../math");

var _tools = require("./tools");

var createDisplayCase = function createDisplayCase(displayCase) {
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var scale = (0, _math.parseQuaternion)(displayCase.scale);
  scale[0] /= 3;
  scale[1] /= 3;
  scale[2] /= 3;
  return {
    primitive: 'a-entity',
    className: 'display-case',
    key: "#displayCase-".concat(displayCase._id),
    'gltf-model': "#display-case-asset-".concat(displayCase._id),
    events: (0, _tools.createEvents)(events.displayCases, displayCase, opts),
    'static-body': true,
    rotation: (0, _math.toEulerianAngle)((0, _math.parseQuaternion)(displayCase.rotation), opts.invertAxisRot).join(' '),
    position: (0, _math.mirror)((0, _math.parseQuaternion)(displayCase.position), opts.invertAxisPos).join(' '),
    scale: scale.join(' ')
  };
};

exports.createDisplayCase = createDisplayCase;

var createDisplayCases = function createDisplayCases(exhibition) {
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (!exhibition || !exhibition.displayCases) {
    return null;
  }

  return exhibition.displayCases.map(function (displayCase) {
    return createDisplayCase(displayCase, events, opts);
  });
};

exports.createDisplayCases = createDisplayCases;
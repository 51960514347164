import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import utils from '../utils';
import ExhibitionList from '../components/exhibitions/lists/ExhibitionList';
import { withState, compose, withDispatch } from '../enhancers';
import { getApiResource, API_KEYS, getUIProperty, getAuthUser } from '../reducers';
import DefaultLayout from '../components/layouts/DefaultLayout';
import ExhibitionViewFrame from '../components/exhibitions/viewer/ExhibitionViewFrame';
import ExhibitionInfo from '../components/exhibitions/viewer/ExhibitionInfo';
import ExhibitionMeta from '../components/exhibitions/viewer/ExhibitionMeta';
import { ExpandButton, StyledTheme, Text } from '../styles/GenericStyled';
import useWindowSize from '../hooks/windowSize';
import CommentList from '../components/comments/lists/CommentList';
import privateConfig from 'artsteps2-config/private.json';
import colors from '../styles/colors';
import Box from '@material-ui/core/Box';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import firebaseActions from '../firebase/Firebase';
import toast from '../components/toast/toast';
import { setUIProperty } from '../actions';

const EXHIBITION_LIMIT = 6;

const ExhibitionViewPageView = ({
  exhibitionId,
  exhibition = { _id: null, user: { _id: null }, categories: [null] },
  query,
  isPrivateSpace,
  categories,
  currentUser,
  setOpenLogin,
}) => {
  const [value, setValue] = useState(0);
  const [isUsers, setIsUsers] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    setIsSmall(size.width < 1280);
  }, [size.width]);

  useEffect(() => {
    currentUser &&
      setIsUsers(currentUser._id === exhibition.user._id || currentUser._id === exhibition.user);
  }, [currentUser, exhibition]);

  let fromCreatorFilter;
  let relatedFilter;
  if (!isPrivateSpace) {
    fromCreatorFilter = {
      published: true,
      user: exhibition.user._id || exhibition.user,
    };

    relatedFilter = {
      //_id: { $ne: exhibition._id },
      published: true,
      categories: exhibition.categories[0] || undefined, // brings all the exhibition that have the main category of this exhibition
    };
  } else {
    fromCreatorFilter = {
      publishedPrivateSpace: true,
      user: exhibition.user._id || exhibition.user,
    };

    relatedFilter = {
      published: true,
      categories: exhibition.categories[0] || undefined, // brings all the exhibition that have the main category of this exhibition
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showVoting, setShowVoting] = React.useState(false);
  useEffect(() => {
    async function fetchData() {
      if (
        privateConfig?.voting?.active === true &&
        !isPrivateSpace &&
        privateConfig?.voting?.exhibitions.includes(exhibitionId)
      ) {
        if (currentUser === '' || currentUser === undefined) {
          setShowVoting(true);
        } else {
          const voteEmpty = await firebaseActions.voteEmpty(currentUser._id);
          if (voteEmpty) {
            setShowVoting(true);
          }
        }
      }
    }
    fetchData();
  }, [currentUser, exhibitionId, isPrivateSpace, isUsers]);


  return (
    <DefaultLayout exhibitionId={exhibitionId} exhibition={exhibition}>
      <ExhibitionMeta exhibitionId={exhibitionId} />
      <Grid
        style={{
          marginBottom: '20px',
          marginTop: `${isUsers && size.width > 720 ? '80px' : '20px'}`,
        }}
        container
        justify="space-evenly"
      >
        <Grid item sm={11} md={11} lg={9} xl={9}>
          <ExhibitionViewFrame
            exhibitionId={exhibitionId}
            webVR={utils.navigator.isIE()}
            unsupportedAgent={utils.navigator.isIE()}
            unsupportedDevice={utils.navigator.isMobile()}
          />

          <ExhibitionInfo
            exhibitionId={exhibitionId}
            exhibition={exhibition}
            voting={
              showVoting ? (
                <Box
                  component={ExpandButton}
                  boxShadow={3}
                  mycolor={colors.ourWhite}
                  mycolorhover={colors.ourWhite}
                  background={colors.ourGreen}
                  backgroundhover={colors.ourGreen}
                  onClick={() => {
                    if (currentUser === '' || currentUser === undefined) {
                      setOpenLogin(true);
                    } else {
                      firebaseActions.castVote(currentUser._id, exhibitionId);
                      setShowVoting(false);
                    }
                  }}
                  style={{ width: '200px', margin: '10px' }}
                >
                  <HowToVoteIcon />
                  <Text
                    style={{
                      opacity: '0.8',
                      marginLeft: '10px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                    type="body1"
                  >
                    Vote
                  </Text>
                </Box>
              ) : (
                <></>
              )
            }
          />
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="flex-start"
          spacing={2}
          style={{ marginBottom: '100px', alignSelf: 'flex-start' }}
          item
          sm={12}
          md={12}
          lg={2}
          xl={2}
        >
          <Grid item xs={12}>
            <ThemeProvider theme={StyledTheme}>
              <Tabs
                style={{
                  width: `${isSmall ? '' : '100%'}`,
                  maxWidth: `${isSmall ? '500px' : '420px'}`,
                  margin: 'auto',
                  fontSize: `${isSmall ? '12px' : '16px'}`,
                }}
                value={value}
                onChange={handleChange}
                centered
                variant="fullWidth"
              >
                <Tab label="Related" />
                <Tab label="More from this creator" />
                {isSmall && <Tab label="Comments" />}
              </Tabs>
            </ThemeProvider>
            {value <= 1 ? (
              <ExhibitionList
                wide
                usernames
                sort="-trending"
                filter={(value === 1 && fromCreatorFilter) || (value === 0 && relatedFilter)}
                include={[
                  'categories',
                  'description',
                  `views.${API_KEYS.META}.totalCount`,
                  `likes.${API_KEYS.META}.totalCount`,
                  'visits',
                  'rating',
                  '_v1Rating',
                ]}
                populate={['user']}
                limit={EXHIBITION_LIMIT}
                viewPage
              />
            ) : (
              <CommentList exhibitionId={exhibitionId} exhibition={exhibition} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

const mapState = (state, { exhibitionId }) => ({
  exhibition: {
    _id: null,
    user: {
      _id: null,
    },
    categories: [null],
    ...getApiResource(
      state,
      `exhibitions${
        window.location.href.includes('currentUser') ? 'UserProfile' : getUIProperty(state, 'isPrivateSpace') ? 'Private' : ''
      }/${exhibitionId}`,
    ),
  },
  categories: Object.values(getApiResource(state, 'categories')),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  currentUser: getAuthUser(state),
});
const mapDispatch = dispatch => ({
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
});

const ExhibitionViewPage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionViewPageView);

export default ExhibitionViewPage;

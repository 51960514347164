// import { __ } from 'artsteps2-common';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import React, { useState, useEffect } from 'react';
import { BookMarkDiv } from '../lists/ExhibitionStyled';
import { API_STATUS, getApiStatus, getAuthUser, getApiResource } from '../../../reducers';
import { apiHEAD, apiGET, apiPOST, apiDELETE, setLocation } from '../../../actions';
import { compose, withState, withDispatch } from '../../../enhancers';

export const ExhibitionBookMarksView = ({
  onBookMark,
  onUnBookMark,
  onFetchBookMark,
  onFetchBookMarks,
  currentUserId,
  onRedirect,
}) => {
  // having as a useSate to set BookMark UnBookMark
  const [userBookMarked, setUserBookMarkd] = useState(false);
  const [busy, setBusy] = useState(false);

  // Used as the componentDidMount for initializing the starting value
  // and whether the user has already BookMarkd the exhibition or not
  useEffect(() => {
    Promise.all([onFetchBookMarks(), onFetchBookMark()])
      .then(data => {
        if (data) {
          currentUserId && setUserBookMarkd(data[1].meta.totalCount !== 0);
        }
      })
      .catch(err => console.log(err));
  }, []);

  // having a useEffect hooked to the busy variable
  useEffect(
    () => {
      // if the component will unmount we have to abort the requests.
      const ac = new AbortController();

      if (busy) {
        userBookMarked && onBookMark(userBookMarked, setBusy, 'POST');
        !userBookMarked && onUnBookMark(userBookMarked, setBusy, 'DEL');
      }

      return () => ac.abort(); // Abort both fetches on unmount
    },
    [busy],
  );

  return (
    <BookMarkDiv>
      {userBookMarked ? (
        <BookmarkIcon
          onKeyPress={event =>
            event.keyCode === 13 && setUserBookMarkd(prevUserBookMarkd => !prevUserBookMarkd)
          }
          onClick={() => {
            if (currentUserId) {
              setUserBookMarkd(prevUserBookMarkd => !prevUserBookMarkd);
              !busy && setBusy(true); // if there arent any requests happening start a request
            } else {
              onRedirect('/login');
            }
          }}
          style={{ fontSize: '2rem' }}
        />
      ) : (
        <BookmarkBorderIcon
          onKeyPress={event =>
            event.keyCode === 13 && setUserBookMarkd(prevUserBookMarkd => !prevUserBookMarkd)
          }
          onClick={() => {
            if (currentUserId) {
              setUserBookMarkd(prevUserBookMarkd => !prevUserBookMarkd);
              !busy && setBusy(true); // if there arent any requests happening start a request
            } else {
              onRedirect('/login');
            }
          }}
          style={{ fontSize: '2rem' }}
        />
      )}
    </BookMarkDiv>
  );
};
const createQuery = (exhibition, user) => ({ filter: { exhibition, user } });
const mapState = (state, { exhibitionId }) => {
  const { _id: currentUserId = null } = getAuthUser(state) || {};
  return {
    currentUserId,
    BookMarks: Object.values(
      getApiResource(state, 'BookMarks', createQuery(exhibitionId, currentUserId)),
    ),
    ready: getApiStatus(state, 'BookMarks', createQuery(exhibitionId)) === API_STATUS.IDLE,
  };
};
const mapDispatch = (dispatch, { exhibitionId, currentUserId, BookMarks }) => {
  const onFetchBookMarks = () =>
    Promise.resolve(dispatch(apiHEAD('BookMarks', createQuery(exhibitionId))));
  const onFetchBookMark = () =>
    currentUserId
      ? Promise.resolve(dispatch(apiGET('BookMarks', createQuery(exhibitionId, currentUserId))))
      : Promise.resolve(`User${undefined}`);

  const postOrDel = (userBookMarkdB, setBusy, lastReq) => {
    // if the user BookMarks(POST) and last request isnt BookMark(POST)
    if (userBookMarkdB && lastReq !== 'POST') {
      dispatch(apiPOST('BookMarks', { exhibition: exhibitionId }));
      onFetchBookMark().then(() => {
        doubleCheck('POST', setBusy, userBookMarkdB);
      });
    } else if (!userBookMarkdB && lastReq !== 'DEL') {
      if (BookMarks.length > 0) {
        dispatch(apiDELETE(`BookMarks/${BookMarks[0]._id}`));
        onFetchBookMark().then(() => {
          doubleCheck('DEL', setBusy, userBookMarkdB);
        });
      }
    }
  };
  // Check if the current state of the BookMark matches the type of previous request
  // If it doesnt perfom an extra request and so on
  const doubleCheck = (lastReq, setBusy, userBookMarkdB) => {
    if (lastReq === 'POST' && !userBookMarkdB) {
      postOrDel(userBookMarkdB, setBusy, lastReq);
    } else if (lastReq === 'DEL' && userBookMarkdB) {
      postOrDel(userBookMarkdB, setBusy, lastReq);
    } else {
      setBusy(false);
    }
  };

  return {
    postOrDel,
    onFetchBookMarks,
    onFetchBookMark,
    onBookMark: (userBookMarkdB, setBusy) => {
      currentUserId && postOrDel(userBookMarkdB, setBusy); // user is able to BookMark only if he is signed in
    },
    onUnBookMark: (userBookMarkdB, setBusy) => {
      currentUserId && postOrDel(userBookMarkdB, setBusy);
    },
    onRedirect: location => dispatch(setLocation(location)),
  };
};

const ExhibitionBookMarks = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionBookMarksView);
export default ExhibitionBookMarks;

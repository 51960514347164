import EditIcon from '@material-ui/icons/Edit';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import React from 'react';
import Dropzone from 'react-dropzone';
import { __ } from 'artsteps2-common';
import utils from '../../../../utils';
import Overlay from '../../../generic/Overlay';
import ExhibitionInfoEditor from '../forms/ExhibitionInfoEditor';
import { compose, withState, withDispatch } from '../../../../enhancers';
import { getUIProperty, getApiResource, getForm } from '../../../../reducers';
import ShareIcon from '@material-ui/icons/Share';

import {
  setUIProperty,
  apiPOST,
  apiPATCH,
  addMessage,
  clearMessages,
  clearFormData,
} from '../../../../actions';
import { StyledButton, Text } from '../../../../styles/GenericStyled';
import { Section, ToolbarSection, UploadContainer } from '../ExhibitionEditStyled';
import ExhibitionEmbed from "../../viewer/ExhibitionEmbed";

const IMAGE_QUALITY = 0.8;
const IMAGE_WIDTH = 640;

const MAX_STR_LEN = 64;
const REGEX_SYMBOL = ' -!\\$%^&*\\(\\)_\\+|~=`{}\\[\\]:";\'<>?,\\./';

function substring(str) {
  if (!str || str.length < MAX_STR_LEN) {
    return str;
  }

  return [
    String(str)
      .match(new RegExp(`^[\\w\\W]{${MAX_STR_LEN},}?([${REGEX_SYMBOL}]|$)`, 'm'))[0]
      .slice(0, -1),
    '...',
  ].join('');
}

const ExhibitionInfoPanelView = ({
  categories = [],
  exhibition = {},
  editorOpen = false,
  currentUser,
  onCapture = () => Promise.resolve(false),
  onSetImage = () => Promise.resolve(false),
  onUpsert = () => Promise.resolve(false),
  onEditorOpen = () => Promise.resolve(false),
  onEditorClose = () => Promise.resolve(false),
}) => {
  const createImagePreview = uri =>
    utils.image
      .toDataURL({
        uri,
        width: IMAGE_WIDTH,
        quality: IMAGE_QUALITY,
      })
      .then(dataUri => onUpsert({ image: { bin: utils.buffer.fromDataURL(dataUri) } }));

  const onDrop = files => {
    if (files && files.length && files[0].preview) {
      createImagePreview(files[0].preview);
    }
  };
  return (
    <div className="exhibition-info-panel">
      <ToolbarSection>
        <Text type="h7">{__('cover_photo')}</Text>
        <div className="ui form">
          <div className="field">
            <Dropzone className="dropzone" onDrop={onDrop} multiple accept="image/*">
              <div className="text small">{__('drop_cover_here')}</div>
              {exhibition.image && (
                <div className="ui image medium">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={utils.exhibition.getExhibitionImage(exhibition)}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <img src={utils.exhibition.getExhibitionImage(exhibition)} alt="" />
                  </a>
                </div>
              )}
            </Dropzone>
            <UploadContainer>
              <StyledButton
                type="secondary"
                data-tooltip={__('generate_cover_tooltip')}
                data-inverted
                data-position="top center"
                onClick={onCapture}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <PhotoCameraIcon style={{ marginRight: '5px' }} />
                {__('generate_cover')}
              </StyledButton>
            </UploadContainer>
          </div>
        </div>
      </ToolbarSection>
      <ToolbarSection>
        <Text type="h7">{__('public_info')}</Text>
        <Section>
          <h5>{__('title')}</h5>
          <span>{exhibition.title || ''}</span>
        </Section>
        {exhibition.description && (
          <Section>
            <h5>{__('description')}</h5>
            <span>{substring(exhibition.description)}</span>
          </Section>
        )}
        {exhibition.categories && exhibition.categories.length > 0 && (
          <Section>
            <h5>{__('categories')}</h5>
            <span>{(exhibition.categories || []).map(c => c.title).join(', ')}</span>
          </Section>
        )}
        {exhibition.tags && exhibition.tags.length > 0 && (
          <Section>
            <h5>{__('tags')}</h5>
            <span>{(exhibition.tags || []).join(', ')}</span>
          </Section>
        )}
        <UploadContainer>
          <StyledButton
            type="secondary"
            data-tooltip={__('edit_info_tooltip')}
            data-inverted
            data-position="top center"
            onClick={onEditorOpen}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <EditIcon style={{ marginRight: '5px' }} />
            {__('edit')}
          </StyledButton>
        </UploadContainer>
      </ToolbarSection>
      <ToolbarSection>
        <Text type="h7">Share and Embed</Text>
        <Section>
          <span>You can Share your exhibition in social media and/or embed it on your website.</span>
          <br />
          {exhibition.published ? (
            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '10px' }}>
              <ExhibitionEmbed exhibitionId={exhibition._id} />
            </div>
          ) : (
            <span>To Share or Embed an exhibition you need to publish it first</span>
          )}

        </Section>
      </ToolbarSection>
      <Overlay fixed open={editorOpen} onClose={onEditorClose(exhibition, 'cancel')}>
        <ExhibitionInfoEditor exhibition={exhibition} onSave={onEditorClose(exhibition, 'save')} />
      </Overlay>
    </div>
  );
};

const mapState = (state, { exhibition: { _id: exhibitionId } = {} }) => ({
  editorOpen: getUIProperty(state, `exhibitions/${exhibitionId}/editor`),
  exhibitionArtifacts: (
    getUIProperty(state, `exhibitions/${exhibitionId}/export/artifacts`) ||
    getUIProperty(state, `exhibitions/${exhibitionId}/import/artifacts`) ||
    []
  ).map(a => a.artifact),
  artifacts: getApiResource(state, 'artifacts'),
  form: getForm(state, `exhibition:${exhibitionId}`),
});

const mapDispatch = (
  dispatch,
  {
    exhibition: { _id: exhibitionId, title: exhibitionTitle } = {},
    artifacts = {},
    exhibitionArtifacts = [],
    form = {},
  },
) => ({
  onEditorOpen: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/editor`, true)),
  onEditorClose: (exhibition, mode) => () => {
    if (mode === 'cancel')
      return dispatch(clearFormData(form.name)).then(() =>
        dispatch(setUIProperty(`exhibitions/${exhibitionId}/editor`, false)),
      );
    return dispatch(setUIProperty(`exhibitions/${exhibitionId}/editor`, false));
  },
  onCapture: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/capturingAt`, Date.now())),
  onSetImage: () => {
    // const artifact = exhibitionArtifacts
    //   .map(id => artifacts[id])
    //   .find(a => a && a.type === 'image');
    // return dispatch(
    //   artifact
    //     ? setUIProperty(
    //         `exhibitions/${exhibitionId}/screenshot`,
    //         utils.artifact.getArtifactImage(artifact),
    //       )
    //     : setUIProperty(`exhibitions/${exhibitionId}/capturingAt`, Date.now()),
    // );
    return dispatch(setUIProperty(`exhibitions/${exhibitionId}/capturingAt`, Date.now()));
  },
  onUpsert: exhibition =>
    exhibitionId === 'new'
      ? dispatch(
          apiPOST(
            'exhibitionsUserProfile',
            {
              title: exhibitionTitle,
              ...exhibition,
            },
            { populate: 'categories' },
          ),
        )
      : dispatch(
          apiPATCH(
            `exhibitionsUserProfile/${exhibitionId}`,
            {
              title: exhibitionTitle,
              ...exhibition,
            },
            { populate: 'categories' },
          ),
        ),
  onAddMessage: message => dispatch(addMessage(message, 'exhibitions')),
  onClearMessages: () => dispatch(clearMessages('exhibitions')),
});

const ExhibitionInfoPanel = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionInfoPanelView);

export default ExhibitionInfoPanel;

import React from 'react';
import Popover from '@material-ui/core/Popover';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { apiGET } from '../../actions';
import { getApiResource } from '../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { HorizontalItems, HorizontalMargin } from '../../styles/GenericStyled';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    maxWidth: '350px',
    padding: theme.spacing(1),
  },
}));

export const LicenseView = ({ license }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <HorizontalItems style={{ marginTop: '20px' }}>
        <HorizontalMargin margin="5px">
          <HelpOutlineIcon onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} />
          <Typography aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true">
            License: {license.acronym}
          </Typography>
        </HorizontalMargin>
      </HorizontalItems>
      <Popover
        disableScrollLock
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{license.title}</Typography>
        <Typography>{license.description}</Typography>
      </Popover>
    </div>
  );
};

const mapState = (state, { licenseId }) => ({
  license: getApiResource(state, `licenses/${licenseId}`),
});

const mapDispatch = (dispatch, { licenseId }) => ({
  onFetchLicense: () => dispatch(apiGET(`licenses/${licenseId}`)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchLicense }) => onFetchLicense(),
};

const License = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(LicenseView);

export default License;

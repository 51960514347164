import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { i18n } from 'artsteps2-common';
import { StylesProvider } from '@material-ui/core/styles';
import 'semantic-ui-css/semantic.min.css';
import store, { history } from './store';
import App from './App';
import './styles/index.css';
import publicConfig from 'artsteps2-config/public.json';

i18n.init({}, () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${publicConfig.googleAnalytics.trackingId}`;
  script.async = true;
  document.head.appendChild(script);
  return render(
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </StylesProvider>
    </Provider>,
    document.getElementById('root'),
  );
});

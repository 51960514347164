import { __ } from 'artsteps2-common';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useWindowSize from '../../../hooks/windowSize';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { apiGET, apiDELETE, setUIProperty, apiPOST, setLocation } from '../../../actions';
import { getApiResource, getUIProperty } from '../../../reducers';
import utils from '../../../utils';
import { HorizontalItems, VerticalItems, TitleLink, InvisibleButton } from './ExhibitionInfoStyled';
import { Text, StyledButton, FullLine, LabelBox } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';

const bp1 = 650; // break point 1
const ExhibitionInfoBeforeCommentsView = ({
  exhibition,
  user,
  currentUserId,
  onFetchFollows,
  postOrDel,
  onRedirect,
  categoryList,
  showDescription = true,
  setShowDescription,
  setOpenLogin,
}) => {
  const size = useWindowSize();
  const [isFollowing, setIsFollowing] = useState(false);
  const [busy, setBusy] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // having an effect that sets if the screen size if mobile or not
  useEffect(() => {
    setIsMobile(size.width < bp1);
  }, [size.width]);
  // Is the user following the curator?
  useEffect(() => {
    onFetchFollows()
      .then(data => {
        if (data) {
          setIsFollowing(data.meta.totalCount !== 0);
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    const ac = new AbortController();
    if (busy) {
      currentUserId && postOrDel(isFollowing, setBusy, isFollowing ? 'DEL' : 'POST');
    }

    return () => ac.abort(); // Abort both fetches on unmount
  }, [busy]);

  // Making an object array with key the category id and value the category title
  // in order to find the category title by id
  const categoryListMapped = Object.values(categoryList).map(value => ({
    [value._id]: value.title,
  }));

  const categoryLista = [];

  for (let i = 0; i < exhibition.categories.length; i++) {
    for (let j = 0; j < categoryListMapped.length; j++) {
      categoryListMapped[j][exhibition.categories[i]] &&
        categoryLista.push(categoryListMapped[j][exhibition.categories[i]]);
    }
  }
  const categoryLabels = categoryLista.map(category => (
    <span style={{ marginBottom: '10px' }} key={category}>
      <LabelBox mycolor="#405363" background="#F3F3F3">
        <Text type="body2">{category}</Text>
      </LabelBox>
    </span>
  ));

  return (
    <div>
      {isMobile && <FullLine />}
      <HorizontalItems style={{ marginBottom: '10px' }}>
        {!isMobile && (
          <Link to={`/profile/${exhibition.user._id}`}>
            <Avatar
              style={{ width: '160px', height: '160px' }}
              alt="Avatar"
              src={utils.user.getUserImage(user)}
            />
          </Link>
        )}
        <span style={{ width: '100%' }}>
          <VerticalItems style={{ width: '100%' }}>
            <HorizontalItems style={{ marginTop: '10px' }}>
              {isMobile && (
                <Link to={`/profile/${exhibition.user._id}`}>
                  <Avatar
                    style={{ width: '40px', height: '40px' }}
                    alt="Avatar"
                    src={utils.user.getUserImage(user)}
                  />
                </Link>
              )}
              {exhibition.user.profile && (
                <span>
                  <TitleLink to={`/profile/${exhibition.user._id}`}>
                    {exhibition.user.profile.name}
                  </TitleLink>
                </span>
              )}
              {currentUserId !== exhibition.user._id && (
                <span>
                  <StyledButton
                    type="secondary"
                    background={colors.ourGreen}
                    onClick={() => {
                      if (currentUserId) {
                        setIsFollowing(!isFollowing);
                        !busy && setBusy(true); // if there arent any requests happening start a request
                      } else {
                        setOpenLogin(true);
                      }
                    }}
                  >
                    <span>{isFollowing ? <span>Following</span> : <span>Follow</span>}</span>
                  </StyledButton>
                </span>
              )}
            </HorizontalItems>
            {categoryLabels.length > 0 && !isMobile && (
              <HorizontalItems style={{ marginTop: '10px' }}>
                <div>
                  <b>Exhibition Categories</b>
                </div>
              </HorizontalItems>
            )}
            <HorizontalItems style={{ flexWrap: 'wrap', marginTop: '10px' }}>
              {!isMobile && categoryLabels}
              {exhibition.description && !isMobile && (
                <InvisibleButton
                  onClick={() => setShowDescription(!showDescription)}
                  style={{ marginLeft: 'auto' }}
                >
                  <HorizontalItems>
                    {showDescription ? (
                      <span>
                        <b>{__('less')}</b> <ArrowDropUpIcon />
                      </span>
                    ) : (
                      <span>
                        <b>{__('more')}</b> <ArrowDropDownIcon />
                      </span>
                    )}
                  </HorizontalItems>
                </InvisibleButton>
              )}
            </HorizontalItems>
          </VerticalItems>
        </span>
      </HorizontalItems>
      {showDescription && (
        <VerticalItems>
          {categoryLabels.length > 0 && isMobile && (
            <>
              <HorizontalItems>
                <div>
                  <b>Exhibition Categories</b>
                </div>
              </HorizontalItems>
              <HorizontalItems style={{ flexWrap: 'wrap', marginTop: '10px' }}>
                {categoryLabels}
              </HorizontalItems>
            </>
          )}
          {exhibition.description && (
            <Text style={{ margin: '10px 0px 10px ' }} body1={1}>
              {exhibition.description}
            </Text>
          )}
        </VerticalItems>
      )}
    </div>
  );
};

const mapState = (state, { exhibition, currentUserId }) => ({
  showDescription: getUIProperty(state, 'showDescription'),
  categoryList: getApiResource(state, `categories`),
  user: getApiResource(state, `users/${exhibition.user._id}`), // In the profile view the user isnt an object
  isReportOpen: getUIProperty(state, `exhibitions/overlay/report`),
  isShareOpen: getUIProperty(state, `exhibitions/overlay/share`),
  follows: Object.values(
    getApiResource(state, 'follows', {
      filter: { user: exhibition.user._id, follower: currentUserId },
    }),
  ),
});

const mapDispatch = (dispatch, { exhibition, currentUserId, follows }) => {
  const onFetchFollows = () =>
    currentUserId && currentUserId !== exhibition.user._id
      ? dispatch(
          apiGET('follows', {
            filter: { user: exhibition.user._id, follower: currentUserId },
          }),
        )
      : Promise.resolve(undefined);

  const postOrDel = (isFollowing, setBusy, lastReq) => {
    // if the user follows(POST) and last request isnt follow(POST)
    if (isFollowing && lastReq !== 'POST') {
      dispatch(
        apiPOST(
          'follows',
          { user: exhibition.user._id },
          {
            filter: { user: exhibition.user._id },
            populate: 'follower',
          },
        ),
      );
      onFetchFollows().then(() => {
        doubleCheck('POST', setBusy, isFollowing);
      });
    } else if (!isFollowing && lastReq !== 'DEL') {
      if (follows.length > 0) {
        dispatch(apiDELETE(`follows/${follows[0]._id}`));
        onFetchFollows().then(() => {
          doubleCheck('DEL', setBusy, isFollowing);
        });
      }
    } else {
      setBusy(false);
    }
  };
  // Check if the current state of the follow matches the type of previous request
  // If it doesnt perfom an extra request and so on
  const doubleCheck = (lastReq, setBusy, isFollowing) => {
    if (lastReq === 'POST' && !isFollowing) {
      postOrDel(isFollowing, setBusy, lastReq);
    } else if (lastReq === 'DEL' && isFollowing) {
      postOrDel(isFollowing, setBusy, lastReq);
    } else {
      setBusy(false);
    }
  };

  return {
    setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
    setShowDescription: showDescription =>
      dispatch(setUIProperty('showDescription', showDescription)),
    onFetchUser: () => dispatch(apiGET(`users/${exhibition.user._id}`)),
    onFetchFollows,
    postOrDel,
    onRedirect: location => dispatch(setLocation(location)),
  };
};

const lifecycleMap = {
  onDidMount: ({ exhibition = {}, onFetchUser, onFetchFollows }) => {
    exhibition.user._id && onFetchUser();
    onFetchFollows();
  },
  onDidUpdate: (props, { exhibition = {}, onFetchUser }) =>
    exhibition.user._id !== props.exhibition.user._id && onFetchUser(),
};
const ExhibitionInfoBeforeComments = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionInfoBeforeCommentsView);
export default ExhibitionInfoBeforeComments;

import { combineReducers } from 'redux';
import api, * as fromApi from './api';
import auth, * as fromAuth from './auth';
import forms, * as fromForms from './forms';
import messages, * as fromMessages from './messages';
import routing, * as fromRouting from './routing';
import ui, * as fromUI from './ui';

export default combineReducers({
  api,
  auth,
  forms,
  messages,
  routing,
  ui,
});

export const { API_STATUS, API_KEYS, API_LIMIT, API_CACHE_TIMEOUT } = fromApi;
export const getApiResource = (state, ...args) => fromApi.getApiResource(state.api, ...args);
export const getApiMeta = (state, ...args) => fromApi.getApiMeta(state.api, ...args);
export const getApiStatus = (state, ...args) => fromApi.getApiStatus(state.api, ...args);

export const { AUTH_STATUS } = fromAuth;
export const getAuthUser = (state, ...args) => fromAuth.getAuthUser(state.auth, ...args);
export const getAuthToken = (state, ...args) => fromAuth.getAuthToken(state.auth, ...args);
export const getAuthStatus = (state, ...args) => fromAuth.getAuthStatus(state.auth, ...args);
export const getAuthUserRoles = (state, ...args) => fromAuth.getAuthUserRoles(state.auth, ...args);
export const getAuthCurrentSpace = (state, ...args) =>
  fromAuth.getAuthCurrentSpace(state.auth, ...args);

export const getForm = (state, ...args) => fromForms.getForm(state.forms, ...args);
export const getFormProperty = (state, ...args) => fromForms.getFormProperty(state.forms, ...args);

export const getMessages = (state, ...args) => fromMessages.getMessages(state.messages, ...args);
export const getAllMessages = (state, ...args) =>
  fromMessages.getAllMessages(state.messages, ...args);

export const getLocation = (state, ...args) => fromRouting.getLocation(state.routing, ...args);
export const getLocationPathname = (state, ...args) =>
  fromRouting.getLocationPathname(state.routing, ...args);
export const getLocationQuery = (state, ...args) =>
  fromRouting.getLocationQuery(state.routing, ...args);

export const getUIProperty = (state, ...args) => fromUI.getUIProperty(state.ui, ...args);

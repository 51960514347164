/*
import config from 'artsteps2-config/public.json';

const API_ENDPOINT = 'https://dialogflow.googleapis.com/v2/';
const API_TOKEN_CLIENT = config.dialogflow.tokens.client;
const API_TOKEN_DEV = config.dialogflow.tokens.developer; // FIXME: move this to the back-end
*/
const INTENT_FALLBACK = 'chatbot/fallback';

export const createIntent = (key, data = []) => Promise.resolve(true);

export const updateIntent = (key, data = []) => Promise.resolve(true);

export const deleteIntent = key => Promise.resolve(true);

export const getIntent = input => Promise.resolve({ intent: INTENT_FALLBACK });

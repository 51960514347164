import React from 'react';
import InputField from '../../generic/forms/InputField';

const needsSearchFirst = ['flickr'];

const UrlInput = ({ files, setFiles, uploadOption }) =>
  !needsSearchFirst.find(option => option === uploadOption) ? (
    <InputField
      id="url"
      name="url"
      label={uploadOption}
      hint="Paste url"
      value={files && files.length > 0 ? files[0].uri : ''}
      handleChange={e => setFiles([{ uri: e.target.value }])}
    />
  ) : (
    <></>
  );

export default UrlInput;

import React from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getUIProperty } from '../../../reducers';
import { setUIProperty } from '../../../actions';
import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import Artifact from './Artifact';
import { StyledFolder } from './ArtifactsStyled';
import { ItemList } from '../../exhibitions/editor/ExhibitionEditStyled';

const ArtifactFolderView = ({
  folder,
  onSetSelectedFolder,
  artifacts = [],
  controls,
  exhibitionId,
  selectedFolder,
}) => (
  <>
    {/* + The unary plus operator converts its operand to Number type. */}
    <StyledFolder
      onClick={() =>
        selectedFolder === folder ? onSetSelectedFolder(undefined) : onSetSelectedFolder(folder)
      }
      selected={+(selectedFolder === folder)}
      notag={+(folder === 'no tag')}
    >
      <HorizontalItems>
        <FolderIcon fontSize="large" />
        <Text style={{ marginLeft: '10px' }} mycolor="inherit" type="body1">
          {folder}
        </Text>
      </HorizontalItems>
    </StyledFolder>
    {selectedFolder === folder && (
      <ItemList>
        {artifacts.map(artifact => (
          <Artifact
            key={artifact._id}
            artifact={artifact}
            controls={controls}
            exhibitionId={exhibitionId}
          />
        ))}
      </ItemList>
    )}
  </>
);
const mapState = state => ({
  selectedFolder: getUIProperty(state, 'selectedFolder'),
});

const mapDispatch = dispatch => ({
  onSetSelectedFolder: selectedFolder => dispatch(setUIProperty('selectedFolder', selectedFolder)),
});

const ArtifactFolder = compose(withState(mapState), withDispatch(mapDispatch))(ArtifactFolderView);

export default ArtifactFolder;

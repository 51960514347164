import React, { useState, useEffect } from 'react';
import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import Uploader from '../../generic/forms/Uploader-2';
import { BlueSwitch } from '../forms/FormStyled';
import FilePreview from '../previews/FilePreview';
import UploadOptionSelect from './UploadOptionSelect';
import InputField from '../../generic/forms/InputField';

const options = [
  { value: 'file', name: 'File', _id: 0 },
  { value: 'url', name: 'Url', _id: 1 },
];

const AudioField = ({ audioFiles, setAudioFiles }) => {
  const [addAudio, setAddAudio] = useState(false);
  const [uploadOption, setUploadOption] = useState('file');

  useEffect(() => {
    if (audioFiles && audioFiles.length > 0) {
      setAddAudio(true);
      if (audioFiles[0].uri) setUploadOption('url');
    }
  }, [audioFiles]);

  return (
    <>
      <HorizontalItems style={{ width: '100%' }}>
        <Text>Audio</Text>
        <BlueSwitch
          checked={addAudio}
          onChange={() => setAddAudio(prev => !prev)}
          name="add-audio"
          style={{ marginRight: '8px' }}
        />
        {addAudio && (
          <UploadOptionSelect
            noHint
            uploadOption={uploadOption}
            setUploadOption={setUploadOption}
            type="Audio"
            options={options}
          />
        )}
      </HorizontalItems>

      {addAudio && (
        <>
          <div style={{ width: '100%', marginTop: '-20px' }}>
            <Text align="left" type="body3">
              Supported file formats ( {['.mp3', '.wav', '.ogg'].join(' ')}).
            </Text>
          </div>
          {uploadOption === 'file' && (
            <Uploader acceptedFiles={['.mp3', '.wav', '.ogg']} setFiles={setAudioFiles} />
          )}
          {uploadOption === 'url' && (
            <InputField
              id="url-input"
              name="url-input"
              label={uploadOption}
              hint="Paste image url"
              value={audioFiles && audioFiles.length > 0 ? audioFiles[0].uri : ''}
              handleChange={e => setAudioFiles([{ uri: e.target.value }])}
            />
          )}

          <FilePreview uploadOption="audio" files={audioFiles} type="audio" />
        </>
      )}
    </>
  );
};

export default AudioField;

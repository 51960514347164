import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import HelpIcon from '@material-ui/icons/Help';
import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import MyToolTip from '../MyToolTip';
import { StyledSelectInput } from '../../artifacts/forms/FormStyled';

const InputSelect = ({ value, handleChange, label, hint, name, options = [] }) => (
  <FormControl variant="filled" fullWidth>
    {label && (
      <HorizontalItems>
        <Text>{label}</Text>
        {hint && (
          <MyToolTip placement="right" title={hint}>
            <HelpIcon style={{ marginLeft: '8px' }} />
          </MyToolTip>
        )}
      </HorizontalItems>
    )}
    <Select
      MenuProps={{ disableScrollLock: true }}
      input={<StyledSelectInput />}
      value={value || options[0]._id}
      name={name}
      onChange={handleChange}
    >
      {options.map(option => (
        <MenuItem key={option.value || option._id} value={option.value || option._id}>
          {option.name || option.title}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default InputSelect;

import React, { useState, useEffect } from 'react';
import InputField from '../../generic/forms/InputField';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Grid from '@material-ui/core/Grid';
import { __ } from 'artsteps2-common';
import Loader from '../../generic/Loader';
import Dialog from '../../generic/Dialog';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { API_STATUS, getApiStatus, getUIProperty, getForm, getAuthUser } from '../../../reducers';
import {
  apiPOST,
  apiPATCH,
  apiGET,
  apiDELETE,
  addMessage,
  clearMessages,
  setUIProperty,
  clearFormData,
  setFormData,
} from '../../../actions';
import { BodyWithPadding, HorizontalItems, StyledButton } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';
import UploadOptionSelect from '../../artifacts/fields/UploadOptionSelect';
import { Text } from '../../../styles/GenericStyled';
import UrlInput from '../../artifacts/fields/UrlInput';
import FilePreview from '../../artifacts/previews/FilePreview';
import Uploader from '../../generic/forms/Uploader-2';
import { VerticalItems } from '../../artifacts/forms/FormStyled';

const options = [
  { value: 'file', name: 'File', _id: 0 },
  { value: 'url', name: 'Url', _id: 1 },
];
const TextureEditorView = ({
  textureId,
  form = { data: {} },
  saving = false,
  ready = true,
  removeDialogOpen = false,
  onUpsert = () => Promise.resolve({ response: { error: 'error' } }),
  onSave = () => Promise.resolve(false),
  onClose,
  onAddMessage = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onRemoveDialogOpen = () => Promise.resolve(false),
  onRemoveDialogClose = () => Promise.resolve(false),
  onRemove,
  type = 'image',
  isNew,
  onSetFormData,
}) => {
  const [uploadOption, setUploadOption] = useState('file');
  const [isFormValid, setIsFormValid] = useState(false);
  const [files, setFiles] = useState([]);
  const [thumbnail, setThumbnail] = useState();

  useEffect(() => {
    if (form.data) setIsFormValid(form.data.title && files && files.length > 0 && thumbnail);
    onSetFormData({ file: { ...files[0], image: thumbnail } });
  }, [form.data.title, files, thumbnail]);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    onSetFormData({ [name]: type === 'checkbox' ? !checked : value });
  };

  const mapFormData = data => ({
    ...data,
    file: data.file && {
      bin: data.file.bin && data.file.bin.content && data.file.bin,
      uri: data.file.uri,
    },
    image: (data.file && data.file.image) || data.image,
  });

  const onSubmit = () =>
    onUpsert(mapFormData(form.data)).then(({ response }) =>
      response.error
        ? onAddMessage({ title: __(response.error), type: 'warning' })
        : onClearMessages().then(() => onSave(response)),
    );

  return (
    <div className="texture-info">
      <Text type="h5">{__(textureId && textureId !== 'new' ? 'edit_texture' : 'add_texture')}</Text>
      <BodyWithPadding padding="24px">
        <Grid container spacing={5} direction="row" justify="space-evenly" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <VerticalItems>
              <UploadOptionSelect
                uploadOption={uploadOption}
                setUploadOption={setUploadOption}
                options={options}
              />
              {uploadOption === 'file' ? (
                <>
                  <div style={{ width: '100%', marginTop: '-24px', marginBottom: '0px' }}>
                    <Text align="left" type="body3">
                      Supported file formats ( {['.jpg', '.png', '.jpeg'].join(' ')})
                    </Text>
                  </div>
                  <Uploader
                    acceptedFiles={['.jpg', '.png', '.jpeg']}
                    setFiles={setFiles}
                    onRemove={onRemove}
                    maxFiles={1}
                  />
                </>
              ) : (
                <UrlInput
                  type={type}
                  files={files}
                  setFiles={setFiles}
                  uploadOption={uploadOption}
                />
              )}
              <FilePreview
                uploadOption={uploadOption || options[0].value}
                setFiles={setFiles}
                setThumbnail={setThumbnail}
                files={files}
                showDelete={false}
                type={type}
              />
            </VerticalItems>
          </Grid>
          <Grid item xs={12} md={6}>
            <VerticalItems>
              <InputField
                handleChange={handleChange}
                value={form.data.title}
                name="title"
                label="Title"
              />
            </VerticalItems>
          </Grid>
        </Grid>
        <HorizontalItems>
          <StyledButton
            style={{ marginLeft: 'auto' }}
            mycolor={colors.ourOrange}
            background={colors.ourWhite}
            bordercolor={colors.ourOrange}
            onClick={isNew ? undefined : onRemoveDialogOpen}
          >
            <DeleteForeverIcon style={{ marginRight: '4px' }} />
            Delete
          </StyledButton>
          <StyledButton disabled={!isFormValid} style={{ marginLeft: '8px' }} onClick={onSubmit}>
            <SaveIcon style={{ marginRight: '4px' }} />
            Save
          </StyledButton>
        </HorizontalItems>
      </BodyWithPadding>
      {saving && <Loader message={`${__('saving')} ${saving}`} />}
      <Dialog
        open={removeDialogOpen}
        title={__('artifact_deletion', form.data)}
        message={__('confirm_artifact_deletion', form.data)}
        type="warning"
        onReject={onRemoveDialogClose}
        onConfirm={() =>
          onRemoveDialogClose()
            .then(() => onRemove())
            .then(({ response: { error } = {} }) =>
              error ? onAddMessage({ type: 'error', title: __(error) }) : Promise.resolve(true),
            )
            .then(() => onClose())
        }
      />
    </div>
  );
};

const createQuery = () => ({});

const mapState = (state, { textureId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    form: getForm(state, `texture:${textureId}`),
    ready:
      getApiStatus(state, `textures/${textureId}`) === API_STATUS.IDLE &&
      getApiStatus(state, 'textures', createQuery(currentUser)) === API_STATUS.IDLE,
    saving:
      getApiStatus(state, `textures/${textureId}`) === API_STATUS.PATCH ||
      getApiStatus(state, 'textures', createQuery(currentUser)) === API_STATUS.POST,
    removeDialogOpen: getUIProperty(state, `textures/${textureId}/removeDialog`),
  };
};

const mapDispatch = (dispatch, { currentUser, textureId }) => ({
  onAddMessage: message => dispatch(addMessage(message, 'textures')),
  onClearMessages: () => dispatch(clearMessages('textures')),
  onUpsert: data =>
    textureId && textureId !== 'new'
      ? dispatch(apiPATCH(`textures/${textureId}`, data))
      : dispatch(apiPOST('textures', data, createQuery(currentUser))),
  onClearFormData: () => dispatch(clearFormData(`texture:${textureId}`)),
  onSetFormData: data => dispatch(setFormData(`texture:${textureId}`, data)),
  onFetchTexture: () =>
    textureId && textureId !== 'new'
      ? dispatch(apiGET(`textures/${textureId}`))
      : Promise.resolve({}),
  onRemoveDialogOpen: () => dispatch(setUIProperty(`textures/${textureId}/removeDialog`, true)),
  onRemoveDialogClose: () => dispatch(setUIProperty(`textures/${textureId}/removeDialog`, false)),
  onRemove: () => dispatch(apiDELETE(`textures/${textureId}`)),
});

const onInitialization = ({ onClearFormData, onFetchTexture, onSetFormData }) =>
  onClearFormData()
    .then(() => onFetchTexture())
    .then(({ response: texture }) => onSetFormData(texture));

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: ({ textureId }, props) => textureId !== props.textureId && onInitialization(props),
};

const TextureEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(TextureEditorView);

export default TextureEditor;

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import OurModal from '../../generic/OurModal';
import React from 'react';
import { __ } from 'artsteps2-common';
import { API_STATUS, getApiStatus, getForm, getAuthUser } from '../../../reducers';
import {
  apiGET,
  apiPOST,
  addMessage,
  clearFormData,
  handleFormPropertyChange,
  setUIProperty,
} from '../../../actions';
import { compose, withState, withDispatch } from '../../../enhancers';
import {
  Text,
  StyledButton,
  BodyWithPadding,
  TextButton,
} from '../../../styles/GenericStyled';

export const ExhibitionReportView = ({
  iconClass,
  form = { data: {} },
  currentUser = {},
  onFormPropertyChange = () => Promise.resolve(false),
  onClearForm = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  onAddReport = () => Promise.resolve({ response: { error: 'error' } }),
  onFetchReports,
  openReport,
  setOpenReport,
  setOpenLogin,
}) => {
  const onSubmit = () =>
    onAddReport(form.data.type).then(({ response }) =>
      response.error
        ? onAddMessage({
            type: 'error',
            title: __('insert_error'),
            description: __(response.error),
          })
        : onAddMessage({
            type: 'success',
            title: __('the_artsteps_team_will_investigate_your_request'),
          }).then(() => onClearForm()),
    );

  return (
    <OurModal
      width="25%"
      height="40%"
      minWidth="300px"
      minHeight="400px"
      openModal={openReport}
      setOpenModal={setOpenReport}
    >
      <BodyWithPadding
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          width: '100%',
          height: '100%',
        }}
        padding="45px"
      >
        <Text type="h6" style={{ marginBottom: '20px' }}>
          Report Exhibition
        </Text>
        <div style={{ marginBottom: '20px' }}>
          <Text>
            If you think this exhibition violates the{' '}
            <a href="../article/terms-of-service">{__('code_of_conduct')}</a>
            please make sure to report it as
          </Text>
        </div>
        <FormControl variant="outlined" style={{ marginBottom: '55px' }}>
          <Select
            name="type"
            className="ui dropdown"
            value={form.data.type}
            onChange={onFormPropertyChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
          >
            <FormHelperText> {__('select_type_of_violation')}</FormHelperText>

            <MenuItem value="sexual_content">{__('sexual_content')}</MenuItem>
            <MenuItem value="violent_or_repulsive_content">
              {__('violent_or_repulsive_content')}
            </MenuItem>
            <MenuItem value="hateful_of_abusive_content">
              {__('hateful_of_abusive_content')}
            </MenuItem>
            <MenuItem value="harmful_dangerous_acts">{__('harmful_dangerous_acts')}</MenuItem>
            <MenuItem value="child_abuse">{__('child_abuse')}</MenuItem>
            <MenuItem value="spam_or_misleading">{__('spam_or_misleading')}</MenuItem>
            <MenuItem value="infringes_my_rights">{__('infringes_my_rights')}</MenuItem>
            <MenuItem value="captions_issue">{__('captions_issue')}</MenuItem>
          </Select>
        </FormControl>
        {currentUser._id ? null : (
          <div style={{ marginBottom: '15px' }}>
            <Text>
              Please<TextButton onClick={() => setOpenLogin(true)}>Sign In</TextButton>before
              submitting a report
            </Text>
          </div>
        )}
        <StyledButton
          disabled={!form.data.type || !currentUser._id}
          type="primary"
          mycolor="white"
          background="#FE7F79"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            onSubmit();
            setOpenReport(false);
          }}
        >
          {__('report')}
        </StyledButton>
      </BodyWithPadding>
    </OurModal>
  );
};

const mapState = (state, { exhibitionId }) => ({
  currentUser: getAuthUser(state),
  ready: getApiStatus(state, 'reports') === API_STATUS.READY,
  form: getForm(state, `report:${exhibitionId}`),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onAddReport: type => dispatch(apiPOST('reports', { exhibition: exhibitionId, type })),
  onAddMessage: message => dispatch(addMessage(message)),
  onClearForm: () => dispatch(clearFormData(`report:${exhibitionId}`)),
  onFormPropertyChange: event =>
    dispatch(handleFormPropertyChange(`report:${exhibitionId}`, event)),
  onFetchReports: () => dispatch(apiGET(`reports`)),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
});

const ExhibitionReport = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionReportView);

export default ExhibitionReport;

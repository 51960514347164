"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDoors = exports.createDoor = void 0;

var _constants = _interopRequireDefault(require("./constants"));

var _tools = require("./tools");

var _math = require("../math");

/*
 *  Creates bounding boxes for the entrance.
 *
 *  Since the bounding cannot support custom geometry, two bounding
 *  boxes have to be created to stop the player from moving through the
 *  door geometry; one to the left and one to the right.
 *
 *  The positions of the bounding boxes are relative to the parent object.
 *  The scale and rotation should be inherited from the parent object.
 *
 *  @param {string) idx - The id of the door to be bounded.
 *  @return {array} The two bounding boxes.
 * */
var createBoundingBoxes = function createBoundingBoxes(idx) {
  var material = {
    shader: 'flat',
    opacity: _constants["default"].DEBUG.SHOW_BBS ? '0.5' : 0,
    color: 'green'
  };
  var posX = _constants["default"].ENTRANCE_WIDTH / 2 - 0.5 * _constants["default"].DOOR_SIDE_WIDTH;
  return [{
    primitive: 'a-box',
    key: "door-bound-box-right-".concat(idx),
    visible: _constants["default"].DEBUG.SHOW_BBS,
    material: material,
    'static-body': true,
    position: [posX, 0, 0].join(' '),
    scale: [1, _constants["default"].DEFAULT_WALL_HEIGHT * 2, _constants["default"].DEFAULT_WALL_DEPTH].join(' ')
  }, {
    primitive: 'a-box',
    key: "door-bound-box-left-".concat(idx),
    visible: _constants["default"].DEBUG.SHOW_BBS,
    material: material,
    'static-body': true,
    position: [-posX, 0, 0].join(' '),
    scale: [1, _constants["default"].DEFAULT_WALL_HEIGHT * 2, _constants["default"].DEFAULT_WALL_DEPTH].join(' ')
  }];
};
/*
 * This function creates and textures the the geometry of the door frame.
 *
 * @param {Door} door       The door object.
 * @param {string} idx      The id of the door
 * @param {boolean} front   Whether the frame will be the front or back.
 * @return {json} The door frame object
 * */


var createDoorFrame = function createDoorFrame(door, idx, front) {
  var texture = front ? door.hallTextureFront : door.hallTextureBack;
  var colour = front ? door.hallColorFront : door.hallColorBack;
  var tiling = front ? door.topPart.tilingFront : door.topPart.tilingBack;
  var rotation = front ? [0, 180, 0] : [0, 0, 0];
  var material = {
    shader: 'flat',
    opacity: _constants["default"].DEBUG.OPAQUE_WALLS ? '0.5' : 1,
    color: (0, _tools.toRGB)(colour) || _constants["default"].COLOUR.STRUCTURE,
    src: texture ? "#door-texture-".concat(front ? 'front' : 'back', "-").concat(idx) : '#wall-emission',
    repeat: (texture ? (0, _math.parseQuaternion)(tiling) : [1, 1]).join(' ')
  };
  return {
    primitive: 'a-entity',
    key: "door-frame-".concat(front ? 'front' : 'back', "-").concat(idx),
    geometry: 'primitive: door',
    material: material,
    position: [0, 0, 0 + front ? 0 : _constants["default"].DEFAULT_WALL_DEPTH].join(' '),
    rotation: rotation.join(' '),
    scale: [1, 1, 1].join(' ')
  };
};
/*
 * This function creates the wall top frame (sovantepi)
 *
 * @param {Door} door       The door object.
 * @param {string} idx      The id of the door
 * @return {json} The door top part frame json object.
 * */


var createDoorTopPart = function createDoorTopPart(door, idx) {
  return {
    primitive: 'a-box',
    key: "door-top-".concat(door.doorId || idx),
    'static-body': true,
    material: {
      shader: 'flat',
      color: _constants["default"].COLOUR.WALL_TOP,
      src: '#wall-top-texture'
    },
    position: [0, _constants["default"].DEFAULT_WALL_HEIGHT - _constants["default"].DEFAULT_WALL_WIDTH * 4, 0].join(' '),
    scale: [_constants["default"].ENTRANCE_WIDTH, _constants["default"].DEFAULT_WALL_WIDTH * 8, _constants["default"].DEFAULT_WALL_WIDTH * 8].join(' ')
  };
};
/*
 * Creates the moving parts of the door object. (the leafs)
 * Bonus: a collider to disable pass through when both parts are closed.
 *
 * @param {Door} door - The door object
 * @return {array} - An array containing the right, left, and collider objects, in that order.
 *
 * */


function createEntranceObjects(door) {
  // For reasons beyond comprehension, the static-body, does not work
  // if the body has animations, so the camera can pass right through it
  // Because of that, a separate invisible collider object is created,
  // which is turned on and off depending on the openness of the door,
  // using the bbox-attributes custom attribute.
  var doorCollider = {
    key: "entrance-door-collider-".concat(door.doorId),
    className: 'collider-door',
    primitive: 'a-box',
    scale: '1.4 6 0.02',
    visible: _constants["default"].DEBUG.SHOW_BBS,
    material: {
      shader: 'flat',
      opacity: _constants["default"].DEBUG.SHOW_BBS ? '0.5' : 0,
      color: 'green'
    },
    'static-body': door.isOpen === 0,
    'bbox-attributes': {
      open: door.isOpen * 2
    }
  };
  var doorObjRight = {
    key: "entrance-door-right-".concat(door.doorId),
    primitive: 'a-entity',
    'gltf-model': '#entrance-door-right',
    position: '-0.915 0 0',
    'open-on-click': 'rotation: 0 120 0',
    'door-attributes': {
      open: door.isOpen > 0,
      locked: door.isLocked,
      position: '0 0 0'
    },
    'click-manager': {
      draggingDelay: _constants["default"].DRAGGING_DELAY
    }
  };
  var doorObjLeft = {
    key: "entrance-door-left-".concat(door.doorId),
    primitive: 'a-entity',
    'gltf-model': '#entrance-door-left',
    position: '0.9 0 0',
    'open-on-click': 'rotation: 0 -120 0',
    'door-attributes': {
      open: door.isOpen > 0,
      locked: door.isLocked,
      position: '0 0 0'
    },
    'click-manager': {
      draggingDelay: _constants["default"].DRAGGING_DELAY
    }
  };
  return [doorObjRight, doorObjLeft, doorCollider];
}
/*
 * Creates the interior door side
 *
 * @param {Wall} topPart - The wall object
 * @param {string} idx - The id of the object
 * @param {boolean} front - Whether the entity created is the front or back. (For texturing and colouring purposes)
 * @return {json} - An object with the entity information.
 *
 * */


var createInteriorDoorWall = function createInteriorDoorWall(topPart, idx, front) {
  var texture = front ? topPart.textureFront : topPart.textureBack;
  var colour = front ? topPart.colorFront : topPart.colorBack;
  var tiling = front ? topPart.tilingFront : topPart.tilingBack;
  var rotation = front ? [0, 0, 0] : [0, 180, 0];
  var material = {
    shader: 'flat',
    opacity: _constants["default"].DEBUG.OPAQUE_WALLS ? '0.5' : 1,
    color: (0, _tools.toRGB)(colour) || _constants["default"].COLOUR.STRUCTURE,
    src: texture ? "#door-texture-".concat(front ? 'front' : 'back', "-").concat(idx) : '#wall-emission-interior-door',
    repeat: (texture ? (0, _math.parseQuaternion)(tiling) : [1, 0.9]).join(' ')
  };
  return {
    primitive: 'a-box',
    key: "entrance-interior-".concat(front ? 'front' : 'back', "-").concat(topPart.wallId || idx),
    'static-body': true,
    material: material,
    rotation: rotation.join(' '),
    position: [0, _constants["default"].DEFAULT_WALL_HEIGHT - _constants["default"].INTERIOR_DOOR_HEIGHT / 2, front ? _constants["default"].DEFAULT_WALL_WIDTH / 2 : -_constants["default"].DEFAULT_WALL_WIDTH / 2].join(' '),
    scale: [_constants["default"].ENTRANCE_WIDTH, _constants["default"].INTERIOR_DOOR_HEIGHT, _constants["default"].DEFAULT_WALL_WIDTH / 2].join(' ')
  };
};
/*
 * Creates the interior door (ie an opening).
 *
 * Note: even if this is called a door, it is, for all intents and pusposes, a wall object.
 *
 * @param {Wall} topPart - The wall object
 * @param {string} idx - The id of the object
 * @param {boolean} front - Whether the entity created is the front or back. (For texturing and colouring purposes)
 * @return {json} - An object with the entity information.
 *
 * */


var createInteriorDoor = function createInteriorDoor(door, idx, events, opts) {
  var start = (0, _math.mirror)((0, _math.parseQuaternion)(door.startPoint), opts.invertAxisPos);
  var end = (0, _math.mirror)((0, _math.parseQuaternion)(door.endPoint), opts.invertAxisPos);
  var mid = (0, _math.getMidpoint3d)(start, end); // Although there is rotation saved in door.topPart, it is always 0.

  var rotation = [0, (0, _tools.fixRotationValue)((0, _math.getHorizontalAngle)(start, end)) - 90, 0];
  return {
    primitive: 'a-entity',
    className: 'door-interior',
    key: "door-interior-door-".concat(idx),
    events: (0, _tools.createEvents)(events.doors, door),
    rotation: rotation.join(' '),
    position: [mid[0], mid[1], mid[2]].join(' '),
    children: [createInteriorDoorWall(door, idx, true), createInteriorDoorWall(door, idx, false), createDoorTopPart(door, idx)]
  };
};
/*
 * Creates the exterior door object (entrance)
 *
 * This is comprised of:
 *    a custom geometry object (DoorFrame) back and front
 *    a pair of door leafs
 *    a pair of bounding boxes (left and right)
 *    a wall top part frame (cornices)
 *    a on/off entrance bounding box.
 *
 * @param {Door} door - The door object
 * @param {string} idx - The id of the object
 * @param {array} events - Any events that might be used (but are not).
 * @opts {??} - ?????
 * @return {json} - An object with the entity information and its children.
 *
 * */


var createEntrance = function createEntrance(door, idx, events, opts) {
  var start = (0, _math.mirror)((0, _math.parseQuaternion)(door.startPoint), opts.invertAxisPos);
  var end = (0, _math.mirror)((0, _math.parseQuaternion)(door.endPoint), opts.invertAxisPos);
  var mid = (0, _math.getMidpoint3d)(start, end); // Although there is rotation saved it is wrong.
  // Floating point precision FTW.

  var rotation = [0, (0, _tools.fixRotationValue)((0, _math.getHorizontalAngle)(start, end)) - 90, 0];
  return {
    primitive: 'a-entity',
    className: 'entrance',
    key: "entrance-".concat(idx),
    events: (0, _tools.createEvents)(events.doors, door),
    rotation: rotation.join(' '),
    position: [mid[0], mid[1], mid[2]].join(' '),
    // Consistency? Never heard of it.
    children: [createDoorFrame(door, idx, true), createDoorFrame(door, idx, false), createDoorTopPart(door, idx)].concat(createBoundingBoxes(door.doorId)).concat(createEntranceObjects(door))
  };
};

var createDoor = function createDoor(door, idx) {
  var events = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var opts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return door.isEntrance ? createEntrance(door, door.wallId || idx, events, opts) : createInteriorDoor(door.topPart, door.wallId || idx, events, opts);
};
/*
 * Creates the door entity
 *
 * This entity might be:
 *      * An entrance door.
 *      * An interior door (essentially a short wall).
 *
 * @param {array} doors - An array containing the door objects of the exhibition
 * @param {??} events - ???
 * @param {??} opts - ?????
 * @return {json} - An object with the entity information for all doors and its children.
 *
 * */


exports.createDoor = createDoor;

var createDoors = function createDoors(doors) {
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return doors.map(function (door, idx) {
    return createDoor(door, idx, events, opts);
  });
};

exports.createDoors = createDoors;
import React, { useState } from 'react';

import { __ } from 'artsteps2-common';

import { getForm } from '../../../reducers';
import { withState, withDispatch, compose } from '../../../enhancers';
import { apiDELETE, setFormData, addMessage, apiGET, setUIProperty } from '../../../actions';
import { ExpandButton, HorizontalItems } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from '@material-ui/core/Box';
import YesOrNo from '../../generic/YesOrNo';
import MyToolTip from '../../generic/MyToolTip';
import { settings } from './spaceSettings';

export const PSrowView = ({
  setEditingSpace,
  space,
  onDeleteSpace,
  onAddMessage = () => Promise.resolve(false),
  setOpenEditor,
  setSpaceData,
}) => {
  const [open, setOpen] = useState(false);

  const handleYes = () => {
    onDeleteSpace(space._id)
      .then(() =>
        onAddMessage({
          type: 'success',
          title: __('Deleted space'),
          description: 'ʕ·͡ᴥ·ʔ',
        }),
      )
      .catch(error => {
        onAddMessage({
          type: 'error',
          title: __('couldnt delete space'),
          description: error,
        });
      });

    setOpen(false);
  };

  const onRedirect = () => {
    const redirectURL = `http://${space.subdomain}.${window.location.hostname
      .replace('www2', '')
      .replace('www', '')
      .replace('.', '')}`.concat(
      window.location.hostname.split('.')[0] === 'localhost' ? ':3000' : '',
    );

    window.open(redirectURL);
  };
  return (
    <>
      <HorizontalItems style={{ justifyContent: 'center' }} margin="0px 16px 0px 0px">
        <MyToolTip title="Edit" placement="top">
          <Box
            component={ExpandButton}
            boxShadow={3}
            mycolor={colors.ourBlack}
            mycolorhover={colors.ourWhite}
            background={colors.ourWhite}
            backgroundhover={colors.ourBlack}
            onClick={() => {
              setOpenEditor(true);
              setEditingSpace(space.subdomain);
              setSpaceData(space);
            }}
          >
            <EditIcon />
          </Box>
        </MyToolTip>
        <MyToolTip title="Delete" placement="top">
          <Box
            component={ExpandButton}
            boxShadow={3}
            mycolor={colors.ourBlack}
            mycolorhover={colors.ourWhite}
            background={colors.ourWhite}
            backgroundhover={colors.ourBlack}
            onClick={() => setOpen(true)}
          >
            <DeleteIcon />
          </Box>
        </MyToolTip>
        <MyToolTip title="View" placement="top">
          <Box
            component={ExpandButton}
            boxShadow={3}
            mycolor={colors.ourBlack}
            mycolorhover={colors.ourWhite}
            background={colors.ourWhite}
            backgroundhover={colors.ourBlack}
            onClick={onRedirect}
          >
            <VisibilityIcon />
          </Box>
        </MyToolTip>
      </HorizontalItems>
      <YesOrNo
        title="Delete private Space?"
        message={`Are you sure you want to delete ${space.subdomain}`}
        optionA="No"
        optionB="Yes"
        open={open}
        setOpen={setOpen}
        handleYes={handleYes}
      />
    </>
  );
};

const mapState = (state, { space }) => ({
  form: getForm(state, space.subdomain),
});

const toFormSpace = data => {
  let spaceSettings = {};

  if (data.settings) {
    settings.forEach(setting => {
      spaceSettings = {
        ...spaceSettings,
        [setting.value]: data.settings[setting.value],
      };
    });

    return {
      ...data,
      ...spaceSettings,
    };
  }

  return data;
};

const mapDispatch = (dispatch, { space }) => ({
  setEditingSpace: subdomain => dispatch(setUIProperty('editingSpace', subdomain)),
  setSpaceData: space => dispatch(setFormData(space.subdomain, toFormSpace(space))),
  onDeleteSpace: spaceId => dispatch(apiDELETE(`spaces/${spaceId}`)),
  onAddMessage: message => dispatch(addMessage(message)),
  fetchSpace: () => dispatch(apiGET(`spaces/${space.subdomain}`, { populate: ['spaceAdmins'] })),
});

const PSrow = compose(withState(mapState), withDispatch(mapDispatch))(PSrowView);

export default PSrow;

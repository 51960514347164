import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import styled, { css } from 'styled-components';
import { DropzoneArea } from 'material-ui-dropzone';
import FormLabel from '@material-ui/core/FormLabel';
import Link from '../components/generic/Link';
import colors from '../styles/colors';

export const StyledTheme = createMuiTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        background: colors.ourBlack,
      },
    },

    // Name of the rule
    MuiTab: {
      root: {
        lineHeight: '0.9',
        minWidth: 0,
        '@media (min-width: 0px)': {
          minWidth: 0,
        },
        background: colors.ourGray,
        textTransform: 'unset',
        fontFamily: 'Muli',
        color: colors.ourGray,
        opacity: 1,
        '&$selected': {
          color: colors.ourBlack,
          '&:hover': {
            opacity: 1,
          },
        },
      },
    },
  },
});

export const OptionButton = styled.button`
  cursor: pointer;
  font-size: 1.2em;
  /* line-height: 1.2em; */
  z-index: 1;
  /* padding: 0.5em; */
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border-width: 0;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.25em;
  margin-left: 0.25em;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const GhostButton = styled.button`
  background: transparent;
  border: none !important;
  font-size: 0;

  &:hover {
    background: #303030;
    opacity: 0.2;
  }
`;

export const TextButton = styled.button`
  text-decoration: underline;
  background: transparent;
  border: none !important;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const BigNumber = styled.span`
  font-family: 'Muli', Arial, Helvetica, sans-serif;
  font-size: 70px;
  /* color: ${colors.ourGray}; */
  color: #bfb8b8;
`;

export const NLinesBreak = styled.div`
  ${props =>
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${props.lines}; /* number of lines to show */
      -webkit-box-orient: vertical;
    `}
`;

export const FullLine = styled.hr`
  width: 1000%;
  margin-left: -500%;
`;

/* *** Menu *** */
export const MenuStyled = withStyles({
  paper: {
    border: '3px solid #ffffff',
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  list: {
    padding: '3px',
  },
})(Menu);

export const MenuItemStyled = styled(MenuItem)`
  background-color: #f4f3f3;
  border-radius: 5px;
  border-radius: 5px;
  margin: 3px 0px 3px;

  ${props =>
    props.background &&
    css`
      background-color: ${props.background};
    `}

  ${props =>
    props.mycolor &&
    css`
      color: ${props.mycolor};
    `}
`;

export const StyledTabs = withStyles({
  root: { width: '400px' },
  indicatorColor: {
    color: colors.ourGray,
  },
  textColorPrimary: {
    color: colors.ourGreen,
  },
})(Tabs);

export const StyledTab = withStyles({
  root: {
    backgroundColor: colors.ourGray,
  },
  textColorPrimary: {
    color: colors.ourBlack,
  },
  textColorSecondary: {
    color: colors.ourBlack,
  },
  list: {
    padding: '3px',
  },
})(Tab);

export const StyledFormLabel = withStyles({
  root: {
    color: 'unset',
  },
  asterisk: {
    color: colors.ourRed,
  },
})(FormLabel);

export const HorizontalItems = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;

  & > * {
    margin: ${props => props.margin};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    vertical-align: middle;
  }

  & > span > * {
    margin-left: 6px;
  }
`;

export const HorizontalPadding = styled.div`
  & > * {
    ${props =>
      props.padding &&
      css`
        padding: ${props.padding};
      `}
  }
`;

export const HorizontalMargin = styled.div`
  & > * {
    ${props =>
      props.margin &&
      css`
        margin: ${props.margin};
      `}
  }
`;

export const VerticalItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
      align-items: ${props.justify};
      @media (max-width: 959px) {
        align-items: center;
        justify-content: center;
      }
    `}

  ${props =>
    props.margin &&
    css`
      & > * {
        margin-bottom: ${props.margin};
      }
    `}

  ${props =>
    props.marginBottom &&
    css`
      align-items: unset;
      & > form > * {
        margin-bottom: ${props.marginBottom};
      }
    `}
  ${props =>
    props.footer === 'true' &&
    css`
      & > * {
        margin-bottom: 20px;
      }
    `}


`;

export const LabelBox = styled(Box)`
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  border-radius: 20px;
  padding: 15px;
  align-content: center;

  ${props =>
    css`
      background: ${props.background};
      color: ${props.mycolor};
    `}
`;

export const Text = styled(Typography)`
  font-family: 'Muli', Regular;
  opacity: 87%;

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

  ${props =>
    props.active === 'true' &&
    css`
      & > a {
        opacity: 100% !important;
      }
    `}

    ${props =>
      props.underline &&
      css`
        text-decoration: underline;
      `}

  ${props =>
    props.mycolor &&
    css`
      text-align: ${props.align};
      color: ${props.mycolor};
      font-weight: ${props.weight};
    `}

  ${props => {
    switch (props.type) {
      case 'h1':
        return css`
          font-size: 56px;
        `;
      case 'h2':
        return css`
          font-size: 56px;
          font-weight: 1000;
        `;
      case 'h3':
        return css`
          font-size: 46px;
          font-weight: 1000;
        `;
      case 'h4':
        return css`
          font-size: 36px;
          font-weight: 1000;
        `;
      case 'h5':
        return css`
          font-size: 26px;
          font-weight: bold;
        `;
      case 'h6':
        return css`
          font-size: 20px;
          font-weight: bold;
        `;
      case 'h7':
        return css`
          font-size: 17px;
          font-weight: bold;
        `;
      case 'sub1':
        return css`
          font-size: 30px;
          opacity: 100%;
        `;
      case 'sub2':
        return css`
          font-size: 22px;
          opacity: 100%;
        `;
      case 'body1':
        return css`
          font-size: 18px;
          opacity: 100%;
        `;
      case 'body2':
        return css`
          font-size: 14px;
          opacity: 100%;
        `;
      case 'body3':
        return css`
          font-size: 11px;
          opacity: 100%;
        `;
      default:
        return css`
          font-size: 16px;
          opacity: 100%;
        `;
    }
  }}
  & > a {
    &:hover {
      opacity: 1;
    }
  }
`;

export const BodyWithPadding = styled.div`
  height: 100%;
  ${props =>
    css`
      padding: ${props.padding};
    `};
`;

export const ExpandButton = styled(Button)`
  font-family: 'Muli', Regular;
  padding: 0px 15px 0px;
  border-radius: 27px;
  height: 46px;
  color: white;
  background: black;
  opacity: 1;

  ${props =>
    props.mycolor &&
    css`
      color: ${props.mycolor};
      background: ${props.background};
    `}

  ${props =>
    props.mycolorhover &&
    css`
      &:hover {
        opacity: 1;
        color: ${props.mycolorhover};
        background: ${props.backgroundhover};
      }
    `}
`;

export const StyledButton = styled(Button)`
  font-family: 'Muli', Regular;
  padding: 10px 25px;
  border-radius: 27px;
  height: 46px;
  text-transform: none;
  color: white;
  background: black;
  opacity: 0.8;
  transition: opacity 350ms, padding 0.2s;
  line-height: 1.3rem;

  @media (max-width: 1600px) and (min-width: 1280px) {
    & .MuiButton-endIcon {
      margin: 0;
    }
  }
  @media (max-width: 814px) {
    & .MuiButton-endIcon {
      margin: 0;
    }
  }

  ${props =>
    props.minwidth &&
    css`
      min-width: ${props.minwidth};
    `}
  ${props =>
    props.hovertransition === 'true' &&
    css`
      padding: 10px 25px 10px 50px;
    `}

  &:hover {
    color: white;
    background-color: black;
    opacity: 1;
    ${props =>
      props.hovertransition === 'true' &&
      css`
        padding: 10px 35px 10px 50px;
      `}
    ${props =>
      css`
        color: ${props.mycolor};
        background: ${props.background};
      `}
  }

  &:disabled {
    opacity: 0.3;
    color: white;
    background: black;
    ${props =>
      css`
        color: ${props.mycolor};
        background: ${props.background};
      `}
  }

  ${props =>
    css`
      color: ${props.mycolor};
      background: ${props.background};
      border: ${props.bordercolor ? `2px solid ${props.bordercolor || 'black'}` : ''};
    `}

  // Adding different color on hover
  &:hover {
    ${props =>
      props.mycolorhover &&
      css`
        color: ${props.mycolorhover};
      `}
    ${props =>
      props.backgroundhover &&
      css`
        background: ${props.backgroundhover};
      `}
  }

  ${props => {
    switch (props.type) {
      case 'primary':
        return css`
          font-size: 20px;
        `;
      case 'secondary':
        return css`
          font-size: 16px;
        `;
      case 'tertiary':
        return css`
          font-size: 16px;
        `;
      case 'ghost':
        return css`
          font-size: 18px;
          font-weight: bold;
          color: black;
          outline: none;
          background: transparent;
          text-transform: uppercase;
          padding: 0px !important;
        `;
      case 'submit':
        return css`
          font-size: 18px;
          margin: 35px 0;
        `;
      default:
        return css`
          font-size: 22px;
        `;
    }
  }}
`;

export const HeaderLink = styled(Link)`
  opacity: 87%;

  ${props =>
    css`
      font-size: 20px;
      color: ${props.mycolor};
      background: ${props.background};
      &:hover {
        color: ${props.mycolor};
      }
    `}
`;

export const UnderLinedLink = styled(Link)`
  font-family: 'Muli', Regular;
  opacity: 87%;
  text-decoration: underline;
  color: black;

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  &:hover {
    color: #fe918d;
    text-decoration: underline;
  }
`;

export const StyledInput = styled.input`
  height: 58px;
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  color: black;

  &:focus {
    border: 2px solid #cbc8c8;
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #808080;
  }
  :-ms-input-placeholder {
    color: #808080;
  }
`;

export const StyledTextField = styled(TextField)`
  border-radius: 5px;
  font-size: 20px;
  color: black;

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid #cbc8c8;

    ${props =>
      props.error &&
      css`
        border: 2px solid colors.ourRed;
      `}
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #cbc8c8;
    ${props =>
      props.error &&
      css`
        border: 2px solid colors.ourRed;
      `}
  }
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #c0b4b43b;
    ${props =>
      props.error &&
      css`
        border: 2px solid colors.ourRed;
      `}
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #808080;
  }
  :-ms-input-placeholder {
    color: #808080;
  }
`;

export const InvisibleTextField = styled.input`
  max-width: 100px;
  border: none !important;
  border-width: 0 !important;
  box-shadow: none !important;
  outline: none;

  &:focus {
    border: none !important;
    border-width: 0 !important;
    box-shadow: none !important;
  }
`;

export const StyledDropZoneArea = withStyles({
  root: {
    backgroundColor: '#343A4012',
    overflowY: 'auto',
    minHeight: '164px',
  },
})(DropzoneArea);

export const ImageContainer = styled.div`
  ${props => css`
    width: ${props.width};
    height: ${props.height};
    min-height: ${props.minHeight};
    background-image: url(${props.image});
    background-size: ${props.size};
    background-repeat: no-repeat;
    background-position: ${props.position};
    border-radius: ${props.radius};
  `}
  ${props =>
    props.isMobile &&
    css`
      margin-left: -11%;
      width: 118%;
    `}
`;

export const IconWithTransition = styled(ListItemIcon)`
  opacity: 0;
  width: 1.5em;
  height: 1.5em;
  min-width: 0;
  align-items: center;
  color: white;
  transition: opacity 0.21s ease-in;
  margin-left: 12px !important;
  ${props =>
    props.open &&
    css`
      opacity: 1;
      /* transform: translate(22px); */
    `}
`;

export const FadeImage = styled.img`
  /* opacity: 0; */
  transition: opacity 1s ease-in-out;
`;

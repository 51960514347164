import FormControl from '@material-ui/core/FormControl';
import React, { useState } from 'react';
import { Text, StyledTextField } from '../../styles/GenericStyled';
import { AdminButton, FaqEditorDiv } from '../../styles/styledComponents/FAQStyled';
import COLORS from '../../styles/colors';

const FaqEditor = ({ faq, action, onCreateFaq, onUpdateFaq, setOpen }) => {
  const [data, setData] = useState({
    question: faq ? faq.question : '',
    answer: faq ? faq.answer : '',
  });

  return (
    <>
      <Text style={{ marginTop: '20px' }} align="center" type="h2">{`${action} FAQ`}</Text>
      <FaqEditorDiv>
        <FormControl
          onChange={e => {
            setData({ ...data, [e.target.name]: e.target.value });
          }}
        >
          <span>
            <Text mycolor={COLORS.ourRed} type="h7">
              Question
            </Text>
            <StyledTextField
              fullWidth
              value={data.question}
              name="question"
              required
              id="question"
              variant="outlined"
              multiline
              rows={2}
            />
          </span>
          <span>
            <Text mycolor={COLORS.ourGreen} type="h7">
              Answer
            </Text>
            <StyledTextField
              fullWidth
              value={data.answer}
              id="answer"
              name="answer"
              required
              variant="outlined"
              multiline
              rows={2}
            />
          </span>
          <span>
            <AdminButton
              onClick={e => {
                e.preventDefault();
                action === 'Create' && onCreateFaq(data);
                action === 'Update' && onUpdateFaq(faq._id, data);
                setOpen(false);
              }}
            >
              {action}
            </AdminButton>
          </span>
        </FormControl>
      </FaqEditorDiv>
    </>
  );
};

export default FaqEditor;

import React from 'react';
import { __ } from 'artsteps2-common';
import Category from './Category';
import utils from '../../../utils';
import {
  apiGET,
  apiPOST,
  handleFormPropertyChange,
  setUIProperty,
  addMessage,
  setFormData,
} from '../../../actions';
import {
  API_STATUS,
  getApiStatus,
  getApiResource,
  getUIProperty,
  getFormProperty,
  getAuthUserRoles,
} from '../../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';

export const CategoryListView = ({
  categories = [],
  parentCategory,
  header = 1,
  displayChildren = 0,
  editable = false,
  isEditing = false,
  newCategoryTitle = '',
  onCreateCategory = () => Promise.resolve({ response: { error: 'error' } }),
  onAddMessage = () => Promise.resolve(false),
  onStartEditing = () => Promise.resolve(false),
  onStopEditing = () => Promise.resolve(false),
  onCategoryTitleChange = () => Promise.resolve(false),
}) => {
  const renderCategories = () =>
    categories
      .filter(c => (parentCategory ? c.parent === parentCategory : !c.parent))
      .map(
        c =>
          c._id && (
            <div key={c._id} className="ui four wide column">
              <Category
                categoryId={c.slug || c._id}
                header={header}
                displayChildren={displayChildren}
              />
            </div>
          ),
      );

  const onSubmit = () => {
    const slug = newCategoryTitle
      .toLowerCase()
      .replace(/\W+$/, '')
      .replace(/\W+/g, '-');
    return onCreateCategory({
      slug,
      title: newCategoryTitle,
      parent: parentCategory,
    }).then(({ response }) => {
      if (response.error) {
        return onAddMessage({
          type: 'error',
          title: __('category_insert_err'),
          description: __(response.error),
        });
      }

      utils.dialogflow.createIntent(`articleCategories/${slug}/text`, [newCategoryTitle]);
      return onStopEditing();
    });
  };

  const renderCategoryEditor = () => (
    <div className="field">
      <div className="ui action input">
        <input
          type="text"
          name="title"
          placeholder={__('title')}
          value={newCategoryTitle}
          onChange={onCategoryTitleChange}
        />
        <button
          className="ui secondary icon button"
          data-tooltip={__('discard_changes')}
          data-inverted
          onClick={onStopEditing}
        >
          <i className="undo icon" />
        </button>
        <button
          className="ui teal icon button"
          data-tooltip={__('save_changes')}
          data-inverted
          onClick={onSubmit}
        >
          <i className="save icon" />
        </button>
      </div>
    </div>
  );

  const Header = `h${header || 2}`;

  return (
    <div className="category-grid">
      {renderCategories()}
      <div className="ui four wide column">
        <div className="ui segment vertical">
          {editable && !isEditing && (
            <Header>
              <button className="bare icon" onClick={onStartEditing}>
                <i className="add circle icon" />
                {__('add_category')}
              </button>
            </Header>
          )}
          {editable && isEditing && renderCategoryEditor()}
        </div>
      </div>
    </div>
  );
};

const EDITOR_ROLES = ['editor', 'admin'];

const mapState = (state, { parentCategory, editable }) => ({
  categories: Object.values(getApiResource(state, 'articleCategories')),
  ready: getApiStatus(state, 'articleCategories') === API_STATUS.IDLE,
  editable: editable && getAuthUserRoles(state).some(role => EDITOR_ROLES.includes(role)),
  isEditing: getUIProperty(state, `article-categoriess/${parentCategory || ''}/edit`),
  newCategoryTitle: getFormProperty(state, `article-categories:${parentCategory || ''}`, 'title'),
});

const mapDispatch = (dispatch, { parentCategory }) => ({
  onFetchCategories: () => dispatch(apiGET('articleCategories')),
  onFetchArticles: () => dispatch(apiGET('articles')),
  onCreateCategory: category => dispatch(apiPOST('articleCategories', category)),
  onAddMessage: message => dispatch(addMessage(message)),
  onStartEditing: () =>
    dispatch(
      setFormData(`article-categories:${parentCategory || ''}`, {
        categoryParent: parentCategory,
      }),
    ).then(dispatch(setUIProperty(`article-categoriess/${parentCategory || ''}/edit`, true))),
  onStopEditing: () =>
    dispatch(setUIProperty(`article-categoriess/${parentCategory || ''}/edit`, false)),
  onCategoryTitleChange: event =>
    dispatch(handleFormPropertyChange(`article-categories:${parentCategory || ''}`, event)),
});

const lifecycleMap = {
  onDidMount: ({ fetchResources, onFetchCategories, onFetchArticles }) =>
    fetchResources && onFetchCategories() && onFetchArticles(),
};

const CategoryList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(CategoryListView);

export default CategoryList;

import React from 'react';
import { __ } from 'artsteps2-common';
import Form from '../../generic/forms/Form';
import Dialog from '../../generic/Dialog';
import utils from '../../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import {
  API_STATUS,
  getApiStatus,
  getApiResource,
  getUIProperty,
  getForm,
  getAuthUser,
} from '../../../reducers';
import {
  apiPOST,
  apiPATCH,
  apiGET,
  apiDELETE,
  setFormData,
  addMessage,
  clearMessages,
  clearFormData,
  setUIProperty,
} from '../../../actions';

export const StorypointEditorView = ({
  storypointId,
  form = { data: {} },
  colour,
  order,
  exhibitionId,
  ready = true,
  removeDialogOpen = false,
  onUpsert = () => Promise.resolve({ response: { error: 'error' } }),
  onSave = () => Promise.resolve(false),
  onClose,
  onAddMessage = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onRemove = () => Promise.resolve({ response: { error: 'error' } }),
  onRemoveDialogOpen = () => Promise.resolve(false),
  onRemoveDialogClose = () => Promise.resolve(false),
}) => {

  const mapFormData = data => ({
    ...data,
    colour,
    order,
    exhibition: exhibitionId,
  });

  const onSubmit = () =>
    onUpsert(mapFormData(form.data)).then(({ response }) =>
      response.error
        ? onAddMessage({ title: __(response.error), type: 'warning' })
        : onClearMessages().then(() => onSave(response)),
    );

  return (
    <div className="exhibition-story-editor">
      <h2>
        {__(storypointId ? 'edit' : 'add')}
        &nbsp;
        {__('story_point')}
        &nbsp;
        {isFinite(form.data.order) ? form.data.order + 1 : null}
      </h2>
      <Form
        onSubmit={onSubmit}
        form={form}
        ready={ready}
        fields={[
          {
            title: { type: 'text', required: true },
            description: { type: 'textarea', required: true, rows: 5 },
            hidden: {
              type: 'toggle',
              toFormValue: value => !value,
              fromFormValue: value => !value,
              label: form.data.hidden ? __('storypoint_hidden') : __('storypoint_visible'),
            },
            audio: {
              type: 'audio',
              label: __('audio'),
              hint: __('audio_point_hint'),
              preview: audio =>
                utils.storypoint.getStorypointAudio({
                  _id: storypointId,
                  audio,
                }),
            },
          },
        ]}
        buttons={[
          ...(storypointId && storypointId !== 'new'
            ? [
                {
                  colour: 'delete',
                  label: __('remove'),
                  onClick: onRemoveDialogOpen,
                  icon: 'trash',
                  type: 'button',
                },
              ]
            : []),
          {
            colour: 'secondary',
            icon: 'save',
            label: __('save'),
            disabled: !form.data.title,
          },
        ]}
      />
      <Dialog
        open={removeDialogOpen}
        title={__('story_point_deletion', form.data)}
        message={__('confirm_story_point_deletion', form.data)}
        type="warning"
        onReject={onRemoveDialogClose}
        onConfirm={() =>
          onRemoveDialogClose()
            .then(() => onRemove())
            .then(
              ({ response: { error } = {} }) =>
                error && onAddMessage({ type: 'error', title: __(error) }),
            )
        }
      />
    </div>
  );
};

const createQuery = ({ _id: user } = {}, exhibition) => ({
  filter: { user, exhibition },
});

const mapState = (state, { storypointId = 'new', exhibitionId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    storypoint: getApiResource(state, `storypoints/${storypointId}`),
    form: getForm(state, `storypoint:${storypointId}`),
    ready:
      getApiStatus(state, `storypoints/${storypointId}`) === API_STATUS.IDLE &&
      getApiStatus(state, 'storypoints', createQuery(currentUser, exhibitionId)) ===
        API_STATUS.IDLE,
    removeDialogOpen: getUIProperty(state, `storypoints/${storypointId}/removeDialog`),
  };
};

const mapDispatch = (dispatch, { storypointId = 'new', exhibitionId, currentUser }) => ({
  onFetchStorypoint: () =>
    storypointId && !storypointId.match(/^new/)
      ? dispatch(apiGET(`storypoints/${storypointId}`))
      : Promise.resolve({}),
  onSetForm: data => dispatch(setFormData(`storypoints:${storypointId}`, data)),
  onUpsert: data =>
    storypointId === 'new'
      ? dispatch(apiPOST('storypoints', data, createQuery(currentUser, exhibitionId)))
      : dispatch(apiPATCH(`storypoints/${storypointId}`, data)),
  onAddMessage: message => dispatch(addMessage(message, 'storypoint')),
  onClearMessages: () => dispatch(clearMessages('storypoint')),
  onClearFormData: () => dispatch(clearFormData(`storypoint:${storypointId}`)),
  onSetFormData: data => dispatch(setFormData(`storypoint:${storypointId}`, data)),
  onRemoveDialogOpen: () =>
    dispatch(setUIProperty(`storypoints/${storypointId}/removeDialog`, true)),
  onRemoveDialogClose: () =>
    dispatch(setUIProperty(`storypoints/${storypointId}/removeDialog`, false)),
  onRemove: () =>
    dispatch(apiDELETE(`storypoints/${storypointId}`, createQuery(currentUser, exhibitionId))),
});

const onInitialization = ({ onClearFormData, onFetchStorypoint, onSetFormData }) =>
  onClearFormData()
    .then(() => onFetchStorypoint())
    .then(({ response: storypoint }) => onSetFormData(storypoint));

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: ({ storypointId }, props) =>
    storypointId !== props.storypointId && onInitialization(props),
};

const StorypointEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(StorypointEditorView);

export default StorypointEditor;

import React from 'react';
import { __ } from 'artsteps2-common';
import MetaTags from '../components/layouts/MetaTags';
import Link from '../components/generic/Link';
import FullLayout from '../components/layouts/FullLayout';
import ResetPassword from '../components/users/forms/PasswordReseter';
import ourColors from '../styles/colors';

const PasswordResetPage = () => (
  <FullLayout>
    <MetaTags title={__('forgot_your_password')} />
    <div className="login-form">
      <div className="ui two column stackable grid">
        <div className="stretched column cover-column">
          <div className="ui basic segment">
            <h1>{__('forgot_your_password')}</h1>
            <h4 className="login-message">
              {__('dont_have_an_account')}
              <Link mycolor={ourColors.ourWhite} to="/register" className="item">
                {__('register')}
              </Link>
            </h4>
          </div>
        </div>
        <div className="stretched column">
          <Link to="/" className="logo" />
          <ResetPassword />
        </div>
      </div>
    </div>
  </FullLayout>
);

export default PasswordResetPage;

import React from 'react';
import convertToBlob from '../../reusableFunctions/convertToBlob';

const Logo = ({
  space = {},
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  width,
  height,
  onRedirect = () => Promise.resolve(false),
}) => {
  // Takes the space name and the type to get logo

  const [hasImage, setHasImage] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState('');

  React.useEffect(() => {
    if (space && space.logo && space.logo[0] && space.logo[0].uri && space.logo[0].uri != null)
      setImageSrc(space.logo[0].uri);
    else if (space && space.logo && space.logo[0] && space.logo[0].bin && space.logo[0].bin != null)
      convertToBlob(space.subdomain, 'logo', setImageSrc);

    setHasImage(space && space.logo && space.logo.length > 0);
  }, [space]);

  return (
    <div style={{ display: 'flex' }}>
      <img
        onClick={onRedirect}
        style={{
          objectFit: 'contain',
          width,
          height,
          maxWidth,
          maxHeight,
          minWidth,
          minHeight,
        }}
        alt=""
        src={hasImage ? imageSrc : '/images/artedu_logo.png'}
      />
    </div>
  );
};

export default Logo;

import React from 'react';

const FullLayoutView = ({ children,style }) => (
  <div className="layout full">
    <main className={`layout-body`} style={style}>{children}</main>
  </div>
);

const FullLayout = FullLayoutView;

export default FullLayout;

import React from 'react';
import { __ } from 'artsteps2-common';

import MetaTags from '../components/layouts/MetaTags';
import DefaultLayout from '../components/layouts/DefaultLayout';

const NotFoundPage = () => (
  <DefaultLayout>
    <MetaTags title={__('not_found')} description={__('page_not_found')} />
    <div className="ui main container center aligned">
      <div className="ui segment vertical">
        <h1>404 - {__('not_found')}</h1>
        <p>{__('page_not_found')}</p>
      </div>
    </div>
  </DefaultLayout>
);

export default NotFoundPage;

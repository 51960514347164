import React from 'react';
import convertToBlob from '../../reusableFunctions/convertToBlob';

const BackGround = ({ space = {} }) => {
  //Takes the space name and the type to get backGround
  const [hasImage, setHasImage] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState('');

  React.useEffect(() => {
    if (
      space &&
      space.backGround &&
      space.backGround[0] &&
      space.backGround[0].uri &&
      space.backGround[0].uri != null
    )
      setImageSrc(space.backGround[0].uri);
    else if (
      space &&
      space.backGround &&
      space.backGround[0] &&
      space.backGround[0].bin &&
      space.backGround[0].bin != null
    )
      convertToBlob(space.subdomain, 'backGround', setImageSrc);

    setHasImage(space && space.backGround && space.backGround.length > 0);
  }, [space]);

  return (
    <div
      style={{
        zIndex: '0',
        filter: space?.title?.trim()?.length === 0 ? 'none' : 'saturate(60%) brightness(80%)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <img
        style={{
          width: '100vw',
          height: 'calc(100vh - 70px)',
          objectFit: 'cover',
        }}
        alt=""
        src={hasImage ? imageSrc : '/images/artsteps-private.jpg'}
      />
    </div>
  );
};

export default BackGround;

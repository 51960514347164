import { utils } from 'artsteps2-common';
import * as image from './image';
import * as webgl from './webgl';
import * as text from './text';
import * as navigator from './navigator';
import * as dialogflow from './dialogflow';
import * as aframe from './aframe';
import * as cookies from './cookies';

const {
  exhibition,
  artifact,
  texture,
  user,
  buffer,
  media,
  storypoint,
  article,
  date,
  obj,
  math,
  filesystem,
  youtube,
} = utils;

export default {
  exhibition,
  artifact,
  texture,
  user,
  image,
  media,
  storypoint,
  buffer,
  webgl,
  text,
  article,
  date,
  obj,
  aframe: { ...utils.aframe, ...aframe },
  math,
  navigator,
  dialogflow,
  cookies,
  filesystem,
  youtube,
};

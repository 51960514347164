import React from 'react';
import Dropzone from 'react-dropzone';
import { __ } from 'artsteps2-common';
import publicConfig from 'artsteps2-config/public.json';
import PolySelect from './PolySelect';
import FlickrSelect from './FlickrSelect';
import utils from '../../../utils';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getAuthUser } from '../../../reducers';
import { addMessage } from '../../../actions';
import { Text } from '../../../styles/GenericStyled';
import IFrame from '../YoutubeFrame';

const IMAGE_HEIGHT = publicConfig.uploader.fallBackImageHeight;
const THUMBNAIL_HEIGHT = publicConfig.uploader.fallBackThumbnailHeight;
const FALLBACK_FILE_SIZE_LIMIT = publicConfig.uploader.fallBackUFileSizeLimit;
const fileSizeLimits = publicConfig.uploader.fileSizeLimit;

const toArray = (obj = []) => (Array.isArray(obj) ? obj : [obj]);
const emptyBin = type => ({
  bin: { content: '', contentType: type, name: '', preview: '' },
});
const emptyFile = {
  file: undefined,
  filetitle: undefined,
  filecreatedAt: undefined,
};
const emptyUri = {
  uri: undefined,
};
const normalizeValue = value => {
  return value.type === 'none'
    ? {
        ...value,
        uri: undefined,
        ...emptyFile,
        ...emptyBin(value.type),
      }
    : {
        ...value,
        ...(value.type === 'uri'
          ? {
              ...emptyFile,
              ...emptyBin(value.type),
            }
          : {}),
        ...(value.type === 'file' && value.bin && value.bin.content && value.bin.content.length
          ? {
              ...emptyUri,
              ...emptyFile,
            }
          : {}),
        ...(value.type === 'file' && value.bin && value.bin.content && !value.bin.content.length
          ? {
              ...emptyUri,
              ...emptyBin(value.type),
            }
          : {}),
      };
};

export const UploaderView = ({
  name = 'uploader',
  label = '',
  hint = '',
  id = 'uploader',
  type: uploadType = 'image',
  accept,
  multiple = false,
  asArray = false,
  onChange: onValueChange = () => false,
  onAddMessage = () => Promise.resolve(false),
  onMediaLoading = () => false,
  onMediaLoaded = () => false,
  onMediaError = () => false,
  value: rawValue = [],
  preview = () => false,
  required = false,
  sourceType,
  width,
  height,
  quality,
  mimeType,
  currentUser,
  thumbnail: {
    name: thumbnailName,
    width: thumbnailWidth,
    height: thumbnailHeight,
    quality: thumbnailQuality,
    mimeType: thumbnailMimeType,
  } = {},
}) => {
  const currentValue = toArray(rawValue).map(v => normalizeValue(v));

  const artifactURI = currentValue[0] && currentValue[0].uri;
  const currentSourceType =
    sourceType ||
    (currentValue[0] && currentValue[0].fileType) ||
    (currentValue[0] && currentValue[0].type) ||
    (currentValue[0] && currentValue[0].bin && currentValue[0].bin.contentType && 'file') ||
    (currentValue[0] && currentValue[0].file && 'file') ||
    (currentValue[0] && currentValue[0].uri && currentValue[0].uri.match(/\/\/poly\./) && 'poly') ||
    (currentValue[0] &&
      currentValue[0].uri &&
      currentValue[0].uri.match(/\/\/[a-z0-9]+\.staticflickr\./) &&
      'flickr') ||
    (currentValue[0] && currentValue[0].uri && 'uri') ||
    (required && uploadType === 'obj' && 'poly') ||
    (required && uploadType === 'image' && 'flickr') ||
    (required && 'file') ||
    'none';

  const createThumbnail = (file, element) => {
    if (!thumbnailName) {
      return Promise.resolve(file);
    }

    return utils.image
      .toDataURL({
        uri: file.uri || (file.bin || {}).preview,
        element,
        height: thumbnailHeight || THUMBNAIL_HEIGHT,
        width: thumbnailWidth,
        quality: thumbnailQuality,
        type: thumbnailMimeType,
      })
      .then(dataUri =>
        Promise.resolve({
          ...file,
          [thumbnailName]: { bin: utils.buffer.fromDataURL(dataUri) },
        }),
      )
      .catch(() => Promise.resolve(file));
  };

  const onChange = ({ value, type, element }) =>
    Promise.all(value.map(file => createThumbnail({ ...file, type }, element))).then(files => {
      const values =
        files.length > 0 ? files : [{ type, ...emptyUri, ...emptyFile, ...emptyBin(type) }];

      return onValueChange({
        target: {
          name,
          value: multiple || asArray ? values : values[0],
        },
      });
    });

  const parseDataUri = (uri, filename, previewURI) =>
    uploadType === 'image'
      ? utils.image
          .toDataURL({
            uri,
            height: height || IMAGE_HEIGHT,
            width,
            quality,
            type: mimeType,
          })
          .then(dataUri => Promise.resolve(utils.buffer.fromDataURL(dataUri, filename, previewURI)))
      : Promise.resolve(utils.buffer.fromDataURL(uri, filename, previewURI));

  const parseFile = (...args) =>
    parseDataUri(...args).then(bin => {
      if (bin.name.match(/\.obj$/)) {
        const content = bin.content
          .replace(/[\r\n]+[a-z]{1}\s*[\r\n]+/g, '\n')
          .replace(/[\r\n]+([a-z]{1}){1}\s\s+/g, '\n$1 ');
        URL.revokeObjectURL(bin.preview);
        const blob = new Blob([content], { type: bin.contentType });
        const previewURI = URL.createObjectURL(blob);
        return Promise.resolve({ ...bin, content, preview: previewURI });
      } else if (bin.name.match(/\.mtl$/)) {
        const content = bin.content
          .replace(/map_Ka ([a-z]+(\\\\|\/))+/gi, 'map_Ka ')
          .replace(/map_Kd ([a-z]+(\\\\|\/))+/gi, 'map_Kd ')
          .replace(/map_Bump ([a-z]+(\\\\|\/))+/gi, 'map_Bump ');
        URL.revokeObjectURL(bin.preview);
        const blob = new Blob([content], { type: bin.contentType });
        const previewURI = URL.createObjectURL(blob);
        return Promise.resolve({ ...bin, content, preview: previewURI });
      }
      return Promise.resolve(bin);
    });

  const onDropFiles = (files = []) =>
    Promise.all(
      files.map(
        file =>
          new Promise(resolve => {
            const sizeLimit = fileSizeLimits[uploadType] || FALLBACK_FILE_SIZE_LIMIT;
            if (file.size && file.size < sizeLimit) {
              const reader = new FileReader();
              reader.onloadend = () => resolve(parseFile(reader.result, file.name, file.preview));
              reader.readAsDataURL(file);
            } else {
              onAddMessage({
                title: __('upload_size_limit_exceeded', {
                  filename: file.name,
                  fileSize: (file.size / 1024).toFixed(2),
                  sizeLimit: (sizeLimit / 1024).toFixed(2),
                }),
                type: 'warning',
              });
            }
          }),
      ),
    ).then(binaries =>
      onChange({
        value: binaries.map(bin => ({ bin, uri: undefined })),
        type: 'file',
      }),
    );

  const getPreviewURI = ({ uri, bin = {} }, key) =>
    uri || bin.preview || (typeof preview === 'function' ? preview({ uri, bin }, key) : preview);

  const renderFilePreview = (asset, key) => {
    const previewURI = getPreviewURI(asset, key);
    let fileAssetUrl;
    let previewSource;
    if (asset.file && currentUser) {
      fileAssetUrl = utils.filesystem.getFileAssetUrl(currentUser._id, asset.file);
    }
    if (!previewURI && !fileAssetUrl) {
      return null;
    }

    if (asset.bin && asset.bin.name && asset.filetitle !== asset.bin.name) {
      previewSource = previewURI;
    } else {
      previewSource = fileAssetUrl || previewURI;
    }

    if (uploadType === 'audio' && previewSource) {
      return (
        <div className="audio-preview-container preview-container" key={key}>
          <audio
            controls
            loop
            className="audio-preview"
            onLoadedData={onMediaLoaded}
            onLoadStart={onMediaLoading}
            onError={onMediaError}
            src={utils.media.createAudioStream(previewSource)}
          >
            <track kind="captions" src={`data:text/plain,${__('preview')}`} />
          </audio>
        </div>
      );
    }

    if (uploadType === 'video') {
      return currentSourceType === 'youtube' ? (
        artifactURI && (
          <div key={artifactURI}>
            <IFrame showTopBar={false} src={artifactURI} width="94%" height="225px" />
          </div>
        )
      ) : (
        <div className="video-preview-container preview-container" key={key}>
          <video
            controls
            loop
            className="video-preview"
            crossOrigin="anonymous"
            onLoadedData={onMediaLoaded}
            onCanPlay={({ target: element }) =>
              setTimeout(
                () =>
                  onChange({
                    value: currentValue,
                    type: currentSourceType,
                    element,
                  }),
                2048,
              )
            }
            onLoadStart={onMediaLoading}
            onError={onMediaError}
            src={utils.media.createVideoStream(fileAssetUrl || previewURI)}
          >
            <track kind="captions" src={`data:text/plain,${__('preview')}`} />
          </video>
        </div>
      );
    }

    if (uploadType === 'image') {
      return (
        <div className="image-preview-container preview-container" key={key}>
          <div className="ui medium">
            <a
              target="_blank"
              className="ui"
              rel="noopener noreferrer"
              href={fileAssetUrl || previewURI}
            >
              <img src={fileAssetUrl || previewURI} alt={__('preview')} />
            </a>
          </div>
        </div>
      );
    }

    const filename = asset.filetitle || asset.uri || (asset.bin || {}).name || '';
    if (uploadType === 'obj' && filename.match(/\.(obj|gltf|glb)$/)) {
      return (
        <div className="object-preview-container preview-container" key={key}>
          <canvas
            className="object-preview"
            ref={canvas =>
              utils.webgl
                .createObjectPreview(canvas, previewURI, filename)
                .then(
                  element =>
                    element &&
                    currentSourceType !== 'poly' &&
                    onChange({
                      value: currentValue,
                      type: currentSourceType,
                      element,
                    }),
                )
                .catch(() => false)
            }
          />
        </div>
      );
    }

    return null;
  };
  return (
    <div className="choice-uploader field">
      {!sourceType && (
        <div className={`${required ? 'required ' : ''} field`}>
          {label && <label htmlFor={`${id}-type`}>{__(label)}</label>}
          {hint === '' ? null : <span className="label-hint">({__(hint)})</span>}
          <select
            id={`${id}-type`}
            name={name}
            value={currentSourceType}
            onChange={event => onChange({ type: event.target.value, value: [] })}
            placeholder={__(label)}
            required={required}
          >
            {required || <option value="none">{__('none')}</option>}
            {uploadType === 'obj' && <option value="poly">{__('poly_asset')}</option>}
            {uploadType === 'image' && <option value="flickr">{__('flickr_asset')}</option>}
            <option value="file">{__('file')}</option>
            {uploadType === 'video' ? (
              <option value="uri">Streamable.com Video URL</option>
            ) : (
              <option value="uri">{__('uri')}</option>
            )}
            {uploadType === 'video' && <option value="youtube">{__('Youtube URL')}</option>}
          </select>
        </div>
      )}
      {currentSourceType === 'youtube' && (
        <div className={`${required ? 'required ' : ''} field`}>
          <Text>Youtube Link</Text>
          <input
            id={`${id}-youtube`}
            name={name}
            type="text"
            placeholder={`${__(label)} ${__('youtube')}`}
            required={required}
            value={currentValue
              .filter(file => file && file.uri)
              .map(file => file.uri)
              .join('\n')}
            onChange={event =>
              onChange({
                type: 'youtube',
                value: event.target.value.split('\n').map(uri => ({ uri, bin: undefined })),
              })
            }
          />
        </div>
      )}

      {currentSourceType === 'uri' && (
        <div className={`${required ? 'required ' : ''} field`}>
          {label && (
            <label style={{ display: 'inline' }} htmlFor={`${id}-uri`}>{`${__(label)} ${__(
              'uri',
            )}`}</label>
          )}
          {uploadType === 'video' && (
            <>
              <div className="streamable-tooltip-container">
                <div className="streamable-tooltip">?</div>
                <div className="streamable-tooltip-text">
                  {__('streamable_tooltip')}
                  <img
                    alt=""
                    style={{ maxHeight: '345px', marginTop: '10px' }}
                    src="/images/streamable-tooltip.jpg"
                  />
                </div>
              </div>
              <div style={{ fontSize: '0.9rem' }}>
                Due to browser limitations only 6 videos can be viewed concurrently
              </div>
            </>
          )}
          {multiple && (
            <textarea
              rows={3}
              id={`${id}-uri`}
              name={name}
              type="text"
              placeholder={`${__(label)} ${__('uris')}`}
              value={currentValue
                .filter(file => file && file.uri)
                .map(file => file.uri)
                .join('\n')}
              required={required}
              onChange={event =>
                onChange({
                  type: 'uri',
                  value: event.target.value.split('\n').map(uri => ({ uri, bin: undefined })),
                })
              }
            />
          )}
          {!multiple && (
            <input
              id={`${id}-uri`}
              name={name}
              type="text"
              placeholder={`${__(label)} ${__('uri')}`}
              value={currentValue
                .filter(file => file && file.uri)
                .map(file => file.uri)
                .join('\n')}
              required={required}
              onChange={event =>
                onChange({
                  type: 'uri',
                  value: event.target.value.split('\n').map(uri => ({ uri, bin: undefined })),
                })
              }
            />
          )}
        </div>
      )}
      {currentSourceType === 'file' && (
        <div className={`${required ? 'required ' : ''} field`}>
          {label && (
            <label htmlFor={`${id}-file`}>
              {`${__(label)} ${__(`file${multiple ? 's' : ''}`)}`}
            </label>
          )}
          <Dropzone
            id={`${id}-file`}
            name={name}
            className="dropzone"
            onDrop={onDropFiles}
            multiple={multiple}
            required={required}
            accept={accept}
          >
            <div className="text">{__('drop_filetype_here', { filetype: __(label) })}</div>
            {currentValue
              .filter(file => file && file.bin)
              .map(file => file.bin)
              .map((bin, idx) => (
                <a
                  key={idx}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui"
                  href={getPreviewURI({ bin }, idx)}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {bin.name}
                </a>
              ))}
            {/* new file system entries for assets */}
            {currentValue
              .filter(
                asset =>
                  asset &&
                  asset.file &&
                  (!asset.bin ||
                    (Object.keys(asset.bin).length === 0 && asset.bin.constructor === Object)),
              )
              .map((asset, idx) => (
                <a
                  key={idx}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ui"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {asset.filetitle}
                </a>
              ))}
          </Dropzone>
          {uploadType === 'image' && currentSourceType === 'file' && (
            <div className="text small">
              {`${__(`upload_types_message`)}: ${__(`image_types`)}`}
            </div>
          )}
          {uploadType === 'video' && currentSourceType === 'file' && (
            <div className="text small">
              <div>{`${__(`upload_types_message`)}: ${__(`video_types`)}`}</div>
              <div>{`${__(`size_general`)}`}</div>
            </div>
          )}
          {uploadType === 'obj' && currentSourceType === 'file' && (
            <div className="text small">
              <div>{`${__(`upload_types_message`)}: ${__(`object_types`)}`}</div>
              <div>{`${__(`size_general`)}`}</div>
            </div>
          )}
          {uploadType === 'audio' && currentSourceType === 'file' && (
            <div className="text small">
              <div>{`${__(`upload_types_message`)}: ${__(`audio_types`)}`}</div>
              <div>{`${__(`size_audio`)}`}</div>
            </div>
          )}
        </div>
      )}
      {currentSourceType === 'poly' && (
        <PolySelect
          name={name}
          id={id}
          asArray={asArray}
          value={rawValue}
          label={`${__('search_for')} ${__(label)}`}
          required={required}
          onChange={onValueChange}
        />
      )}
      {currentSourceType === 'flickr' && (
        <FlickrSelect
          name={name}
          id={id}
          asArray={asArray}
          value={rawValue}
          label={`${__('search_for')} ${__(label)}`}
          required={required}
          onChange={onValueChange}
        />
      )}
      <div className={`preview-wrapper ${multiple ? 'multiple' : ''}`}>
        {currentValue
          .filter(asset => asset && (asset.bin || asset.uri || asset.file))
          .map(renderFilePreview)}
      </div>
    </div>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
});

const mapDispatch = (dispatch, { exhibitionId = 'new', currentStep, isPrivateSpace }) => ({
  onAddMessage: message => dispatch(addMessage(message)),
});

const Uploader = compose(withState(mapState), withDispatch(mapDispatch))(UploaderView);

export default Uploader;

import React from 'react';
import Overlay from '../generic/Overlay';
import { __ } from 'artsteps2-common';
import { compose, withDispatch } from '../../enhancers';
import { apiPOST } from '../../actions';

const UnverifiedNotification = ({
  open,
  onClose,
  verificationEmailReq = () => Promise.resolve(false),
}) => (
  <Overlay fixed open={open} onClose={onClose} extraClasses="not-verified-notification">
    <h2 style={{ textAlign: 'center' }}>{__('Important Notice')}</h2>
    <p className="welcome-message" style={{ whiteSpace: 'pre-line' }}>
      {__('not_verified_notification_part1')}
      <strong>"Verify Now"</strong>
      {__('not_verified_notification_part2')}
    </p>
    <br />
    <br />
    <div className="not-verified-notification__buttons-container">
      <div className="ui form">
        <button onClick={verificationEmailReq} className="ui button teal">
          {__('Verify Now')}
        </button>
      </div>
      <div className="ui form" style={{ marginLeft: '5px' }}>
        <button onClick={onClose} className="ui button secondary">
          {__('Maybe Later')}
        </button>
      </div>
    </div>
  </Overlay>
);

const mapDispatch = (dispatch, { onClose }) => ({
  verificationEmailReq: () => {
    dispatch(apiPOST('auth/artsteps/existing-accounts-verification', {}));
    onClose();
  },
});

const UnverifiedNotificationModal = compose(withDispatch(mapDispatch))(UnverifiedNotification);

export default UnverifiedNotificationModal;

import React from 'react';
import { __ } from 'artsteps2-common';
import MyToolTip from '../../generic/MyToolTip';
import { ItemEditButton, ItemEditIcon } from './ExhibitionEditStyled';

const EditItem = ({ onEditorOpen }) => (
  <MyToolTip title={__('edit')} placement="left">
    <ItemEditButton
      onClick={e => {
        // Stoping event from bubbling up to parent and firing
        e.stopPropagation();
        onEditorOpen();
      }}
    >
      <ItemEditIcon />
    </ItemEditButton>
  </MyToolTip>
);

export default EditItem;

import publicConfig from 'artsteps2-config/public.json';
import Recaptcha from 'react-recaptcha';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import SendIcon from '@material-ui/icons/Send';
import Grid from '@material-ui/core/Grid';
import contributeImg from '../../styles/images/contribute.png';
import contributeImgMobile from '../../styles/images/contributeMobile.png';
import * as generic from '../../styles/GenericStyled';
import ModalUploader from '../generic/ModalUploader';
import useWindowSize from '../../hooks/windowSize';

const initialState = {
  message: '',
  name: '',
  email: '',
  files: [],
};

export default function ModalForm({
  onAddMessage,
  onClearForm,
  onClearMessages,
  onSetRecaptcha,
  setOpenModal,
  onCreateContactForm,
  recaptcha,
}) {
  const [data, setData] = useState({ ...initialState });
  const [isVerified, setVerified] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [maxSizeReached, setMaxSizeReached] = useState(false);
  const [currentSize, setCurrentSize] = useState(null);
  const [icon, setIcon] = useState(false);

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const bp = 1050;

  useEffect(() => {
    setIsMobile(size.width < bp);
  }, [size.width, recaptcha]);

  useEffect(() => {
    let sizeArray = data.files;
    if (sizeArray.length >= 1) {
      sizeArray = sizeArray.map(file => new Blob([file.content]).size);
      const sizeCur = sizeArray.reduce((accumulator, currentValue) => accumulator + currentValue);
      setCurrentSize(Math.round((sizeCur / 1000000) * 10) / 10);
      if (sizeCur > 7800000) setMaxSizeReached(true);
      else setMaxSizeReached(false);
      // onAddMessage({type: 'error', title: 'The total size of your files must not exceed 7,8 MB'})
    }
  }, [data]);

  const onChange = e => {
    data[e.target.name] = e.target.value;
    if (data.message.length > 1000 && e.target.name === 'message')
      data[e.target.name] = e.target.value.substring(0, data.message.length - 1);

    setData({ ...data });
  };

  const onSubmit = e => {
    setSpinner(true);
    setOpenModal(false);
    e.preventDefault();
    if (!Object.values(data).some(v => v.length === 0) && isVerified) {
      onCreateContactForm(data).then(({ response }) => {
        if (!response.error) {
          onClearMessages();
          onClearForm();
          setSpinner(false);
          onAddMessage({ title: 'Your contributiion has been sent! ', type: 'success' });
          return;
        }
        onAddMessage({ title: response.error, type: 'error' });
        setSpinner(false);
      });
    } else {
      setSpinner(false);
      onAddMessage({
        title: 'Please fill all fields and attach at least one file before sending',
        type: 'error',
      });
    }
  };

  const recaptchaLoaded = () => {
    if (recaptcha !== 'contribute_recaptcha') onSetRecaptcha('contribute_recaptcha');
  };

  const verifyCallback = response => {
    if (response) setVerified(true);
  };
  return (
    <generic.BodyWithPadding padding="20px">
      <Grid
        container
        wrap="wrap"
        direction={`${isMobile ? 'column' : 'row'}`}
        spacing={2}
        justify="space-between"
        alignItems="flex-start"
        style={{ height: !isMobile && 'inherit' }}
      >
        {!isMobile && (
          <Grid item xs={isMobile ? 12 : 6}>
            <generic.ImageContainer
              image={contributeImg}
              radius="17px"
              width="100%"
              height="52rem"
              minHeight="309px"
              size="cover"
              position="0px 1px"
            />
          </Grid>
        )}
        <Grid item xs={isMobile ? 12 : 6}>
          <generic.VerticalItems marginBottom="5px">
            <form style={{ display: 'flex', flexDirection: 'column' }}>
              <generic.Text type="h6">
                Become a Verified {size.width < 638 ? <br /> : ''} Contributor
              </generic.Text>
              {isMobile && (
                <generic.ImageContainer
                  image={contributeImgMobile}
                  isMobile={isMobile}
                  width="100%"
                  radius="17px"
                  height="100%"
                  minHeight="309px"
                  size={'cover'}
                />
              )}
              <generic.Text type="body2" style={{ height: '91px' }}>
                By sending us your creations of custom space templates and 3D objects you become a
                <b> Verified Contributor</b>. This includes artist copyright credits, an Artsteps
                badge and more exciting features to come.
              </generic.Text>
              <generic.Text type="h7">Name</generic.Text>
              <generic.StyledInput
                fullWidth
                onChange={e => onChange(e)}
                value={data.name}
                id="username-input"
                name="name"
                key="input-name"
                required
              />
              <generic.Text type="h7">Email</generic.Text>
              <generic.StyledInput
                fullWidth
                value={data.email}
                id="email-input"
                type="email"
                name="email"
                onChange={e => onChange(e)}
                key="input-email"
                required
              />
              <generic.HorizontalItems style={{ justifyContent: 'space-between' }}>
                <generic.Text type="h7">Message</generic.Text>
                <generic.Text type="h7">{`${data.message.length}/1000`}</generic.Text>
              </generic.HorizontalItems>
              <generic.StyledTextField
                fullWidth
                value={data.message}
                onChange={e => onChange(e)}
                id="message-input"
                name="message"
                required
                key="input-message"
                variant="outlined"
                multiline
                rows={4}
              />
              <ModalUploader
                data={data}
                setData={setData}
                onAddMessage={onAddMessage}
                spinner={spinner}
                maxSizeReached={maxSizeReached}
                currentSize={currentSize}
              />
              {recaptcha === 'contribute_recaptcha' && (
                <Recaptcha
                  size={`${isMobile ? 'compact' : 'normal'}`}
                  sitekey={publicConfig.artstepsRECAPTCHA}
                  render="explicit"
                  onloadCallback={recaptchaLoaded}
                  verifyCallback={verifyCallback}
                />
              )}
            </form>
          </generic.VerticalItems>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify={size.width < 591 ? 'center' : 'space-between'}
          direction={size.width < 591 ? 'column-reverse' : 'row'}
        >
          <generic.StyledButton
            onClick={() => setOpenModal(false)}
            mycolor="white"
            background="#343a4047"
            style={{ marginTop: size.width < 591 ? '9px' : '' }}
          >
            <generic.HorizontalItems>
              <generic.Text component="span" mycolor="#343A40">
                Cancel
              </generic.Text>
            </generic.HorizontalItems>
          </generic.StyledButton>
          <generic.StyledButton
            onClick={e => onSubmit(e)}
            mycolor="white"
            background="#12AD8F"
            disabled={maxSizeReached}
            onMouseEnter={() => setIcon(true)}
            onMouseLeave={() => setIcon(false)}
            hovertransition={'true'}
          >
            <generic.HorizontalItems>
              <generic.Text component="span" mycolor="#FFFFFF">
                Send your Message{spinner && <CircularProgress />}
                <generic.IconWithTransition open={icon}>
                  <SendIcon />
                </generic.IconWithTransition>
              </generic.Text>
            </generic.HorizontalItems>
          </generic.StyledButton>
        </Grid>
      </Grid>
    </generic.BodyWithPadding>
  );
}

import React from 'react';
import { Text } from '../../styles/GenericStyled';
import useWindowSize from '../../hooks/windowSize';
import Carousel from '../privateSpaces/Carousel';

import carouselImage1 from '../../styles/images/privateSpaces/carousel1.png';
import carouselImage2 from '../../styles/images/privateSpaces/carousel2.png';
import carouselImage3 from '../../styles/images/privateSpaces/carousel3.png';
import carouselImage4 from '../../styles/images/privateSpaces/carousel4.png';
import Grid from '@material-ui/core/Grid';

const PSbanner = ({
  title,
  sub1,
  sub2,
  button,
  images = [carouselImage1, carouselImage2, carouselImage3, carouselImage4],
}) => {
  const size = useWindowSize();
  const isMobile = size.width < 960;
  const isMobileFont = size.width < 1280;

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <div
          style={{
            padding: isMobile ? '20px 20px 30px 20px' : '20px 15px 20px 60px',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          <Text
            align={isMobile ? 'center' : 'left'}
            type={isMobileFont ? 'h4' : 'h1'}
            style={{ fontWeight: 'bold', marginBottom: '30px' }}
          >
            {title}
          </Text>
          <Text
            align={isMobile ? 'center' : 'left'}
            type={isMobileFont ? 'body1' : 'sub2'}
            style={{ marginBottom: '30px' }}
          >
            {sub1}
          </Text>
          <Text
            align={isMobile ? 'center' : 'left'}
            type={isMobileFont ? 'body1' : 'sub2'}
            style={{ marginBottom: '30px' }}
          >
            {sub2}
          </Text>
          {button}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <Carousel images={images} />
      </Grid>
    </Grid>
  );
};

export default PSbanner;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createWalls = exports.createWall = void 0;

var _math = require("../math");

var _tools = require("./tools");

var _constants = _interopRequireDefault(require("./constants"));

var createWall = function createWall(wall, idx) {
  var events = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var opts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var start = (0, _math.mirror)((0, _math.parseQuaternion)(wall.startPoint), opts.invertAxisPos);
  var end = (0, _math.mirror)((0, _math.parseQuaternion)(wall.endPoint), opts.invertAxisPos);
  var vector = [end[0] - start[0], end[1] - start[1], end[2] - start[2]];
  var magnitude = (0, _math.getMagnitude)(vector);
  var mid = (0, _math.getMidpoint3d)(start, end);
  var rotation = wall.rotation ? (0, _math.toEulerianAngle)((0, _math.mirror)((0, _math.parseQuaternion)(wall.rotation), opts.invertAxisRot)) : [0, (0, _math.getHorizontalAngle)(start, end), 0];
  rotation = rotation.map(function (item) {
    return (0, _tools.fixRotationValue)(item);
  });
  return {
    primitive: 'a-entity',
    className: 'wall',
    key: "wall-".concat(wall.wallId || idx),
    events: (0, _tools.createEvents)(events.walls, wall),
    rotation: rotation.join(' '),
    position: [mid[0], mid[1] + _constants["default"].DEFAULT_WALL_HEIGHT / 2, mid[2]].join(' '),
    children: [{
      primitive: 'a-box',
      key: "wall-front-".concat(wall.wallId || idx),
      'static-body': true,
      material: {
        shader: 'flat',
        opacity: _constants["default"].DEBUG.OPAQUE_WALLS ? '0.5' : 1,
        color: (0, _tools.toRGB)(wall.colorFront) || _constants["default"].COLOUR.STRUCTURE,
        src: wall.textureFront ? "#wall-texture-front-".concat(idx) : '#wall-emission',
        repeat: (wall.textureFront ? (0, _math.parseQuaternion)(wall.tilingFront) : [1, 1]).join(' ')
      },
      position: [_constants["default"].DEFAULT_WALL_WIDTH, 0, 0].join(' '),
      scale: [_constants["default"].DEFAULT_WALL_WIDTH, _constants["default"].DEFAULT_WALL_HEIGHT, magnitude].join(' ')
    }, {
      primitive: 'a-box',
      key: "wall-back-".concat(wall.wallId || idx),
      'static-body': true,
      material: {
        shader: 'flat',
        opacity: _constants["default"].DEBUG.OPAQUE_WALLS ? '0.5' : 1,
        color: (0, _tools.toRGB)(wall.colorBack) || _constants["default"].COLOUR.STRUCTURE,
        src: wall.textureBack ? "#wall-texture-back-".concat(idx) : '#wall-emission',
        repeat: (wall.textureBack ? (0, _math.parseQuaternion)(wall.tilingBack) : [1, 1]).join(' ')
      },
      position: [0, 0, 0].join(' '),
      scale: [_constants["default"].DEFAULT_WALL_WIDTH, _constants["default"].DEFAULT_WALL_HEIGHT, magnitude].join(' ')
    }, {
      primitive: 'a-box',
      key: "wall-top-".concat(wall.wallId || idx),
      'static-body': true,
      material: {
        shader: 'flat',
        color: _constants["default"].COLOUR.WALL_TOP,
        src: '#wall-top-texture'
      },
      position: [0, _constants["default"].DEFAULT_WALL_HEIGHT / 2 - _constants["default"].DEFAULT_WALL_WIDTH * 4, 0].join(' '),
      scale: [_constants["default"].DEFAULT_WALL_WIDTH * 8, _constants["default"].DEFAULT_WALL_WIDTH * 8, magnitude + _constants["default"].DEFAULT_WALL_WIDTH * 8].join(' ')
    }]
  };
};

exports.createWall = createWall;

var createWalls = function createWalls(walls) {
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return walls.map(function (wall, idx) {
    return createWall(wall, idx, events, opts);
  });
};

exports.createWalls = createWalls;
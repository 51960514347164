import React, { useEffect, useRef, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { addMessage, apiPOST, setUIProperty } from '../actions';
import { compose, withState, withDispatch, withLifecycle } from '../enhancers';
import { getAuthUser, getUIProperty } from '../reducers';
import * as PrivateComponent from '../styles/PrivateSpacesPageStyled';
import DefaultLayout from '../components/layouts/DefaultLayout';
import useWindowSize from '../hooks/windowSize';
import { BodyWithPadding, StyledButton, Text } from '../styles/GenericStyled';
import Grid from '@material-ui/core/Grid';
import PSbanner from '../components/privateSpaces/PSbanner';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import logo from '../styles/images/logo.png';
import CloseIcon from '@material-ui/icons/Close';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as Send } from '../styles/images/send-24px.svg';
import Modal from '@material-ui/core/Modal';
import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import videoPreview from '../styles/images/privateSpaces/virtual-events-video.mp4';
import { CarouselGrid, VideoArea } from '../styles/PrivateSpacesPageStyled';
import image1 from '../styles/images/pages/virtual-events-1.jpg';
import image2 from '../styles/images/pages/virtual-events-2.jpg';
import image3 from '../styles/images/pages/virtual-events-3.jpg';
import image4 from '../styles/images/pages/virtual-events-4.jpg';
import image5 from '../styles/images/pages/virtual-events-5.jpg';
import ServicesContent from '../components/privateSpaces/ServicesContent';

const { getNames } = require('country-list');

const breakPoint = 1515;

const VirtualEventsView = props => {
  const size = useWindowSize();

  const [open, setOpen] = React.useState(false);
  const [countryInput, setCountryInput] = useState('');
  const [inputData, setInputData] = useState({
    username: '',
    email: '',
    country: '',
    company: '',
  });

  useEffect(() => {
    document.title = `Artsteps | 3D Virtual Event`;
  }, []);

  const handleClose = () => {
    setOpen(false);
    setInputData({
      username: '',
      email: '',
      country: '',
      company: '',
    });
  };

  const handleSubmit = e => {
    props.onCreateContactForm({ ...inputData }).then(({ response }) => {
      if (!response.error) {
        props.onAddMessage({ title: 'Your message has been sent', type: 'success' });
      } else {
        props.onAddMessage({ title: response.error, type: 'error' });
      }
    });
    handleClose();
  };

  const isDisabled = state => {
    let disabled = false;
    for (const [key, value] of Object.entries(state)) {
      if (key !== 'company' && value === '') disabled = true;
    }
    return disabled;
  };

  const handleChange = (event, option) => {
    let name;
    let value;
    if (option === null) {
      value = '';
      name = 'country';
    } else if (!option) {
      name = event.target.name ? event.target.name : event.target.getAttribute('name');
      value = event.target.value !== 0 ? event.target.value : event.target.getAttribute('value');
    } else {
      value = option;
      name = 'country';
    }
    inputData[name] = value;
    setInputData({ ...inputData });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const CloseButton = styled(IconButton)`
    position: absolute;
    right: 15px;
    top: 5px;
    display: none;
    ${props =>
      props.visible &&
      css`
        display: flex;
      `}
  `;

  return (
    <DefaultLayout>
      <PSbanner
        title="3D Virtual Event"
        sub1="Host virtual fairs and every other type of event in VR 3D spaces"
        images={[image1, image2, image3, image4, image5]}
        button={
          <StyledButton onClick={handleOpen} background="#12AD8F">
            Contact us
          </StyledButton>
        }
      />

      <BodyWithPadding padding="100px 5% 0% 5%">
        <ServicesContent video={videoPreview} size={size}>
          <Text type={'h4'} align="left">
            What We Offer
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            Our 3D Virtual Events connect you with business leads all around the world from the
            comfort of your office
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Interconnected 3D virtual halls</Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Content Management System for the organiser and each exhibitor
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>Branded and Curated booths</Text>
            </li>
            <li>
              <Text type={'sub2'}>Various options for 3D booth templates</Text>
            </li>
            <li>
              <Text type={'sub2'}>Avatars for real-time chat with visitors</Text>
            </li>
            <li>
              <Text type={'sub2'}>Forms for direct sales to clients</Text>
            </li>
            <li>
              <Text type={'sub2'}>Visitor metrics</Text>
            </li>
          </ul>
          <br />
          <br />
          <Text type={'h4'} align="left">
            How It Works
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            We set up your 3D Virtual Event with a layout built on your preferences or its physical
            manifestation. You only have to:
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Send the floorplan of your event</Text>
            </li>
            <li>
              <Text type={'sub2'}>Submit details on the number of participants</Text>
            </li>
            <li>
              <Text type={'sub2'}>Determine the type of booth for each exhibitor</Text>
            </li>
            <li>
              <Text type={'sub2'}>Define the content of the exhibitors’ booths</Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Establish the metrics you desire to receive, based on the number of visits
              </Text>
            </li>
          </ul>
          <br />
          <br />
          <Text type={'h4'} align="left">
            Chosen By
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            3D Virtual Events simulate the feeling of a real-life event and have become a must for
            every organizer who aspires to a high level of engagement
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Trade organisations for virtual fairs</Text>
            </li>
            <li>
              <Text type={'sub2'}>Universities for virtual career days</Text>
            </li>
            <li>
              <Text type={'sub2'}>Corporations for virtual team building</Text>
            </li>
          </ul>
        </ServicesContent>
      </BodyWithPadding>

      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={4}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Grid item>
          <Text type="h5" mycolor="black" align="center">
            <b>Interested in this Service?</b>
          </Text>
        </Grid>
        <Grid item>
          <StyledButton onClick={handleOpen} background="#12AD8F">
            Contact us
          </StyledButton>
        </Grid>
      </Grid>
      {/*Modal*/}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        style={{ overflow: 'auto' }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PrivateComponent.ModalGrid container direction="column" justify="center" spacing={2}>
            <Box component={Grid} item textAlign="center">
              <img alt="" src={logo} width="177px" height="59px" />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.CustomTypography variant="h4" modalhead="true">
                Request Details
              </PrivateComponent.CustomTypography>
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="username-input">
                Name
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="username-input"
                onChange={e => handleChange(e)}
                name="username"
                value={inputData.username}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="email-input">
                Email
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="email-input"
                onChange={handleChange}
                name="email"
                value={inputData.email}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="country-input">
                Country
              </PrivateComponent.InputsLabel>
              <PrivateComponent.CustomAutocomplete
                value={inputData.country}
                onChange={(e, value) => handleChange(e, value)}
                onInputChange={(event, newInputValue) => {
                  setCountryInput(newInputValue);
                }}
                inputValue={countryInput}
                autoComplete
                options={getNames()}
                clearOnEscape
                getOptionSelected={option => option}
                getOptionLabel={option => option}
                renderOption={option => <React.Fragment>{option}</React.Fragment>}
                renderInput={params => (
                  <PrivateComponent.CustomTextField
                    {...params}
                    placeholder="Select"
                    style={{ border: 0 }}
                    required
                  />
                )}
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="company-input">
                <PrivateComponent.CustomTypography company="true">
                  Company
                </PrivateComponent.CustomTypography>
                <PrivateComponent.CustomTypography optional="true">
                  (Optional)
                </PrivateComponent.CustomTypography>
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="company-input"
                onChange={handleChange}
                name="company"
                value={inputData.company}
              />
            </Box>
            <Box component={CloseButton} onClick={handleClose} visible={size.width < breakPoint}>
              <CloseIcon />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.SendButton disabled={isDisabled(inputData)} onClick={handleSubmit}>
                Send your Request
                <SvgIcon>
                  <Send />
                </SvgIcon>
              </PrivateComponent.SendButton>
            </Box>
          </PrivateComponent.ModalGrid>
        </Fade>
      </Modal>
    </DefaultLayout>
  );
};

const lifecycleMap = {
  onDidMount: () => {
    ReactPixel.track('ViewContent');
  },
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  requestOpen: getUIProperty('requestOpen'),
});
const mapDispatch = dispatch => ({
  setOpenRequest: status => dispatch(setUIProperty('requestOpen', status)),
  onCreateContactForm: form =>
    dispatch(apiPOST('privatespaces', { ...form, requestQuoteFor: '3D Virtual Event Service' })),
  onAddMessage: message => dispatch(addMessage(message, 'privatespaces')),
});

const VirtualEvents = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(VirtualEventsView);

export default VirtualEvents;

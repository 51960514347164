import React from 'react';
import { __ } from 'artsteps2-common';
import { StyledButton } from '../../styles/GenericStyled';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

const ICONS = {
  debug: 'info',
  info: 'info circle',
  warning: 'warning sign',
  error: 'remove circle',
  success: 'check circle',
};

export const DialogView = ({
  title = '',
  message = '',
  type = 'info',
  open = true,
  onConfirm,
  onReject,
  onClose,
  exhibition,
  children = null,
}) =>
  open && (
    <div className="ui dimmer modals page transition visible active fixed">
      <div className="dialog-overlay ui basic modal active">
        <div className="ui icon header center aligned">
          <i className={`${ICONS[type] || ICONS.info} icon`} />
          <div className="header">{title}</div>
        </div>
        <div className="ui basic segment center aligned">
          <p>{message}</p>
          {children}
        </div>
        <div className="actions">
          {(onConfirm || onReject) && (
            <div className="buttons center aligned">
              {onClose && (
                <StyledButton
                  onClick={onClose}
                  mycolor="white"
                  mycolorhover="black"
                  background="transparent"
                  border="white"
                  bordercolor="white"
                  backgroundhover="white"
                >
                  <ClearIcon fontSize="large" style={{ marginRight: '4px' }} />
                  {__('cancel')}
                </StyledButton>
              )}
              {onReject && (
                <StyledButton
                  onClick={onReject}
                  mycolor="#ff5144"
                  mycolorhover="white"
                  background="transparent"
                  border="#ff5144"
                  bordercolor="#ff5144"
                  backgroundhover="#ff5144"
                >
                  <ClearIcon fontSize="large" style={{ marginRight: '4px' }} />
                  {__('no')}
                </StyledButton>
              )}
              {onConfirm && (
                <StyledButton
                  onClick={onConfirm}
                  mycolor="#22be34"
                  mycolorhover="white"
                  background="transparent"
                  border="#22be34"
                  bordercolor="#22be34"
                  backgroundhover="#22be34"
                >
                  <DoneIcon fontSize="large" style={{ marginRight: '4px' }} />
                  {__('yes')}
                </StyledButton>
              )}
            </div>
          )}
          {!onConfirm && !onReject && onClose && (
            <div
              className="ui inverted buttons center aligned"
              style={{ justifyContent: 'center' }}
            >
              <StyledButton
                onClick={onClose}
                mycolor="white"
                mycolorhover="black"
                background="transparent"
                border="white"
                bordercolor="white"
                backgroundhover="white"
              >
                <ClearIcon fontSize="large" style={{ marginRight: '4px' }} />
                {exhibition ? (
                  <a href={`artsteps://view/${exhibition._id}`}>{__('ok')}</a>
                ) : (
                  __('ok')
                )}
              </StyledButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );

const Dialog = DialogView;

export default Dialog;

import React from 'react';
import { __ } from 'artsteps2-common';
import utils from '../../../utils';
import Link from '../../generic/Link';
import Loader from '../../generic/Loader';
import Dialog from '../../generic/Dialog';
import ArticleList from './ArticleList';
import CategoryList from './CategoryList';
import {
  apiGET,
  apiPATCH,
  apiDELETE,
  setFormData,
  handleFormPropertyChange,
  setUIProperty,
  addMessage,
  setLocation,
} from '../../../actions';
import {
  API_STATUS,
  getApiStatus,
  getApiResource,
  getFormProperty,
  getUIProperty,
  getAuthUserRoles,
} from '../../../reducers';
import { compose, withState, withDispatch } from '../../../enhancers';

export const CategoryView = ({
  category = {},
  articles = [],
  categories = [],
  ready = true,
  editable = false,
  isEditing = false,
  isDialogOpen = false,
  displayChildren = true,
  breadcrumb = false,
  header = 1,
  editedTitle = '',
  editedParent = '',
  editedSlug,
  redirectionURL = '/categories',
  onAddMessage = () => Promise.resolve(false),
  onStartEditing = () => Promise.resolve(false),
  onStopEditing = () => Promise.resolve(false),
  onCategoryTitleChange = () => Promise.resolve(false),
  onCategoryParentChange = () => Promise.resolve(false),
  onOpenDialog = () => Promise.resolve(false),
  onCloseDialog = () => Promise.resolve(false),
  onRedirect = () => Promise.resolve(false),
  onUpdate = () => Promise.resolve({ response: { error: 'error' } }),
  onDelete = () => Promise.resolve({ response: { error: 'error' } }),
}) => {
  if (!ready) {
    return <Loader />;
  }

  const createPath = (cref = {}) =>
    cref._id
      ? [
          ...createPath(
            categories.find(c => cref.parent && c._id === (cref.parent._id || cref.parent)),
          ),
          cref,
        ]
      : [];

  const onSubmit = () =>
    onUpdate({
      title: editedTitle,
      parent: editedParent || undefined,
      slug: editedSlug,
    }).then(({ response }) => {
      if (response.error) {
        return onAddMessage({
          type: 'error',
          title: __('category_update_err'),
          description: __(response.error),
        });
      }

      utils.dialogflow.updateIntent(`articleCategories/${category._id}/text`, [editedTitle]);
      return onStopEditing();
    });

  const onConfirmDelete = () => {
    if (articles.find(a => a.category === category._id)) {
      return onAddMessage({
        title: __('category_contains_articles'),
        description: __('delete_articles_first'),
        type: 'warning',
      });
    }

    return onDelete().then(({ response }) => {
      if (!response.error) {
        onCloseDialog();
        return onRedirect(redirectionURL);
      }
      return onAddMessage({
        title: __('category_deletion'),
        description: __(response.error),
        type: 'error',
      });
    });
  };

  const Header = `h${header || 1}`;

  return (
    <div className="ui segment vertical">
      <div className="ui breadcrumb">
        <Header>
          {breadcrumb &&
            (!editable || !isEditing) &&
            createPath(category).map((c, idx) => (
              <div className="section" key={c._id}>
                {idx ? <div className="divider">/</div> : null}
                <Link exact to={`/categories/${c.slug || c._id}`}>
                  {c.title}
                </Link>
              </div>
            ))}
          {!breadcrumb && (!editable || !isEditing) && (
            <Link to={`/categories/${category.slug || category._id}`}>{category.title}</Link>
          )}
          {editable && isEditing && (
            <div className="section">
              <select name="parent" value={editedParent} onChange={onCategoryParentChange}>
                <option key="" value="" />
                {categories
                  .filter(cat => cat._id !== category._id)
                  .map(c => (
                    <option key={c._id} value={c._id}>
                      {createPath(c)
                        .map(cat => cat.title)
                        .join('/')}
                    </option>
                  ))}
              </select>
            </div>
          )}
          {editable && !isEditing && (
            <div className="ui section">
              <button
                className="bare icon"
                data-tooltip={__('edit_this_category_title')}
                data-inverted=""
                onClick={onStartEditing}
              >
                <i className="edit icon small" />
              </button>
              <button
                className="bare icon"
                data-tooltip={__('delete_category')}
                data-inverted=""
                onClick={onOpenDialog}
              >
                <i className="trash alternate outline icon small" />
              </button>
            </div>
          )}
          {editable && isEditing && (
            <div className="section">
              <div className="ui input">
                <div className="divider">/</div>
                <input
                  type="text"
                  name="title"
                  placeholder={__('title')}
                  value={editedTitle}
                  onChange={onCategoryTitleChange}
                />
                <div className="ui section">
                  <button
                    className="ui secondary button tiny"
                    data-tooltip={__('discard_changes')}
                    data-inverted
                    onClick={onStopEditing}
                  >
                    <i className="undo icon" /> {__('cancel')}
                  </button>
                  <button
                    className="ui teal button tiny"
                    data-tooltip={__('save_changes')}
                    data-inverted
                    onClick={onSubmit}
                  >
                    <i className="save icon" /> {__('save')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Header>
      </div>
      <ArticleList categoryId={category._id} editable={editable} />
      {displayChildren > 0 && (
        <div className="child-categories">
          <CategoryList
            categories={categories}
            editable={editable}
            parentCategory={category._id}
            articles={articles}
            header={(header || 1) + 1}
            displayChildren={(displayChildren || 0) - 1}
          />
        </div>
      )}
      <Dialog
        open={editable && isDialogOpen}
        title={__('category_deletion')}
        message={__('confirm_category_deletion', category)}
        type="warning"
        onConfirm={onConfirmDelete}
        onReject={onCloseDialog}
      />
    </div>
  );
};

const EDITOR_ROLES = ['editor', 'admin'];

const mapState = (state, { categoryId, editable }) => ({
  category: getApiResource(state, `articleCategories/${categoryId}`),
  articles: Object.values(getApiResource(state, 'articles')),
  categories: Object.values(getApiResource(state, 'articleCategories')),
  ready:
    getApiStatus(state, `articleCategories/${categoryId}`) === API_STATUS.IDLE &&
    getApiStatus(state, 'articles') === API_STATUS.IDLE &&
    getApiStatus(state, 'categories') === API_STATUS.IDLE,
  editable: editable && getAuthUserRoles(state).some(role => EDITOR_ROLES.includes(role)),
  isEditing: getUIProperty(state, `article-categories/${categoryId}/edit`),
  isDialogOpen: getUIProperty(state, `article-categories/${categoryId}/dialogVisible`),
  editedTitle: getFormProperty(state, `article-category:${categoryId}`, 'title'),
  editedParent: getFormProperty(state, `article-category:${categoryId}`, 'parent'),
  editedSlug: getFormProperty(state, `article-category:${categoryId}`, 'slug'),
});

const mapDispatch = (dispatch, { categoryId, category }) => ({
  onFetchCategory: () => dispatch(apiGET(`articleCategories/${categoryId}`)),
  onAddMessage: message => dispatch(addMessage(message)),
  onStartEditing: () =>
    dispatch(setFormData(`article-category:${categoryId}`, category)).then(() =>
      dispatch(setUIProperty(`article-categories/${categoryId}/edit`, true)),
    ),
  onStopEditing: () => dispatch(setUIProperty(`article-categories/${categoryId}/edit`, false)),
  onCategoryTitleChange: event =>
    dispatch(handleFormPropertyChange(`article-category:${categoryId}`, event)),
  onCategoryParentChange: event =>
    dispatch(handleFormPropertyChange(`article-category:${categoryId}`, event)),
  onOpenDialog: () =>
    dispatch(setUIProperty(`article-categories/${categoryId}/dialogVisible`, true)),
  onCloseDialog: () =>
    dispatch(setUIProperty(`article-categories/${categoryId}/dialogVisible`, false)),
  onRedirect: location => dispatch(setLocation(location)),
  onUpdate: data => dispatch(apiPATCH(`articleCategories/${categoryId}`, data)),
  onDelete: () => dispatch(apiDELETE(`articleCategories/${categoryId}`)),
});

const Category = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(CategoryView);

export default Category;

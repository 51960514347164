import React from 'react';
import { __ } from 'artsteps2-common';

import MetaTags from '../components/layouts/MetaTags';
import DefaultLayout from '../components/layouts/DefaultLayout';
import Unsubscription from '../components/subscribers/forms/Unsubscription';

const UnsubscriptionPage = ({ subscriptionId, query: { email } = {} }) => (
  <DefaultLayout>
    <MetaTags title={__('unsubscribe')} />
    <div className="ui main container text">
      <div className="ui segment vertical">
        <h1 className="ui header">{__('unsubscribe')}</h1>
        <Unsubscription subscriptionId={subscriptionId} email={email} />
      </div>
    </div>
  </DefaultLayout>
);

export default UnsubscriptionPage;

import React from 'react';
import OurModal from '../generic/OurModal';
import RegisterForm from '../../modal_content/RegisterForm';
import { BodyWithPadding } from '../../styles/GenericStyled';
import { withState, withDispatch, compose } from '../../enhancers';
import { getUIProperty } from '../../reducers';
import { setUIProperty } from '../../actions';

const RegisterModalView = ({ openRegister, setOpenRegister }) => {
  return (
    <OurModal
      minWidth="320px"
      minHeight="560px"
      maxWidth="450px"
      maxHeight="800px"
      width="30%"
      height="80%"
      openModal={openRegister}
      setOpenModal={setOpenRegister}
    >
      <BodyWithPadding padding="40px">
        <RegisterForm />
      </BodyWithPadding>
    </OurModal>
  );
};

const mapState = state => ({
  openRegister: getUIProperty(state, 'openRegister'),
});

const mapDispatch = dispatch => ({
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
});

const RegisterModal = compose(withState(mapState), withDispatch(mapDispatch))(RegisterModalView);

export default RegisterModal;

import React from 'react';
import { Redirect } from 'react-router';
import DefaultLayout from '../components/layouts/DefaultLayout';
import { getUIProperty, getAuthUser, getApiStatus, API_STATUS, getApiResource } from '../reducers';
import { withState, withDispatch, compose, withLifecycle } from '../enhancers';
import { addMessage, apiGET, setLocation, setUIProperty } from '../actions';
import MaintenanceBanner from '../components/banners/maintenanceBanner';
import UverifiedNotificationModal from '../components/banners/UnverifiedNotificationModal';
import {BodyWithPadding, ImageContainer, NLinesBreak, StyledButton, Text} from '../styles/GenericStyled';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import config from 'artsteps2-config/private.json';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MyToolTip from '../components/generic/MyToolTip';
import OurModal from '../components/generic/OurModal';

function groupBy(arr, prop) {
  const map = new Map(Array.from(arr, obj => [obj[prop], []]));
  arr.forEach(obj => map.get(obj[prop]).push(obj));
  return Array.from(map.values());
}

const MarketplaceCard = ({
  bundleTemplates,
  isXs,
  onRedirect,
  myTemplates,
  currentUser,
  handleBuy,
}) => {
  const [viewer, setViewer] = React.useState({});
  const [popup, setPopup] = React.useState(false);
  const [terms, setTerms] = React.useState(false);

  React.useEffect(() => {
    if (bundleTemplates && bundleTemplates.length > 0 && Object.keys(viewer).length === 0) {
      setViewer(bundleTemplates.sort((a, b) => b.size.localeCompare(a.size)).slice(-1)[0]);
    }
  }, [bundleTemplates, viewer]);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const getHeight = () => {
    if (isXs) {
      return 'auto';
    }
    if (isMd) {
      return '310px';
    }
    return '280px';
  }
  return (
    <Card style={{ marginBottom: '20px', height: getHeight() }} key={bundleTemplates[0].bundle}>
      <Grid container style={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={3}
          xl={3}
          style={{ cursor: 'pointer', position: 'relative' }}
          onClick={() => onRedirect(`/marketplace/${bundleTemplates[0].bundle}`)}
        >
          {/*<div
            style={{ position: 'absolute', top: '8px', left: '16px', cursor: 'pointer', zIndex: "100" }}
            onClick={() => onRedirect(`/marketplace/${bundleTemplates[0]?.bundle}`)}
          >
            <MyToolTip title="Preview" placement="top">
              <VisibilityIcon style={{ fontSize: '48px', color: 'white' }} />
            </MyToolTip>
          </div>*/}
          {isXs ? (
            <img /* Mobile */
              style={{
                width: '100%',
                height: '260px',
                borderRadius: '4px 4px 0 0',
                objectFit: 'cover',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
              src={viewer?.image}
              alt={viewer?.title}
            />
          ) : (
            <MyToolTip title="Preview" placement="top">
              <img /* Desktop */
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '4px 0 0 4px',
                  objectFit: 'cover',
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                }}
                src={viewer?.image}
                alt={viewer?.title}
              />
            </MyToolTip>
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={9} xl={9} style={{ height: '100%', paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px', paddingBottom: '8px' }}>
          <MyToolTip title="Preview" placement="left">
            <Text
              style={{ fontWeight: 600, fontSize: '20px', cursor: 'pointer', marginBottom: '4px' }}
              onClick={() => onRedirect(`/marketplace/${bundleTemplates[0]?.bundle}`)}
            >
              {viewer?.title}
            </Text>
          </MyToolTip>
          <Text style={{ fontSize: '14px', marginBottom: '4px' }}>Style: {viewer?.style}</Text>
          <NLinesBreak lines={2} style={{ marginBottom: '8px' }}>
            <Text style={{ fontSize: '14px' }}>{viewer?.description}</Text>
          </NLinesBreak>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} sm={6} md={6}>
              {bundleTemplates
                .sort((a, b) => b.size.localeCompare(a.size))
                .map(b => (
                  <div key={b._id}>
                    <FormControlLabel
                      key={b._id}
                      control={
                        <Checkbox
                          checked={viewer?._id === b?._id}
                          onChange={e => setViewer(b)}
                          name={b._id}
                          style={{ color: '#12AD8F' }}
                          key={b._id}
                        />
                      }
                      label={
                        <Text
                          mycolor={viewer?._id === b?._id ? 'rgba(18, 173, 143, 1)' : 'black'}
                          style={{
                            fontSize: isMd ? '14px' : '18px',
                            /*fontWeight: viewer?._id === b?._id ? '600' : 'initial',*/
                          }}
                          key={b._id}
                        >
                          {b.size}: {b.price}€{' '}
                          {b.pricePreSale && (
                            <del
                              style={{
                                color: viewer?._id === b?._id ? 'rgba(18, 173, 143, 1)' : 'black',
                              }}
                            >
                              {b?.pricePreSale}€
                            </del>
                          )}
                          <bold style={{ fontSize: '12px' }}>
                            {' ('}
                            {b.details.floorArea}m² area, {b.details.wallLength}m wall length)
                          </bold>
                        </Text>
                      }
                    />
                    <br />
                  </div>
                ))}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {myTemplates?.includes(viewer?._id) && currentUser ? (
                <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '10px' }}>
                  <StyledButton
                    background="#12AD8F"
                    type="secondary"
                    onClick={() => onRedirect(currentUser ? '/curate/new/1' : '')}
                  >
                    <Text style={{ fontSize: '18px', marginLeft: '10px' }}>Already Acquired</Text>
                  </StyledButton>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '10px',
                  }}
                >
                  <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#12ad8f' }}>
                    Total: {viewer?.price}€ {viewer.pricePreSale && <del>{viewer?.pricePreSale}€</del>}
                  </Text>
                  <StyledButton
                    background="#FE625B"
                    type="secondary"
                    onClick={() => setPopup(true)}
                    style={{ marginBottom: '10px', marginTop: '5px' }}
                  >
                    <ShoppingCartOutlinedIcon />{' '}
                    <Text style={{ fontSize: '18px', marginLeft: '10px' }}>Buy</Text>
                  </StyledButton>
                  <br />
                  <Text style={{ fontSize: '14px', color: 'gray' }}>
                    Taxes/VAT calculated at checkout
                  </Text>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <OurModal
        width="330px"
        height="440px"
        openModal={popup}
        setOpenModal={() => {
          setPopup(false);
          setTerms(false);
        }}
        key={bundleTemplates[0].bundle}
      >
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Text style={{ textAlign: 'left', fontSize: '24px', fontWeight: 'bold' }}>
            Buying on Artsteps
          </Text>
          <br />
          <Text style={{ fontSize: '16px' }}>{config.accounts.services.paddle.termsPopupText}</Text>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={terms}
                onChange={() => setTerms(t => !t)}
                name="terms"
                style={{ color: '#F26767' }}
              />
            }
            label={
              <Text style={{ fontSize: '14px', textAlign: 'center' }}>
                I have read and accept the above terms and the{' '}
                <a href="/article/terms-of-service" target="_blank" rel="noopener noreferrer">
                  Artsteps Terms & Conditions
                </a>
              </Text>
            }
          />
          <br />
          <StyledButton
            background="#F26767"
            type="secondary"
            onClick={() => {
              handleBuy(viewer);
              setPopup(false);
              setTerms(false);
            }}
            style={{ marginBottom: '10px', marginTop: '10px' }}
            disabled={!terms}
          >
            <ShoppingCartOutlinedIcon />{' '}
            <Text style={{ fontSize: '18px', marginLeft: '10px' }}>Buy</Text>
          </StyledButton>
        </div>
      </OurModal>
    </Card>
  );
};

const FiltersPanel = ({
  isSm,
  styles,
  checkStyle,
  isStyleChecked,
  filters,
  handleChange,
  onClose,
}) => (
  <div
    style={{
      width: '200px',
      marginTop: '20px',
      marginLeft: isSm ? 'auto' : '40px',
      marginRight: isSm ? 'auto' : '20px',
    }}
  >
    <Text style={{ fontSize: '24px', marginBottom: '10px' }} mycolor="gray">
      Filters
      {isSm && (
        <IconButton style={{ marginLeft: '80px' }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      )}
    </Text>
    <Text type="sub3" mycolor="gray">
      Style
    </Text>
    {styles?.map((s, i) => (
      <div key={s}>
        <FormControlLabel
          key={s}
          control={<Checkbox checked={isStyleChecked(s)} onChange={checkStyle} name={s} />}
          label={
            <Text type="sub3" mycolor="gray">
              {s}
            </Text>
          }
        />
        <br />
      </div>
    ))}
    <br />
    <Text type="sub3" mycolor="gray">
      Total floor area
    </Text>
    <Grid container direction="row" alignItems="flex-end">
      <Grid item>
        <TextField
          label="Min"
          type="number"
          name="minFloorArea"
          id="minFloorArea"
          value={filters.minFloorArea || ''}
          onChange={handleChange}
          style={{ width: '70px' }}
        />
      </Grid>
      <Grid item>
        <Text type="sub3" mycolor="gray" style={{ marginLeft: '20px', marginRight: '20px' }}>
          to
        </Text>
      </Grid>
      <Grid item>
        <TextField
          label="Max"
          type="number"
          name="maxFloorArea"
          id="maxFloorArea"
          value={filters.maxFloorArea || ''}
          onChange={handleChange}
          style={{ width: '70px' }}
        />
      </Grid>
    </Grid>

    <br />
    <Text type="sub3" mycolor="gray">
      Total usable wall length
    </Text>
    <Grid container direction="row" alignItems="flex-end">
      <Grid item>
        <TextField
          label="Min"
          type="number"
          name="minWallLength"
          id="minWallLength"
          value={filters.minWallLength || ''}
          onChange={handleChange}
          style={{ width: '70px' }}
        />
      </Grid>
      <Grid item>
        <Text type="sub3" mycolor="gray" style={{ marginLeft: '20px', marginRight: '20px' }}>
          to
        </Text>
      </Grid>
      <Grid item>
        <TextField
          label="Max"
          type="number"
          name="maxWallLength"
          id="maxWallLength"
          value={filters.maxWallLength || ''}
          onChange={handleChange}
          style={{ width: '70px' }}
        />
      </Grid>
    </Grid>
  </div>
);

const MarketplacePageView = ({
  isPrivateSpace,
  templates,
  templatePopularity,
  authorization,
  styles,
  unverifiedOpen = false,
  currentUser,
  ready,
  onUnverifiedNotificationClosed = () => Promise.resolve(false),
  onRedirect = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  setOpenLogin = () => Promise.resolve(false),
  onSetModal = () => Promise.resolve(false),
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [filters, setFilters] = React.useState({ style: [] });
  const [openDrawer, setOpenDrawer] = React.useState(false);

  React.useEffect(() => {
    document.title = `Artsteps | Marketplace`;
  }, []);

  const [oldUser, setOldUser] = React.useState('');
  const [flagPolling, setFlagPolling] = React.useState(false);
  React.useEffect(() => {
    if (oldUser !== currentUser?._id && flagPolling) window.location.reload();
    setOldUser(currentUser?._id);
    setFlagPolling(oldFlagPolling => !oldFlagPolling);
  }, [currentUser]);

  let myTemplates =
    authorization && authorization.length > 0 && authorization !== []
      ? authorization[0].templates
      : [];
  const popularity = new Map();
  templatePopularity.forEach(p => {
    popularity.set(p.bundle, p.purchases);
  });

  const isStyleChecked = style => {
    return filters?.style?.includes(style);
  };

  const checkStyle = event => {
    if (event.target.checked === true) {
      setFilters({ ...filters, style: [...filters.style, event.target.name] });
    } else {
      setFilters({ ...filters, style: filters.style.filter(s => s !== event.target.name) });
    }
  };

  const handleChange = event => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const filtererStyles = element => {
    if (filters.style.length === 0) return true;
    return filters.style.includes(element[0]?.style);
  };
  const filtererFloorAreaMin = element => {
    if (!('minFloorArea' in filters)) return true;
    if (filters.minFloorArea === '') return true;
    for (const e of element) {
      if (e.details.floorArea >= filters.minFloorArea) return true;
    }
    return false;
  };
  const filtererFloorAreaMax = element => {
    if (!('maxFloorArea' in filters)) return true;
    if (filters.maxFloorArea === '') return true;
    for (const e of element) {
      if (e.details.floorArea <= filters.maxFloorArea) return true;
    }
    return false;
  };
  const filtererWallLengthMin = element => {
    if (!('minWallLength' in filters)) return true;
    if (filters.minWallLength === '') return true;
    for (const e of element) {
      if (e.details.wallLength >= filters.minWallLength) return true;
    }
    return false;
  };
  const filtererWallLengthMax = element => {
    if (!('maxWallLength' in filters)) return true;
    if (filters.maxWallLength === '') return true;
    for (const e of element) {
      if (e.details.wallLength <= filters.maxWallLength) return true;
    }
    return false;
  };
  const getMaxPrice = el => {
    return Math.max(...el.map(e => e?.price));
  };
  const sorterer = (a, b) => {
    // Sort by price descending
    return getMaxPrice(b) - getMaxPrice(a);
    // Sort by bundle descending
    // return b[0].bundle - a[0].bundle;
  };

  function checkoutComplete(data) {
    if (data?.checkout?.completed === false) return;
    onSetModal(true);
    onAddMessage({
      title:
        'Your purchase has been completed! Your template is now available for use when you create a new exhibition.',
      type: 'success',
    });
    onRedirect('/');
  }

  function checkoutClosed(data) {
    return onAddMessage({ title: 'Your purchase has been cancelled', type: 'error' });
  }

  const handleBuy = viewer => {
    if (!currentUser) {
      onAddMessage({ title: 'You need to be logged in to buy this template', type: 'error' });
      onRedirect('/marketplace');
      setOpenLogin(true);
      return;
    }
    if (myTemplates?.includes(viewer?._id)) {
      onAddMessage({ title: 'You already own this template', type: 'error' });
      return;
    }
    let Paddle = window.Paddle;
    Paddle.Setup({
      vendor: config.accounts.services.paddle.vendorId,
      debug: config.accounts.services.paddle.debug,
    });
    if (config.accounts.services.paddle.sandbox === true) {
      Paddle.Environment.set('sandbox');
    }
    Paddle.Checkout.open({
      product: viewer.paddleProductId,
      passthrough: JSON.stringify({ user: currentUser._id, template: viewer._id }),
      allowQuantity: false,
      disableLogout: true,
      successCallback: checkoutComplete,
      closeCallback: checkoutClosed,
    });
  };

  if (isPrivateSpace) {
    return <Redirect to="/" />;
  }
  if (!ready && !currentUser) {
    return <div />;
  }

  return (
    <DefaultLayout>
      {process.env.REACT_APP_SHOW_BANNER === 'TRUE' && <MaintenanceBanner />}
      <BodyWithPadding padding="0px">
        <Grid container direction="row" alignItems="flex-start">
          {!isSm && (
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <FiltersPanel
                isSm={isSm}
                styles={styles}
                checkStyle={checkStyle}
                isStyleChecked={isStyleChecked}
                filters={filters}
                handleChange={handleChange}
                onClose={() => setOpenDrawer(false)}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={9} lg={10}>
            <div
              style={{
                width: '100%',
                paddingLeft: '20px',
                paddingRight: '20px',
                marginTop: '20px',
              }}
            >
              <Card style={{width: '100%', backgroundColor: 'hsl(0, 0%, 95%)', padding: '20px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                <Text style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '8px' }} mycolor="#FE625B">
                  Buy Now your Premium 3D Space Templates and Add Value to your Content
                </Text>
                <Text style={{ fontSize: '18px', marginBottom: '4px' }} mycolor="#2D6493">
                  {config?.accounts?.services?.paddle?.marketplaceText}
                </Text>
              </Card>
              <br />
              {isSm && (
                <Button
                  variant="outlined"
                  style={{
                    marginTop: '-2px',
                    height: '35px',
                    marginRight: '20px',
                    marginBottom: '20px',
                    textTransform: 'none',
                    border: '1px solid gray',
                    borderRadius: '6px',
                  }}
                  onClick={() => setOpenDrawer(true)}
                >
                  <TuneIcon style={{ color: 'gray' }} />
                  <Text style={{ fontSize: '12px', marginLeft: '10px' }}>Filters</Text>
                </Button>
              )}
              {templates?.length === 0 && (
                <Text style={{ fontSize: '20px', fontWeight: 'bold' }} mycolor="gray">
                  The templates will be available for sale very soon.
                </Text>
              )}
              {groupBy(templates, 'bundle')
                .sort(sorterer)
                .filter(filtererStyles)
                .filter(filtererFloorAreaMin)
                .filter(filtererFloorAreaMax)
                .filter(filtererWallLengthMin)
                .filter(filtererWallLengthMax)
                .map(b => (
                  <div key={b[0].bundle}>
                    <MarketplaceCard
                      bundleTemplates={b}
                      isXs={isXs}
                      onRedirect={onRedirect}
                      myTemplates={myTemplates}
                      currentUser={currentUser}
                      handleBuy={handleBuy}
                      key={b[0].bundle}
                    />
                  </div>
                ))}
            </div>
          </Grid>
        </Grid>
      </BodyWithPadding>

      <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <div style={{ padding: '20px' }}>
          <FiltersPanel
            isSm={isSm}
            styles={styles}
            checkStyle={checkStyle}
            isStyleChecked={isStyleChecked}
            filters={filters}
            handleChange={handleChange}
            onClose={() => setOpenDrawer(false)}
          />
        </div>
      </Drawer>

      <UverifiedNotificationModal
        open={
          !!(
            unverifiedOpen &&
            currentUser &&
            currentUser.services.password &&
            !currentUser.emails[0].verified
          )
        }
        onClose={onUnverifiedNotificationClosed}
      />
    </DefaultLayout>
  );
};

const mapState = state => ({
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  templates: Object.values(getApiResource(state, 'templates/marketplace')),
  templatePopularity: Object.values(getApiResource(state, 'templates/marketplacePopularity')),
  authorization: Object.values(getApiResource(state, 'templates/authorization')),
  styles: Object.values(getApiResource(state, 'templates/styles')),
  unverifiedOpen: getUIProperty(state, 'unverifiedNotificationOpen'),
  currentUser: getAuthUser(state),
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  ready:
    getApiStatus(state, `spaces/${window.location.hostname.split('.')[0]}`) === API_STATUS.IDLE,
});

const mapDispatch = dispatch => ({
  fetchTemplates: () => dispatch(apiGET('templates/marketplace')),
  fetchTemplatePopularity: () => dispatch(apiGET('templates/marketplacePopularity')),
  fetchAuthorization: () => dispatch(apiGET('templates/authorization')),
  fetchStyles: () => dispatch(apiGET('templates/styles')),
  onUnverifiedNotificationClosed: () =>
    dispatch(setUIProperty('unverifiedNotificationOpen', false)),
  onRedirect: location => dispatch(setLocation(location)),
  onAddMessage: message => dispatch(addMessage(message, 'marketplace')),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  onSetModal: bool => dispatch(setUIProperty('marketplaceModalOpen', bool)),
});

const lifecycleMap = {
  onDidMount: ({ fetchTemplates, fetchStyles, fetchTemplatePopularity, fetchAuthorization }) => {
    fetchTemplates();
    fetchStyles();
    fetchTemplatePopularity();
    fetchAuthorization();
  },
};

const MarketplacePage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(MarketplacePageView);

export default MarketplacePage;

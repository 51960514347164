import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import DefaultLayout from '../components/layouts/DefaultLayout';
import ExhibitionList from '../components/exhibitions/lists/ExhibitionList';
import {
  API_KEYS,
  getUIProperty,
  getAuthUser,
  getApiStatus,
  API_STATUS,
  getApiResource,
} from '../reducers';
import { withState, withDispatch, compose } from '../enhancers';
import { setUIProperty } from '../actions';

import MaintenanceBanner from '../components/banners/maintenanceBanner';
import UverifiedNotificationModal from '../components/banners/UnverifiedNotificationModal';
import { BodyWithPadding, Text } from '../styles/GenericStyled';
import privateConfig from 'artsteps2-config/private.json';
import useWindowSize from '../hooks/windowSize';

const ExhibitionVotingListPageView = ({
  isPrivateSpace,
  unverifiedOpen = false,
  currentUser,
  space,
  ready,
  onUnverifiedNotificationClosed = () => Promise.resolve(false),
}) => {
  const populate = ['user'];
  const { width } = useWindowSize();

  const include = [
    'categories',
    'description',
    `views.${API_KEYS.META}.totalCount`,
    `likes.${API_KEYS.META}.totalCount`,
    'visits',
    'rating',
    '_v1Rating',
  ];

  const {
    active,
    uri,
    exhibitions,
    page_content_title,
    page_content_subtitle,
    page_content_body,
  } = privateConfig.voting;

  useEffect(() => {
    document.title = `Artsteps | ${uri.replace('/', '')}`;
  }, [uri]);

  if (!active) {
    return <Redirect to="/explore" />;
  }
  if (!ready && !currentUser) {
    return <div />;
  }
  if (ready && isPrivateSpace && !currentUser && !space.title) {
    return <Redirect to="/login" />;
  }

/*  const half = Math.ceil(exhibitions.length / 2);
  const firstHalf = exhibitions.slice(0, half);
  const secondHalf = exhibitions.slice(-half);*/

  return (
    <DefaultLayout>
      <BodyWithPadding padding="10px" className="exhibition-list-page ui segment basic">
        <div
          style={{
            textAlign: 'center',
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '20px',
          }}
        >
          <Text type={width > 700 ? 'h2' : 'h4'} style={{ whiteSpace: 'pre-line' }}>
            {page_content_title}
          </Text>
          <br />
          <Text type={width > 700 ? 'sub1' : 'sub2'} style={{ whiteSpace: 'pre-line' }}>
            {page_content_subtitle}
          </Text>
          <br />
          <Text type={width > 700 ? 'body1' : 'body1'} style={{ whiteSpace: 'pre-line' }}>
            {page_content_body}
          </Text>
        </div>
        {/*<ExhibitionList
          wide
          usernames
          filter={{
            _id: {
              $in: firstHalf.map(e => e.toString()),
            },
          }}
          include={include}
          populate={populate}
          limit={firstHalf.length}
        />
        <ExhibitionList
          wide
          usernames
          filter={{
            _id: {
              $in: secondHalf.map(e => e.toString()),
            },
          }}
          include={include}
          populate={populate}
          limit={secondHalf.length}
        />*/}
        <ExhibitionList
          wide
          usernames
          filter={{
            _id: {
              $in: exhibitions.map(e => e.toString()),
            },
          }}
          include={include}
          populate={populate}
        />
      </BodyWithPadding>
      {process.env.REACT_APP_SHOW_BANNER === 'TRUE' && <MaintenanceBanner />}

      <UverifiedNotificationModal
        open={
          !!(
            unverifiedOpen &&
            currentUser &&
            currentUser.services.password &&
            !currentUser.emails[0].verified
          )
        }
        onClose={onUnverifiedNotificationClosed}
      />
    </DefaultLayout>
  );
};

const mapState = state => ({
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  unverifiedOpen: getUIProperty(state, 'unverifiedNotificationOpen'),
  currentUser: getAuthUser(state),
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  ready:
    getApiStatus(state, `spaces/${window.location.hostname.split('.')[0]}`) === API_STATUS.IDLE,
});

const mapDispatch = dispatch => ({
  onUnverifiedNotificationClosed: () =>
    dispatch(setUIProperty('unverifiedNotificationOpen', false)),
});

const ExhibitionVotingListPage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionVotingListPageView);

export default ExhibitionVotingListPage;

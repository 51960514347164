import React from 'react';
import { VerticalItems, Text, StyledButton, BodyWithPadding } from '../styles/GenericStyled';

const Paypal = () => {
  return (
    <BodyWithPadding padding="30px">
      <VerticalItems>
        <Text style={{ textAlign: 'center' }} type="h5">
          Support our work
        </Text>
        <br />
        <Text style={{ textAlign: 'center', fontWeight: 'normal' }} type="h7">
          At ArtSteps we constantly work on improving our platform, so that our global community has
          the best features to create and share its unique 3D virtual stories and spaces in high bandwidth connections and with high limits in disk storage.
        </Text>
        <img
          src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg"
          alt="PayPal Logo"
          style={{ padding: '10px', transform: 'scale(0.7)' }}
        />
        <StyledButton
          onClick={() => {
            window.location =
              'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7N3GKFABLACGQ&source=url';
          }}
          type="secondary"
          background="#cb4943"
          mycolor="white"
        >
          I want to support
        </StyledButton>
      </VerticalItems>
    </BodyWithPadding>
  );
};

export default Paypal;

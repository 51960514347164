import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import HelpIcon from '@material-ui/icons/Help';
import Paper from '@material-ui/core/Paper';
import { HorizontalItems, InvisibleTextField, Text } from '../../../styles/GenericStyled';
import MyToolTip from '../../generic/MyToolTip';
import colors from '../../../styles/colors';

// should pass handle change here and not manipulate redux directly
const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid rgba(0,0,0,.25)',
    boxShadow: 'none',
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    marginTop: '10px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const TagsInput = ({ handleChange, tags = [], label, hint }) => {
  const classes = useStyles();
  const [tmpTag, setTmpTag] = React.useState('');

  const setFormTags = tags => handleChange({ target: { name: 'tags', value: tags } });

  const handleDelete = tagToDelete => setFormTags(tags.filter(tag => tag !== tagToDelete));

  const onType = e => {
    // Do not allow space convert to lower case letters
    const tag = e.target.value
      .trim()
      .replace(';', '')
      .toLowerCase();
    setTmpTag(tag);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 || e.keyCode === 186) {
      e.preventDefault();
      if (tmpTag !== '' && !tags.includes(tmpTag)) setFormTags([...tags, tmpTag]);
      setTmpTag('');
    }
  };

  const handleOnBlur = () => {
    if (tmpTag !== '' && !tags.includes(tmpTag)) setFormTags([...tags, tmpTag]);
    setTmpTag('');
  };

  return (
    <div style={{ width: '100%' }}>
      <HorizontalItems>
        <Text>{label}</Text>
        <MyToolTip placement="right" title={hint}>
          <HelpIcon style={{ marginLeft: '5px' }} />
        </MyToolTip>
        <Text style={{ marginLeft: '10px' }} mycolor={colors.ourRed}>
          (Enter a semicolon after each tag)
        </Text>
      </HorizontalItems>
      <Paper
        onClick={() => document.getElementById('tag-input-field').focus()}
        style={{ justifyContent: 'flex-start' }}
        className={classes.root}
      >
        <HorizontalItems style={{ flexWrap: 'wrap' }}>
          {tags &&
            tags.length > 0 &&
            tags.map((tag, idx) => (
              <Chip
                key={`${tag}-${idx}`}
                label={tag}
                onDelete={() => handleDelete(tag)}
                className={classes.chip}
              />
            ))}
          <InvisibleTextField
            id="tag-input-field"
            value={tmpTag}
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
            onChange={onType}
          />
        </HorizontalItems>
      </Paper>
    </div>
  );
};

export default TagsInput;

import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import styled, { css } from 'styled-components';
import * as header from '../../styles/styledComponents/header';
import * as generic from '../../styles/GenericStyled';
import PaymentIcon from '@material-ui/icons/Payment';

import {
  getFormProperty,
  getUIProperty,
  getLocationPathname,
  getLocationQuery,
} from '../../reducers';
import { setFormProperty, setUIProperty, setLocation } from '../../actions';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import MyToolTip from '../generic/MyToolTip';

const SearchBarWrapper = styled(Grid)`
  background-color: #fafafa;
  border-radius: 4px;
  position: relative;
  margin-left: 16px;
  align-self: stretch;
  flex-wrap: nowrap;
  margin-right: 10px;
  ${props =>
    props.bordered &&
    css`
       {
        height: 58px;
        border: 2px solid #f3f3f3;
        @media (max-width: 720px) {
          height: 38px;
        }
      }
    `}
`;

const SearchInput = styled(InputBase)`
  color: #343a40;
  opacity: 0.7;
  font-size: 1.2rem;
  padding-left: 20px;
  font-family: 'Muli', Regular;
`;


export const ExhibitionListSearch = ({
  search = '',
  onSearchTermChange = Promise.resolve(false),
  onClickSearchButton = Promise.resolve(false),
  onToggleDonateModal = Promise.resolve(false),
  isPrivateSpace = false,
  navbarView = false,
  width,
}) => {
  const handleClear = e => {
    if (e) {
      e.target.value = '';
      onSearchTermChange(e);
      if (window.location.pathname === '/explore') onClickSearchButton('');
    }
  };

  return (
    <header.ToggledGrid
      container
      item
      alignItems="center"
      justify="flex-start"
      wrap="nowrap"
      xl={isPrivateSpace ? 6 : 5}
      lg={isPrivateSpace ? 5 : 4}
      md={9}
      sm={12}
      xs={12}
      navbarview={+navbarView}
      isprivatespace={+isPrivateSpace}
      search="true"
    >
      <SearchBarWrapper
        container
        item
        xl={8}
        lg={12}
        md={12}
        sm={12}
        alignItems="center"
        bordered="true"
        wrap="nowrap"
      >
        <SearchBarWrapper container item xl={12} lg={12} md={10} sm={12} alignItems="center">
          <SearchInput
            fullWidth
            inputProps={{ 'aria-label': 'search' }}
            placeholder={__('search_exhibitions')}
            value={search}
            onChange={e => {
              if (search === '') handleClear();
              onSearchTermChange(e);
            }}
          />
        </SearchBarWrapper>
        <>
          {search !== '' && (
            <IconButton style={{ padding: '11px' }} onClick={e => handleClear(e)}>
              <CloseIcon />
            </IconButton>
          )}
          <IconButton
            style={{ padding: '11px' }}
            disabled={search === ''}
            onClick={() => onClickSearchButton(search)}
          >
            <SearchIcon />
          </IconButton>
        </>
      </SearchBarWrapper>
      {/*{width > 720 && !isPrivateSpace && (
        <generic.StyledButton
          type="secondary"
          style={{ margin: '0 8px' }}
          background="#cb4943"
          onClick={() => onToggleDonateModal(true)}
        >
          <header.Span>Contribute</header.Span>
          {width < 1281 && (
            <PaymentIcon
              style={{
                opacity: '0.5',
                fontSize: '1.9rem',
              }}
            />
          )}
        </generic.StyledButton>
      )}*/}
    </header.ToggledGrid>
  );
};

const mapState = (state, { searchPage }) => ({
  search: getFormProperty(state, 'search', 'keyword'),
  query: getLocationQuery(state),
  searchPage: searchPage || getLocationPathname(state),
});

const mapDispatch = (dispatch, { query, searchPage }) => {
  const onSetSearchTerm = keyword => dispatch(setFormProperty('search', 'keyword', keyword));
  const onSearch = search => {
    dispatch(
      setLocation({
        pathname: searchPage,
        query: { ...query, search },
      }),
    );
  };
  return {
    onSetSearchTerm,
    onSearchTermChange: ({ target: { value } }) => onSetSearchTerm(value),
    onToggleDonateModal: show => dispatch(setUIProperty('donateModal', show)),
    onClickSearchButton: value => onSearch(value),
  };
};

const onInitialization = ({ query: { search = '' } = {}, onSetSearchTerm }) =>
  onSetSearchTerm(search);

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: ({ query: { search = '' } = {} }, props) =>
    ((props.query || {}).search || '') !== search && onInitialization(props),
};

const SearchBar = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionListSearch);

export default SearchBar;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getArtifactURI = exports.getVideoThumbnail = exports.getArtifactAudio = exports.getArtifactCover = exports.getArtifactImageFull = exports.getArtifactImage = exports.getArtifactNamedBlobURI = exports.getArtifactFileURI = exports.getArtifactBlobURI = exports.parseFileUri = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _public = _interopRequireDefault(require("artsteps2-config/public.json"));

var _media = require("./media");

var _buffer = require("./buffer");

var PATH_ARTIFACTS = '/api/artifacts';
var PATH_ARTIFACT_FILES = 'files';
var PATH_ARTIFACT_IMAGE = 'image';
var PATH_ARTIFACT_COVER = 'covers';
var PATH_ARTIFACT_AUDIO = 'audio';
var FILE_SYSTEM_ROOT_URL = _public["default"].usersFileSystemUrl;

var getRootURL = function getRootURL() {
  return typeof window === 'undefined' ? process.env.ROOT_URL || 'http://localhost:3000' : "".concat(window.location.protocol, "//").concat(window.location.host);
};

var parseFileUri = function parseFileUri(file, currentUserId) {
  return file.uri || file.base64 || "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(currentUserId, "/").concat(file.file);
};

exports.parseFileUri = parseFileUri;

var getArtifactBlobURI = function getArtifactBlobURI(artifact, bin, path) {
  if (!bin) {
    return undefined;
  }

  if (bin.preview) {
    return bin.preview;
  }

  if (bin.content && bin.contentType && !artifact._id) {
    return (0, _buffer.toDataURL)(bin);
  }

  return "".concat(getRootURL()).concat(PATH_ARTIFACTS, "/").concat(artifact._id, "/").concat(path);
};

exports.getArtifactBlobURI = getArtifactBlobURI;

var getArtifactFileURI = function getArtifactFileURI(artifact, name) {
  if (!artifact || !artifact.files) {
    return undefined;
  } // getting file from file system


  var fileEntry = artifact.files[0];
  if (fileEntry.file && fileEntry.bin && fileEntry.filecreatedAt > fileEntry.bin.updatedAt || fileEntry.file && (!fileEntry.bin || !fileEntry.bin.name)) return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(artifact.user, "/").concat(artifact.files[0].file);
  var file = artifact.files.filter(function (f) {
    return f.uri;
  }).find(function (u) {
    return !name || u.match(name);
  });

  if (file) {
    return file.uri;
  }

  var _artifact$files$map$f = artifact.files.map(function (b, idx) {
    return [b.bin, idx];
  }).find(function (b) {
    return !name || b[0].name && b[0].name.match(name);
  }),
      _artifact$files$map$f2 = (0, _slicedToArray2["default"])(_artifact$files$map$f, 2),
      bin = _artifact$files$map$f2[0],
      index = _artifact$files$map$f2[1];

  if (!bin || !bin.contentType) {
    return undefined;
  }

  return getArtifactBlobURI(artifact, bin, "".concat(PATH_ARTIFACT_FILES, "/").concat(index, "/bin"));
};

exports.getArtifactFileURI = getArtifactFileURI;

var getArtifactNamedBlobURI = function getArtifactNamedBlobURI(artifact, name) {
  if (!artifact || !artifact.files) {
    return undefined;
  }

  var uri = artifact.files.map(function (f) {
    return f.uri;
  }).find(function (ref) {
    return ref;
  });

  if (uri) {
    return uri;
  }

  var file = artifact.files.map(function (b) {
    return b.file;
  }).find(function (b) {
    return b && b.match(name);
  });

  if (file) {
    return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(artifact.user, "/").concat(file);
  }

  var bin = artifact.files.map(function (b) {
    return b;
  }).find(function (b) {
    return b.name && b.name.match(name);
  });

  if (!bin || !bin.contentType) {
    return undefined;
  }

  return getArtifactBlobURI(artifact, bin, "".concat(PATH_ARTIFACT_FILES, "/~bin.name:bin/").concat(bin.name));
};

exports.getArtifactNamedBlobURI = getArtifactNamedBlobURI;

var getArtifactImage = function getArtifactImage(artifact) {
  if (!artifact || !artifact.image) {
    return undefined;
  }

  if (typeof artifact.image === 'string') {
    return artifact.image;
  } // getting file from file system


  var thumbnailEntry = artifact.image;
  if (thumbnailEntry.file && thumbnailEntry.bin && thumbnailEntry.filecreatedAt > thumbnailEntry.bin.updatedAt || thumbnailEntry.file && !thumbnailEntry.bin) return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(artifact.user, "/").concat(artifact.image.file);
  return artifact.image.uri || getArtifactBlobURI(artifact, artifact.image.bin, "".concat(PATH_ARTIFACT_IMAGE, "/bin"));
};

exports.getArtifactImage = getArtifactImage;

var getArtifactImageFull = function getArtifactImageFull(artifact) {
  if (!artifact || !artifact.files || !artifact.files.length > 0) {
    return undefined;
  } // getting file from file system


  if (artifact.files[0].file) return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(artifact.user, "/").concat(artifact.files[0].file);else if (artifact.files[0].uri) return artifact.files[0].uri;else if (artifact.files[0].bin) return getArtifactBlobURI(artifact, artifact.files[0].bin, "".concat(PATH_ARTIFACT_IMAGE, "/bin"));
  return undefined;
};

exports.getArtifactImageFull = getArtifactImageFull;

var getArtifactCover = function getArtifactCover(artifact, name) {
  if (!artifact || !artifact.covers) {
    return undefined;
  }

  var _artifact$covers$map$ = artifact.covers.map(function (b, idx) {
    return [b.bin, idx];
  }).find(function (b) {
    return !name || b[0].name && b[0].name.match(name);
  }),
      _artifact$covers$map$2 = (0, _slicedToArray2["default"])(_artifact$covers$map$, 2),
      cover = _artifact$covers$map$2[0],
      index = _artifact$covers$map$2[1];

  if (!cover || !cover.contentType) {
    return undefined;
  }

  return getArtifactBlobURI(artifact, cover, "".concat(PATH_ARTIFACT_COVER, "/").concat(index, "/bin"));
};

exports.getArtifactCover = getArtifactCover;

var getArtifactAudio = function getArtifactAudio(artifact) {
  if (!artifact || !artifact.audio) {
    return undefined;
  }

  if (typeof artifact.audio === 'string') {
    return artifact.audio;
  }

  if (artifact.audio.uri) {
    return (0, _media.createAudioStream)(artifact.audio.uri);
  } // getting file from file system


  var audioEntry = artifact.audio;
  if (audioEntry && audioEntry.file) return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(artifact.user, "/").concat(artifact.audio.file);
  return getArtifactBlobURI(artifact, artifact.audio.bin, "".concat(PATH_ARTIFACT_AUDIO, "/bin"));
};

exports.getArtifactAudio = getArtifactAudio;

var getVideoThumbnail = function getVideoThumbnail(artifact) {
  if (artifact && artifact.type === 'video') {
    if (artifact.files && artifact.files.length > 0 && artifact.uploadOption === 'youtube') {
      return {
        service: artifact.uploadOption,
        uri: getArtifactImage(artifact)
      };
    }
  }

  return null;
};

exports.getVideoThumbnail = getVideoThumbnail;

var getArtifactURI = function getArtifactURI(artifact) {
  if (artifact && artifact.type === 'image') {
    return getArtifactFileURI(artifact) || getArtifactImage(artifact);
  }

  if (artifact && artifact.type === 'video') {
    return "".concat((0, _media.createVideoStream)(getArtifactFileURI(artifact)));
  }

  if (artifact && artifact.type === 'object') {
    return getArtifactNamedBlobURI(artifact, /\.(obj|gltf|glb)$/);
  }

  if (artifact && artifact.type === 'text') {
    if (!artifact.files || !artifact.files.length) {
      return undefined;
    }

    return (0, _buffer.toUTF8String)(artifact.files[0].bin);
  }

  return undefined;
};

exports.getArtifactURI = getArtifactURI;
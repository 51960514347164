import React from 'react';
import { __ } from 'artsteps2-common';

const AppIconsSectionView = ({ googlePlayURI, appStoreURI }) => (
  <div className="storeicons">
    {googlePlayURI && (
      <a target="_blank" rel="noopener noreferrer" href={googlePlayURI}>
        <div className="logo-img-google-play" />
      </a>
    )}
    {appStoreURI && (
      <a target="_blank" rel="noopener noreferrer" href={appStoreURI}>
        <div className="logo-img-app-store" alt={__('download_on_appstore')} />
      </a>
    )}
  </div>
);

const AppIconsSection = AppIconsSectionView;

export default AppIconsSection;

import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import i18nextInstance from 'artsteps2-common';
import UserDropDown from './UserDropDown';
import {getAuthUser, getLocationQuery, getUIProperty} from '../../reducers';
import { apiGET } from '../../actions';
import utils from '../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import SearchWrapper from './SearchBar';
import { ReactComponent as LoginIcon } from '../../styles/images/loginIcon.svg';
import * as header from '../../styles/styledComponents/header';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import StoreIcon from '@material-ui/icons/Store';
import privateConfig from 'artsteps2-config/private.json';


const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    top: '58px!important',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '200px',
    maxWidth: '100%',
    width: '100%',
    left: '0!important',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

export const MobileHeaderView = ({ currentUser, query = {}, isPrivateSpace }) => {


  const loggedinOptions = (currentUser, action) => {
    let options = [
      {
        path: `/explore`,
        name: 'Exhibitions',
        action: action[0],
        icon: <ExploreOutlinedIcon />,
      },
      {
        path: `/privatespaces`,
        name: 'Private Web Space',
        action: action[0],
        navlink: 'true',
      },
      {
        path: `/curatorial-service`,
        name: 'Curation',
        action: action[0],
        navlink: 'true',
      },
      {
        path: `/custom-space-3d`,
        name: 'Custom 3D Design',
        action: action[0],
        navlink: 'true',
      },
      {
        path: `/virtual-events`,
        name: '3D Virtual Event',
        action: action[0],
        navlink: 'true',
      },
      {
        path: `https://blog.artsteps.com`,
        name: 'Blog',
        action: action[0],
        icon: <MenuBookOutlinedIcon />,
      },
      {
        path: `/change-password`,
        name: 'Change Password',
        action: action[0],
        icon: <VpnKeyIcon />,
      },
      {
        path: `/edit-profile/${currentUser._id}`,
        name: 'Edit Profile',
        action: action[0],
        icon: <AccountCircleIcon />,
      },
      // ACTIVATE WHEN ACCOUNT SETTING PAGE WILL BE BUILT
      // {
      //   path: `/profile/${currentUser._id}`,
      //   name: 'Account Settings',
      //   action: action[0],
      //   icon: <SettingsIcon />,
      // },
      {
        path: `/contact`,
        name: 'Contact us',
        action: action[0],
        icon: <ContactSupportIcon />,
      },
      {
        path: `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7N3GKFABLACGQ&source=url`,
        name: 'Contribute',
        action: action[0],
        icon: <PaymentIcon />,
        fillcolor: '#d56d69',
      },
      {
        name: `Lang: ${i18nextInstance.language === 'en' ? 'FR' : 'EN'}`,
        action: () => {
          action[0]();
          action[1]();
        },
        icon: <LanguageIcon />,
      },
      {
        path: `/logout`,
        name: 'Sign Out',
        action: action[0],
        icon: <LoginIcon />,
      },
    ];


    if (
      privateConfig.accounts.services.paddle.marketplaceEnabled === true &&
      isPrivateSpace === false
    ) {
      options.splice(1, 0, {
        path: `/marketplace`,
        name: 'Marketplace',
        action: action[0],
        icon: <StoreIcon />,
      });
    }
    return options;
  };

  const loggedOutOptions = (action) => {
    let options = [
      {
        path: `/explore`,
        name: 'Exhibitions',
        action: action[0],
        icon: <ExploreOutlinedIcon/>,
        //navlink: 'true',
      },
      {
        path: `/privatespaces`,
        name: 'Private Web Space',
        action: action[0],
        navlink: 'true',
      },
      {
        path: `/custom-space-3d`,
        name: 'Custom 3D Design',
        action: action[0],
        navlink: 'true',
      },
      {
        path: `/curatorial-service`,
        name: 'Curation',
        action: action[0],
        navlink: 'true',
      },
      {
        path: `/virtual-events`,
        name: '3D Virtual Event',
        action: action[0],
        navlink: 'true',
      },
      {
        path: `https://blog.artsteps.com`,
        name: 'Blog',
        action: action[0],
        icon: <MenuBookOutlinedIcon />,
      },
      {
        // path: `/login`,
        name: 'Sign In',
        action: () => {
          action[0]();
          action[3]();
        },
        icon: <LoginIcon />,
      },
      {
        // path: `/register`,
        name: 'Create Account',
        action: () => {
          action[0]();
          action[4]();
        },
        icon: <AddIcon />,
      },
      {
        path: `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7N3GKFABLACGQ&source=url`,
        name: 'Contribute',
        action: action[0],
        icon: <PaymentIcon />,
        fillcolor: '#d56d69',
      },
      {
        name: `Lang: ${i18nextInstance.language === 'en' ? 'FR' : 'EN'}`,
        action: () => {
          action[0]();
          action[1]();
        },
        icon: <LanguageIcon />,
      },
      {
        path: `/contact`,
        name: 'Contact us',
        action: action[0],
        icon: <ContactSupportIcon />,
      },
    ];
    if (
      privateConfig.accounts.services.paddle.marketplaceEnabled === true &&
      isPrivateSpace === false
    ) {
      options.splice(1, 0, {
        path: `/marketplace`,
        name: 'Marketplace',
        action: action[0],
        icon: <StoreIcon />,
      });
    }
    return options;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SearchWrapper query={query} searchPage="/explore" />
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <UserDropDown
          loggedinOptions={loggedinOptions}
          loggedOutOptions={loggedOutOptions}
          handleClose={handleClose}
          currentUser={currentUser}
        />
      </StyledMenu>
      <header.AccountButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={e => {
          handleMenu(e);
        }}
        color="inherit"
        logged_out={`${currentUser !== '' || currentUser !== undefined}`}
      >
        {currentUser &&
          (currentUser.profile.image !== undefined &&
          Object.keys(currentUser.profile.image).length !== 0 ? (
            <header.AvatarIcon
              src={utils.user.getUserImage({ _id: currentUser._id, profile: currentUser.profile })}
            />
          ) : (
            <header.AvatarIcon />
          ))}
        {!currentUser && <header.AvatarIcon />}
      </header.AccountButton>
    </>
  );
};
const mapState = state => ({
  currentUser: getAuthUser(state),
  query: getLocationQuery(state),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
});
const lifecycleMap = {
  onDidMount: ({ onFetchUser, currentUser }) => {
    if (currentUser) onFetchUser({ currentUser });
  },
};

const mapDispatch = dispatch => ({
  onFetchUser: ({ currentUser }) => dispatch(apiGET(`users/${currentUser._id}`)),
});
const MobileHeader = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(MobileHeaderView);

export default MobileHeader;

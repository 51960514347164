import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import { Link } from 'react-router-dom';
import { __ } from 'artsteps2-common';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TextField from '@material-ui/core/TextField';
import {
  CommentDiv,
  CommentText,
  HorizontalItems,
  VerticalItems,
  ReplyDiv,
  Buttons,
  InvisibleButton,
} from '../../exhibitions/viewer/ExhibitionInfoStyled';
import CommentList from './CommentList';
import utils from '../../../utils';
import {
  API_STATUS,
  getApiStatus,
  getApiMeta,
  getForm,
  getAuthUser,
  getUIProperty,
} from '../../../reducers';
import {
  handleFormPropertyChange,
  clearFormData,
  apiPOST,
  apiHEAD,
  addMessage,
  setUIProperty,
} from '../../../actions';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';

export const CommentView = ({
  isReply,
  comment = {},
  exhibitionId,
  displayReplyEditor = false,
  displayReplies = false,
  repliesDisabled = false,
  ready = true,
  form = { data: {} },
  replyMeta: { totalCount: replyCount = 0 } = {},
  currentUser,
  onAddReply = () => Promise.resolve({ response: { error: 'error' } }),
  onAddMessage = () => Promise.resolve(false),
  onFormPropertyChange = () => Promise.resolve(false),
  onClearForm = () => Promise.resolve(false),
  onShowReplyEditor = () => Promise.resolve(false),
  onHideReplyEditor = () => Promise.resolve(false),
  onShowReplies = () => Promise.resolve(false),
  onHideReplies = () => Promise.resolve(false),
}) => {
  const onSubmit = () =>
    onAddReply({
      body: form.data.body,
      parent: comment._id,
      exhibition: exhibitionId,
    }).then(({ response }) =>
      response.error
        ? onAddMessage({
            type: 'error',
            title: __('insert_error'),
            description: __(response.error),
          })
        : onClearForm(),
    );

  return (
    <>
      <CommentDiv style={isReply ? { marginLeft: '80px' } : {}}>
        <VerticalItems>
          <HorizontalItems style={{ margin: '10px 10px 10px 0px' }}>
            {comment.user &&
            comment.user.profile &&
            comment.user.profile.name &&
            comment.user._id ? (
              <>
                <span>
                  <Link to={`/profile/${comment.user._id}`}>
                    <Avatar alt="Avatar" src={utils.user.getUserImage(comment.user)} />
                  </Link>
                </span>
                <span>
                  <Link className="author" to={`/profile/${comment.user._id}`}>
                    {comment.user.profile.name}
                  </Link>
                </span>
              </>
            ) : (
              <>
                <span>
                  <Avatar alt="Avatar" />
                </span>
                <span>Inactive user</span>
              </>
            )}

            <span style={{ fontSize: '0.7rem' }}>
              &bull; {utils.date.fromNow(comment.createdAt)}
            </span>
          </HorizontalItems>
          <CommentText>{comment.body}</CommentText>
        </VerticalItems>
        {!repliesDisabled && currentUser && !displayReplyEditor && (
          <ReplyDiv>
            <Buttons
              onKeyPress={event => event.keyCode === 13 && onShowReplyEditor()}
              onClick={onShowReplyEditor}
              reply={1}
            >
              {__('Reply')}
            </Buttons>
          </ReplyDiv>
        )}
      </CommentDiv>

      {!repliesDisabled && currentUser && displayReplyEditor && (
        <div className="field">
          <span style={{ marginLeft: '20px', width: '100%' }}>
            <TextField
              fullWidth
              multiline
              rowsMax={4}
              type="text"
              name="body"
              placeholder={__('reply_placeholder')}
              value={form.data.body || ''}
              onChange={onFormPropertyChange}
            />
          </span>
          {(form.data.body || '').trim() && (
            <HorizontalItems style={{ marginTop: '14px' }}>
              <span style={{ marginLeft: 'auto' }}>
                <Buttons onClick={onClearForm} tag={1}>
                  {__('Cancel')}
                </Buttons>
                <Buttons
                  onClick={() => {
                    onSubmit();
                    onHideReplyEditor();
                    onShowReplies();
                  }}
                  follow={1}
                >
                  {__('Comment')}
                </Buttons>
              </span>
            </HorizontalItems>
          )}
        </div>
      )}
      {!repliesDisabled && replyCount > 0 && (
        <div>
          {!displayReplies ? (
            <InvisibleButton
              onKeyPress={event => event.keyCode === 13 && onShowReplies()}
              onClick={onShowReplies}
            >
              <HorizontalItems style={{ margin: '0' }}>
                <span>
                  <ArrowDropDownIcon />
                  <b>{`${__('Show')} ${replyCount} ${__('replies')}`}</b>
                </span>
              </HorizontalItems>
            </InvisibleButton>
          ) : (
            <InvisibleButton
              onKeyPress={event => event.keyCode === 13 && onHideReplies()}
              onClick={onHideReplies}
            >
              <HorizontalItems style={{ margin: '0' }}>
                <span>
                  <ArrowDropUpIcon />
                  <b>{`${__('Hide')} ${replyCount} ${__('replies')}`}</b>
                </span>
              </HorizontalItems>
            </InvisibleButton>
          )}
        </div>
      )}
      {!repliesDisabled && displayReplies && (
        <div className="comment-replies">
          <CommentList exhibitionId={exhibitionId} parentCommentId={comment._id} />
        </div>
      )}
    </>
  );
};

const createQuery = (exhibition, parent) => ({
  filter: parent ? { parent } : { exhibition, parent: null },
  populate: 'user',
  include: 'body',
});

const mapState = (state, { exhibitionId, comment: { _id: commentId } = {} }) => ({
  replyMeta: getApiMeta(state, 'comments', createQuery(exhibitionId, commentId)),
  currentUser: getAuthUser(state),
  form: getForm(state, `comment:${commentId}`),
  ready: getApiStatus(state, 'comments', createQuery(exhibitionId, commentId)) === API_STATUS.IDLE,
  displayReplyEditor: getUIProperty(state, `comments/${commentId}/replyEditor`),
  displayReplies: getUIProperty(state, `comments/${commentId}/replies`),
});

const mapDispatch = (dispatch, { exhibitionId, comment: { _id: commentId } = {} }) => {
  const onFetchReplies = () => dispatch(apiHEAD('comments', createQuery(exhibitionId, commentId)));
  return {
    onFetchReplies,
    onAddReply: reply =>
      dispatch(apiPOST('comments', reply, createQuery(exhibitionId, commentId))).then(() =>
        onFetchReplies(),
      ),
    onAddMessage: message => dispatch(addMessage(message)),
    onFormPropertyChange: event =>
      dispatch(handleFormPropertyChange(`comment:${commentId}`, event)),
    onClearForm: () => dispatch(clearFormData(`comment:${commentId}`)),
    onShowReplyEditor: () => dispatch(setUIProperty(`comments/${commentId}/replyEditor`, true)),
    onHideReplyEditor: () => dispatch(setUIProperty(`comments/${commentId}/replyEditor`, false)),
    onShowReplies: () => dispatch(setUIProperty(`comments/${commentId}/replies`, true)),
    onHideReplies: () => dispatch(setUIProperty(`comments/${commentId}/replies`, false)),
  };
};

const lifecycleMap = {
  onDidMount: ({ repliesDisabled, onFetchReplies }) => repliesDisabled || onFetchReplies(),
  onDidUpdate: (props, { onFetchReplies, exhibitionId, comment = {}, repliesDisabled }) =>
    (exhibitionId !== props.exhibitionId || comment._id !== (props.comment || {})._id) &&
    !repliesDisabled &&
    onFetchReplies(),
};

const Comment = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(CommentView);

export default Comment;

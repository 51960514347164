import config from 'artsteps2-config/public.json';
import {
  compose,
  pick,
  omit,
  mergeDeepLeft,
  path as pathAssoc,
  assocPath,
  memoizeWith,
} from 'ramda';
import qs from 'qs';

import {
  API_NAMESPACE,
  API_ACTION_GET_REQUEST,
  API_ACTION_GET_SUCCESS,
  API_ACTION_GET_FAILURE,
  API_ACTION_HEAD_REQUEST,
  API_ACTION_HEAD_SUCCESS,
  API_ACTION_HEAD_FAILURE,
  API_ACTION_PATCH_REQUEST,
  API_ACTION_PATCH_SUCCESS,
  API_ACTION_PATCH_FAILURE,
  API_ACTION_POST_REQUEST,
  API_ACTION_POST_SUCCESS,
  API_ACTION_POST_FAILURE,
  API_ACTION_DELETE_REQUEST,
  API_ACTION_DELETE_SUCCESS,
  API_ACTION_DELETE_FAILURE,
} from '../actions/api';

export const API_STATUS = {
  IDLE: undefined,
  GET: 'fetching',
  HEAD: 'synchronizing',
  PATCH: 'updating',
  POST: 'creating',
  DELETE: 'deleting',
};

export const API_KEYS = {
  META: config.api.spec.members.meta,
  QUERY: config.api.spec.members.query,
};

export const API_LIMIT = config.api.defaults.limit;
export const API_CACHE_TIMEOUT = config.api.cache.timeout;

const splitPath = memoizeWith(str => str, str => (str ? str.split('/') : []));

export default (state = {}, { type: nsType = '', ...payload } = {}) => {
  const [namespace, ...path] = splitPath(nsType);
  const type = path.pop();
  return namespace !== API_NAMESPACE || !path.length
    ? state
    : assocPath(path, reduceResource(pathAssoc(path, state), type, payload), state);
};

const reduceResource = (state = {}, type, { response = {}, meta = {}, query }) => {
  const paths = { meta: metaPathFromQuery(query) };
  paths.status = [...paths.meta, 'status'];
  paths.index = [...paths.meta, 'index'];

  switch (type) {
    case API_ACTION_GET_REQUEST: {
      return assocPath(paths.status, API_STATUS.GET, state);
    }
    case API_ACTION_HEAD_REQUEST: {
      return assocPath(paths.status, API_STATUS.HEAD, state);
    }
    case API_ACTION_PATCH_REQUEST: {
      return assocPath(paths.status, API_STATUS.PATCH, state);
    }
    case API_ACTION_POST_REQUEST: {
      return assocPath(paths.status, API_STATUS.POST, state);
    }
    case API_ACTION_DELETE_REQUEST: {
      return assocPath(paths.status, API_STATUS.DELETE, state);
    }
    case API_ACTION_GET_SUCCESS: {
      return compose(
        assocPath(paths.status, API_STATUS.IDLE),
        assocPath(paths.index, reduceIndex(pathAssoc(paths.index, state), response, meta, query)),
        assocPath(paths.meta, meta),
        mergeDeepLeft(response),
      )(state);
    }
    case API_ACTION_HEAD_SUCCESS: {
      return compose(
        assocPath(paths.status, API_STATUS.IDLE),
        assocPath(paths.index, pathAssoc(paths.index, state)),
        assocPath(paths.meta, meta),
      )(state);
    }
    case API_ACTION_PATCH_SUCCESS: {
      return compose(
        assocPath(paths.status, API_STATUS.IDLE),
        assocPath(paths.index, pathAssoc(paths.index, state)),
        assocPath(paths.meta, meta),
        mergeDeepLeft(response),
      )(state);
    }
    case API_ACTION_POST_SUCCESS: {
      return compose(
        assocPath(paths.status, API_STATUS.IDLE),
        assocPath(paths.index, reduceIndex(pathAssoc(paths.index, state), response, meta, query)),
        assocPath(paths.meta, meta),
        mergeDeepLeft(response._id ? { [response.slug || response._id]: response } : response),
      )(state);
    }
    case API_ACTION_DELETE_SUCCESS: {
      return undefined;
    }
    case API_ACTION_GET_FAILURE: {
      return omit(paths.meta, state);
    }
    case API_ACTION_HEAD_FAILURE: {
      return state;
    }
    case API_ACTION_PATCH_FAILURE: {
      return assocPath(paths.status, API_STATUS.IDLE, state);
    }
    case API_ACTION_POST_FAILURE: {
      return assocPath(paths.status, API_STATUS.IDLE, state);
    }
    case API_ACTION_DELETE_FAILURE: {
      return assocPath(paths.status, API_STATUS.IDLE, state);
    }
    default: {
      return state;
    }
  }
};

const reduceIndex = (state = [], response = {}, meta = {}, query = {}) => {
  if (meta.totalCount === undefined) {
    return undefined;
  }
  if (response._id) {
    return [response.slug || response._id, ...state];
  }
  const ids = Object.keys(response);
  const offset = (query.page || {}).offset || 0;
  return state
    .concat(new Array(offset))
    .slice(0, offset)
    .concat(ids);
};

const stringifyQuery = (query = {}, include = ['filter', 'sort']) =>
  qs.stringify(pick(include, query), {
    sort: (a, b) => a.localeCompare(b),
    strictNullHandling: true,
  });

const getApiObject = (state, path) => pathAssoc(splitPath(path), state);

const metaPathFromQuery = query => {
  const qstr = stringifyQuery(query);
  return [...(qstr ? [API_KEYS.QUERY, qstr] : []), API_KEYS.META];
};

export const getApiMeta = (state, path, query) =>
  getApiObject(state, `${path}/${metaPathFromQuery(query).join('/')}`) || {};

export const getApiIndex = (state, path, query) =>
  getApiMeta(state, path, query).index ||
  Object.keys(omit(Object.values(API_KEYS), getApiObject(state, path, query) || {}));

export const getApiResource = (state, path, query) => {
  const apiIndex = getApiIndex(state, path, query);
  const resource = {};
  for (let i = 0; i < apiIndex.length; i += 1) {
    const index = apiIndex[i];
    const obj = getApiObject(state, `${path}/${index}`);
    if (obj) {
      resource[index] = obj;
    }
  }
  return resource;
};

export const getApiStatus = (state, path, query) =>
  getApiMeta(state, path, query).status || API_STATUS.IDLE;

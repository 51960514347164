import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import { addMessage, apiPOST } from '../../../actions';
import { API_STATUS, getApiStatus, getFormProperty } from '../../../reducers';
import { compose, withState, withDispatch } from '../../../enhancers';
import * as footer from '../../../styles/styledComponents/footer';
import * as generic from '../../../styles/GenericStyled';
import HowToRegIcon from '@material-ui/icons/HowToReg';

export const NewsletterView = ({
  social,
  onSubscribe = () => Promise.resolve({ response: { error: true } }),
  onAddMessage = () => Promise.resolve(false),
  width,
}) => {
  const [input, setInput] = useState('');

  const onSubmit = () => {
    onSubscribe(input).then(({ response }) =>
      response.error
        ? onAddMessage({ title: __(response.error), type: 'warning' })
        : onAddMessage({
            title: __('subscription_success'),
            type: 'success',
          }).then(() => setInput('')),
    );
  };

  return (
    <footer.StyledForm
      onSubmit={event => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <generic.VerticalItems justify="flex-start" footer="true">
        <generic.Text type="h5">{width > 386 && 'Subscribe to Artsteps '}Newsletter</generic.Text>
        <footer.NewsletterWrapper>
          <footer.NewsletterInput
            placeholder="Type your email"
            type="email"
            value={input}
            onChange={e => setInput(e.target.value)}
          />
          <footer.NewsletterButton type="submit" disabled={input === ''}>
            <generic.Text mycolor="white">{__('subscribe')}</generic.Text>
            <HowToRegIcon />
          </footer.NewsletterButton>
        </footer.NewsletterWrapper>
        <generic.Text type="h5">{width > 386 && 'Find Artsteps on '}Social Media</generic.Text>
        <footer.SocialWrapper>
          {social.map((el, index) => (
            <a key={index} href={el.path} target="_blank" rel="noopener noreferrer">
              {el.icon}
            </a>
          ))}
        </footer.SocialWrapper>
      </generic.VerticalItems>
    </footer.StyledForm>
  );
};

const mapState = state => ({
  ready: getApiStatus(state, 'subscribers') !== API_STATUS.IDLE,
  termsAccepted: getFormProperty(state, 'newsletter', 'termsAccepted'),
  inputValue: getFormProperty(state, 'newsletter', 'email'),
});

const mapDispatch = dispatch => ({
  onSubscribe: mail => dispatch(apiPOST('subscribers', { mail })),
  onAddMessage: message => dispatch(addMessage(message, 'newsletter')),
});

const Newsletter = compose(withState(mapState), withDispatch(mapDispatch))(NewsletterView);

export default Newsletter;

"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createBuildingPolygons = void 0;

var _constants = _interopRequireDefault(require("./constants"));

var _tools = require("./tools");

var _math = require("../math");

var createBuildingPolygons = function createBuildingPolygons(exhibition) {
  var events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (!exhibition || !exhibition.buildingPolygons) {
    return null;
  }

  var polygons = [];
  exhibition.buildingPolygons.forEach(function (polygon, idx) {
    var vertices = (polygon.room || []).map(function (v) {
      return (0, _math.mirror)((0, _math.parseQuaternion)(v), opts.invertAxisPos);
    });
    var maxX = Math.max.apply(null, vertices.map(function (v) {
      return v[0];
    }));
    var minX = Math.min.apply(null, vertices.map(function (v) {
      return v[0];
    }));
    var maxZ = Math.max.apply(null, vertices.map(function (v) {
      return v[2];
    }));
    var minZ = Math.min.apply(null, vertices.map(function (v) {
      return v[2];
    }));
    polygons.push({
      primitive: 'a-entity',
      key: "building-floor-".concat(idx),
      'static-body': true,
      events: (0, _tools.createEvents)(events.floor, undefined, opts),
      faceset: {
        vertices: vertices.join(', ')
      },
      material: {
        shader: 'flat',
        color: (0, _tools.toRGB)(polygon.floorColor) || _constants["default"].COLOUR.FLOOR,
        src: polygon.floorTexture || '#floor-texture',
        transparent: false,
        opacity: 1,
        repeat: "".concat(maxX - minX, " ").concat(maxZ - minZ),
        side: 'double'
      },
      rotation: (0, _math.toEulerianAngle)((0, _math.mirror)([0, 0, 0, 0], opts.invertAxisRot)).join(' '),
      position: (0, _math.mirror)([0, _constants["default"].DEFAULT_WALL_WIDTH, 0], opts.invertAxisPos).join(' '),
      'ground-listener': '',
      'click-manager': {
        draggingDelay: _constants["default"].DRAGGING_DELAY
      }
    });
    polygons.push({
      primitive: 'a-entity',
      key: "building-ceiling-".concat(idx),
      'static-body': true,
      events: (0, _tools.createEvents)(events.ceiling, undefined, opts),
      faceset: {
        vertices: vertices.join(', ')
      },
      material: {
        shader: 'flat',
        color: (0, _tools.toRGB)(polygon.ceilingColor) || _constants["default"].COLOUR.CEILING,
        src: polygon.ceilingTexture || '#ceiling-texture',
        opacity: 1,
        repeat: "".concat(maxX - minX, " ").concat(maxZ - minZ),
        side: 'double'
      },
      rotation: (0, _math.toEulerianAngle)((0, _math.mirror)([0, 0, 0, 0], opts.invertAxisRot)).join(' '),
      position: (0, _math.mirror)([0, _constants["default"].DEFAULT_WALL_HEIGHT, 0], opts.invertAxisPos).join(' ')
    });
  });
  return polygons;
};

exports.createBuildingPolygons = createBuildingPolygons;
import React from 'react';

export const OverlayView = ({
  open = true,
  clear = false,
  transparent = false,
  fixed = false,
  position = 'center',
  content,
  extraClasses,
  children,
  onClose,
}) =>
  open && (
    <div className={`overlay-dialog ${clear ? 'clear' : ''} ${fixed ? 'fixed' : ''}`}>
      <div
        className={`overlay-dialog-content
        ${position || 'center'}
        ${transparent ? 'transparent' : ''}
        ${fixed ? 'fixed' : ''}
        ${extraClasses ? extraClasses : ''}`}
      >
        {content}
        {children}
        {onClose && (
          <button
            className="ui square tiny icon button secondary basic close-button"
            onClick={onClose}
          >
            <i className="icon remove" />
          </button>
        )}
      </div>
    </div>
  );

const Overlay = OverlayView;

export default Overlay;

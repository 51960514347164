import React, {useEffect, useState} from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import PSbanner from '../components/privateSpaces/PSbanner';
import { Text, StyledButton, BodyWithPadding } from '../styles/GenericStyled';
import useWindowSize from '../hooks/windowSize';
import Grid from '@material-ui/core/Grid';
import * as PrivateComponent from '../styles/PrivateSpacesPageStyled';
import { apiPOST, addMessage, setUIProperty } from '../actions';
import { compose, withState, withDispatch, withLifecycle } from '../enhancers';
import { getUIProperty } from '../reducers';
import ReactPixel from 'react-facebook-pixel';
import Box from '@material-ui/core/Box';
import { ReactComponent as Send } from '../styles/images/send-24px.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import CloseIcon from '@material-ui/icons/Close';
import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import logo from '../styles/images/logo.png';
import { withStyles } from '@material-ui/core/styles';

import image1 from '../styles/images/privateSpaces/carousel1.png';
import image3 from '../styles/images/privateSpaces/carousel2.png';
import image5 from '../styles/images/privateSpaces/carousel3.png';
import image7 from '../styles/images/privateSpaces/carousel4.png';

import image2 from '../styles/images/pages/curatorial-2.jpg';
import image4 from '../styles/images/pages/curatorial-4.jpg';
import image6 from '../styles/images/pages/curatorial-6.jpg';
import image8 from '../styles/images/pages/curatorial-8.jpg';
import ServicesContent from '../components/privateSpaces/ServicesContent';
import videoPreview from '../styles/images/privateSpaces/curation-video.mp4';

const { getNames } = require('country-list');

const licences = ['Commercial Licence', 'Academic Licence', 'Other/Not sure'];

const breakPoint = 1515;

const isDisabled = state => {
  let disabled = false;
  for (const [key, value] of Object.entries(state)) {
    if (key !== 'company' && value === '') disabled = true;
  }
  return disabled;
};

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 5px;
  display: none;
  ${props =>
    props.visible &&
    css`
      display: flex;
    `}
`;

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '300px',
    width: '357px',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

const initialState = {
  username: '',
  email: '',
  country: '',
  company: '',
  licence: '',
};

const CurationalServicePageView = props => {
  const size = useWindowSize();
  const isMobile = size.width < 600;
  const [open, setOpen] = React.useState(false);
  const [inputData, setInputData] = useState({ ...initialState });
  const [countryInput, setCountryInput] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    document.title = `Artsteps | Curation`;
  }, []);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputData({ ...initialState });
  };

  const handleSubmit = e => {
    props.onCreateContactForm({ ...inputData }).then(({ response }) => {
      if (!response.error) {
        props.onAddMessage({ title: 'Your message has been sent', type: 'success' });
      } else {
        props.onAddMessage({ title: response.error, type: 'error' });
      }
    });
    handleClose();
  };

  const handleChange = (event, option) => {
    let name;
    let value;
    if (option === null) {
      value = '';
      name = 'country';
    } else if (!option) {
      name = event.target.name ? event.target.name : event.target.getAttribute('name');
      value = event.target.value !== 0 ? event.target.value : event.target.getAttribute('value');
    } else {
      value = option;
      name = 'country';
    }
    inputData[name] = value;
    setInputData({ ...inputData });
  };

  return (
    <DefaultLayout>
      <PSbanner
        title="Curation"
        sub1="Expert advice and support for a professional presentation of the content in your VR space"
        images={[image1, image2, image3, image4, image5, image6, image7, image8]}
        button={
          <StyledButton onClick={handleOpen} background="#12AD8F">
            Request a quote
          </StyledButton>
        }
      />
      <BodyWithPadding padding="100px 5% 0% 5%">
        <ServicesContent video={videoPreview} size={size}>
          <Text type={'h4'} align="left">
            What We Offer
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            Employing our curatorial services gives your VR space a professional outlook
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Advice on the suitable VR space for the collection</Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Advice on how your items should be exhibited in your VR space
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Curatorial organization and set-up of your pictures and 3D objects
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>Guided tour scripts of your VR exhibitions</Text>
            </li>
          </ul>
          <br />
          <br />
          <Text type={'h4'} align="left">
            How It Works
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            Our team of experts reviews your collection and organizes it in your VR space as curated
            exhibitions, according to your needs. You only have to:
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>
                Submit the digital content that you want to be exhibited in your VR space
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Send a brief of the purpose of your VR space in relation to your institution or
                company
              </Text>
            </li>
            <li>
              <Text type={'sub2'}>
                Explain how you want your vision to be reflected throughout your VR exhibitions or
                stories
              </Text>
            </li>
          </ul>
          <br />
          <br />
          <Text type={'h4'} align="left">
            Chosen By
          </Text>
          <br />
          <Text type={'sub2'} align="left" style={{ fontWeight: 'bold', color: '#12AD8F' }}>
            Curation services are necessary for those who find it difficult to navigate the waters
            of virtual reality, but want their VR space to promote a more professional look
          </Text>
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              <Text type={'sub2'}>Museums</Text>
            </li>
            <li>
              <Text type={'sub2'}>Corporations</Text>
            </li>
            <li>
              <Text type={'sub2'}>Art Institutions</Text>
            </li>
            <li>
              <Text type={'sub2'}>Art & NFT Collectors</Text>
            </li>
            <li>
              <Text type={'sub2'}>Educational Organizations</Text>
            </li>
          </ul>
        </ServicesContent>
      </BodyWithPadding>

      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={4}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Grid item>
          <Text type="h5" mycolor="black" align="center">
            <b>Interested in this Service?</b>
          </Text>
        </Grid>
        <Grid item>
          <StyledButton onClick={handleOpen} background="#12AD8F">
            Request a quote
          </StyledButton>
        </Grid>
      </Grid>

      {/**/}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        style={{ overflow: 'auto' }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PrivateComponent.ModalGrid container direction="column" justify="center" spacing={2}>
            <Box component={Grid} item textAlign="center">
              <img alt="" src={logo} width="177px" height="59px" />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.CustomTypography variant="h4" modalhead="true">
                Request Details
              </PrivateComponent.CustomTypography>
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="username-input">
                Name
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="username-input"
                onChange={e => handleChange(e)}
                name="username"
                value={inputData.username}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="email-input">
                Email
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="email-input"
                onChange={handleChange}
                name="email"
                value={inputData.email}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="country-input">
                Country
              </PrivateComponent.InputsLabel>
              <PrivateComponent.CustomAutocomplete
                value={inputData.country}
                onChange={(e, value) => handleChange(e, value)}
                onInputChange={(event, newInputValue) => {
                  setCountryInput(newInputValue);
                }}
                inputValue={countryInput}
                autoComplete
                options={getNames()}
                clearOnEscape
                getOptionSelected={option => option}
                getOptionLabel={option => option}
                renderOption={option => <React.Fragment>{option}</React.Fragment>}
                renderInput={params => (
                  <PrivateComponent.CustomTextField
                    {...params}
                    placeholder="Select"
                    style={{ border: 0 }}
                    required
                  />
                )}
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="company-input">
                <PrivateComponent.CustomTypography company="true">
                  Company
                </PrivateComponent.CustomTypography>
                <PrivateComponent.CustomTypography optional="true">
                  (Optional)
                </PrivateComponent.CustomTypography>
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="company-input"
                onChange={handleChange}
                name="company"
                value={inputData.company}
              />
            </Box>
            <Box component={Grid} item textAlign="center" style={{ position: 'relative' }}>
              <PrivateComponent.InputsLabel htmlFor="licence-input">
                Licence
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="licence-input"
                aria-controls="licence-input"
                aria-haspopup="true"
                name="licence"
                value={inputData.licence}
                placeholder="Select"
                readOnly
                onClick={handleMenu}
                required
              />
              {openMenu ? <PrivateComponent.UpArrow /> : <PrivateComponent.DownArrow />}
              <StyledMenu
                id="licence-input"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                open={openMenu}
                onClose={handleCloseMenu}
              >
                {licences.map((licence, index) => (
                  <PrivateComponent.DropDownOptions
                    key={`space${index}`}
                    value={licence}
                    name="licence"
                    onClick={e => {
                      handleChange(e);
                      handleCloseMenu();
                    }}
                  >
                    {licence}
                  </PrivateComponent.DropDownOptions>
                ))}
              </StyledMenu>
            </Box>
            <Box component={CloseButton} onClick={handleClose} visible={size.width < breakPoint}>
              <CloseIcon />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.SendButton disabled={isDisabled(inputData)} onClick={handleSubmit}>
                Send your Request
                <SvgIcon>
                  <Send />
                </SvgIcon>
              </PrivateComponent.SendButton>
            </Box>
          </PrivateComponent.ModalGrid>
        </Fade>
      </Modal>
    </DefaultLayout>
  );
};

const lifecycleMap = {
  onDidMount: () => {
    ReactPixel.track('ViewContent');
  },
};

const mapState = state => ({
  requestOpen: getUIProperty('requestOpen'),
});
const mapDispatch = dispatch => ({
  onCreateContactForm: form =>
    dispatch(apiPOST('privatespaces', { ...form, requestQuoteFor: 'Curatorial Service' })),
  onAddMessage: message => dispatch(addMessage(message, 'privatespaces')),
  setOpenRequest: status => dispatch(setUIProperty('requestOpen', status)),
});

const CurationalServicePage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(CurationalServicePageView);

export default CurationalServicePage;

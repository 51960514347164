import React from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import { getUIProperty, getAuthUser, getApiStatus, API_STATUS, getApiResource } from '../reducers';
import { withState, withDispatch, compose, withLifecycle } from '../enhancers';
import { addMessage, apiGET, setLocation, setUIProperty } from '../actions';
import MaintenanceBanner from '../components/banners/maintenanceBanner';
import UverifiedNotificationModal from '../components/banners/UnverifiedNotificationModal';
import { BodyWithPadding, StyledButton, Text } from '../styles/GenericStyled';
import utils from '../utils';
import ExhibitionViewFrame from '../components/exhibitions/viewer/ExhibitionViewFrame';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CarouselWrapper from '../components/privateSpaces/Carousel';
import config from 'artsteps2-config/private.json';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import OurModal from '../components/generic/OurModal';
import MyToolTip from '../components/generic/MyToolTip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@material-ui/core/CircularProgress';

const MarketplaceViewBundlePageView = ({
  isPrivateSpace,
  templates,
  authorization,
  bundleId,
  unverifiedOpen = false,
  currentUser,
  ready,
  onUnverifiedNotificationClosed = () => Promise.resolve(false),
  onRedirect = () => Promise.resolve(false),
  onSetModal = () => Promise.resolve(false),
  onAddMessage,
  setOpenLogin,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  let bundleTemplates = templates.filter(t => t.bundle == bundleId);
  let myTemplates =
    authorization && authorization.length > 0 && authorization !== []
      ? authorization[0].templates
      : [];

  const [viewer, setViewer] = React.useState({});
  const [disableCheckbox, setDisableCheckbox] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [terms, setTerms] = React.useState(false);

  React.useEffect(() => {
    document.title = 'Artsteps | Marketplace';
  }, [document.title]);

  const [oldUser, setOldUser] = React.useState('');
  const [flagPolling, setFlagPolling] = React.useState(false);
  React.useEffect(() => {
    if (oldUser !== currentUser?._id && flagPolling) window.location.reload();
    setOldUser(currentUser?._id);
    setFlagPolling(oldFlagPolling => !oldFlagPolling);
  }, [currentUser]);

  React.useEffect(() => {
    if (bundleTemplates && bundleTemplates.length > 0 && Object.keys(viewer).length === 0) {
      changeViewer(bundleTemplates.sort((a, b) => b.size.localeCompare(a.size)).slice(-1)[0]);
    }
  }, [bundleTemplates, viewer]);

  React.useEffect(() => {
    if (ready === true) {
      if (templates?.length > 0) {
        if (bundleTemplates?.length === 0) {
          onRedirect('/marketplace');
        }
      }
    }
  }, [bundleTemplates, onRedirect, ready, templates.length]);

  const changeViewer = async v => {
    /*await setDisableCheckbox(true);*/
    await setViewer(v);
    /*setTimeout(() => {
      setDisableCheckbox(false);
    }, 3000);*/
  };

  function checkoutClosed(data) {
    return onAddMessage({ title: 'Your purchase has been cancelled', type: 'error' });
  }

  function checkoutComplete(data) {
    if (data?.checkout?.completed === false) return;
    onSetModal(true);
    onAddMessage({
      title:
        'Your purchase is now complete! Find your new template on your profile, under the My Templates section, and create something new',
        /*'Your purchase has been completed, your template is now available in your library for use in as many exhibitions as you want',*/
      type: 'success',
    });
    onRedirect('/');
  }

  const handleBuy = () => {
    if (!currentUser) {
      onAddMessage({ title: 'You need to be logged in to buy this template', type: 'error' });
      onRedirect('/marketplace');
      setOpenLogin(true);
      return;
    }
    if (myTemplates?.includes(viewer?._id)) {
      onAddMessage({ title: 'You already own this template', type: 'error' });
      return;
    }
    let Paddle = window.Paddle;
    Paddle.Setup({
      vendor: config.accounts.services.paddle.vendorId,
      debug: config.accounts.services.paddle.debug,
    });
    if (config.accounts.services.paddle.sandbox === true) {
      Paddle.Environment.set('sandbox');
    }
    Paddle.Checkout.open({
      product: viewer.paddleProductId,
      passthrough: JSON.stringify({ user: currentUser._id, template: viewer._id }),
      allowQuantity: false,
      disableLogout: true,
      successCallback: checkoutComplete,
      closeCallback: checkoutClosed,
    });
  };

  if (bundleTemplates?.length === 0 || Object.keys(viewer).length === 0) return <></>;

  return (
    <DefaultLayout>
      {process.env.REACT_APP_SHOW_BANNER === 'TRUE' && <MaintenanceBanner />}
      <BodyWithPadding padding="0px">
        <br />
        <Grid container justify="space-evenly">
          {Object.keys(viewer).length !== 0 && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div style={{ margin: isSm ? 'initial' : '20px' }}>
                <ExhibitionViewFrame
                  exhibitionId={viewer.exhibitionDemo}
                  webVR={utils.navigator.isIE()}
                  unsupportedAgent={utils.navigator.isIE()}
                  unsupportedDevice={utils.navigator.isMobile()}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ position: 'relative' }}>
            {!isSm &&
              <div style={{ position: 'absolute', top: '16px', right: '16px' }}>
                <StyledButton
                  mycolor="#12AD8F"
                  type="secondary"
                  background="transparent"
                  onClick={() => onRedirect(`/marketplace`)}
                >
                  <ArrowBackIosIcon />
                  <Text style={{ fontSize: '18px', marginLeft: '10px' }}>Back to marketplace</Text>
                </StyledButton>
              </div>
            }
            <div style={{ padding: '10px', marginTop: '10px' }}>
              <Text style={{ fontWeight: 600, fontSize: '28px' }}>{viewer?.title}</Text>
              <Text style={{ fontSize: '14px' }}>Style: {viewer?.style}</Text>
              <Text style={{ fontWeight: 600, fontSize: '20px', color: 'black' }}>Sizes:</Text>
              <Divider style={{ height: '3px', color: 'gray' }} />
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {bundleTemplates
                  ?.sort((a, b) => b.size.localeCompare(a.size))
                  .map((t, i) => (
                    <Grid
                      item
                      key={t._id}
                      style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        border: viewer?._id === t?._id ? '1px solid #12AD8F' : 'initial',
                        borderRadius: '5px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{ position: 'absolute', top: '7px', left: '7px', cursor: 'pointer' }}
                        onClick={() => onRedirect(`/marketplace/${bundleTemplates[0]?.bundle}`)}
                      >
                        {viewer?._id !== t?._id && disableCheckbox && <CircularProgress size={25}/>}
                      </div>
                      <FormControlLabel
                        key={t._id}
                        control={
                          <Checkbox
                            checked={viewer?._id === t?._id}
                            onChange={e => changeViewer(t)}
                            name={t._id}
                            style={{
                              color:
                                viewer?._id === t?._id
                                  ? '#12AD8F'
                                  : disableCheckbox
                                  ? 'gray'
                                  : '#12AD8F',
                            }}
                            disabled={disableCheckbox}
                          />
                        }
                        label={
                          <Text
                            mycolor={
                              viewer?._id === t?._id
                                ? '#12AD8F'
                                : disableCheckbox
                                ? 'gray'
                                : 'black'
                            }
                            style={{
                              fontSize: '16px',
                              fontWeight: viewer?._id === t?._id ? 'bold' : 'initial',
                            }}
                          >
                            {t.size}:{'  '}
                            <small style={{ fontSize: '16px' }}>{t.price}€ {t.pricePreSale && <del>{t?.pricePreSale}€</del>}</small>
                          </Text>
                        }
                      />
                      <ul style={{ color: 'black' }}>
                        {t?.details?.floorArea && <li>{t.details.floorArea}m² area</li>}
                        {t?.details?.wallLength && <li>{t.details.wallLength}m wall length</li>}
                        {t?.details?.wallHeight && <li>{t.details.wallHeight}m wall height</li>}
                        {t?.details?.suggestedArtifactCapacity && (
                          <li>
                            {t.details.suggestedArtifactCapacity} artifacts
                            {t?.details?.artifactCapacity && ` (max ${t.details.artifactCapacity})`}
                          </li>
                        )}
                      </ul>
                    </Grid>
                  ))}
                <Grid item xs>
                  {myTemplates?.includes(viewer?._id) && currentUser ? (
                    <div style={{ marginTop: '40px', marginBottom: '40px', textAlign: 'center' }}>
                      <StyledButton
                        background="#12AD8F"
                        type="secondary"
                        onClick={() => onRedirect(currentUser ? '/curate/new/1' : '')}
                        minwidth="220px"
                      >
                        <Text style={{ fontSize: '16px', marginLeft: '10px' }}>
                          Already Acquired
                        </Text>
                      </StyledButton>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#12ad8f' }}>
                        Total: {viewer?.price}€{' '}{viewer.pricePreSale && <del>{viewer?.pricePreSale}€</del>}
                      </Text>
                      <br />
                      <StyledButton background="#FE625B" type="secondary" onClick={()=>setPopup(true)}>
                        <ShoppingCartOutlinedIcon />{' '}
                        <Text style={{ fontSize: '18px', marginLeft: '10px' }}>Buy</Text>
                      </StyledButton>
                      <br />
                      <br />
                      <Text style={{ fontSize: '14px', color: 'gray' }}>
                        Taxes/VAT calculated at checkout
                      </Text>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Divider style={{ height: '3px', color: 'gray' }} />
              <div>
                <Text
                  style={{
                    fontSize: '14px',
                    marginBottom: '40px',
                    marginTop: '40px',
                    width: '90%',
                  }}
                >
                  {viewer?.description}
                </Text>
              </div>
              <div style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
                <CarouselWrapper images={viewer.photoGallery} small={true} />
              </div>
              {isSm &&
                <div style={{ width: '100%', textAlign: 'center', marginTop: '30px' }}>
                  <StyledButton
                    background="#12AD8F"
                    type="secondary"
                    onClick={() => onRedirect(`/marketplace`)}
                  >
                    <ArrowBackIosIcon />
                    <Text style={{ fontSize: '16px', marginLeft: '10px' }}>
                      Back to marketplace
                    </Text>
                  </StyledButton>
                </div>
              }
            </div>
          </Grid>
        </Grid>
      </BodyWithPadding>

      <OurModal
        width="330px"
        height="440px"
        openModal={popup}
        setOpenModal={() => {
          setPopup(false);
          setTerms(false);
        }}
      >
        <div style={{ textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
          <Text style={{ textAlign: 'left', fontSize: '24px', fontWeight: 'bold' }}>
            Buying on Artsteps
          </Text>
          <br />
          <Text style={{ fontSize: '16px' }}>{config.accounts.services.paddle.termsPopupText}</Text>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={terms}
                onChange={() => setTerms(t => !t)}
                name="terms"
                style={{ color: '#F26767' }}
              />
            }
            label={
              <Text style={{ fontSize: '14px', textAlign: 'center' }}>
                I have read and accept the above terms and the{' '}
                <a href="/article/terms-of-service" target="_blank" rel="noopener noreferrer">
                  Artsteps Terms & Conditions
                </a>
              </Text>
            }
          />
          <br />
          <StyledButton
            background="#F26767"
            type="secondary"
            onClick={() => {
              handleBuy(viewer);
              setPopup(false);
              setTerms(false);
            }}
            style={{ marginBottom: '10px', marginTop: '10px' }}
            disabled={!terms}
          >
            <ShoppingCartOutlinedIcon />{' '}
            <Text style={{ fontSize: '18px', marginLeft: '10px' }}>Buy</Text>
          </StyledButton>
        </div>
      </OurModal>

      <UverifiedNotificationModal
        open={
          !!(
            unverifiedOpen &&
            currentUser &&
            currentUser.services.password &&
            !currentUser.emails[0].verified
          )
        }
        onClose={onUnverifiedNotificationClosed}
      />
    </DefaultLayout>
  );
};

const mapState = state => ({
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  templates: Object.values(getApiResource(state, 'templates/marketplace')),
  authorization: Object.values(getApiResource(state, 'templates/authorization')),
  unverifiedOpen: getUIProperty(state, 'unverifiedNotificationOpen'),
  currentUser: getAuthUser(state),
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  ready: getApiStatus(state, 'templates/marketplace') === API_STATUS.IDLE,
});

const mapDispatch = dispatch => ({
  fetchTemplates: () => dispatch(apiGET('templates/marketplace')),
  fetchAuthorization: () => dispatch(apiGET('templates/authorization')),
  onUnverifiedNotificationClosed: () =>
    dispatch(setUIProperty('unverifiedNotificationOpen', false)),
  onRedirect: location => dispatch(setLocation(location)),
  onAddMessage: message => dispatch(addMessage(message, 'marketplace')),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  onSetModal: bool => dispatch(setUIProperty('marketplaceModalOpen', bool)),
});

const lifecycleMap = {
  onDidMount: ({ fetchTemplates, fetchAuthorization }) => {
    fetchTemplates();
    fetchAuthorization();
  },
};

const MarketplaceViewBundlePage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(MarketplaceViewBundlePageView);

export default MarketplaceViewBundlePage;

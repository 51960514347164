import React, { useRef, useEffect } from 'react';
import utils from '../../../utils';
import YoutubeFrame from '../../generic/YoutubeFrame';

const VideoPreview = ({ uri, onPreviewLoad, uploadOption }) => {
  const previewRef = useRef();

  useEffect(() => {
    if (previewRef && previewRef.current) {
      previewRef.current.src = utils.media.createVideoStream(uri);
      previewRef.current.oncanplay = ({ target: element }) =>
        setTimeout(() => onPreviewLoad(element), 2048);
    }
  }, [previewRef, onPreviewLoad, uri]);

  return (
    <>
      {(uploadOption === 'file' || uploadOption === 'url' || uploadOption === 'streamable') && (
        <video
          ref={previewRef}
          controls
          loop
          style={{ width: '100%', maxWidth: '100%', margin: 'auto' }}
          crossOrigin="anonymous"
          type="video/mp4"
        />
      )}
      {uploadOption === 'youtube' && (
        <YoutubeFrame
          showTopBar={false}
          src={uri}
          onPreviewLoad={onPreviewLoad}
          width="100%"
          height="225px"
        />
      )}
    </>
  );
};

export default VideoPreview;

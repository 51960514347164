import React from 'react';
import ReactMetaTags from 'react-meta-tags';
import { __ } from 'artsteps2-common';
import { withLifecycle } from '../../enhancers';
import logo from '../../styles/images/logo.png';

const MetaTagsView = ({ title, description, image }) => (
  <ReactMetaTags>
    <title>{`${__('brand_name')}${title && ` | ${title}`}`}</title>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image || logo} />
  </ReactMetaTags>
);

const emitDOMMetaLoaded = () => window.dispatchEvent(new Event('DOMMetaLoaded'));

const lifecycleMap = {
  onDidMount: ({ title }) => title && emitDOMMetaLoaded(),
  onDidUpdate: ({ title: prevTitle }, { title }) => title && !prevTitle && emitDOMMetaLoaded(),
};

const MetaTags = withLifecycle(lifecycleMap)(MetaTagsView);

export default MetaTags;

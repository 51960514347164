import Popup from 'reactjs-popup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateIcon from '@material-ui/icons/Create';
import React from 'react';
import { __ } from 'artsteps2-common';
import Link from '../../generic/Link';
import { SettingsDots, ExhibitionMenu, ExhibitionMenuItem } from './ExhibitionStyled';

import { compose, withState, withDispatch } from '../../../enhancers';
import { addMessage, setUIProperty } from '../../../actions';
import { API_STATUS, getAuthUser, getApiStatus, getAuthToken } from '../../../reducers';

export const ExhibitionSettingsView = ({
  exhibition = {},
  curatePage = '/curate',
  showLikes,
  onOpenDialog = () => Promise.resolve(false),
  onDeleteExhibition,
  onAddMessage,
}) => {
  return (
    <SettingsDots>
      <Popup
        trigger={<MoreVertIcon aria-controls="simple-menu" aria-haspopup="true" />}
        position="left top"
        on="hover"
        closeOnDocumentClick
        mouseLeaveDelay={200}
        mouseEnterDelay={0}
        contentStyle={{ padding: '0px', width: '100px', border: 'none' }}
        arrow={false}
      >
        <ExhibitionMenu>
          <Link to={`${curatePage}/${exhibition._id}/1`}>
            <ExhibitionMenuItem>
              <CreateIcon />
              <span>{__('edit')}</span>
            </ExhibitionMenuItem>
          </Link>
          <ExhibitionMenuItem onClick={() => onOpenDialog()}>
            <DeleteForeverIcon />
            <span> {__('delete')}</span>
          </ExhibitionMenuItem>
        </ExhibitionMenu>
      </Popup>
    </SettingsDots>
  );
};
const mapState = (state, { exhibition = {}, userId }) => ({
  currentUser: getAuthUser(state),
  ready: getApiStatus(state, `exhibitions/${exhibition._id}`) === API_STATUS.IDLE,
  token: getAuthToken(state),
});
const mapDispatch = (dispatch, { exhibition = {} }) => ({
  onOpenDialog: () => dispatch(setUIProperty(`exhibitions/${exhibition._id}/dialog`, true)),
  onAddMessage: message => dispatch(addMessage(message)),
});

const ExhibitionSettings = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionSettingsView);
export default ExhibitionSettings;

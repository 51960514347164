import React from 'react';
import { __ } from 'artsteps2-common';

const CHROME_URI = 'https://www.google.com/chrome/';
const CHROME_ICON_URI =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/512px-Google_Chrome_icon_%28September_2014%29.svg.png';
const FIREFOX_URI = 'https://www.mozilla.org/en-US/firefox/new/';
const FIREFOX_ICON_URI = 'https://blog.mozilla.org/firefox/files/2017/12/firefox-logo-300x310.png';

const BrowserIconsSectionView = () => (
  <div className="agenticons">
    <a target="_blank" rel="noopener noreferrer" href={FIREFOX_URI}>
      <img className="logo-img-firefox" alt="" src={FIREFOX_ICON_URI} />
      <span>{__('download_firefox')}</span>
    </a>
    <a target="_blank" rel="noopener noreferrer" href={CHROME_URI}>
      <img className="logo-img-chrome" alt="" src={CHROME_ICON_URI} />
      <span>{__('download_chrome')}</span>
    </a>
  </div>
);

const BrowserIconsSection = BrowserIconsSectionView;

export default BrowserIconsSection;

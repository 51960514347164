import { mergeDeepRight, assocPath, path, memoizeWith } from 'ramda';
import { FORMS_NAMESPACE, FORMS_ACTION_SET_DATA, FORMS_ACTION_CLEAR_DATA } from '../actions/forms';

const splitPath = memoizeWith(str => str, str => (str ? str.split('/') : []));
const splitProp = memoizeWith(str => str, str => (str ? str.split('.') : []));

export default (state = {}, { type: nsType = '', ...payload }) => {
  const [namespace, form, type] = splitPath(nsType);
  if (namespace !== FORMS_NAMESPACE || !form) {
    return state;
  }
  return {
    ...state,
    [form]: reduce(state[form], type, { ...payload, name: form }),
  };
};

const initialState = {
  name: undefined,
  action: undefined,
  method: undefined,
  enctype: 'application/json',
  data: {},
};

const reduce = (state = initialState, type, { data = {}, name, method, enctype } = {}) => {
  switch (type) {
    case FORMS_ACTION_SET_DATA: {
      return {
        ...state,
        name: name || state.name,
        method: method || state.method,
        enctype: enctype || state.enctype,
        data: mergeDeepRight(state.data, expand(data)),
      };
    }
    case FORMS_ACTION_CLEAR_DATA: {
      return {
        ...state,
        name: name || state.name,
        method: method || state.method,
        enctype: enctype || state.enctype,
        data: {},
      };
    }
    default: {
      return state;
    }
  }
};

const expand = (obj = {}) =>
  Object.keys(obj).reduce(
    (expandedObj, property) => assocPath(splitProp(property), obj[property], expandedObj),
    {},
  );

export const getForm = (state, form) => state[form] || { ...initialState, name: form };

export const getFormProperty = (state, form, property) =>
  path(splitProp(property), getForm(state, form).data);

const COLORS = {
  ourWhite: '#FFFFFF',
  ourBlack: '#343A40',
  ourRed: '#F26767',
  ourGray: '#F3F3F3',
  ourGreen: '#12AD8F',
  ourBlue: '#2D6493',
  ourOrange: '#FE625B',
  ourCyan: '#4FBCDA',
};

export default COLORS;
